<h1 class="form-header-below-link">Underwriting</h1>
<p>Please check all that apply.</p>

<div
  class="js-wc-executive-exclusion-question"
  *ngIf="underwritingFormGroup.controls['EMPCO-2000'].enabled"
>
  <h4 class="question-subsection-header">Officers Exclusion</h4>
  <app-form-field-checkbox
    inputId="quote-question-{{ getExecutiveExclusionQuestion().code }}"
    labelText="{{ getExecutiveExclusionQuestion().question }}"
    nameOfFormControl="{{ getExecutiveExclusionQuestion().code }}"
    [submitted]="submitted"
    [form]="underwritingFormGroup"
  >
  </app-form-field-checkbox>
</div>

<div class="js-wc-class-specific-questions" *ngIf="hasClassSpecificQuestions()">
  <h4 class="question-subsection-header">Class specific</h4>
  <ng-container *ngFor="let question of getClassSpecificQuestions()">
    <app-form-field-checkbox
      inputId="quote-question-{{ question.code }}"
      labelText="{{ question.question }}"
      sublabelText="{{ question.sublabelText }}"
      nameOfFormControl="{{ question.code }}"
      [form]="underwritingFormGroup"
    >
    </app-form-field-checkbox>
    <ul *ngIf="question.subpoints" class="bulleted-list">
      <li *ngFor="let subpoint of question.subpoints">
        {{ subpoint }}
      </li>
    </ul>

    <ng-container
      *ngIf="question.code === 'EMPCO-3009' && underwritingFormGroup.value['EMPCO-3009'] === true"
    >
      <div class="form-subsection form-subsection__no-margin">
        <app-form-field-checkbox
          inputId="quote-question-EMPCO-3109"
          labelText="The applicant exclusively uses a 3rd party for ALL delivery services (i.e. GrubHub, Uber Eats, etc)?"
          nameOfFormControl="EMPCO-3109"
          [form]="underwritingFormGroup"
        >
        </app-form-field-checkbox>

        <ng-container *ngIf="underwritingFormGroup.value['EMPCO-3109'] === false">
          <app-form-field-checkbox
            inputId="quote-question-EMPCO-3209"
            labelText="Does the applicant:"
            nameOfFormControl="EMPCO-3209"
            [form]="underwritingFormGroup"
          >
          </app-form-field-checkbox>

          <ul class="bulleted-list">
            <li>Use motorcycles, scooters or bicycles</li>
            <li>Make deliveries past 11:00 PM</li>
            <li>Have delivery that exceeds a 10 mile radius</li>
            <li>Have delivery receipts that exceed 60% of total sales</li>
            <li>Have drivers under 18 years of age</li>
            <li>NOT have a distracted driving policy in place</li>
          </ul>
        </ng-container>
      </div>
    </ng-container>
    <ng-container
      *ngIf="question.code === 'EMPCO-3018' && underwritingFormGroup.value['EMPCO-3018'] === false"
    >
      <div class="form-subsection form-subsection__no-margin">
        <app-form-field-checkbox
          inputId="quote-question-EMPCO-3118"
          labelText="Does the Applicant perform any residential cleaning?"
          nameOfFormControl="EMPCO-3118"
          [form]="underwritingFormGroup"
        >
        </app-form-field-checkbox>

        <ng-container *ngIf="underwritingFormGroup.value['EMPCO-3118'] === true">
          <app-form-field-checkbox
            inputId="quote-question-EMPCO-3218"
            labelText="Is the Applicant affiliated with a franchise (e.g. Merry Maids)?"
            nameOfFormControl="EMPCO-3218"
            [form]="underwritingFormGroup"
          >
          </app-form-field-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<h4 class="question-subsection-header">General</h4>
<app-form-field-checkbox
  inputId="quote-question-WORK06"
  labelText="The applicant engages sub-contractors or independent contractors"
  nameOfFormControl="WORK06"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>
<div class="form-subsection" *ngIf="underwritingFormGroup.value.WORK06">
  <app-form-field-checkbox
    inputId="quote-question-BOP40"
    cssClass="form-field__no-margin"
    labelText="Certificates of Insurance are obtained when sub-contractors or independent contractors are used"
    nameOfFormControl="BOP40"
    [form]="underwritingFormGroup"
  >
  </app-form-field-checkbox>
</div>
<app-form-field-checkbox
  inputId="quote-question-WORK11"
  labelText="The applicant uses motor vehicles for business purposes"
  nameOfFormControl="WORK11"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>
<div class="form-subsection" *ngIf="underwritingFormGroup.value.WORK11">
  <app-form-field-checkbox
    inputId="quote-question-WORK11RecReview"
    labelText="All drivers' Motor Vehicle Records are reviewed upon hire and annually"
    nameOfFormControl="WORK11RecReview"
    [form]="underwritingFormGroup"
  >
  </app-form-field-checkbox>
  <app-form-field-checkbox
    inputId="quote-question-WORK11FiveEmp"
    cssClass="form-field__no-margin"
    labelText="The applicant provides group transportation when five or more employees are in a vehicle at one time"
    nameOfFormControl="WORK11FiveEmp"
    [form]="underwritingFormGroup"
  >
  </app-form-field-checkbox>
</div>
<app-form-field-checkbox
  inputId="quote-question-EMPCO-1007"
  labelText="Any prior coverage declined/cancelled/non-renewed (other than for pandemic, risk class of business or carrier no longer writing) in the last three (3) years?"
  nameOfFormControl="EMPCO-1007"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>
<app-form-field-checkbox
  inputId="quote-question-WORK08"
  labelText="The applicant provides employee health plans"
  nameOfFormControl="WORK08"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>
<app-form-field-checkbox
  inputId="quote-question-CGL04"
  labelText="The applicant leases employees from other employers"
  nameOfFormControl="CGL04"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>
<app-form-field-checkbox
  inputId="quote-question-WORK07"
  labelText="The applicant owns, operates, or leases aircraft/watercraft"
  nameOfFormControl="WORK07"
  [form]="underwritingFormGroup"
>
</app-form-field-checkbox>

<div class="js-wc-state-org-type-specific-questions" *ngIf="hasStateOrgTypeSpecificQuestions()">
  <h4 class="question-subsection-header">Listed Partners</h4>

  <!-- Partners -->
  <div
    id="partners-{{ i }}"
    class="multi-form-item"
    *ngFor="let partner of getPartnersControls(); let i = index"
  >
    <div class="form-split">
      <app-form-field-text
        inputId="partner-{{ i }}-firstName"
        [form]="partner"
        [submitted]="submitted"
        labelText="First name"
        nameOfFormControl="firstName"
        showErrorText="true"
        errorText="This is a required field"
        maxLength="60"
      >
      </app-form-field-text>

      <app-form-field-text
        inputId="partner-{{ i }}-lastName"
        [form]="partner"
        [submitted]="submitted"
        labelText="Last name"
        nameOfFormControl="lastName"
        showErrorText="true"
        errorText="This is a required field"
        maxLength="60"
      >
      </app-form-field-text>
    </div>
  </div>
</div>
