<div *ngIf="commissionStatement" class="commission-statement-container" style="margin: 0 3em">
  <img src="assets/img/attune-log-outdated-light-horizontal.svg" alt="" height="117" width="340" />
  <h4>Producer Name: {{ commissionStatement.producerName }}</h4>
  <h4>Statement Date: {{ commissionStatement.statementDate | date }}</h4>
  <table class="table">
    <thead>
      <tr>
        <th>Policy Number</th>
        <th>Insured</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>Commissionable Premium</th>
        <th>Non-commissionable Premium</th>
        <th>Collected by EMPLOYERS</th>
        <th>Commission</th>
        <th>Product</th>
        <th>Sub-producer Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let policy of commissionStatement.policies">
        <td>{{ policy.policyNumber }}</td>
        <td>{{ policy.insuredName }}</td>
        <td>{{ policy.effectiveDate | date: 'yyyy-MM-dd' }}</td>
        <td>{{ policy.expiryDate | date: 'yyyy-MM-dd' }}</td>
        <td>{{ policy.commissionPremium | currency: 'USD':'symbol':'1.2-2' }}</td>
        <td>{{ policy.nonCommissionPremium | currency: 'USD':'symbol':'1.2-2' }}</td>
        <td>{{ policy.collectedByEmployers | currency: 'USD':'symbol':'1.2-2' }}</td>
        <td>{{ policy.earnedCommission | currency: 'USD':'symbol':'1.2-2' }}</td>
        <td>{{ policy.productType }}</td>
        <td>{{ policy.subProducerName }}</td>
      </tr>
      <tr>
        <td colspan="7">
          <p><strong>Total</strong></p>
        </td>
        <td colspan="3">
          {{ totalPremium() | currency: 'USD':'symbol':'1.2-2' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
