import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, reduce, find } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import { NaicsService } from 'app/shared/services/naics.service';
import {
  ProductCombination,
  QuoteResponse,
  FormDSLFormData,
  FormDSLQuoteRequest,
  ProductAvailability,
  QuoteErrorResponse,
  isQuoteErrorResponse,
} from 'app/features/digital-carrier/models/types';
import {
  bopControlPath,
  COB_CODE_EXCEPTIONS_BY_STATE,
} from 'app/features/liberty-mutual/models/lm-bop-constants';
import {
  formatFormFieldValuesBop,
  formatFormDataForDisplayBop,
} from 'app/features/liberty-mutual/models/form-helpers';
import { LibertyMutualBopQuoteFormService } from 'app/features/liberty-mutual/services/liberty-mutual-bop-quote-form.service';
import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import {
  LmButtonFunctionName,
  EARLY_DECLINE_REASONS,
} from 'app/features/liberty-mutual/models/common-typings';
import { verifyBopQuestionsAreInFlow } from 'app/features/liberty-mutual/models/debug-helpers';
import {
  LmBopQuestion,
  LmBopComplexValidators,
  LmBopValidators,
  LmBopArrayValidators,
  LmBopFormStepPath,
} from 'app/features/liberty-mutual/models/lm-bop-typings';
import {
  LM_BOP_VALIDATORS,
  LM_BOP_FORM_ARRAY_VALIDATORS,
  LM_BOP_COMPLEX_VALIDATORS,
} from 'app/features/liberty-mutual/models/lm-bop-validators';
import { FormDslData, TextAreaNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { SentryService } from 'app/core/services/sentry.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  UNKNOWN_ERROR_WITHOUT_RETRY,
  LIBERTY_MUTUAL_DECLINED_ERROR_BOP,
  LIBERTY_MUTUAL_CLASS_CODES_ERROR,
} from 'app/shared/quote-error-modal/errors';
import { getControl } from 'app/shared/helpers/form-helpers';
import { retryWithBackoff } from '../../../../shared/helpers/api-helpers';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';

// For reference, use the FormDslMultiStepTestFormComponent (and its test data)
@Component({
  selector: 'app-liberty-mutual-bop-form',
  templateUrl: './liberty-mutual-bop-form.component.html',
  providers: [LibertyMutualBopQuoteFormService],
})
export class LibertyMutualBopFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit, OnDestroy
{
  accountId: string;
  quoteId: string;
  insuredAccount: InsuredAccount;
  insuredAccount$: Subject<InsuredAccount> = new Subject();
  isSubmittingForm = false;
  isEditing = false;
  // Casting is necessary here so compiler does not think some values are 'undefined'
  formValidators = LM_BOP_VALIDATORS as LmBopValidators;
  formArrayValidators = LM_BOP_FORM_ARRAY_VALIDATORS as LmBopArrayValidators;
  complexFormValidators = LM_BOP_COMPLEX_VALIDATORS as LmBopComplexValidators;

  quoteError: QuoteErrorResponse | null = null;
  earlyDeclineErrors: EARLY_DECLINE_REASONS[] = [];
  quoteErrorModalOpen = false;
  quoteEarlyDeclinedModalOpen = false;
  quoteClassCodesErrorModalOpen = false;

  libertyMutualOutages: Observable<boolean | null>;

  UNKNOWN_ERROR_WITHOUT_RETRY = UNKNOWN_ERROR_WITHOUT_RETRY;
  LIBERTY_MUTUAL_DECLINED_ERROR = LIBERTY_MUTUAL_DECLINED_ERROR_BOP;
  LIBERTY_MUTUAL_CLASS_CODES_ERROR = LIBERTY_MUTUAL_CLASS_CODES_ERROR;

  // Product combination for LM BOP
  digitalCarrierProduct: ProductCombination = {
    pasSource: 'liberty_mutual',
    product: 'bop',
  };

  constructor(
    public formService: LibertyMutualBopQuoteFormService,
    private route: ActivatedRoute,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private insuredAccountService: InsuredAccountService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private featureFlagService: FeatureFlagService,
    private naicsService: NaicsService,
    private router: Router
  ) {
    super(formService);
  }

  ngOnInit() {
    if (this.isDevMode) {
      // Log a warning if there are any questions that are not included in the quote flow.
      verifyBopQuestionsAreInFlow();
    }
    // Retrieve form configuration for LM
    const formConfiguration = this.formService.generateFormSteps(false, []);
    // When the form steps are first generated, the class codes dropdown is set
    // to a loading state until the Product Availability is loaded. 👆

    // Set account id based on quote route
    this.accountId = this.route.snapshot.params['accountId'];

    // Set quote id based on quote route
    this.quoteId = this.route.snapshot.params['quoteId'];

    // Product combination for LM BOP
    const digitalCarrierProduct = this.digitalCarrierProduct;

    // Check Liberty Mutual outages feature flags
    this.libertyMutualOutages = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.LIBERTY_MUTUAL_OUTAGES
    );

    // Extract final string in route path and determine
    // whether this is a new or an edit quote flow
    const formActionIndex = this.route.snapshot.url.length - 1;
    const formAction = this.route.snapshot.url[formActionIndex].path;
    this.isEditing = formAction === 'edit';

    if (this.isEditing) {
      this.digitalCarrierService
        .getQuoteSubmission(digitalCarrierProduct, this.quoteId)
        .subscribe((submission: FormDSLQuoteRequest) => {
          const oldFormData = submission.formData;
          // Format data for display
          const oldFormDataDisplay = formatFormDataForDisplayBop(oldFormData);
          // Start form-dsl component
          super.ngOnInit();
          // Submit form configuration and data for form-dsl component
          this.setFormConfig(formConfiguration);
          this.setFormData(oldFormDataDisplay as FormDslData);
          // Retrieve and set insured account info
          this.setAccountInfo();
          // Sets values for class code dropdown based on Product Availability
          this.setClassCodeDropdown();
        });
    } else {
      super.ngOnInit();
      // Submit form configuration and data for form-dsl component
      this.setFormConfig(formConfiguration);
      // Retrieve and set insured account info
      this.setAccountInfo();
      // Sets values for class code dropdown based on Product Availability
      this.setClassCodeDropdown();
    }
  }

  // Retrieve relevant account and populate form with dependent data
  setAccountInfo() {
    const MAX_TRIES = 4;
    const RETRY_DELAY = 2000;
    // Fill in information within the quote flow based on account details
    this.sub.add(
      this.insuredAccount$
        .pipe(
          tap((account) => {
            this.insuredAccount = account;
            const { description, state } = account;
            const baseState = getControl(
              this.formService.form,
              bopControlPath(LmBopQuestion.PRIMARY_RISK_STATE)
            );
            const descriptionControl = getControl(
              this.formService.form,
              bopControlPath(LmBopQuestion.DESCRIPTION_OF_OPERATIONS)
            );
            if (!descriptionControl.value) {
              const descriptionNode = this.formService.getNodeFunc(
                LmBopQuestion.DESCRIPTION_OF_OPERATIONS
              ) as TextAreaNode;
              if (descriptionNode.maxLength) {
                descriptionControl.patchValue(
                  description.slice(0, parseInt(descriptionNode.maxLength, 10))
                );
              } else {
                descriptionControl.patchValue(description);
              }
            }
            if (!baseState.value) {
              baseState.patchValue(state);
            }
          })
        )
        .subscribe()
    );

    // Set insured account information
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          retryWithBackoff(MAX_TRIES, RETRY_DELAY),
          filter(
            (insuredAccount: InsuredAccount) => String(this.accountId) === String(insuredAccount.id)
          ),
          tap((insuredAccount) => this.insuredAccount$.next(insuredAccount))
        )
        .subscribe()
    );
  }

  setClassCodeDropdown() {
    // Sets the class codes dropdown, `LmBopQuestion.BOP_CLASS_CODE`, if the
    // broker should only see a subset, i.e a `PARTIAL` selection, or all of
    // them, i.e. an `ALL` selection.
    // When calling getProductAvailability, passing in true,
    // to denote where the call is coming from, e.g. isQuoteFlow = true
    this.naicsService.getProductAvailability(true).subscribe(
      (productAvailabilities) => {
        const productAvailability = find(
          productAvailabilities,
          this.digitalCarrierProduct
        ) as ProductAvailability;
        if (productAvailability && productAvailability.classCodeSelection === 'PARTIAL') {
          const formConfiguration = this.formService.generateFormSteps(
            true,
            productAvailability.classCodes.carrier
          );

          this.setFormConfig(formConfiguration);
        } else if (productAvailability && productAvailability.classCodeSelection === 'ALL') {
          const formConfiguration = this.formService.generateFormSteps(true);
          this.setFormConfig(formConfiguration);
        } else {
          // unexpected case, but handling
          this.sentryService.notify(
            'Liberty Mutual BOP: product availability is NONE. Quote flow is broken because user will not be able to select class code.',
            {
              severity: 'error',
              metaData: {
                productAvailabilities,
              },
            }
          );

          this.quoteClassCodesErrorModalOpen = true;
        }
      },
      (error) => {
        this.sentryService.notify(
          'Liberty Mutual BOP: product availability not found. Quote flow is broken because user will not be able to select class code.',
          {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          }
        );

        this.quoteClassCodesErrorModalOpen = true;
      }
    );
  }

  getFormData() {
    const rawFormData = this.formService.getValue();
    const flattened = this.flattenFormData(rawFormData);

    return formatFormFieldValuesBop(flattened) as FormDSLFormData;
  }

  // Returns a flattened list of form controls
  // NOTE: If form groups/arrays are added to LM, we may need to adapt this.
  flattenFormData(formValueWithSteps: any) {
    return reduce(
      formValueWithSteps,
      (fields, stepFields, _stepName) => {
        return merge(fields, stepFields);
      },
      {}
    );
  }

  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.isSubmittable()) {
      this.clickForward();
      return;
    }

    this.isSubmittingForm = true;

    const digitalCarrierProduct = this.digitalCarrierProduct;
    const formData = this.getFormData();
    const effectiveDate = formData[LmBopQuestion.EFFECTIVE_DATE] as string;
    const account = this.digitalCarrierService.parseAccountForQuoteSubmit(this.insuredAccount);

    this.sub.add(
      this.digitalCarrierService
        .submitQuote(
          digitalCarrierProduct,
          formData,
          account,
          effectiveDate,
          this.isEditing,
          false,
          this.quoteId
        )
        .subscribe((response: QuoteResponse) => {
          this.isSubmittingForm = false;

          if (isQuoteErrorResponse(response)) {
            this.sentryService.notify('Liberty Mutual BOP: Error submitting quote', {
              severity: 'error',
              metaData: {
                backendErrorMessage: response.errorMessage,
                errors: response.errors,
              },
            });
            this.quoteError = response;
            this.quoteErrorModalOpen = true;
          } else {
            this.router.navigate(['/accounts', this.accountId, 'quotes', response.uuid]);
          }
        })
    );
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields';
  }

  handleInterpreterOutput({ methodName, args }: { methodName: LmButtonFunctionName; args: any[] }) {
    // Note: If we end up adding a few more button-accessible methods, we can consider something more generic like the following.
    // if (this.childAccessibleMethodNames.includes(methodName) && isFunction(this[methodName])) {
    //   (this[methodName] as Function)(...args);
    // }
    switch (methodName) {
      case LmButtonFunctionName.ADD_LOSS_GROUP:
        this.formService.addLossGroup();
        break;
      case LmButtonFunctionName.REMOVE_LOSS_GROUP:
        // REMOVE_LOSS_GROUP is the function on the loss array node's `childFooterButtons`. Any function bound to
        // these buttons receives a single argument corresponding to the child's index within the array.
        const idx = args[0];
        this.formService.removeLossGroup(idx);
        break;
    }
  }

  onCloseErrorModal(event: Event) {
    this.quoteErrorModalOpen = false;
    this.router.navigate(['/accounts', this.accountId]);
  }

  onClassCodeCloseErrorModal(result: { close: boolean; retry: boolean }) {
    if (result.retry) {
      this.quoteClassCodesErrorModalOpen = false;
      this.setClassCodeDropdown();
    } else {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  fillInHappyPath() {
    this.formService.fillInHappyPath();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  loadNextStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this.currentStep.formPath === LmBopFormStepPath.POLICY_INFO) {
      this.preDeclineValidation();
    }
    super.loadNextStep(event);
  }

  preDeclineValidation() {
    const baseStateStr = bopControlPath(LmBopQuestion.PRIMARY_RISK_STATE);
    const baseState = getControl(this.formService.form, baseStateStr).value;
    const cobExceptionsForSelectedStateArray = COB_CODE_EXCEPTIONS_BY_STATE[baseState];

    const classCodeStr = bopControlPath(LmBopQuestion.BOP_CLASS_CODE);
    const classOfBusinessValue = getControl(this.formService.form, classCodeStr).value;

    const trackDetails: { [string: string]: string } = {
      baseState,
    };

    if (baseState === 'FL') {
      trackDetails.declineReason = EARLY_DECLINE_REASONS.FLORIDA_STATE;
      trackDetails.classOfBusinessCode = classOfBusinessValue ? classOfBusinessValue.code : null;

      this.amplitudeService.trackWithOverride({
        eventName: 'early_quote_decline',
        detail: 'liberty_mutual-bop',
        payloadOverride: trackDetails,
      });
      this.earlyDeclineErrors.push(EARLY_DECLINE_REASONS.FLORIDA_STATE);
      this.quoteEarlyDeclinedModalOpen = true;
    } else if (cobExceptionsForSelectedStateArray && classOfBusinessValue) {
      const classOfBusinessCode = classOfBusinessValue.code;
      const cobExceptionFound = cobExceptionsForSelectedStateArray.some((exception) => {
        return exception === classOfBusinessCode;
      });

      if (cobExceptionFound) {
        trackDetails.declineReason = EARLY_DECLINE_REASONS.COB_STATE_COMBO;
        trackDetails.classOfBusinessCode = classOfBusinessValue.code || null;

        this.amplitudeService.trackWithOverride({
          eventName: 'early_quote_decline',
          detail: 'liberty_mutual-bop',
          payloadOverride: trackDetails,
        });
        this.earlyDeclineErrors.push(EARLY_DECLINE_REASONS.COB_STATE_COMBO);
        this.quoteEarlyDeclinedModalOpen = true;
      }
    }
  }
}
