import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidationErrors,
  UntypedFormArray,
  AbstractControl,
} from '@angular/forms';
import * as moment from 'moment';
import {
  combineLatest,
  zip,
  Subscription,
  Observable,
  BehaviorSubject,
  of as observableOf,
  merge as observableMerge,
} from 'rxjs';
import { map, switchMap, distinctUntilChanged, take } from 'rxjs/operators';

import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  minDateExceededValidator,
  feinValidator,
  numberValidator,
  zipCodeValidator,
  rangeValidator,
  createMinLengthValidator,
  maxDateExceededValidator,
  getControl,
  getFormArray,
  getFormGroup,
  enableDisableControl,
} from 'app/shared/helpers/form-helpers';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { US_DATE_MASK, PLACEHOLDER_ORG_TYPE } from 'app/constants';
import { US_STATES } from 'app/shared/services/us-state.service';
import { UsState } from 'app/shared/models/us-state';
import {
  EMP_LIABILITY_DEFAULT_OPTIONS,
  EMP_LIABILITY_OPTIONS_BY_STATE,
  EMP_TAX_ID_KEY_BY_STATE,
  EMP_TAX_ID_KEY_LENGTH_BY_STATE,
  EXECUTIVE_EXCLUSION_QUESTION,
  EXECUTIVE_EXCLUSION_QUESTION_CODE,
  EXECUTIVE_QUESTION_SLUG,
  EXECUTIVE_QUESTIONS,
  UW_CLASS_SPECIFIC_QUESTIONS,
} from 'app/workers-comp/employers/constants';
import { WorkersCompExec } from 'app/workers-comp/shared/models/executives.model';

import { WcClassCodesService } from 'app/workers-comp/employers/services/workers-comp-class-codes.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import {
  WcFormEmployeeClassificationCode,
  WcCompanionClass,
  WcFormExecutive,
  WcFormLocation,
  WcFormValue,
  WcFormLossPolicyPeriod,
} from 'app/workers-comp/employers/models/wc-policy';
import {
  validateAddressIsNotPO,
  validateNoSpecialCharactersInAddress,
} from '../../../features/attune-bop/models/form-validators';
import {
  createQuoteFeedbackFormGroup,
  QUOTE_FEEDBACK_STEP,
} from 'app/shared/helpers/quote-feedback-helpers';

const EMPLOYEE_CLASSIFICATIONS_CONTROL_NAME = 'employeeClassifications';
const EXECUTIVES_CONTROL_NAME = 'executives';
const EXECUTIVE_CONTROL_QUESTIONS = 'questions';
const PARTNERS_CONTROL_NAME = 'partners';

const LOSS_HISTORY_CONTROL_NAME = 'lossHistory';
const LOSS_PERIOD_OPTIONAL_CONTROLS = ['numClaims', 'amountPaid', 'amountReserved'];

const alwaysRequiredUWQuestionFormControls = {
  WORK06: [false, Validators.required],
  BOP40: [false],
  WORK11: [false, Validators.required],
  WORK11FiveEmp: [false, Validators.required],
  WORK11RecReview: [false, Validators.required],
  'EMPCO-1007': [false, Validators.required],
  WORK08: [false, Validators.required],
  CGL04: [false, Validators.required],
  WORK07: [false, Validators.required],
};

@Injectable()
export class WcQuoteFormService extends FormDslSteppedFormBaseService implements OnDestroy {
  form: UntypedFormGroup;
  submitted = false;
  hasExecutiveTitles = new BehaviorSubject(false);
  industryGroups: string[] = [];
  sub: Subscription = new Subscription();

  availableExecTypes: WorkersCompExec[];
  executiveTitleOptions: { [key: string]: string };
  empLiabilityOptions = EMP_LIABILITY_DEFAULT_OPTIONS;

  classCodesError = false;
  classCodesEmpty = false;
  loadingClassCodes = true;
  fuse: any;
  classCodeOptions: WcFormEmployeeClassificationCode[] = [];
  companionCodes: { [key: string]: WcCompanionClass[] } = {};
  organizationTypes: { [key: string]: string } = {};

  hasPartners = false;

  private _showNewAccountStep = false;
  private _showQuoteFeedbackStep = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private wcClassCodesService: WcClassCodesService,
    private insuredAccountService: InsuredAccountService,
    private orgTypeService: OrganizationTypeService
  ) {
    super();
    this.initializeForm();
    this.syncAllSteps();
    // We need the org type here to determine if it is one that can have executive exclusions.
    this.sub.add(
      this.insuredAccountService.insuredSubject.subscribe((account) => {
        const orgType = account.organizationType;
        if (!orgType || orgType === PLACEHOLDER_ORG_TYPE) {
          return;
        }
        this.form.patchValue({ basicInfo: { organizationType: orgType } });
      })
    );

    this.orgTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  initializeForm() {
    const today = moment().utc();

    const basicInfo = this.formBuilder.group({
      employerIdentificationNumber: [null, [Validators.required, feinValidator]],
      numberOfLocations: [1, [Validators.required, Validators.max(6)]],
      yearsInBusiness: [null, [Validators.required, rangeValidator(0, 999)]],
      yearsOfIndustryExperience: [{ value: null, disabled: true }, Validators.required],
      organizationType: [null, Validators.required],
    });

    const locations = this.formBuilder.array([]);

    const threeMonthsFromToday: moment.Moment = moment.utc().add(3, 'months');
    const policyInfo = this.formBuilder.group({
      // For the cross sell experiment, we're trying to move the FEIN field later in the flow, so everything on the first page is
      // already filled with BOP data
      employerIdentificationNumberCrossSellExperiment: [null, [Validators.required, feinValidator]],
      effectiveDate: [
        today.format(US_DATE_MASK),
        [
          Validators.required,
          minDateExceededValidator(today, 'day'),
          maxDateExceededValidator(threeMonthsFromToday),
        ],
      ],
      stateWorkersCompBureauInfo: this.formBuilder.array([]),
      employersLiabilityLimits: [null, Validators.required],
    });

    getControl(policyInfo, 'employerIdentificationNumberCrossSellExperiment').disable();

    // Loss info

    const lossInfo = this.formBuilder.group({
      [LOSS_HISTORY_CONTROL_NAME]: this.formBuilder.array([
        this.newLossPolicyItem(),
        this.newLossPolicyItem(),
        this.newLossPolicyItem(),
        this.newLossPolicyItem(),
      ]),
    });

    const lossHistoryArray = getFormArray(lossInfo, LOSS_HISTORY_CONTROL_NAME);
    this.updateLossPeriodDates(today.format(US_DATE_MASK), lossHistoryArray);

    // Update loss period dates whenever effective date changes.
    getControl(policyInfo, 'effectiveDate').valueChanges.subscribe((effectiveDate) => {
      this.updateLossPeriodDates(effectiveDate, lossHistoryArray);
    });

    lossHistoryArray.controls.forEach((lossPolicyItem: UntypedFormGroup) => {
      // subscribe to changes
      this.sub.add(
        getControl(lossPolicyItem, 'hasClaims').valueChanges.subscribe((hasClaims) => {
          this.enableOrDisableLossPeriod(<UntypedFormGroup>lossPolicyItem, hasClaims);
        })
      );
    });

    // End of loss info

    const underwritingInfo = this.setupUwQuestionFormControls();

    this.form = this.formBuilder.group({
      basicInfo,
      locations,
      lossInfo,
      policyInfo,
      quoteFeedback: createQuoteFeedbackFormGroup(),
      underwritingInfo,
      uuid: uuidv4(),
    });

    // Add first location
    this.addLocation();

    // Show / enable years of industry experience if years in business < 3
    this.sub.add(
      getControl(basicInfo, 'yearsInBusiness').valueChanges.subscribe((value: string) => {
        const industryExpControl = getControl(basicInfo, 'yearsOfIndustryExperience');
        if (parseMaskedInt(value) < 3) {
          industryExpControl.enable();
        } else {
          industryExpControl.disable();
        }
      })
    );

    // Update executive types whenever the organization type and/or state changes, including autofill
    const stateControl = getControl(this.form, 'locations.0.state');
    const organizationControl = getControl(basicInfo, 'organizationType');

    combineLatest(
      observableMerge(stateControl.valueChanges, observableOf(stateControl.value)),
      observableMerge(organizationControl.valueChanges, observableOf(organizationControl.value))
    ).subscribe(([stateCode, orgTypeValue]: [string, string]) => {
      if (!stateCode || !orgTypeValue) {
        return;
      }

      this.updateExecutiveTypes(stateCode, orgTypeValue);
    });

    this.sub.add(
      locations.valueChanges
        .pipe(
          distinctUntilChanged((prev: WcFormLocation[], curr: WcFormLocation[]) => {
            return _.isEqual(this.uniqueStates(prev), this.uniqueStates(curr));
          })
        )
        .subscribe((locsValue: WcFormLocation[]) => {
          // Show minimum limits for CA and OR
          this.updateLiabilityLimits(locsValue);

          // Manage form inputs for state bureau ID numbers, state-specific employer IDs
          this.createOrUpdateStateBureauInfoArray(locsValue);
        })
    );

    // Add California Partners questions, if org type requires it, and if any location is in CA
    combineLatest(
      observableMerge(organizationControl.valueChanges, observableOf(organizationControl.value)),
      observableMerge(
        locations.valueChanges.pipe(
          distinctUntilChanged((prev: WcFormLocation[], curr: WcFormLocation[]) => {
            return _.isEqual(this.uniqueStates(prev), this.uniqueStates(curr));
          })
        ),
        observableOf(locations.value)
      )
    ).subscribe(([orgTypeValue, locsValue]: [string, WcFormLocation[]]) => {
      const hasCaliforniaLocation = locsValue.some((location) => location.state === 'CA');
      const orgTypesRequiringPartners = ['partnership', 'limitedpartnership', 'llp'];

      const shouldEnable =
        hasCaliforniaLocation && orgTypesRequiringPartners.includes(orgTypeValue);
      this.hasPartners = shouldEnable;
      const partners = this.partners();
      if (!_.isNull(partners)) {
        enableDisableControl(partners, shouldEnable);
      }
    });

    // We call setIndustryGroups here to enable/disable the appropriate UW questions form controls after the initial location has been setup.
    this.setIndustryGroups();
  }

  moveFeinFieldToPolicyPage() {
    const basicInfo = getFormGroup(this.form, 'basicInfo');
    getControl(basicInfo, 'employerIdentificationNumber').disable();
    const policyInfo = getFormGroup(this.form, 'policyInfo');
    getControl(policyInfo, 'employerIdentificationNumberCrossSellExperiment').enable();
  }

  updateExecutiveTypes(stateCode: string, orgType: string) {
    this.availableExecTypes = this.orgTypeService.getExecTypes(stateCode, orgType);

    this.executiveTitleOptions = this.availableExecTypes.reduce(
      (acc: { [key: string]: string }, execType: WorkersCompExec) => {
        acc[execType['code']] = execType['name'];
        return acc;
      },
      {}
    );

    this.hasExecutiveTitles.next(!_.isEmpty(this.executiveTitleOptions));
  }

  newLocation() {
    const newLocationIndex = this.locationsFormArray().controls.length + 1;
    const now: moment.Moment = moment();

    const locationFormGroup = this.formBuilder.group({
      addressLine1: [
        null,
        [Validators.required, validateAddressIsNotPO, validateNoSpecialCharactersInAddress],
      ],
      addressLine2: [
        null,
        [this.addressLine2Validation, validateAddressIsNotPO, validateNoSpecialCharactersInAddress],
      ],
      city: [null, Validators.required],
      employeeCount: [null, [Validators.required, rangeValidator(1)]],
      employeeClassifications: this.formBuilder.array(
        [this.createEmployeeClassificationControl()],
        this.classificationListValidator.bind(this)
      ),
      manyEmployees: this.formBuilder.group({
        constructionType: [null, Validators.required],
        employeesInShift1: [null, Validators.required],
        employeesInShift2: [null, Validators.required],
        employeesInShift3: [null, Validators.required],
        storiesCount: [null, [Validators.required, rangeValidator(1)]],
        yearBuilt: [
          null,
          [
            Validators.required,
            rangeValidator(1800, now.year()),
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ],
        ],
      }),
      state: [null, Validators.required],
      zip: [null, [Validators.required, zipCodeValidator]],
    });

    // Start with many employees disabled
    getFormGroup(locationFormGroup, 'manyEmployees').disable();

    // Show 100+ employee form when employee count changes
    this.setupEmployeeCountSubscription(locationFormGroup);

    // Update class codes when state changes
    this.setupStateCodeSubscription(locationFormGroup);

    if (newLocationIndex === 1) {
      // Set a validator for locaiton 1
      locationFormGroup.setValidators(this.executivesExcludedValidator.bind(this));

      // Update address for first location
      this.insuredAccountService.insuredSubject.subscribe((account) => {
        if (account.addressLine1) {
          this.updateFirstLocationAddress(locationFormGroup);
        }
      });

      this.sub.add(
        this.hasExecutiveTitles.subscribe((hasExecutiveTitles) => {
          if (!hasExecutiveTitles) {
            locationFormGroup.removeControl(EXECUTIVES_CONTROL_NAME);
            const execQuestionControl = this.form.get([
              'underwritingInfo',
              EXECUTIVE_EXCLUSION_QUESTION_CODE,
            ]);
            if (execQuestionControl) {
              execQuestionControl.disable();
            }
          } else if (!locationFormGroup.get('executives')) {
            const executivesControl = this.formBuilder.array([]);
            locationFormGroup.setControl(EXECUTIVES_CONTROL_NAME, executivesControl);
            locationFormGroup.valueChanges.subscribe((newVal) => {
              this.setExecutiveExclusionQuestionFormControls();
            });
          }
        })
      );
    }

    // Update industry groups, underwriting questions based on multiple location group values
    this.setupLocationGroupSubscription(locationFormGroup);

    return locationFormGroup;
  }

  setupLocationGroupSubscription(locationFormGroup: UntypedFormGroup) {
    this.sub.add(
      locationFormGroup.valueChanges
        .pipe(
          distinctUntilChanged((prev: WcFormLocation, curr: WcFormLocation) => {
            return (
              prev.state === curr.state &&
              _.isEqual(prev.employeeClassifications, curr.employeeClassifications)
            );
          })
        )
        .subscribe((location: WcFormLocation) => {
          // Set the industry groups / underwriting questions
          if (_.get(location, 'state') && _.get(location, 'employeeClassifications[0].code')) {
            this.setIndustryGroups();
          }
        })
    );
  }

  locationsFormArray(): UntypedFormArray {
    return getFormArray(this.form, 'locations');
  }

  partnersFormArray(underwriting: UntypedFormGroup): UntypedFormArray {
    return getFormArray(underwriting, 'partners');
  }

  getPartnersControls() {
    const partners = this.partners();
    if (!_.isNull(partners)) {
      return partners.controls;
    } else {
      return [];
    }
  }

  addLocation() {
    this.locationsFormArray().push(this.newLocation());
    this.syncAllSteps();
  }

  removeLastLocation() {
    const locations = this.locationsFormArray();
    locations.removeAt(locations.length - 1);
  }

  generateSteps(): RouteFormStep[] {
    const numLocations = this.form ? this.locationsFormArray().controls.length : 1;
    const locationSteps = _.times(numLocations, (index) => {
      return {
        displayName: `Location ${index + 1}`,
        formPath: `locations.${index}`,
        slug: `location-${index + 1}`,
        parent: `location-${index + 1}`,
        args: {},
      };
    });

    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    return [
      this.newAccountStep(),
      this.quoteFeedbackStep(),
      {
        displayName: 'Basic info',
        formPath: 'basicInfo',
        slug: 'basic-info',
        parent: 'basic-info',
        args: {},
      },
      ...locationSteps,
      {
        displayName: 'Policy info',
        formPath: 'policyInfo',
        slug: 'policy-info',
        parent: 'policy-info',
        args: {},
      },
      {
        displayName: 'Loss info',
        formPath: 'lossInfo',
        slug: 'loss-info',
        parent: 'loss-info',
        args: {},
      },
      {
        displayName: 'Underwriting',
        formPath: 'underwritingInfo',
        slug: 'underwriting-info',
        parent: 'underwriting-info',
        args: {},
      },
    ].filter(isStep);
  }

  fillInHappyPath() {
    this.form.patchValue({
      basicInfo: {
        employerIdentificationNumber: '344232232',
        yearsInBusiness: '4',
      },
      locations: [
        {
          addressLine1: '123 Abc Center',
          addressLine2: null,
          city: 'Anytown',
          employeeCount: '8',
          state: 'MA',
          zip: '02492',
        },
      ],
      lossInfo: {},
      policyInfo: {
        employersLiabilityLimits: '1m',
      },
    });

    (<UntypedFormArray>this.form.get('locations')).controls[0].patchValue({
      employeeClassifications: [
        {
          code: {
            classCode: '8810',
            classSeq: '03',
            quoteable: true,
            easyRate: true,
            description: 'CLERICAL OFFICE EMPLOYEES NOC',
          },
          remuneration: '$250,000',
        },
      ],
    });

    this.getPolicyInfo().setControl(
      'stateWorkersCompBureauInfo',
      this.formBuilder.array([
        this.formBuilder.group({
          state: 'MA',
          stateDisplay: 'Massachusetts',
        }),
      ])
    );
  }

  setupUwQuestionFormControls() {
    const underwritingFormGroup = this.formBuilder.group(alwaysRequiredUWQuestionFormControls);

    const allIndustryGroupQuestions = this.getAllIndustryGroupQuestions();

    allIndustryGroupQuestions.forEach((question) => {
      underwritingFormGroup.addControl(
        question.code,
        this.formBuilder.control(false, Validators.required)
      );
    });

    // Add executive exclusion control
    underwritingFormGroup.addControl(
      EXECUTIVE_EXCLUSION_QUESTION_CODE,
      this.formBuilder.control(false, [Validators.requiredTrue])
    );

    // Add partners control
    underwritingFormGroup.addControl(
      PARTNERS_CONTROL_NAME,
      this.formBuilder.array([this.newPartner(), this.newPartner()])
    );

    const formGroupKeys = Object.keys(underwritingFormGroup.controls);

    // Disable all controls to start, setIndustryGroups will enable the necessary controls.
    formGroupKeys.forEach((key) => {
      (<UntypedFormControl>underwritingFormGroup.get(key)).disable();
    });

    return underwritingFormGroup;
  }

  hasExecutiveExclusion(): boolean {
    const locations = this.locationsFormArray().controls;
    if (!locations[0]) {
      return false;
    }

    // Executive exclusion only applies to location 1 (primary location).
    const locationValues = locations[0].value;

    const { executives } = locationValues;
    if (executives && executives.length && this.hasExecutiveTitles.getValue()) {
      return true;
    }
    return false;
  }

  setExecutiveExclusionQuestionFormControls() {
    const shouldEnableFormControl = this.hasExecutiveExclusion();
    const executiveExclusionFormControl = <UntypedFormControl>(
      this.form.get(['underwritingInfo', EXECUTIVE_EXCLUSION_QUESTION_CODE])
    );

    enableDisableControl(executiveExclusionFormControl, shouldEnableFormControl);
  }

  setPartnersQuestionFormControl() {
    const shouldEnableFormControl = this.hasPartnersQuestions();

    const underwritingFormGroup = getFormGroup(this.form, 'underwritingInfo');
    const partnersFormArrayControl = getControl(underwritingFormGroup, PARTNERS_CONTROL_NAME);
    enableDisableControl(partnersFormArrayControl, shouldEnableFormControl);
  }

  getExecutiveExclusionQuestion() {
    return EXECUTIVE_EXCLUSION_QUESTION;
  }

  hasClericalClassification() {
    return this.locationInfo().some((location) => location.code === '8810');
  }

  setIndustryGroups() {
    const locationObservables: Observable<any>[] = this.locationInfo().map((location) => {
      if (!location.state) {
        return observableOf([]);
      }
      return this.wcClassCodesService.getClassCodes(location.state).pipe(
        map((response: any) => {
          if (!response || !response.classCodes) {
            return [];
          }
          // TODO: Persist industryGroup in form class codes so we don't have to filter through all CC's here
          return response.classCodes.filter((classCode: WcFormEmployeeClassificationCode) => {
            return classCode.classCode === location.code && classCode.classSeq === location.seq;
          });
        })
      );
    });

    zip(...locationObservables)
      .pipe(take(1))
      .subscribe((responses: any[]) => {
        const codeIndustryGroups = _.flatMap(
          responses,
          (classCodesForLocation: WcFormEmployeeClassificationCode[]) => {
            return classCodesForLocation.map((classCode) => classCode.industryGroup);
          }
        );

        this.industryGroups = _.uniq(codeIndustryGroups);

        const questionIds = _.flatMap(this.industryGroups, (industryGroup: string) => {
          return this.getIndustryGroupQuestions(industryGroup);
        });

        const alwaysRequiredUwQuestionKeys = Object.keys(alwaysRequiredUWQuestionFormControls);
        const classCodeSpecificQuestionKeys = _.uniq(questionIds);

        const underwritingInfoFormGroup = getFormGroup(this.form, 'underwritingInfo');
        Object.keys(underwritingInfoFormGroup.controls).forEach((formControlKey) => {
          const shouldEnable = [
            ...alwaysRequiredUwQuestionKeys,
            ...classCodeSpecificQuestionKeys,
          ].includes(formControlKey);
          (<UntypedFormControl>underwritingInfoFormGroup.get(formControlKey))[
            shouldEnable ? 'enable' : 'disable'
          ]();
        });

        // Enable controls for executive exclusion if necessary
        this.setExecutiveExclusionQuestionFormControls();

        this.setPartnersQuestionFormControl();
      });
  }

  getIndustryGroupQuestions(industryGroup: string) {
    const extraQuestions = UW_CLASS_SPECIFIC_QUESTIONS[industryGroup];

    if (!extraQuestions) {
      return [];
    }

    return extraQuestions.map((question) => {
      return question.code;
    });
  }

  getAllIndustryGroupQuestions() {
    return _.flatMap(UW_CLASS_SPECIFIC_QUESTIONS);
  }

  getExecutiveQuestionText(questionCode: string): string | null {
    const state = this.firstLocationState();
    const executiveQuestions = EXECUTIVE_QUESTIONS[state];
    const question = _.find(executiveQuestions, (q) => {
      return q.code === questionCode;
    });
    return question ? question.question : null;
  }

  locationInfo(): { state: string; code: string; seq: string }[] {
    const locations = <UntypedFormGroup[]>this.locationsFormArray().controls;
    const locationCodes = _.flatMap(locations, (location) => {
      const classifications = this.employeeClassifications(location).controls;
      return classifications.map((classification) => {
        return {
          state: _.get(location.value, 'state', ''),
          code: _.get(classification.value, 'code.classCode', ''),
          seq: _.get(classification.value, 'code.classSeq', ''),
        };
      });
    });

    return _.uniq(locationCodes);
  }

  getIndustryGroupPayrollSums(): Record<string, number> {
    const payrollSums: Record<string, number> = {};
    const locations = <UntypedFormGroup[]>this.locationsFormArray().controls;
    locations.forEach((location) => {
      const classifications = this.employeeClassifications(location).controls;
      classifications.forEach((classification) => {
        const industryGroup = _.get(classification.value, 'code.industryGroup', '');
        if (!industryGroup) {
          return;
        }
        const payroll = _.get(classification.value, 'remuneration', '0');
        const payNumber = parseFloat(payroll.replace(/,|\$/g, ''));
        if (payrollSums[industryGroup]) {
          payrollSums[industryGroup] += payNumber;
        } else {
          payrollSums[industryGroup] = payNumber;
        }
      });
    });

    return payrollSums;
  }

  employeeClassifications(location: UntypedFormGroup): UntypedFormArray {
    return getFormArray(location, EMPLOYEE_CLASSIFICATIONS_CONTROL_NAME);
  }

  hasClassSpecificQuestions() {
    return (
      _.intersection(this.industryGroups, Object.keys(UW_CLASS_SPECIFIC_QUESTIONS)).length !== 0
    );
  }

  getVisibleClassSpecificQuestions() {
    const payrolls = this.getIndustryGroupPayrollSums();

    const groups = _.intersection(this.industryGroups, Object.keys(UW_CLASS_SPECIFIC_QUESTIONS));
    return _.flatMap(groups, (group) => {
      return UW_CLASS_SPECIFIC_QUESTIONS[group].filter((question) => {
        if (question.hidden) {
          return false;
        }
        if (question.payrollThreshold) {
          const matchingPayroll = payrolls[group];
          if (!matchingPayroll || question.payrollThreshold > matchingPayroll) {
            return false;
          }
        }
        return true;
      });
    });
  }

  // End of underwriting form logic

  // Location form logic

  private createEmployeeClassificationControl() {
    return this.formBuilder.group({
      code: [null, [Validators.required, this.quoteableValidation]],
      remuneration: [null, Validators.required],
    });
  }

  quoteableValidation(formControl: AbstractControl): null | ValidationErrors {
    const code: null | WcFormEmployeeClassificationCode = formControl.value
      ? formControl.value
      : null;

    if (code && !code.quoteable === true) {
      return {
        notQuoteable: `Unfortunately, ${code.classCode}-${code.classSeq} is not in our appetite.`,
      };
    }

    return null;
  }

  addressLine2Validation(formControl: AbstractControl): null | ValidationErrors {
    const addressLine2 = formControl.value;

    if ((addressLine2 && addressLine2.length < 3) || (addressLine2 && addressLine2.length > 300)) {
      return {
        invalidAddressLength:
          'Address line 2 must be at least 3 characters long - please include Apt or Suite as appropriate.',
      };
    }
    return null;
  }

  showNewAccountStep() {
    this._showNewAccountStep = true;
    this.syncAllSteps();
  }

  newAccountStep(): RouteFormStep | null {
    if (this._showNewAccountStep) {
      return {
        args: {},
        displayName: 'Account',
        slug: 'account',
        parent: 'account',
        formPath: 'account',
      };
    }
    return null;
  }

  showQuoteFeedbackStep() {
    this._showQuoteFeedbackStep = true;
    // We need to step here because the form will
    // default to the first static step (basic-info) otherwise.
    this.stepWithoutValidation(QUOTE_FEEDBACK_STEP);
    this.syncAllSteps();
  }

  quoteFeedbackStep(): RouteFormStep | null {
    if (this._showQuoteFeedbackStep) {
      return QUOTE_FEEDBACK_STEP;
    }
    return null;
  }

  patchFormControlsForEdit(formValuesForEdit: WcFormValue) {
    // Uuid patch
    if (formValuesForEdit && formValuesForEdit.uuid) {
      this.form.patchValue({
        uuid: formValuesForEdit.uuid,
      });
    }

    // Basic info patch
    const basicInfo = getFormGroup(this.form, 'basicInfo');

    if (formValuesForEdit && formValuesForEdit.basicInfo && !basicInfo.dirty) {
      basicInfo.patchValue({
        ...formValuesForEdit.basicInfo,
        organizationType: formValuesForEdit.basicInfo.organizationType
          ? formValuesForEdit.basicInfo.organizationType
          : basicInfo.value.organizationType,
      });
    }

    // Location forms patch
    const locations = getFormArray(this.form, 'locations');

    if (formValuesForEdit && formValuesForEdit.locations && !locations.dirty) {
      // Reset the locations form group.

      formValuesForEdit.locations.forEach((_loc, index) => {
        if (index >= locations.controls.length) {
          locations.push(this.newLocation());
        }
      });

      formValuesForEdit.locations.forEach((location, index) => {
        // Patch existing form controls
        const locationFormGroup = locations.controls[index] as UntypedFormGroup;
        locationFormGroup.patchValue(location);

        locationFormGroup.setControl(
          EMPLOYEE_CLASSIFICATIONS_CONTROL_NAME,
          this.formBuilder.array(
            location.employeeClassifications.map(() => this.createEmployeeClassificationControl()),
            this.classificationListValidator.bind(this)
          )
        );

        locationFormGroup.patchValue({
          [EMPLOYEE_CLASSIFICATIONS_CONTROL_NAME]: location[EMPLOYEE_CLASSIFICATIONS_CONTROL_NAME],
        });

        this.patchMatchingStubCodes(locationFormGroup);

        let executivesFormValues = location[EXECUTIVES_CONTROL_NAME] || [];
        if (executivesFormValues.length > 0) {
          executivesFormValues = this.reformatExecutiveQuestions(executivesFormValues);
          // Reset executive form controls
          locationFormGroup.setControl(
            EXECUTIVES_CONTROL_NAME,
            this.formBuilder.array(
              executivesFormValues.map(() => {
                return this.newExecutive();
              })
            )
          );
          locationFormGroup.patchValue({ executives: executivesFormValues || [] });
        }

        this.syncAllSteps();
      });
    }

    if (formValuesForEdit && formValuesForEdit.policyInfo) {
      this.getPolicyInfo().patchValue(formValuesForEdit.policyInfo);
    }

    // UW form patch
    const underwritingFormGroup = getFormGroup(this.form, 'underwritingInfo');
    if (formValuesForEdit && formValuesForEdit.underwritingInfo && !underwritingFormGroup.dirty) {
      // Enable all controls before patching.
      Object.keys(underwritingFormGroup.controls).forEach((key) => {
        (<UntypedFormControl>underwritingFormGroup.get(key)).enable();
      });

      underwritingFormGroup.patchValue(formValuesForEdit.underwritingInfo);
      // call setIndustryGroups to enable/disable UW questions as required.
      this.setIndustryGroups();
    }

    // Loss info form patch
    const lossInfoFormGroup = getFormGroup(this.form, 'lossInfo');
    const lossHistoryFormArray = getFormArray(lossInfoFormGroup, 'lossHistory');
    if (formValuesForEdit && formValuesForEdit.lossInfo && !lossInfoFormGroup.dirty) {
      // Enable or disable loss history form controls so that we can patch in values
      formValuesForEdit.lossInfo.lossHistory.forEach(
        (lossHistoryItem: WcFormLossPolicyPeriod, index: number) => {
          this.enableOrDisableLossPeriod(
            <UntypedFormGroup>lossHistoryFormArray.controls[index],
            lossHistoryItem.hasClaims
          );
        }
      );
      lossInfoFormGroup.patchValue(formValuesForEdit.lossInfo);
    }
  }

  reformatExecutiveQuestions(executives: WcFormExecutive[]) {
    executives.forEach((executive) => {
      if (executive.questions && executive.questions.length > 0) {
        executive.questions = executive.questions.reduce((accumulator, question) => {
          const answerBoolean = question.answer === 'Yes';
          return { ...accumulator, [question.questionCode]: answerBoolean };
        }, {});
      }
    });
    return executives;
  }

  classificationListValidator(classificationControls: UntypedFormArray) {
    const enteredCodes = classificationControls.value.map((classGroup: any) => {
      return classGroup.code && `${classGroup.code.classCode}-${classGroup.code.classSeq}`;
    });

    const requiredCodes = this.collectRequiredCodes(classificationControls);

    const validationErrors = _.compact(
      _.map(requiredCodes, (requiredList, code) => {
        // if requirements are not met, return a validation error message
        // if requirements are met, return null (which gets removed by compact)
        if (!_.isEmpty(_.difference(requiredList, enteredCodes))) {
          return this.requiredCodeMessage(code, requiredList);
        }
      })
    );

    return _.isEmpty(validationErrors) ? null : { invalidClassCodeList: validationErrors };
  }

  executivesExcludedValidator(firstLocFormGroup: UntypedFormGroup) {
    // Returns list of WcExecType >codes< that are currently selected
    const state = _.get(firstLocFormGroup.value, 'state', '');
    const execValues: WcFormExecutive[] = _.get(firstLocFormGroup.value, 'executives', []);

    const errors = execValues
      .filter((exec: WcFormExecutive) => exec.title !== null)
      .map((executive: WcFormExecutive) => {
        const title = executive.title;

        if (!this.availableExecTypes) {
          return null;
        }

        const execType = this.availableExecTypes.find((ex: WorkersCompExec) => ex.code === title);

        if (execType === undefined) {
          return `${title} is an invalid officer type`;
        }

        if (!executive.isIncluded && !execType.canBeExcludedIn.includes(state)) {
          return `A ${execType.name} cannot be scheduled as excluded in ${state} - form not available.`;
        }

        if (executive.isIncluded && !execType.canBeIncludedIn.includes(state)) {
          return `A ${execType.name} cannot be scheduled as included in ${state} - form not available.`;
        }

        return null;
      })
      .filter((errString: string | null) => errString !== null);

    if (errors.length) {
      return { execExclusion: errors.join(' ') };
    }

    return null;
  }

  // Counted from 1, user-visible location index
  locationIndex() {
    const currentStepSlug = this.getCurrentStep().slug;
    if (/^location-\d+/.test(currentStepSlug)) {
      return Number(currentStepSlug.split('-')[1]);
    }

    return null;
  }

  currentLocation() {
    const locationsArray = this.locationsFormArray();
    const loc = this.locationIndex();

    if (loc) {
      return locationsArray && locationsArray.at(loc - 1);
    }
    return null;
  }

  firstLocationState() {
    const locationsArray = this.locationsFormArray();
    const currentLocation = <UntypedFormGroup>locationsArray.controls[0];
    if (currentLocation) {
      const state = getControl(currentLocation, 'state');
      return state.value;
    }
    return null;
  }

  private updateClassCodes(result: any, location: UntypedFormGroup) {
    if (result === null) {
      return;
    } // Still loading...
    this.loadingClassCodes = false;

    if (!_.isEmpty(result.classCodes)) {
      this.classCodesEmpty = false;
      this.classCodesError = false;
      this.classCodeOptions = result.classCodes;
      this.companionCodes = <{ [key: string]: WcCompanionClass[] }>result.companionCodes;
      this.fuse = result.fuse;
      this.patchMatchingStubCodes(location);
    } else {
      if (result.error) {
        this.classCodesError = true;
      }
      this.classCodesEmpty = true;
      this.classCodeOptions = [];
      this.companionCodes = {};
      this.fuse = null;
    }
  }

  private requiredCodeMessage(code: string, requiredList: string[]) {
    const multiple = requiredList.length > 1;
    return `Class code${multiple ? 's' : ''} ${requiredList.join(', ')} ${
      multiple ? 'are' : 'is'
    } required when ${code} is present`;
  }

  private collectRequiredCodes(classificationControls: UntypedFormArray): {
    [key: string]: string[];
  } {
    // Combines required class codes from current class codes
    // e.g. if 0050-08 requires 0150-12 and 0250-29, this returns:
    // { "0050-08" : ["0150-12", "0250-29"]
    if (!this.companionCodes) {
      return {};
    }

    return _.reduce(
      classificationControls.controls,
      (acc: { [key: string]: string[] }, wcClassification) => {
        const classCodeFormVal = wcClassification.value;
        const currentClassWithSeq =
          classCodeFormVal.code &&
          `${classCodeFormVal.code.classCode}-${classCodeFormVal.code.classSeq}`;

        if (!currentClassWithSeq) {
          return acc;
        }

        const possibleCompanions = this.companionCodes[currentClassWithSeq];

        if (!possibleCompanions) {
          return acc;
        }

        acc[currentClassWithSeq] = possibleCompanions.map((c: any) => c.codeWithSeqHigh);
        return acc;
      },
      {}
    );
  }

  private setupEmployeeCountSubscription(location: UntypedFormGroup) {
    const manyEmployeesForm = <UntypedFormControl>location.get('manyEmployees');
    this.sub.add(
      (<UntypedFormControl>location.get('employeeCount')).valueChanges.subscribe((value) => {
        if (parseMaskedInt(value) > 100) {
          manyEmployeesForm.enable();
        } else {
          manyEmployeesForm.disable();
        }
      })
    );
  }

  /**
   *  Replaces any server-side codes w/ full info that came
   *  in from this state's classCodeOptions.
   */
  private patchMatchingStubCodes(location: UntypedFormGroup) {
    this.employeeClassifications(location).controls.forEach((ecControl: UntypedFormGroup) => {
      const classCodeControl = getControl(ecControl, 'code');
      const currentVal = classCodeControl.value;

      // Update if and only if the class code came from a translated / edit payload
      if (currentVal !== null && _.get(currentVal, 'classSeq', '') === '') {
        const fullCode = _.find(this.classCodeOptions, {
          classCode: currentVal.classCode,
          description: currentVal.description,
        });
        if (!_.isEmpty(fullCode)) {
          classCodeControl.patchValue(<WcFormEmployeeClassificationCode>fullCode);
        }
      }
    });
  }

  buildOrRemoveGroup(
    parent: UntypedFormGroup,
    groupName: string,
    enable: boolean
  ): UntypedFormGroup | null {
    // Set up FormGroup if it's not already there, tear down if enable is false
    let group = parent.get(groupName) as UntypedFormGroup | null;
    if (group === null) {
      group = this.formBuilder.group({});
      parent.addControl(groupName, group);
    } else {
      if (!enable) {
        // Exists but should be disabled
        parent.removeControl(groupName);
        return null;
      }
    }
    return group;
  }

  updateExecutiveQuestions(
    executiveGroup: UntypedFormGroup,
    state: string,
    orgType: string,
    title: string | null
  ): void {
    const applicableQuestions = (EXECUTIVE_QUESTIONS[state] || []).filter((question) => {
      const inOrgType = question.orgTypes.includes(orgType);
      if (!question.titles) {
        return inOrgType;
      } else if (title == null) {
        return false; // Before a title is selected, show no questions
      } else {
        return inOrgType && question.titles.includes(title);
      }
    });

    const questionsGroup = this.buildOrRemoveGroup(
      executiveGroup,
      'questions',
      applicableQuestions.length > 0
    );
    if (questionsGroup === null) {
      return;
    }

    const applicableQuestionCodes = applicableQuestions.map((q) => q.code);

    // Remove extra controls (e.g. when switching out of a orgType / title with questions)
    Object.keys(questionsGroup.controls).forEach((existingQuestion) => {
      if (!applicableQuestionCodes.includes(existingQuestion as EXECUTIVE_QUESTION_SLUG)) {
        questionsGroup.removeControl(existingQuestion);
      }
    });

    // Add question controls (e.g. when switching into a orgType / title with questions)
    applicableQuestionCodes.forEach((code) => {
      if (questionsGroup.get(code)) {
        return null;
      } else {
        const q = this.formBuilder.control(null, Validators.required);
        questionsGroup.addControl(code, q);
      }
    });
  }

  newExecutive() {
    const execKeys = _.keys(this.executiveTitleOptions);
    const title = execKeys.length === 1 ? execKeys[0] : null;

    const executiveGroup = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      ownership: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      title: [title, Validators.required],
      isIncluded: [false, Validators.required],
    });

    // Update executive questions based on state, org type, and selected title
    const stateControl = getControl(this.form, 'locations.0.state');
    const organizationControl = getControl(this.form, 'basicInfo.organizationType');
    const titleControl = getControl(executiveGroup, 'title');

    combineLatest(
      observableMerge(stateControl.valueChanges, observableOf(stateControl.value)),
      observableMerge(organizationControl.valueChanges, observableOf(organizationControl.value)),
      observableMerge(titleControl.valueChanges, observableOf(title))
    ).subscribe(([state, orgType, updatedTitle]: [string, string, string | null]) => {
      const acordOrgType = this.orgTypeService.getEmployersOrgCode(orgType);
      this.updateExecutiveQuestions(executiveGroup, state, acordOrgType, updatedTitle);
    });

    return executiveGroup;
  }

  newPartner() {
    const partnerGroup = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
    });

    return partnerGroup;
  }

  // Executives controls
  removeExecutive(location: UntypedFormGroup, index: number): void {
    const execs = this.executives(location);
    if (execs) {
      execs.removeAt(index);
    }
  }

  addExecutive(location: UntypedFormGroup): void {
    const execs = this.executives(location);

    if (execs) {
      execs.push(this.newExecutive());
    }
  }

  executives(location: UntypedFormGroup): UntypedFormArray | null {
    return getFormArray(location, EXECUTIVES_CONTROL_NAME);
  }

  partners(): UntypedFormArray | null {
    const underwritingFormGroup = getFormGroup(this.form, 'underwritingInfo');
    return getFormArray(underwritingFormGroup, PARTNERS_CONTROL_NAME);
  }

  hasPartnersQuestions() {
    return this.hasPartners;
  }

  hasExecutiveQuestions(executive: UntypedFormGroup) {
    const questions = this.executiveQuestions(executive);

    if (questions) {
      const questionControls = Object.keys(questions.controls);
      return questionControls.length > 0;
    } else {
      return false;
    }
  }

  executiveQuestions(executive: UntypedFormGroup): UntypedFormGroup | null {
    return getFormGroup(executive, EXECUTIVE_CONTROL_QUESTIONS);
  }

  // Class Codes controls
  removeEmployeeClassification(location: UntypedFormGroup, index: number) {
    this.employeeClassifications(location).removeAt(index);
  }

  addEmployeeClassification(location: UntypedFormGroup) {
    this.employeeClassifications(location).push(this.createEmployeeClassificationControl());
  }

  moreThanOneEmployeeClassification(location: UntypedFormGroup) {
    return this.employeeClassifications(location).length > 1;
  }

  clearEmployeeClassificationCodes(locationFormGroup: UntypedFormGroup) {
    this.employeeClassifications(locationFormGroup).controls.forEach(
      (ecControl: UntypedFormGroup) => {
        ecControl.patchValue({ code: null });
      }
    );
  }

  private setupStateCodeSubscription(locationFormGroup: UntypedFormGroup) {
    this.sub.add(
      (<UntypedFormControl>(<UntypedFormGroup>locationFormGroup).get('state')).valueChanges
        .pipe(
          switchMap((state) => {
            this.loadingClassCodes = true;
            this.clearEmployeeClassificationCodes(locationFormGroup);
            return this.wcClassCodesService.getClassCodes(state);
          })
        )
        .subscribe((result: any) => {
          this.updateClassCodes(result, locationFormGroup);
        })
    );
  }

  private updateFirstLocationAddress(locationFormGroup: UntypedFormGroup) {
    const account = this.insuredAccountService.insuredSubject.getValue();

    if (!locationFormGroup.dirty) {
      locationFormGroup.patchValue({
        addressLine1: account.addressLine1,
        addressLine2: account.addressLine2,
        city: account.city,
        state: account.state,
        zip: account.zip,
      });
    }
  }

  // End of location form logic
  // Loss form logic

  private newLossPolicyItem() {
    const lossPolicyGroup = this.formBuilder.group({
      amountPaid: ['$0', Validators.required],
      amountReserved: ['$0', Validators.required],
      hasClaims: [false, Validators.required],
      numClaims: [null, [Validators.required, Validators.min(1)]],
      policyEffectiveDate: [null, Validators.required],
    });
    this.enableOrDisableLossPeriod(lossPolicyGroup, false);
    return lossPolicyGroup;
  }

  enableOrDisableLossPeriod(lossPeriodFormGroup: UntypedFormGroup, enable: boolean) {
    const enableFunc = enable ? 'enable' : 'disable';
    LOSS_PERIOD_OPTIONAL_CONTROLS.forEach((controlName) => {
      (<UntypedFormControl>lossPeriodFormGroup.get(controlName))[enableFunc]();
    });
  }

  private updateLossPeriodDates(effectiveDate: string, lossHistoryArray: UntypedFormArray) {
    [0, 1, 2, 3].forEach((yearsAgo) => {
      const effDate = moment(effectiveDate, US_DATE_MASK);
      const periodEffDate = moment(effDate)
        .subtract(yearsAgo + 1, 'year')
        .format(US_DATE_MASK);

      lossHistoryArray.controls[yearsAgo].patchValue({
        policyEffectiveDate: periodEffDate,
      });
    });
  }

  // End of loss form logic
  // Policy Info form logic

  private uniqueStates(locations: WcFormLocation[]) {
    const states = locations.map((loc: WcFormLocation) => {
      return US_STATES.find((usState) => usState.value === loc.state);
    });

    return _.uniq(_.compact(states));
  }

  // If states with fewer options are present, use smallest set of options
  // Otherwise, use default set of Employers Emp. Liability Limits
  private updateLiabilityLimits(locations: WcFormLocation[]) {
    const reducedOptions = _.compact(
      this.uniqueStates(locations).map((state) => {
        return EMP_LIABILITY_OPTIONS_BY_STATE[state.value];
      })
    );

    if (_.isEmpty(reducedOptions)) {
      this.empLiabilityOptions = EMP_LIABILITY_DEFAULT_OPTIONS;
    } else {
      this.empLiabilityOptions = _.sortBy(reducedOptions, (optionSet) => optionSet.length)[0];
    }
  }

  private createOrUpdateStateBureauInfoArray(locations: WcFormLocation[]) {
    const locationStates = this.uniqueStates(locations);
    this.getPolicyInfo().setControl(
      'stateWorkersCompBureauInfo',
      this.formBuilder.array(this.mapStateBureauInfo(locationStates))
    );
  }

  private findStateBureauInfo(
    usState: UsState,
    formArray?: UntypedFormArray
  ): UntypedFormGroup | null {
    formArray = formArray ? formArray : this.getStateBureauInfoFormArray();
    const group = formArray.controls.find(
      (info) => (<UntypedFormControl>info.get('state')).value === usState.value
    );
    return group ? (group as UntypedFormGroup) : null;
  }

  private mapStateBureauInfo(states: UsState[]): UntypedFormGroup[] {
    return states.map((usState) => {
      // If a bureauInfo control already exists in the array, reuse that control
      const foundBureauInfo = this.findStateBureauInfo(usState);
      if (foundBureauInfo) {
        return foundBureauInfo as UntypedFormGroup;
      }

      // Creates a bureau control for any state that is added to the form
      return this.newStateBureauInfo(usState);
    });
  }

  private newStateBureauInfo(usState: UsState): UntypedFormGroup {
    const newStateBureauInfo: UntypedFormGroup = this.formBuilder.group({
      state: [usState.value, Validators.required],
      stateDisplay: usState.name,
    });

    const state: string = usState.value;
    if (Object.prototype.hasOwnProperty.call(EMP_TAX_ID_KEY_BY_STATE, state)) {
      newStateBureauInfo.setControl(
        'taxOrUnemploymentLabel',
        this.formBuilder.control(EMP_TAX_ID_KEY_BY_STATE[state].labelText)
      );
      newStateBureauInfo.setControl(
        'taxOrUnemploymentId',
        this.formBuilder.control('', [
          Validators.required,
          numberValidator,
          createMinLengthValidator(EMP_TAX_ID_KEY_LENGTH_BY_STATE[state].min),
        ])
      );
    }
    return newStateBureauInfo;
  }

  getPolicyInfo(): UntypedFormGroup {
    return this.form.get('policyInfo') as UntypedFormGroup;
  }

  getStateBureauInfoFormArray(): UntypedFormArray {
    return this.form.get('policyInfo.stateWorkersCompBureauInfo') as UntypedFormArray;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
