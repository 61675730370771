import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import {
  getControl,
  getValidationMessageFromControl,
} from '../../../../shared/helpers/form-helpers';
import { forkJoin } from 'rxjs';
import { InsuredAccountService } from '../../../insured-account/services/insured-account.service';
import { GWBindService } from '../../../../shared/services/gw-bind.service';
import {
  InsuredAccountSummaryService,
  AccountSummaryView,
  FrontendQuoteWithLinks,
} from '../../../insured-account/services/insured-account-summary.service';
import { BUNDLE_BIND_QUESTION } from '../../models/constants';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { numberToMoneyString, parseMoney } from '../../../../shared/helpers/number-format-helpers';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-bundle-bind-selection-form',
  templateUrl: './bundle-bind-selection-form.component.html',
})
export class BundleBindSelectionFormComponent implements OnInit {
  @Input() accountId: string;
  @Input() selectedBopId = '';
  @Input() selectedCyberId = '';
  @Output() closeModal = new EventEmitter<void>();

  form: UntypedFormGroup;
  submitted = false;

  cyberQuotes: QuoteDetails[] = [];
  bopSelected = true;
  bopOptions: Record<string, string>;
  bopOptionAdditionalColumns: { [key: string]: { innerHTML: string; cssClass?: string }[] };

  cyberSelected = true;
  cyberOptions: Record<string, string>;
  cyberOptionDescriptions: Record<string, string>;
  cyberOptionAdditionalColumns: { [key: string]: { innerHTML: string; cssClass?: string }[] };

  bopIncludedControl: UntypedFormControl;
  cyberIncludedControl: UntypedFormControl;
  bopQuoteControl: UntypedFormControl;
  cyberQuoteControl: UntypedFormControl;

  getValidationMessageFromControl = getValidationMessageFromControl;

  constructor(
    private insuredAccountService: InsuredAccountService,
    private bindService: GWBindService,
    private accountSummaryService: InsuredAccountSummaryService,
    private currencyPipe: CurrencyPipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = new UntypedFormBuilder().group({
      [BUNDLE_BIND_QUESTION.BOP_INCLUDED]: [this.bopSelected],
      [BUNDLE_BIND_QUESTION.BOP_QUOTES]: [this.selectedBopId],
      [BUNDLE_BIND_QUESTION.CYBER_INCLUDED]: [this.cyberSelected],
      [BUNDLE_BIND_QUESTION.CYBER_QUOTES]: [this.selectedCyberId],
    });
    this.bopIncludedControl = getControl(this.form, BUNDLE_BIND_QUESTION.BOP_INCLUDED);
    this.cyberIncludedControl = getControl(this.form, BUNDLE_BIND_QUESTION.CYBER_INCLUDED);
    this.bopQuoteControl = getControl(this.form, BUNDLE_BIND_QUESTION.BOP_QUOTES);
    this.cyberQuoteControl = getControl(this.form, BUNDLE_BIND_QUESTION.CYBER_QUOTES);

    this.insuredAccountService
      .get(this.accountId)
      .pipe(
        switchMap((model) => {
          return forkJoin(
            ...model.bopQuotes
              .filter((quote) => quote.uwCompanyAccredited && quote.status === 'Quoted')
              .map((quote) => this.bindService.getQuoteDetails(quote.id))
          );
        })
      )
      .subscribe((quoteDetailsArray: QuoteDetails[]) => {
        this.handleBopQuotes(quoteDetailsArray);
      });

    this.accountSummaryService
      .getSummary(this.accountId)
      .subscribe((accountSummary: AccountSummaryView) => {
        if (accountSummary.status === 'success') {
          const cyberQuotes = accountSummary.quotes.filter(
            (quote) =>
              quote.pasSource === 'coalition' &&
              quote.product === 'cyber_admitted' &&
              quote.status === 'quoted'
          );
          this.handleCyberQuotes(cyberQuotes);
        }
      });
    this.handleProductIncludedListeners();
  }

  handleProductIncludedListeners() {
    this.bopIncludedControl.valueChanges.subscribe((value) => {
      this.bopSelected = value;
    });

    this.cyberIncludedControl.valueChanges.subscribe((value) => {
      this.cyberSelected = value;
    });
  }

  handleBopQuotes(quoteDetailsArray: QuoteDetails[]) {
    this.bopOptions = {};
    this.bopOptionAdditionalColumns = {};
    _.forEach(quoteDetailsArray, (quote: QuoteDetails) => {
      this.bopOptions[quote.id] = quote.id;

      const premium = this.formatCost(quote);
      this.bopOptionAdditionalColumns[quote.id] = [
        { innerHTML: premium ? premium : '', cssClass: 'td__bold' },
      ];
    });
    if (quoteDetailsArray.length === 1) {
      this.bopQuoteControl.setValue(quoteDetailsArray[0].id);
    }
    this.bopIncludedControl.setValidators(() => {
      if (this.bopIncludedControl.value && !this.bopQuoteControl.value) {
        return {
          bopIncludedButNotSelected: {
            validationMessage: 'Please select a bop quote',
          },
        };
      }
      return null;
    });
  }

  handleCyberQuotes(quoteDetailsArray: FrontendQuoteWithLinks[]) {
    this.cyberOptions = {};
    this.cyberOptionDescriptions = {};
    this.cyberOptionAdditionalColumns = {};
    _.forEach(quoteDetailsArray, (quote: FrontendQuoteWithLinks) => {
      this.cyberOptions[quote.displayId] = quote.uuid;
      this.cyberOptionDescriptions[quote.displayId] = quote.displayId;

      const premium = numberToMoneyString(parseMoney(quote.premium));
      const product = quote.product === 'cyber_admitted' ? 'Admitted' : 'Surplus';
      this.cyberOptionAdditionalColumns[quote.displayId] = [
        { innerHTML: product },
        { innerHTML: premium ? premium : '', cssClass: 'td__bold' },
      ];
    });

    if (quoteDetailsArray.length === 1) {
      this.cyberQuoteControl.setValue(quoteDetailsArray[0].uuid);
    }
    this.cyberIncludedControl.setValidators(() => {
      if (this.cyberIncludedControl.value && !this.cyberQuoteControl.value) {
        return {
          cyberIncludedButNotSelected: {
            validationMessage: 'Please select a cyber quote',
          },
        };
      }
      return null;
    });
  }

  handleContinue() {
    this.submitted = true;
    this.bopIncludedControl.updateValueAndValidity();
    this.cyberIncludedControl.updateValueAndValidity();
    if (this.form.valid) {
      const bopPolicyId = this.bopIncludedControl.value ? this.bopQuoteControl.value : null;
      const cyberPolicyId = this.cyberIncludedControl.value ? this.cyberQuoteControl.value : null;
      if (bopPolicyId && cyberPolicyId) {
        this.router.navigate(['/accounts', this.accountId, 'bundle-bind'], {
          queryParams: {
            bopPolicyId,
            cyberPolicyId,
          },
        });
      } else if (bopPolicyId) {
        this.router.navigate(['/accounts', this.accountId, 'bop', 'policies', bopPolicyId, 'bind']);
      } else if (cyberPolicyId) {
        // Currently only implementing for cyber-admitted
        this.router.navigate([
          '/accounts',
          this.accountId,
          'cyber-admitted',
          'quotes',
          cyberPolicyId,
          'bind',
        ]);
      }
    }
  }

  formatCost(...loadedModels: QuoteDetails[]) {
    const totalCost = loadedModels.reduce((accumulatedCost, model) => {
      return accumulatedCost + model.totalCost;
    }, 0);
    return this.currencyPipe.transform(totalCost, 'USD', 'symbol', '1.2-2');
  }
}
