import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { API_V3_BASE } from 'app/constants';
import { WcFormEmployeeClassificationCode } from 'app/workers-comp/employers/models/wc-policy';
import { HttpClient } from '@angular/common/http';
import * as Fuse from 'fuse.js';
import * as _ from 'lodash';

interface WcClassCode {
  code: string; // 8810-02
  classCode: string; // 8810
  classSeq: string; // 02
  easyRate: boolean;
  quoteable: boolean;
  description: string; // COMPUTER PROGRAMMERS & STUFF
  industryGroup: string;
}

const CLASS_CODE_SEARCH_OPTIONS = {
  distance: 1000,
  keys: ['description', 'classCode', 'industryGroup'],
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.65,
};

@Injectable()
export class WcClassCodesService {
  classCodesByState: { [key: string]: BehaviorSubject<any> } = {};
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getClassCodes(state: string): Observable<any> {
    if (!this.availableOrFetching(state)) {
      this.classCodesByState[state] = new BehaviorSubject(null);
      this.http.get(`${API_V3_BASE}/employers-appetite/${state}`).subscribe(
        (result: any) => {
          const classCodes = this.generateClassCodeOptions(<WcClassCode[]>result.classCodes);
          const companionCodes = result.companionClassCodes;
          const fuse = new Fuse(classCodes, CLASS_CODE_SEARCH_OPTIONS);

          this.classCodesByState[state].next({ classCodes, companionCodes, fuse });
        },
        (resp: any) => {
          this.sentryService.notify('Failed to load WC Class Codes', {
            severity: 'error',
            metaData: {
              state: state,
              responseStatusCode: resp ? resp.status : null,
              responseError: resp ? resp.error : null,
              underlyingErrorMessage: resp.error && resp.error.message,
            },
          });

          this.classCodesByState[state].next({ error: true });
        }
      );
    }

    return this.classCodesByState[state].asObservable();
  }

  private availableOrFetching(state: string) {
    return (
      this.classCodesByState[state] &&
      _.isEmpty(_.get(this.classCodesByState[state], 'value.error'))
    );
  }

  private generateClassCodeOptions(classCodes: WcClassCode[]): WcFormEmployeeClassificationCode[] {
    return classCodes.map((code) => ({
      classCode: code.classCode,
      classSeq: code.classSeq,
      description: code.description,
      // Note: industryGroup and label are useful for the Fuse fuzzy searching, they are not needed in payload
      easyRate: code.easyRate,
      quoteable: code.quoteable,
      industryGroup: code.industryGroup || '',
    }));
  }
}
