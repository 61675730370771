import { Component, OnInit } from '@angular/core';
import { IncentivesNewUsersService } from '../services/incentives-new-users.service';
import * as moment from 'moment';

const MAX_BIND_REWARDS_FOR_NEW_USERS = 3;

@Component({
  selector: 'app-incentives-new-users-progress',
  templateUrl: 'incentives-new-users-progress.component.html',
})
export class IncentivesNewUsersProgressComponent implements OnInit {
  isEligible = false;
  totalBinds = 0;
  incentiveEndDate: string;

  MAX_BIND_REWARDS_FOR_NEW_USERS = MAX_BIND_REWARDS_FOR_NEW_USERS;

  constructor(private incentivesService: IncentivesNewUsersService) {}

  ngOnInit() {
    this.incentivesService.getNewUserBinds().subscribe(({ binds }) => {
      this.totalBinds = binds;
    });
    this.incentivesService.getUserCreationTime().subscribe((creationTime) => {
      if (!creationTime) {
        this.isEligible = false;
        return;
      }
      this.incentiveEndDate = creationTime.clone().add(30, 'days').endOf('day').format('MMMM Do');
      const thirtyDaysAgo = moment().subtract(30, 'days');
      this.isEligible = thirtyDaysAgo.isBefore(creationTime.endOf('day'));
    });
  }
}
