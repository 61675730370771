import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BundleService } from '../../services/bundle.service';
import {
  AttuneBopFrontendQuoteWithLinks,
  FrontendQuote,
  FrontendQuoteBundle,
} from '../../../digital-carrier/models/types';
import { Observable, Subscription } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { InformService } from '../../../../core/services/inform.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { FrontendQuoteWithLinks } from '../../../insured-account/services/insured-account-summary.service';
import {
  DismissalRecords,
  UNBUNDLE_TOOLTIP_DISMISSAL_RECORDS_KEY,
} from '../../../../shared/product-cross-sell/configs';
import { US_DATE_MASK } from '../../../../constants';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import { InsuredAccountService } from '../../../insured-account/services/insured-account.service';
import {
  UserService,
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
} from '../../../../core/services/user.service';
import {
  CoalitionDocument,
  CoalitionDocumentType,
  quoteStatusHasAssociatedCarrierDocuments,
  quoteStatusHasAssociatedComplianceDocuments,
} from '../../../coalition/models/cyber-typings.model';
import { SentryService } from '../../../../core/services/sentry.service';
import { visibleCarrierDocumentsByQuoteStatus } from '../../../coalition/models/cyber-constants.model';
import { DocumentService } from '../../../documents/services/document.service';
import * as moment from 'moment';
import {
  getAttuneQuoteLetterUrl,
  getAttuneQuoteSubmissionUrl,
  getCoalitionCyberDocumentUrl,
  getCoalitionCyberRiskAssessmentUrl,
  getDcpQuoteLetterUrl,
} from '../../../documents/models/document-urls.model';
import {
  getCoalitionCyberRiskAssessmentFileName,
  getQuoteLetterFileName,
  getQuoteSubmissionFileName,
} from '../../../documents/models/document-file-names.model';

@Component({
  selector: 'app-bundle-quote-detail.policy-pane',
  templateUrl: './bundle-quote-details.component.html',
})
export class BundleQuoteDetailsComponent implements OnInit {
  accountId: string;
  bundleUuid: string;

  nonBindingRole: boolean;

  bopQuote: AttuneBopFrontendQuoteWithLinks;
  cyberQuote: FrontendQuoteWithLinks;
  tooltipText = '';
  tooltipTop = 0;
  carrierDocuments: CoalitionDocument[] = [];
  isLoadingCarrierDocuments = false;

  showCyberDocuments = false;
  hasDomains = false;
  showSubmissionDownload = environment.showSubmissionDownload;
  sub: Subscription = new Subscription();

  showEditDropdown = false;
  isUnBundleTooltipDismissed = true;

  bopQuoteLetterDownload$: Observable<any>;
  bopQuoteLetterDownloadReady$: Observable<any>;
  cyberQuoteLetterDownload$: Observable<any>;
  cyberQuoteLetterDownloadReady$: Observable<any>;
  bopSubmissionDownload$: Observable<any>;
  carrierDocumentsReady$: Observable<any>;
  coalitionRiskAssessmentDownload$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bundleService: BundleService,
    private sentryService: SentryService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private informService: InformService,
    private insuredAccountService: InsuredAccountService,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params['accountId'];
    this.bundleUuid = this.route.snapshot.params['bundleUuid'];
    this.bundleService
      .getBundle(this.bundleUuid)
      .subscribe((response: FrontendQuoteBundle | null) => {
        if (!response) {
          this.router.navigate(['/accounts', this.accountId]);
        }
        const bundle = response as FrontendQuoteBundle;
        bundle.quotes.forEach((quote) => {
          switch (quote.pasSource) {
            case 'coalition':
              this.cyberQuote = {
                ...quote,
                premium: quote.premium ? Number(quote.premium) : null,
              };
              this.showCyberDocuments = quoteStatusHasAssociatedComplianceDocuments(
                this.cyberQuote.status
              );
              this.hasDomains = quote?.details?.domains?.length > 0;
              return;
            case 'attune_gw':
              this.bopQuote = {
                ...quote,
                premium: quote.premium ? Number(quote.premium) : null,
              };
              return;
          }
        });

        if (this.cyberQuote.status === 'quoted') {
          this.loadCarrierDocuments(this.cyberQuote);
          const url = getDcpQuoteLetterUrl(this.cyberQuote.uuid);
          const fileName = getQuoteLetterFileName(this.cyberQuote.pasId, this.cyberQuote.product);
          this.cyberQuoteLetterDownloadReady$ = this.documentService.pollDocument(url);
          this.cyberQuoteLetterDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
        }

        if (this.bopQuote.status === 'quoted') {
          const url = getAttuneQuoteLetterUrl(this.bopQuote.pasId);
          const fileName = getQuoteLetterFileName(this.bopQuote.pasId, this.bopQuote.product);
          this.bopQuoteLetterDownloadReady$ = this.documentService.pollDocument(url);
          this.bopQuoteLetterDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
        }

        const url = getAttuneQuoteSubmissionUrl(this.bopQuote.pasId, this.accountId, 'bop');
        const fileName = getQuoteSubmissionFileName(this.bopQuote.pasId, this.bopQuote.product);
        this.bopSubmissionDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
      });

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
    this.setupUnBundleTooltip();
  }

  setupUnBundleTooltip() {
    const accountIds: DismissalRecords = JSON.parse(
      localStorage.getItem(UNBUNDLE_TOOLTIP_DISMISSAL_RECORDS_KEY) || '{}'
    );

    if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
      const { lastDismissedAt, doNotShow } = accountIds[this.accountId];

      const now = moment();
      this.isUnBundleTooltipDismissed = doNotShow || now.isSameOrBefore(lastDismissedAt, 'date');
    } else {
      this.isUnBundleTooltipDismissed = false;
    }
  }

  handleUnBundleTooltipClose() {
    const accountIds: DismissalRecords = JSON.parse(
      localStorage.getItem(UNBUNDLE_TOOLTIP_DISMISSAL_RECORDS_KEY) || '{}'
    );

    const today = moment().format(US_DATE_MASK);

    if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
      // if it already existed then this is the second time it is being dismissed
      accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: true };
    } else {
      accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: false };
    }

    localStorage.setItem(UNBUNDLE_TOOLTIP_DISMISSAL_RECORDS_KEY, JSON.stringify(accountIds));
  }

  editBop() {
    this.router.navigate([`/accounts/${this.accountId}/bop/policies/${this.bopQuote.pasId}/edit`], {
      queryParams: {
        cyberParentId: this.cyberQuote.uuid,
      },
    });
  }

  editCyber() {
    const product =
      this.cyberQuote.product === 'cyber_admitted' ? 'cyber-admitted' : 'cyber-surplus';
    this.router.navigate([`/accounts/${this.accountId}/${product}/new`], {
      queryParams: {
        fromQuote: this.cyberQuote.uuid,
        bopParentId: this.bopQuote.pasId,
      },
    });
  }

  unbundle() {
    this.bundleService.deleteBundle(this.bundleUuid).subscribe((result) => {
      if (!result) {
        this.informService.infoToast(
          'There was an error unbundling this bundle. If the issue persists, please contact our Customer Care Team.'
        );
      } else {
        this.router.navigate(['/accounts', this.accountId]);
      }
    });
  }

  loadCarrierDocuments(quote: FrontendQuote) {
    const status = quote.status;
    if (!quoteStatusHasAssociatedCarrierDocuments(status)) {
      return;
    }
    this.isLoadingCarrierDocuments = true;
    this.carrierDocumentsReady$ = this.digitalCarrierService
      .getCoalitionCyberDocuments('quote', quote.uuid)
      .pipe(
        tap((documents: CoalitionDocument[]) => {
          const documentsToShow = visibleCarrierDocumentsByQuoteStatus[status];
          this.carrierDocuments = documents.filter(({ documentType }) => {
            return documentsToShow.includes(documentType);
          });
          this.isLoadingCarrierDocuments = false;
        })
      );
    this.coalitionRiskAssessmentDownload$ = this.insuredAccountService.get(this.accountId).pipe(
      mergeMap((insuredAccount) => {
        const cyberRiskAssessmentUrl = getCoalitionCyberRiskAssessmentUrl(
          quote.uuid,
          insuredAccount.companyName
        );
        const cyberRiskAssessmentFileName = getCoalitionCyberRiskAssessmentFileName();
        return this.documentService.getDocument(
          cyberRiskAssessmentUrl,
          cyberRiskAssessmentFileName,
          'pdf'
        );
      })
    );
  }

  coalitionDocumentDownload$(documentType: CoalitionDocumentType, fileName: string) {
    const url = getCoalitionCyberDocumentUrl('quote', this.cyberQuote.uuid, documentType);
    return this.documentService.getDocument(url, fileName, 'pdf');
  }

  updateTooltip(event: any) {
    const targetElement = (event.toElement || event.relatedTarget) as Element;

    if (targetElement && this.hasDomains) {
      this.tooltipTop = event.y;
      this.tooltipText =
        'The domain scan is currently in progress to generate the Coalition Risk Assessment. This could take up to three to five minutes.';
    } else {
      this.hideTooltip();
    }
  }

  hideTooltip() {
    this.tooltipText = '';
  }

  handleErrorToast() {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
  }
}
