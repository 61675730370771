import { isEmpty, uniq } from 'lodash';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ProducerDetailsResponse } from 'app/bop/guidewire/typings';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import {
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
  UserService,
} from 'app/core/services/user.service';
import { first, combineLatest, Observable, Subscription, switchMap, timer } from 'rxjs';
import { AttuneWCQuoteService } from 'app/workers-comp/attune/services/attune-wc-quote.service';
import {
  ACCOUNT_MANAGERS,
  DEDUCTIBLE_OPTIONS,
  EMP_LIABILITY_LIMITS_OPTIONS,
  HIGH_HAZARD_GROUPS,
  HOT_PREFERRED_HAZARD_GROUPS,
  PREFERRED_HAZARD_GROUPS,
} from 'app/workers-comp/attune/constants';
import { DocumentService } from '../../../../features/documents/services/document.service';
import {
  getAttuneQuoteLetterUrl,
  getAttuneQuoteSubmissionUrl,
} from '../../../../features/documents/models/document-urls.model';
import {
  getQuoteLetterFileName,
  getQuoteSubmissionFileName,
} from '../../../../features/documents/models/document-file-names.model';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import {
  BOOLEAN_FLAG_NAMES,
  FeatureFlagService,
} from '../../../../core/services/feature-flag.service';

@Component({
  selector: 'app-attune-wc-quote-pane.policy-pane, app-attune-wc-quote-pane',
  templateUrl: './attune-wc-quote-pane.component.html',
  providers: [CurrencyPipe],
})
export class AttuneWcQuotePaneComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();

  accountNumber: string;
  quoteNumber: string;

  accountDetails: InsuredAccount;
  quoteDetails: QuoteDetails;
  uwIssues: string[] = [];

  experienceModifiersByState: {
    [state: string]: ExperienceModifier[];
  } = {};
  accountManager: typeof ACCOUNT_MANAGERS[0] | undefined = undefined;

  // Flags
  isDevMode = isDevMode();
  shouldDisplayRequoteButton = false;
  bindBlockStatus: 'non-preferred-no-access' | 'non-preferred-bind-block' | undefined = undefined;
  nonBindingRole: boolean = false;
  isSampleAccount: boolean = false;
  requoting = false;
  requoteError = false;
  archivingConfirmationPanelShowing = false;
  archiving = false;

  quoteLetterDownload$: Observable<any>;
  quoteLetterDownloadReady$: Observable<any>;
  quoteSubmissionDownload$: Observable<any>;

  quoteType = '';
  quoteTypeTip = '';
  isHotPreferredQuote = false;
  isPreferredQuote = false;
  isNonPreferredQuote = false;
  governingClassCssClass = '';

  // helpers for html template
  isEmpty = isEmpty;

  constructor(
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private bindService: GWBindService,
    private quoteService: AttuneWCQuoteService,
    private userService: UserService,
    private insuredAccountService: InsuredAccountService,
    private informService: InformService,
    private featureFlagService: FeatureFlagService,
    private currencyPipe: CurrencyPipe,
    private documentService: DocumentService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountNumber = params['accountId'];
            this.quoteNumber = params['quoteNumber'];
            return combineLatest([
              this.bindService.getQuoteDetails(this.quoteNumber),
              this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_WC_BIND_BLOCK),
              this.featureFlagService.isEnabled(
                BOOLEAN_FLAG_NAMES.ATTUNE_WC_NON_PREFERRED_NO_ACCESS
              ),
            ]);
          })
        )
        .subscribe(([quoteDetails, isBindBlockProducer, isNonPreferredNoAccess]) => {
          this.quoteDetails = quoteDetails;
          this.setUniqueUwIssues(quoteDetails);
          this.setExperienceModifiersByState(quoteDetails);
          this.updateRequoteDisplayStatus(quoteDetails);
          this.updateBindBlockStatus(quoteDetails, !!isBindBlockProducer, !!isNonPreferredNoAccess);
          this.initiateQuoteLetterObservables(quoteDetails.id);
          this.initiateQuoteSubmissionObservables(quoteDetails.id, this.accountNumber);
          this.setQuoteType(quoteDetails);
        })
    );

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          if (response.State) {
            this.accountManager = ACCOUNT_MANAGERS.find((accountManager) =>
              accountManager.states.includes(response.State)
            );
          }
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );

    this.sub.add(
      this.insuredAccountService.get(this.accountNumber).subscribe((account) => {
        this.accountDetails = account;
        this.isSampleAccount = account.sampleAccount || false;
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  formatCost(quoteDetails: QuoteDetails) {
    const isExpired = quoteDetails.status === 'Expired';
    if (isExpired) {
      return 'N/A';
    }
    const totalCost = quoteDetails.totalCost;
    return this.currencyPipe.transform(totalCost, 'USD', 'symbol', '1.2-2');
  }

  formatDeductible(deductibleStr: string) {
    const deductibleWithoutSuffix = this.removeUsdSuffix(deductibleStr);
    const formattedDeductible = DEDUCTIBLE_OPTIONS[deductibleWithoutSuffix];

    if (formattedDeductible) {
      return formattedDeductible;
    }

    // Fallback if someone quotes a deductible avail in GW but not in AP.
    return this.currencyPipe.transform(deductibleWithoutSuffix, 'USD', 'symbol', '1.0-0');
  }

  formatEmpLiabLimits(empLiabilityLimits: string) {
    const empLiabLimitsWithoutSuffix = this.removeUsdSuffix(empLiabilityLimits);
    const formattedLimits = EMP_LIABILITY_LIMITS_OPTIONS[empLiabLimitsWithoutSuffix];
    if (formattedLimits) {
      return formattedLimits;
    }

    // Fallback if someone quotes a limit avail in GW but not in AP.
    // Produces a string like `$100k / $200k / $100k`
    return empLiabLimitsWithoutSuffix
      .split('_')
      .map((limit) => `$${limit}k`)
      .join(' / ');
  }

  private removeUsdSuffix(covTermValue: string) {
    return covTermValue.replace('usd', '');
  }

  initiateQuoteLetterObservables(id: string) {
    const url = getAttuneQuoteLetterUrl(id);
    const filename = getQuoteLetterFileName(id, 'wc');
    this.quoteLetterDownload$ = this.documentService.getDocument(url, filename, 'pdf');
    this.quoteLetterDownloadReady$ = this.documentService.pollDocument(url);
  }

  initiateQuoteSubmissionObservables(quoteId: string, accountId: string) {
    const url = getAttuneQuoteSubmissionUrl(quoteId, accountId, 'wc');
    const fileName = getQuoteSubmissionFileName(quoteId, 'wc');
    this.quoteSubmissionDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
  }

  continueToBind() {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
    if (this.isSampleAccount) {
      this.informService.infoToast("We'll show you binding once you have made a real quote.");
      return;
    }

    this.router.navigate(['bind'], { relativeTo: this.route });
  }

  requote() {
    this.requoting = true;
    this.sub.add(
      this.quoteService
        .requote({
          tsRequestId: this.generateNewTsRequestId(),
          insuredAccount: this.accountDetails,
          quoteNumber: this.quoteNumber,
          // Not updating anything, requoting b/c address and/or named insured changed.
          dataToUpdate: {},
        })
        .subscribe({
          next: (quoteResp) => {
            this.requoting = false;
            if (!quoteResp.validQuote) {
              // Error logged in service
              this.requoteError = true;
              return;
            }
            this.insuredAccountService.cachebust();
            const newQuoteId = quoteResp.quoteNumber;
            return this.navigateToNewQuote(newQuoteId);
          },
          error: () => {
            // Error logged in service.
            this.requoteError = true;
            this.requoting = false;
          },
        })
    );
  }

  archiveQuote() {
    this.archiving = true;
    this.sub.add(
      this.insuredAccountService.archive(this.quoteNumber).subscribe(() => {
        this.informService.infoToast('Quote archived');
        this.insuredAccountService.cachebust();
        this.archiving = false;
        this.router.navigate(['/accounts', this.accountNumber]);
      })
    );
  }

  showArchivingConfirmationPanel() {
    const DELAY = 10000;
    this.archivingConfirmationPanelShowing = true;
    this.sub.add(
      timer(DELAY)
        .pipe(first())
        .subscribe(() => {
          this.archivingConfirmationPanelShowing = false;
        })
    );
  }

  navigateToNewQuote(quoteNumber: string) {
    return this.router.navigate(['../', quoteNumber], { relativeTo: this.route });
  }

  private updateRequoteDisplayStatus(quoteDetails: QuoteDetails) {
    this.requoteError = false;
    this.shouldDisplayRequoteButton = quoteDetails.needsRequote;
  }

  private updateBindBlockStatus(
    quoteDetails: QuoteDetails,
    isBindBlockProducer: boolean,
    isNonPreferredNoAccess: boolean
  ) {
    const bindableNonPreferredQuote =
      quoteDetails.bindable &&
      !!quoteDetails.hazardGroup &&
      HIGH_HAZARD_GROUPS.includes(quoteDetails.hazardGroup);
    if (!bindableNonPreferredQuote) {
      this.bindBlockStatus = undefined;
      return;
    }
    if (isNonPreferredNoAccess) {
      this.bindBlockStatus = 'non-preferred-no-access';
      this.amplitudeService.track({
        eventName: 'wc_bind_block_no_access_quote_pane',
        detail: quoteDetails.id,
      });
    } else if (isBindBlockProducer) {
      this.bindBlockStatus = 'non-preferred-bind-block';
      this.amplitudeService.track({
        eventName: 'wc_bind_block_quote_pane',
        detail: quoteDetails.id,
      });
    } else {
      this.bindBlockStatus = undefined;
    }
  }

  private generateNewTsRequestId() {
    return uuidv4();
  }

  private setUniqueUwIssues(quoteDetails: QuoteDetails) {
    // In some cases, UW issues are duplicated for quote/bind blocks, here we ensure each entry is unique.
    this.uwIssues = uniq(quoteDetails.uwIssues);
  }

  private setExperienceModifiersByState(quoteDetails: QuoteDetails) {
    if (!quoteDetails.experienceModifiers) {
      return;
    }

    quoteDetails.experienceModifiers.forEach((modifier) => {
      if (!this.experienceModifiersByState[modifier.state]) {
        this.experienceModifiersByState[modifier.state] = [];
      }
      this.experienceModifiersByState[modifier.state].push(modifier);
    });
  }

  get fein() {
    return this.getOfficialIdFromQuoteDetails('FEIN');
  }

  get riskId() {
    return this.getOfficialIdFromQuoteDetails('NCCIintrastate');
  }

  private getOfficialIdFromQuoteDetails(idType: string): string | null {
    const idEntry = this.quoteDetails.contact?.OfficialIDs?.Entry?.find(
      (id: { OfficialIDType: string; OfficialIDValue: string }) => id.OfficialIDType === idType
    );

    if (idEntry) {
      return idEntry.OfficialIDValue;
    }
    return null;
  }

  private setQuoteType(quoteDetails: QuoteDetails) {
    if (quoteDetails.hazardGroup) {
      if (HOT_PREFERRED_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
        const fireEmoji = '\u{1F525}';
        this.quoteType = `${fireEmoji}  Hot Class Quote`;
        this.quoteTypeTip =
          'This business is accepted and/or targeted. For more information, review our Underwriting Guidelines below.';
        this.isHotPreferredQuote = true;
        this.governingClassCssClass = 'governing-class-preferred';
      } else if (PREFERRED_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
        this.quoteType = 'Preferred Quote';
        this.quoteTypeTip =
          'This business is accepted and/or targeted. For more information, review our Underwriting Guidelines below.';
        this.isPreferredQuote = true;
        this.governingClassCssClass = 'governing-class-preferred';
      } else if (HIGH_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
        this.quoteType = 'Non-preferred Quote';
        this.quoteTypeTip =
          'This business is accepted on a limited basis. For more information, review our Underwriting Guidelines below.';
        this.isNonPreferredQuote = true;
        this.governingClassCssClass = 'governing-class-non-preferred';
      }
    }
  }
}
