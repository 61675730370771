<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote"
  product="wc"
  pasSource="employers"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insAccount"
  [sidebarTitle]="action === 'edit' ? 'Edit quote' : 'New quote'"
  [fillInHappyPath]="populateHappyPath.bind(this)"
>
</app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form" content>
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>
    <form (ngSubmit)="handleSubmit($event)" novalidate>
      <!-- NOTE: form-animation class already added to quote-feedback component -->
      <section *ngIf="isCurrentStep('quote-feedback')">
        <app-quote-feedback-form></app-quote-feedback-form>
      </section>

      <section *ngIf="isCurrentStep('basic-info')" class="form-animation">
        <h1>Basic info</h1>

        <div *ngIf="!feinFieldOnPolicyPage" class="form-split">
          <app-form-field-text
            inputId="employerIdentificationNumber"
            [form]="formService.form.get('basicInfo')"
            [submitted]="submitted()"
            labelText="FEIN"
            nameOfFormControl="employerIdentificationNumber"
            placeholder="00-0000000"
            appFeinMask
            (focusOut)="checkFEINValue()"
          >
          </app-form-field-text>
          <app-form-field-text
            inputId="yearsInBusiness"
            [form]="formService.form.get('basicInfo')"
            [submitted]="submitted()"
            labelText="Years in business"
            nameOfFormControl="yearsInBusiness"
            appDelimitedNumberMask
          >
          </app-form-field-text>
        </div>
        <app-form-field-text
          *ngIf="feinFieldOnPolicyPage"
          inputId="yearsInBusiness"
          [form]="formService.form.get('basicInfo')"
          [submitted]="submitted()"
          labelText="Years in business"
          nameOfFormControl="yearsInBusiness"
          appDelimitedNumberMask
        >
        </app-form-field-text>

        <app-form-field-select
          inputId="organizationType"
          labelText="Organization type"
          [form]="formService.form.get('basicInfo')"
          [readonly]="organizationTypeReadOnly"
          [submitted]="submitted()"
          [availableOptions]="formService.organizationTypes"
          nameOfFormControl="organizationType"
          labelText="Organization type"
        >
        </app-form-field-select>
        <div *ngIf="isFEINPlaceholderValue" class="dialog-text dialog-text__error">
          <strong>Is this a real FEIN number?</strong> FEINs are used to calculate premium, so
          please, no placeholder values. If you proceed with this FEIN, your account will be flagged
          for internal review.
        </div>

        <app-form-field-text
          inputId="yearsOfIndustryExperience"
          [form]="formService.form.get('basicInfo')"
          [submitted]="submitted()"
          labelText="Years of industry experience"
          nameOfFormControl="yearsOfIndustryExperience"
          appDelimitedNumberMask
        >
        </app-form-field-text>

        <app-number-of-locations
          [form]="formService.form.get('basicInfo')"
          [submitted]="submitted()"
          [currentNumberOfLocations]="locationsFormArray().length"
          (addLocations)="onAddLocations($event)"
          (removeLocations)="onRemoveLocations($event)"
        >
        </app-number-of-locations>
        <div
          *ngIf="tooManyLocations()"
          class="dialog-text dialog-text__warning dialog-text__warning-icon"
        >
          <span>
            We currently don't quote more than 6 locations online but our customer care team will be
            happy to through our
            <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">helpdesk</a> or over the
            phone (1-888-530-4650).
          </span>
        </div>
      </section>

      <section *ngIf="isCurrentStep('location-\\d')" class="form-animation">
        <app-wc-location-form
          [locationIndex]="locationIndex()"
          [locationFormGroup]="currentLocation()"
          [submitted]="submitted()"
        ></app-wc-location-form>
      </section>

      <section *ngIf="isCurrentStep('policy-info')" class="form-animation">
        <app-wc-policy-info-form
          [policyFormGroup]="formService.form.get('policyInfo')"
          [submitted]="submitted()"
        ></app-wc-policy-info-form>
      </section>

      <section *ngIf="isCurrentStep('loss-info')" class="form-animation">
        <app-wc-loss-form
          [lossInfoFormGroup]="formService.form.get('lossInfo')"
          [submitted]="submitted()"
        ></app-wc-loss-form>
      </section>

      <section *ngIf="isCurrentStep('underwriting-info')" class="form-animation">
        <app-wc-underwriting-form
          [underwritingFormGroup]="formService.form.get('underwritingInfo')"
          [locationsFormArray]="locationsFormArray()"
          [submitted]="submitted()"
        >
        </app-wc-underwriting-form>
      </section>

      <div class="form-footer" *ngIf="!tooManyLocations()">
        <div class="validation" *ngIf="submitted() && !isCurrentStepValid()">
          <div class="validation-message">Please fill out all required fields</div>
        </div>
        <div class="nav-button-group nav-button-group__left">
          <button
            *ngIf="!isFinalStep()"
            type="submit"
            class="button button__primary js-step-forward-button"
            [disabled]="loadingValuesForEdit()"
            [ngClass]="{ button__discouraged: !isCurrentStepValid() }"
          >
            Next
          </button>

          <button
            *ngIf="isFinalStep()"
            type="submit"
            class="button button__primary js-step-forward-button"
            [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
            [disabled]="isRequestingQuote"
          >
            <div class="button-loader" *ngIf="isRequestingQuote">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            <span *ngIf="isRequestingQuote">Submitting...</span>
            <span *ngIf="!isRequestingQuote">{{
              action === 'edit' ? 'Overwrite Quote' : 'Submit'
            }}</span>
          </button>
        </div>
      </div>
    </form>

    <ng-container *ngIf="isDevMode">
      <div class="adminz-only">
        <div>
          <strong>Current Form Status:</strong>
          {{ formService.form.status }}
        </div>
        <div>
          <strong>Current Step Valid?:</strong>
          {{ isCurrentStepValid() }}
        </div>
        <strong>Current Step:</strong>
        <pre>{{ currentStep | json }}</pre>
        <br />
        <strong>ERRORS</strong>
        <pre>{{ allErrors() | json }}</pre>
        <h3>FORM</h3>
        <pre>{{ formService.form.value | json }}</pre>
        <h3>PAYLOAD</h3>
        <hr />
        <pre>{{ policyData() | json }}</pre>
      </div>
    </ng-container>
  </div>
  <div class="app-page-form-side-rail"></div>
</div>

<app-quote-error-modal
  [open]="quoteResultModalOpen"
  (closeQuoteErrorModal)="handleQuoteResultModalClose($event)"
  [errors]="requestQuoteValidationErrors"
  [errorType]="getErrorType()"
>
  <ng-container *ngIf="!hasDisplayableQuoteValidationErrors()">
    <p class="js-wc-contact-team">
      We're sorry, there was an issue with this quote. Please contact our Customer Care team so that
      we can help finish your quote.
    </p>
  </ng-container>

  <ng-container *ngIf="hasDisplayableQuoteValidationErrors()">
    <p class="js-wc-retry-quote">
      We're sorry, there was an issue processing your request. Please retry your quote or contact
      our Customer Care team.
    </p>
  </ng-container>
</app-quote-error-modal>

<div class="quoting-game-container" *ngIf="isRequestingQuote">
  <app-quoting-game [finish$]="getQuoteSuccessSubject()"></app-quoting-game>
</div>
