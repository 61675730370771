export const hasNcciValidationError = (quoteErrors: (string | {})[]) => {
  const validationErrorMessages = [
    'multiple risk ids returned for federal employer identification number',
    'restricted fein entered',
  ];

  return quoteErrors.some((error) =>
    validationErrorMessages.some(
      (validationError) =>
        typeof error === 'string' && error.toLowerCase().includes(validationError.toLowerCase())
    )
  );
};
