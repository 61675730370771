import * as _ from 'lodash';
import * as moment from 'moment';
import { HiscoxQuoteStatus, HISCOX_API_VERSION } from './hiscox-types';

interface ErrorObjectKeys {
  [key: string]: string;
}

export const HISCOX_ERROR_CODES: { [key: string]: any } = {
  '000': 'Successful request.',
  '100': 'Merchant Identifier left blank or not valid. The transaction was not processed.',
  '200': 'Name not present',
  '310': 'Credit card number left blank or is incorrect', // failed Mod10 check
  '315': 'Credit card number is incorrect', // failed Mod10 check
  '320': 'Credit card type left blank or invalid',
  '330': 'Expiration month left blank',
  '340': 'Expiration year left blank',
  '350': 'CVV2 field submitted but does not match card',
  '355': 'CVV2 required but not present',
  '357': 'An invalid character was entered, such as a letter in a numeric field',
  '360': 'Payment declined by financial institution, or some other error has occurred',
  '370': 'Expiration date invalid',
};

export const HISCOX_POLICY_PAYMENT_PLAN_OPTIONS = {
  'One-time payment': 1,
  Monthly: 10,
};

export interface HiscoxPaymentRes {
  QuoteId: string;
  TransactionId: string;
  PaymentReference: string;
  IFrameURL: string;
  SessionId: string;
}
export interface HiscoxBackendPricedQuote {
  uuid: string;
  accountId: string;
  createdAt: string;
  effectiveDate?: string;
  quoteId: string;
  downpayment: number | null;
  premium: number | null;
  annual: number | null;
  monthly: number | null;
  version: HISCOX_API_VERSION;
  surcharge?: number | null;
  status: HiscoxQuoteStatus;
  declineReasons?: string[];
  referredReasons?: string[];
  origFrontendRequest?: any;
  hx_response_payload?: any;
  hx_request_payload?: any;
}

/**
 * code: "000"
 * message: "Success"
 * profileId: "cpt500994742102SB"
 * rurl: "https://www.chasepaymentechhostedpay-var.com/hpf/1_1/"
 * uID: "9DCCC1F8DEE4E71D55019D7B90756A11"
 *
 * uID = payment reference
 */

export interface HiscoxTransactionRes {
  code: string;
  message: string;
  profileId: string;
  rurl: string;
  uID: string;
}

export class HiscoxPricedQuote {
  uuid: string;
  quoteId: string;
  policyNumber: string;
  premium: number;
  downpayment: number;
  monthly: number;
  surcharge: number;
  createdAt: moment.Moment;
  effectiveDate: moment.Moment;
  status: HiscoxQuoteStatus;
  declineReasons?: string[];
  referredReasons?: string[];
  hasQuoteLetter = false;
  state: string;
  cob: string;
  version: HISCOX_API_VERSION;

  constructor(backendPricedQuote: HiscoxBackendPricedQuote) {
    Object.assign(
      this,
      _.pick(backendPricedQuote, [
        'uuid',
        'policyNumber',
        'effectiveDate',
        'quoteId',
        'premium',
        'downpayment',
        'monthly',
        'surcharge',
        'version',
        // TODO: eventually plumb in state and cob here once SQ is sending it
      ])
    );

    this.status = backendPricedQuote.status;

    this.state = _.get(
      backendPricedQuote,
      'hx_request_payload.InsuranceSvcRq.QuoteRq.StateOrProvCd',
      null
    );
    this.cob = _.get(
      backendPricedQuote,
      'hx_request_payload.InsuranceSvcRq.QuoteRq.ClassOfBusinessCd',
      null
    );

    if (_.get(backendPricedQuote, 'hx_response_payload.status', null) === 'DECLINED') {
      this.status = HiscoxQuoteStatus.DECLINED;
    }

    if (_.get(backendPricedQuote, 'hx_response_payload.status', null) === 'PENDING_REFER') {
      this.status = HiscoxQuoteStatus.REFERRED;
    }

    this.createdAt = moment.utc(backendPricedQuote.createdAt);
    this.effectiveDate = moment.utc(backendPricedQuote.effectiveDate);

    if (!_.isEmpty(backendPricedQuote.declineReasons)) {
      this.declineReasons = backendPricedQuote.declineReasons;
    }

    if (!_.isEmpty(backendPricedQuote.referredReasons)) {
      this.referredReasons = backendPricedQuote.referredReasons;
    }
  }

  isBound() {
    return this.status === HiscoxQuoteStatus.BOUND;
  }

  isQuoted() {
    return this.status === HiscoxQuoteStatus.QUOTED;
  }

  isDeclined() {
    return this.status === HiscoxQuoteStatus.DECLINED;
  }

  isReferred() {
    return this.status === HiscoxQuoteStatus.REFERRED;
  }

  isUnknown() {
    return this.status === HiscoxQuoteStatus.UNKNOWN;
  }
}
