// Libraries
import { Component, isDevMode, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Subscription,
  merge,
  timer,
  race,
  zip as zipObservables,
  concat,
  forkJoin,
  of as observableOf,
  Observable,
  ReplaySubject,
  interval,
  EMPTY,
  merge as observableMerge,
  combineLatest,
  of,
} from 'rxjs';
import {
  map,
  switchMap,
  bufferCount,
  mergeMap,
  take,
  startWith,
  tap,
  catchError,
} from 'rxjs/operators';
import { AttuneBopQuoteUpdateComponent } from 'app/features/attune-bop/components/attune-bop-quote-update/attune-bop-quote-update.component';
import { SentryService } from 'app/core/services/sentry.service';
import { UWAlertService } from 'app/shared/services/uw-alert.service';
import { environment } from 'environments/environment';

// Constants
import {
  ALL_PROF_LIABILITIES,
  AVAILABLE_BUSINESS_TYPES,
  AVAILABLE_EACH_COVERED_JOB_SITE_LIMITS,
  AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_10000,
  AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_25000,
  AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY,
  BOPV1_AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE_NY,
  AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE,
  AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_EACH_COMMON_CAUSE,
  AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES,
  AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES_UP_TO_1000000,
  AVAILABLE_LIMITS_PER_PERSON_OF_MEDICAL_EXPENSES,
  AVAILABLE_INSTALLATION_LIMITS,
  AVAILABLE_TOOLS_BLANKET_LIMITS,
  AVAILABLE_TOOLS_SUBLIMITS,
  BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000,
  DEFAULT_FRAUD_LAUNGUAGE,
  EXCESS_LIABILITY_LIMITS,
  FRAUD_LANGUAGE_BY_STATE,
  BOPV1_MAX_LIQUOR_LIABILITY_AGGREGATE_NY,
  MAX_LIQUOR_LIABILITY_AGGREGATE,
  PREFILL_REQUEST_GROUP_SIZE,
  PREFILL_TIMEOUT_MS,
  REQUIRED_BOPV1_WIND_COVERAGE_STATES,
  REQUIRED_BOPV2_WIND_COVERAGE_STATES,
  UW_INTERVENTION_BUILDING_LIMIT_TEMPLATE,
  UW_INTERVENTION_BPP_TEMPLATE,
  BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD,
  BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD,
  AVAILABLE_PROPERTY_DEDUCTIBLES,
  AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_HIGH,
  AVAILABLE_PROPERTY_DEDUCTIBLES_HIGH,
  AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_CA,
  ELECTRONICS_STORE_CLASS_CODE,
  UW_INTERVENTION_SALES_TEMPLATE,
} from 'app/features/attune-bop/models/constants';
import { PLACEHOLDER_ORG_TYPE, US_DATE_MASK, ISO_DATE_MASK } from 'app/constants';
import { POPULAR_CLASS_CODES } from 'app/features/attune-bop/models/constants-popular-class-codes';

import { RetrieveQuoteResponse } from 'app/bop/guidewire/typings';

// Services
import {
  BopPolicy,
  BopQuotePayload,
  BopQuoteFormValue,
} from 'app/features/attune-bop/models/bop-policy';
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import {
  BuildingVerificationResponse,
  AttuneBopBuildingVerificationService,
} from 'app/features/attune-bop/services/attune-bop-building-verification.service';
import { EligibilityService } from 'app/shared/services/eligibility.service';
import { AttuneBopExcessQuoteService } from 'app/features/attune-bop/services/attune-bop-excess-quote.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InformService } from 'app/core/services/inform.service';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  DraftQuote,
  DraftQuoteService,
  DraftInsuranceProduct,
  DraftOrigin,
} from 'app/shared/services/draft-quote.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { UserService } from 'app/core/services/user.service';
// Models
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  shouldShowInvalid,
  getControl,
  subscribeToControlValueChanges,
  getAddress,
  enableDisableControl,
} from 'app/shared/helpers/form-helpers';
import { addressesEqual } from 'app/features/attune-bop/models/form-validators';
import { getBuildingClassificationsForLocations } from 'app/features/attune-bop/models/classification-helpers';
import { effectiveDateToPeriodDate } from 'app/features/attune-bop/models/date-helpers';
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { PrefillService } from 'app/shared/services/prefill.service';
import { BopPricedQuote } from '../../models/bop-priced-quote';
import {
  buildingClassifications,
  BuildingClassificationInfo,
} from 'app/features/attune-bop/models/building-classifications';
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import { RewardsService } from 'app/shared/services/rewards.service';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import {
  disallowAccountEdits,
  shouldGetBopV2,
  shouldGetMeToo,
} from 'app/shared/helpers/account-helpers';
import { WcQuoteService } from 'app/workers-comp/employers/services/workers-comp-quote.service';
import { DEFAULT_SAMPLE } from 'app/shared/sample-accounts/sample-accounts';
import { NaicsService } from '../../../../shared/services/naics.service';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import {
  INFORMATIONAL_NAMES,
  NewInformationalService,
} from 'app/shared/services/new-informational.service';
import { FullstoryService } from 'app/core/services/fullstory.service';
import { parseMaskedInt, parseMoney } from 'app/shared/helpers/number-format-helpers';
import {
  ProductAvailability,
  ProductCombination,
  CoalitionCyberAdmittedProduct,
  isDraftQuoteResponse,
  QuoteResponse,
  AttuneInsuredAccount,
} from 'app/features/digital-carrier/models/types';
import { UWInterventionAlert } from '../../components/attune-bop-estimated-replacement-cost-modal/attune-bop-estimated-replacement-cost-modal.component';
import {
  CyberQuestionEnablementFlag,
  CyberIndustryData,
  CyberQuestionEnablementFlags,
  CoalitionCyberQuestion,
  CoalitionCyberFormDataFormatted,
  UpsellEarlyDeclineKey,
} from '../../../coalition/models/cyber-typings.model';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { InsuredAccountSummaryService } from '../../../insured-account/services/insured-account-summary.service';
import { AmplitudePayloadOverride } from '../../../../core/constants/amplitude-helpers';
import { sanitizeDomain } from '../../../coalition/models/cyber-form-helpers.model';
import {
  CYBER_DOMAIN_REGEX,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../../coalition/models/cyber-constants.model';
import { BundleService } from '../../../bundle/services/bundle.service';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';
import { determineBopIndustryGroup } from '../../models/quoting-helpers';

@Component({
  selector: 'app-attune-bop-quote-new-form-page.app-page.app-page__form',
  templateUrl: './attune-bop-quote-new-form-page.component.html',
  providers: [AttuneBopQuoteFormService],
})
export class AttuneBopQuoteNewFormPageComponent
  extends AttuneBopQuoteUpdateComponent
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  wcPromptForm: UntypedFormGroup;
  availableClassificationSpecificLiabilities: string[] = [];
  organizationTypes: { [key: string]: string } = {};
  accountId: string;
  tsRequestId: string;
  insAccount$: BehaviorSubject<InsuredAccount> = new BehaviorSubject(new InsuredAccount());
  declinedQuote: BopPricedQuote | undefined;
  isDevMode = isDevMode();
  uwDecisionLoading = false;
  buildingVerificationLoading = false;
  quoteResultModalOpen = false;
  quoteTimeout = false;
  quoteError = false;
  quoteIssue = false;
  createQuoteFromMostRecentQuote = false;
  quoteIssueMessage: string;
  isoLocationError = false;
  isSampleQuote = false;
  retrievedQuote?: RetrieveQuoteResponse;
  originalTranslatedQuote: BopQuotePayload | null = null;
  isEditing = false;
  isEditingDraft = false;
  hasAddedEditDefaults = false; // Flag during edit quote to indicate we've prefilled in the original quote's details
  shouldSaveDraftForPrefillOutage = false;
  hideOrganizationType = false;
  wcQuoteExists = false;
  availableLimitsPerPersonOfMedicalExpenses = AVAILABLE_LIMITS_PER_PERSON_OF_MEDICAL_EXPENSES;
  availableLimitsForEmployeeDishonesty = AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY;
  availablePropertyDeductiblesForLocation: number[][] = [];
  businessIncomeAndExtraExpensesIndemnityInMonthsOptions =
    BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS;
  availableEachCoveredJobSiteLimits = AVAILABLE_EACH_COVERED_JOB_SITE_LIMITS;
  availableLimitsForLiquorLiabilityEachCommonCause =
    AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_EACH_COMMON_CAUSE;
  availableLimitsForLiquorLiabilityAggregate = AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE;
  availableExcessLiabilityLimits = EXCESS_LIABILITY_LIMITS;
  availableInstallationLimits = AVAILABLE_INSTALLATION_LIMITS;
  availableToolsBlanketLimits = AVAILABLE_TOOLS_BLANKET_LIMITS;
  availableToolsSubLimits = AVAILABLE_TOOLS_SUBLIMITS;
  loading = true;
  currentStep: RouteFormStep;
  uwQuestionsHaveValidAnswers = false;
  excessLiabiltyQuotable$ = new BehaviorSubject<boolean>(false);
  guidelinesData: string[] = [];
  additionalGuidelines: string[] = [];
  naicsIsoSuggestions: AskKodiakISOSuggestions;
  displayWindDeclineModal = false;
  displayNewOrOverwriteQuoteModal = false;
  displayMixedCarrierDeclineModal = false;
  displayBopCatDeclineModal = false;
  displayEstimatedReplacementCostModal = false;
  displayCyberPriceComparisonModal = false;
  displayDeductibleChangedWarning = false;
  quoteOverrideDeclineModalOpen = false;
  lroFirstTimeModalOpen = false;
  uwInterventionAlerts: UWInterventionAlert[] = [];
  showEffectiveDateTip = false;
  isBetween = false;
  isHoveringOnDateTooltip = false;
  displayGuidelinesRecheckModal = false;
  displayClassCodeIneligibleModal = false;
  classCode = '';
  guidelinesRecheckError = false;
  requiredV1WindCoverageStates = REQUIRED_BOPV1_WIND_COVERAGE_STATES.join(', ');
  requiredV2WindCoverageStates = REQUIRED_BOPV2_WIND_COVERAGE_STATES.join(', ');
  windDeclineModalState = '';
  eligibilityCheckLoading = false;
  showWCQuotePrompt = false;
  wcCrossSellPromptProducers = environment.wcCrossSellPromptProducers;
  userProducerCode: string;
  currentDraftId$: ReplaySubject<string> = new ReplaySubject(1);
  currentDraftId: string | null;
  lastSavedDraftFormValue: BopQuoteFormValue;
  isEffectiveDateInPast = false;
  isEffectiveDateNinetyDaysInFuture = false;
  productAvailabilities: ProductAvailability[];
  isProductAvailabilitiesLoading = true;
  cyberNaicsMappings: string[] = [];
  displayHnoaIneligibleModal = false;
  buildingPrefillIsEnabled = true;

  /**
   * cyberParentId is provided via query param
   * When provided indicates quote form is in bundle quote edit flow.
   * Id of the cyber quote to clone to create a new bundle with bop quote result.
   */
  cyberParentId: string;
  cyberQuoteWasAttempted = false;
  cyberDefaultForAmplitudeEvent: 'defaulted_to_standalone';
  cyberUpsellIsEnabled = false;
  flagsFromIndustryData: CyberQuestionEnablementFlags;

  showDomain = false;
  domainFormGroup = new UntypedFormGroup({
    domain: new UntypedFormControl(),
  });

  // Will be used to provide estimates on the Cyber Price Estimator for Cyber Upsell
  activeCyberPrice: number;
  endorsementPrice: number;

  public now = moment().startOf('day').add(12, 'hours');

  public currentYear = moment().year();

  protected sub: Subscription = new Subscription();
  protected prefillAddressMap: Map<number, Address> = new Map<number, Address>();

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public formService: AttuneBopQuoteFormService,
    private formBuilder: UntypedFormBuilder,
    protected bopQuoteService: AttuneBopQuoteService,
    protected buildingVerificationService: AttuneBopBuildingVerificationService,
    protected sentryService: SentryService,
    protected bundleService: BundleService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    protected excessQuoteService: AttuneBopExcessQuoteService,
    private featureFlagService: FeatureFlagService,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected prefillService: PrefillService,
    protected amplitudeService: AmplitudeService,
    protected fullstoryService: FullstoryService,
    protected usStateService: UsStateService,
    protected requestQuoteService: AttuneBopQuoteRequestService,
    protected rewardsService: RewardsService,
    protected draftQuoteService: DraftQuoteService,
    protected bindService: GWBindService,
    protected organizationTypeService: OrganizationTypeService,
    protected wcQuoteService: WcQuoteService,
    private userService: UserService,
    private newInformationalService: NewInformationalService,
    protected naicsService: NaicsService,
    protected uwAlertService: UWAlertService,
    protected onboardingService: OnboardingService,
    protected eligibilityService: EligibilityService,
    protected quoteService: DigitalCarrierQuoteService,
    private accountSummaryService: InsuredAccountSummaryService,
    private segmentService: SegmentService
  ) {
    super(
      insuredAccountService,
      requestQuoteService,
      amplitudeService,
      fullstoryService,
      router,
      rewardsService,
      sentryService,
      bundleService,
      quoteService,
      onboardingService
    );
    this.form = this.formService.form;

    this.organizationTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  fromAccountCreate() {
    return !!(this.route.snapshot && this.route.snapshot.queryParams['from-account-create']);
  }

  // Set to true in the case where we are overriding eligibility to always be true
  // (for the sake of gathering data even about illegible class codes)
  isEligibilityOverride() {
    return !!localStorage.getItem(`${BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }

  isEligibilityClassOverride() {
    return !!localStorage.getItem(`${BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }

  overrideAllBuildingClassifications() {
    const locations = this.locationsFormArray();
    for (let i = 0; i < locations.length; i++) {
      const latestLocation = locations.at(i) as UntypedFormGroup;
      const buildings = latestLocation.get('buildings') as UntypedFormArray;
      for (let j = 0; j < buildings.length; j++) {
        const latestBuilding = buildings.at(j) as UntypedFormGroup;
        latestBuilding.patchValue({
          exposure: {
            businessType: 'Wholesaler',
            classification: {
              code: '50061',
              descriptionCode: 'ApplianceDistbtrsHouseholdTypesRadioTelevisionComp',
            },
          },
        });
      }
    }
  }

  initiateEligibilityOverride() {
    localStorage.setItem(`${BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`, 'true');
  }

  isFinalStep() {
    return this.formService.isFinalStep();
  }

  isFirstStep() {
    return this.formService.isFirstStep();
  }

  // TODO? These methods are the same as in the ConsumerBopFormComponent
  clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  handleSubmit(submitEvent?: Event) {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    this.formService.stepForward();
  }

  generateRequestId() {
    const requestId = uuidv4();
    this.updateRequestId(requestId);
  }

  updateRequestId(requestId: string) {
    this.tsRequestId = requestId;
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  // END Copy/Paste from ConsumerBopFormComponent
  handleNavigateToSlug(slug: string) {
    if (slug === 'account') {
      this.returnToAccountCreate();
      return;
    }

    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  submitted() {
    return this.formService.submitted;
  }

  returnToAccountCreate() {
    this.router.navigate(['/accounts/new'], {
      queryParams: { 'existing-account': this.accountId },
    });
  }

  isNavigating() {
    return this.currentStep !== this.formService.getCurrentStep();
  }

  // TODO (olex|wil): Break out the sections into components and use routes to move between form steps
  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    const result = currentSlug ? currentSlug.match(slugRegex) : false;
    return result;
  }

  baseState(): string | null {
    const baseStateControl: AbstractControl | null = this.formService.get('policyInfo.baseState');
    return baseStateControl ? baseStateControl.value : null;
  }

  bopVersion(): BopVersion {
    return this.formService.bopVersion();
  }

  meToo(): boolean {
    return this.formService.meToo();
  }

  getProductBadgeName(): string {
    if (this.bopVersion() === 2 || this.meToo()) {
      return 'bop-plus';
    }
    return 'bop';
  }

  getBopVersionBasedOnStates(): BopVersion {
    const effectiveDate = this.formService.get('policyInfo.effectiveDate')?.value;
    const effectiveDateObj = effectiveDate ? moment(effectiveDate, US_DATE_MASK) : moment();
    if (this.locationAddressesFormArray().length < 1) {
      if (this.baseState()) {
        return shouldGetBopV2(this.baseState(), effectiveDateObj) ? 2 : 1;
      }
      return shouldGetBopV2(this.insAccount$.getValue().state) ? 2 : 1;
    }
    const everyLocationShouldGetBopV2 = this.locationAddressesFormArray().value.every(
      (address: Address) => {
        return shouldGetBopV2(address.state, effectiveDateObj);
      }
    );
    return everyLocationShouldGetBopV2 ? 2 : 1;
  }

  isMixedCarrierQuote(): boolean {
    if (this.locationAddressesFormArray().length < 2) {
      return false;
    }
    const effectiveDate = this.formService.get('policyInfo.effectiveDate')?.value;
    const effectiveDateMoment = effectiveDate ? moment(effectiveDate, US_DATE_MASK) : moment();

    const hasBOPV1States = this.locationAddressesFormArray().value.some((address: Address) => {
      return !shouldGetBopV2(address.state, effectiveDateMoment);
    });

    const hasBOPV2States = this.locationAddressesFormArray().value.some((address: Address) => {
      return shouldGetBopV2(address.state, effectiveDateMoment);
    });

    return hasBOPV1States && hasBOPV2States;
  }

  isCurrentStepValid() {
    return this.formService.isCurrentStepValid();
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields';
  }

  private prefill$() {
    const { companyName, doingBusinessAs, website, phoneNumber, naicsCode } =
      this.insAccount$.getValue();
    const naicsHash = naicsCode ? naicsCode.hash : '';

    this.amplitudeService.track({
      eventName: 'prefill_attempt',
      detail: '',
      useLegacyEventName: true,
    });

    const prefillRequestGroups = _.chunk(
      this.locationAddressesFormArray().value,
      PREFILL_REQUEST_GROUP_SIZE
    );

    return concat(
      ...prefillRequestGroups.map((prefillRequestGroup) => {
        return forkJoin(
          ...prefillRequestGroup.map((address: Address) =>
            race(
              this.prefillService.fetch(
                address,
                companyName,
                this.tsRequestId,
                this.accountId,
                'AGENT_PORTAL',
                this.meToo() ? 2 : this.bopVersion(),
                doingBusinessAs,
                website,
                phoneNumber,
                naicsHash
              ),
              timer(PREFILL_TIMEOUT_MS).pipe(map(() => null))
            )
          )
        );
      })
    ).pipe(
      bufferCount(prefillRequestGroups.length),
      tap({
        next: (res: Array<Array<PrefillData | null>>) => {
          const consolidatedResponses = _.flatten(res);
          const successfulResponses = consolidatedResponses.filter(
            (prefillData: PrefillData) => prefillData !== null
          ) as PrefillData[];
          if (successfulResponses.length > 0) {
            this.shouldSaveDraftForPrefillOutage = false;
            const declineReasons = this.gatherDeclineReasons(successfulResponses);
            successfulResponses.forEach((prefillData: PrefillData, i: number) => {
              this.prefillForm(prefillData, i);
            });

            if (!this.shouldSkipUwDecision() && declineReasons.length) {
              return this.showUwDecisionErrorModal(declineReasons);
            }
          }

          if (successfulResponses.length < res.length) {
            // If locations have prefill data already, then we don't need to go to draft mode.
            const locationsFormArray = this.locationsFormArray();
            const locations: BopLocation[] = locationsFormArray.value;

            const hasPrefillData = (location: BopLocation) => {
              return !_.isEmpty(location.locationPrefill);
            };
            if (!locations.every(hasPrefillData)) {
              this.informService.warnToast(
                'Due to a system outage some fields might be unavailable. Feel free to complete it as a draft now or try again later.',
                null,
                'This quote is in draft mode.',
                'Got it',
                null,
                0
              );
              this.amplitudeService.track({
                eventName: 'prefill_failed',
                detail: '',
                useLegacyEventName: true,
              });
              this.shouldSaveDraftForPrefillOutage = true;
            }
          } else {
            this.amplitudeService.track({
              eventName: 'prefill_success',
              detail: '',
              useLegacyEventName: true,
            });
          }
        },
      }),
      catchError(() => {
        this.informService.warnToast(
          'Due to a system outage some fields might be unavailable. Feel free to complete it as a draft now or try again later.',
          null,
          'This quote is in draft mode.',
          'Got it',
          null,
          0
        );
        this.amplitudeService.track({
          eventName: 'prefill_failed',
          detail: '',
          useLegacyEventName: true,
        });
        this.shouldSaveDraftForPrefillOutage = true;
        return of(null);
      })
    );
  }

  public shouldSkipUwDecision() {
    // Returns true for quotes that are edits AND iff the addresses have not changed
    if (
      !this.isEditing ||
      !this.originalTranslatedQuote ||
      !this.originalTranslatedQuote.locations
    ) {
      // TODO (olex | dtag): Draft quote handling
      return false;
    }

    const currentFormAddresses = this.locationAddressesFormArray().value;
    const sameNumberOfLocations =
      this.originalTranslatedQuote.locations.length === currentFormAddresses.length;

    if (!sameNumberOfLocations) {
      return false;
    }

    return _.every(
      this.originalTranslatedQuote.locations.map((location, idx) => {
        const locAddr =
          location && location.locationDetails
            ? (location.locationDetails as BopLocationDetails)
            : null;
        return locAddr && addressesEqual(locAddr, currentFormAddresses[idx]);
      })
    );
  }

  private gatherDeclineReasons(locationPrefillDatas: PrefillData[]) {
    return locationPrefillDatas.map(this.getUwDeclinedReasonsForLocation).filter(Boolean);
  }

  private getUwDeclinedReasonsForLocation(prefillData: PrefillData, locationIndex: number): string {
    const riskDeclineReason = _.get(prefillData, 'response.uwDecisionData.riskDeclineReason', []);

    return riskDeclineReason.length
      ? `Location ${locationIndex + 1}: ${riskDeclineReason.join(' | ')}`
      : '';
  }

  private showUwDecisionErrorModal(declineReasons: string[] = []) {
    // Delete draft on early declines.
    if (this.currentDraftId) {
      this.sub.add(this.draftQuoteService.delete(this.accountId, this.currentDraftId).subscribe());
    }

    if (environment.storePrefillDeclineDraftQuotes) {
      this.savePrefillDeclineDraftQuote(declineReasons);
    }
    if (this.requestQuoteService.isQuoteInProgress()) {
      this.requestQuoteService.hideQuoteGame();
    }
    this.underWritingDeclineReasons = this.underWritingDeclineReasons.concat(declineReasons);
    this.quoteResultModalOpen = true;
    this.amplitudeService.track({
      eventName: 'prefill-decline',
      detail: JSON.stringify(this.underWritingDeclineReasons),
      useLegacyEventName: true,
    });
  }

  // Counted from 1, user-visible location index
  locationIndex(): number | null {
    const currentStepSlug = this.currentStep.slug;
    if (/^location-\d+/.test(currentStepSlug) || /^building-\d+-\d+/.test(currentStepSlug)) {
      return Number(currentStepSlug.split('-')[1]);
    } else if (/^policy-info/.test(currentStepSlug)) {
      return 1;
    }

    return null;
  }

  // LOCATION RELATED CODE

  // Counted from 1, user-visible building index
  buildingIndex() {
    const currentStepSlug = this.currentStep.slug;
    if (/^building-\d+-\d+/.test(currentStepSlug)) {
      return Number(currentStepSlug.split('-')[2]);
    }

    return null;
  }

  protected locationSize(): number {
    return this.locationsFormArray().length;
  }

  protected locationGet(index: number): AbstractControl {
    return <AbstractControl>this.locationsFormArray().at(index - 1);
  }

  currentLocation(): UntypedFormGroup | null {
    const locationsArray = this.locationsFormArray();
    const loc = this.locationIndex();
    if (loc) {
      return locationsArray && <UntypedFormGroup>locationsArray.at(loc - 1);
    }
    return null;
  }

  currentLocationDetails(): UntypedFormGroup | null {
    const locationFormGroup: UntypedFormGroup | null = this.currentLocation();
    return locationFormGroup ? <UntypedFormGroup>locationFormGroup.get('locationDetails') : null;
  }

  currentBuilding(): UntypedFormGroup | null {
    const loc = this.locationIndex();
    const building = this.buildingIndex();

    if (loc && building) {
      return this.buildingsFormArray(loc - 1).at(building - 1) as UntypedFormGroup;
    }
    return null;
  }

  currentBuildingExposure() {
    return (<UntypedFormGroup>this.currentBuilding()).get('exposure');
  }

  currentBuildingLessorsRisk() {
    return (<UntypedFormGroup>this.currentBuilding()).get('lessorsRisk');
  }

  currentBuildingCoverage() {
    return (<UntypedFormGroup>this.currentBuilding()).get('coverage');
  }

  reviseBuildingLimitFromEstimate(interventions: UWInterventionAlert[]) {
    this.displayEstimatedReplacementCostModal = false;

    if (interventions && interventions.length > 0) {
      interventions.forEach((intervention: UWInterventionAlert) => {
        if (intervention.userCorrection && intervention.field) {
          intervention.field.patchValue(intervention.userCorrection);
        }
      });

      this.sub.add(this.checkEligibility().subscribe());
      this.navigateToCurrentStep();
    } else {
      this.clickBackward();
    }
  }

  // END LOCATION RELATED CODE

  ngOnInit() {
    const today = moment().utc();
    this.generateRequestId();
    this.accountId = this.route.snapshot.params['accountId'];
    this.sub.add(
      this.route.queryParams.subscribe((queryParams) => {
        this.cyberParentId = _.get(queryParams, 'cyberParentId', null);
        const selectCyberCoverages = getControl(
          this.formService.form,
          'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage'
        );
        if (selectCyberCoverages && this.cyberParentId) {
          selectCyberCoverages.setValue('none');
        }
      })
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        this.userProducerCode = user.producer;
        // Sets the agent name in BOP quote form.
        // This control will only be visible when the Base State is Florida.
        this.formService.setAgentName(fullName);
      })
    );

    this.sub.add(
      this.onboardingService
        .featureEnabled()
        .subscribe((sampleQuotesEnabled) => this.setUpSampleQuoteInformation(sampleQuotesEnabled))
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.COALITION_CYBER_STANDALONE_UPSELL)
        .subscribe((upsellIsEnabled) => {
          this.cyberUpsellIsEnabled = !!upsellIsEnabled;
        })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.BOP_BUILDING_PREFILL)
        .subscribe((prefillEnabled) => {
          // If LaunchDarkly returns null due to outage/misconfiguration, keep prefill at the default (enabled) and log an amplitude event
          if (prefillEnabled !== null) {
            this.buildingPrefillIsEnabled = !!prefillEnabled;
          } else {
            this.amplitudeService.track({
              eventName: 'building_prefill_flag_null',
              detail: '',
            });
          }
        })
    );

    if (!this.isEditing) {
      this.initializeForm();
    }

    if (this.locationSize() === 0) {
      this.addLocation();
    }

    this.sub.add(
      this.formService.submittedForm$.subscribe(() => {
        if (this.shouldSaveDraftForPrefillOutage) {
          this.savePrefillOutageDraftQuote();
        } else {
          if (this.isEditing && !this.isEditingDraft && !this.cyberParentId) {
            this.displayNewOrOverwriteQuoteModal = true;
          } else {
            this.resetQuoteResults();
            // Begin quote game while eligibility runs
            this.requestQuoteService.quoteInProgress = true;
            this.sub.add(
              this.checkIfCanQuoteWC().subscribe((canQuoteWC) => {
                if (canQuoteWC) {
                  this.amplitudeService.track({
                    eventName: 'showing_wc_cross_sell_prompt',
                    detail: '',
                    useLegacyEventName: true,
                  });
                  this.showWCQuotePrompt = true;
                }
              })
            );
            // Check eligibility one final time before quoting
            this.sub.add(
              this.checkEligibility().subscribe(() => {
                if (this.underWritingDeclineReasons.length === 0) {
                  this.submitQuote();
                }
              })
            );
          }
        }
      })
    );

    this.sub.add(
      this.formService.incrementedStep$.subscribe((nextStep: RouteFormStep) => {
        this.onIncrementedStep(nextStep);
      })
    );

    this.sub.add(
      this.formService.decrementedStep$.subscribe((prevStep: RouteFormStep) => {
        this.onDecrementedStep(prevStep);
      })
    );

    const setInsuredAccount = (insuredAccount: InsuredAccount) => {
      if (this.accountId === insuredAccount.id.toString()) {
        this.insAccount$.next(insuredAccount);
        this.sendSegmentEvent('Quote Started');
      }
    };

    this.loading = true;
    this.insuredAccountService.get(this.accountId).subscribe(setInsuredAccount, (error) => {
      console.warn('*** error fetching Account ***' + error.ToString());
      this.loading = false;
    });

    this.sub.add(
      this.draftQuoteService.get(this.accountId).subscribe((drafts: DraftQuote[]) => {
        const currentDraft = drafts.filter((draft) => draft.product === 'BOP')[0];
        if (this.isEditingDraft) {
          const formData = currentDraft.formData as any;
          const formerEffectiveDate = moment(formData.policyInfo.effectiveDate, US_DATE_MASK);
          if (formerEffectiveDate.isBefore(today)) {
            formData.policyInfo.effectiveDate = today.format(US_DATE_MASK);
          }
          this.lastSavedDraftFormValue = formData;
          this.formService.patchForm(formData, null);
          if (currentDraft.formData.uuid) {
            this.updateRequestId(currentDraft.formData.uuid);
          }
          this.currentDraftId$.next(currentDraft.id);
        }

        if (!this.isEditingDraft && !this.isEditing) {
          // save tsRequestId with draft form data
          this.formService.form.value.uuid = this.tsRequestId;
          this.lastSavedDraftFormValue = this.formService.form.value;
          this.draftQuoteService
            .create({
              accountId: this.accountId,
              formValue: this.formService.form.value,
              product: DraftInsuranceProduct.BOP,
              origin: DraftOrigin.QuoteFlow,
              portalViewable: true,
            })
            .subscribe((response: { id: string }) => {
              const draftId = response && response.id;
              if (draftId) {
                this.currentDraftId$.next(draftId);
              }
            });
        }
      })
    );

    this.sub.add(
      this.currentDraftId$.subscribe((currentDraftId: string) => {
        this.currentDraftId = currentDraftId;
      })
    );

    this.sub.add(
      this.naicsService.getProductAvailability().subscribe((resp) => {
        this.productAvailabilities = resp;
        this.formService.productAvailabilitiesSubject.next(resp);
        this.isProductAvailabilitiesLoading = false;
      })
    );

    this.sub.add(
      this.accountSummaryService
        .getSummary(this.route.snapshot.params.accountId)
        .subscribe((accountSummary) => {
          this.formService.accountSummarySubject.next(accountSummary);
        })
    );

    // Decide whether to show the annualRevenue question or not
    const stateControl = getControl(this.form, 'policyInfo.baseState');
    const stateControl$ = stateControl.valueChanges.pipe(startWith(stateControl.value));

    this.sub.add(
      combineLatest([this.insAccount$, stateControl$]).subscribe(
        ([account, state]: [InsuredAccount, string]) => {
          this.setAnnualRevenueDisplay(account, state);
        }
      )
    );

    this.sub.add(
      getControl(this.form, 'policyInfo.baseState')
        .valueChanges.pipe(
          switchMap((baseState: string) =>
            this.insuredAccountService.isProducerCatRestricted(this.userProducerCode, baseState)
          )
        )
        .subscribe((isRestricted) => {
          this.displayBopCatDeclineModal = isRestricted;
        })
    );

    // Decide whether to default to HSB endorsement (default) or None (NY)
    this.sub.add(
      getControl(this.form, 'policyInfo.baseState').valueChanges.subscribe((baseState: string) => {
        this.setDefaultCyberCoverage(baseState);
      })
    );

    const FIVE_SECONDS_IN_MS = 5 * 1000;
    // don't save drafts in edit mode
    if (!this.isEditing || this.isEditingDraft) {
      this.sub.add(
        interval(FIVE_SECONDS_IN_MS)
          .pipe(
            mergeMap(() => this.currentDraftId$),
            mergeMap((currentDraftId) => {
              const currentFormVal = this.formService.form.value;
              const lastDraft = this.lastSavedDraftFormValue;
              if (lastDraft && !_.isEqual(lastDraft, currentFormVal)) {
                // save tsRequestId with draft form data
                currentFormVal.uuid = lastDraft.uuid || this.tsRequestId;
                this.lastSavedDraftFormValue = currentFormVal;
                return this.draftQuoteService.update(
                  this.accountId,
                  currentDraftId,
                  currentFormVal
                );
              } else {
                return EMPTY;
              }
            })
          )
          .subscribe()
      );
    }

    this.sub.add(
      zipObservables(
        this.insuredAccountService.get(this.accountId),
        this.wcQuoteService.getQuote(this.accountId)
      )
        .pipe(
          map(([insuredAccount, wcQuoteWithDocuments]) => {
            this.hideOrganizationType = disallowAccountEdits(insuredAccount, wcQuoteWithDocuments);
            this.wcQuoteExists = wcQuoteWithDocuments && !!wcQuoteWithDocuments.quote;
          })
        )
        .subscribe()
    );

    const employeeBenefitsFormGroup = this.formService.get<UntypedFormGroup>(
      'liabilityCoverages.employeeBenefitsLiabilityCoverage'
    );

    getControl(employeeBenefitsFormGroup, 'optedIn').valueChanges.subscribe((value) => {
      const enableOrDisable = value ? 'enable' : 'disable';

      Object.keys(employeeBenefitsFormGroup.controls)
        .filter((formControlName) => formControlName !== 'optedIn')
        .forEach((formControlName) => {
          employeeBenefitsFormGroup.controls[formControlName][enableOrDisable]();
        });
    });

    // NOTE: Probably CANNOT go into form service - manages limits / ranges
    this.sub.add(
      this.formService.getBopExposure$().subscribe((exposureInfo: BopExposureInfo) => {
        this.determinePropertyDeductibleRange(exposureInfo);
        this.determineEmployeeDishonestyCoverageRange(exposureInfo);
      })
    );

    // NOTE: Probably CANNOT go into form service - manages limits / ranges
    merge(
      getControl(this.form, 'liabilityCoverages.liquorLiability.optedIn').valueChanges,
      getControl(this.form, 'liabilityCoverages.liquorLiability.eachCommonCauseLimit').valueChanges
    ).subscribe((_whatever: any) => {
      this.updateLiquorAggregate();
    });

    const excessLiabilityOptInControl = getControl(this.formService.form, 'excessLiabilityOptIn');
    this.sub.add(
      subscribeToControlValueChanges(excessLiabilityOptInControl, () => {
        this.formService.syncAllSteps();
        this.triggerExcessLiabiltyQuotable();
      })
    );

    zendeskLeftSnap();
    this.formService.syncAllSteps();
    this.navigateToCurrentStep();

    this.sub.add(
      this.onboardingService
        .featureEnabled()
        .subscribe((sampleQuotesEnabled) => this.fillInSampleQuoteInformation(sampleQuotesEnabled))
    );

    this.wcPromptForm = this.formBuilder.group({
      dontShowAgain: [null],
    });

    this.sub.add(
      this.insAccount$.subscribe((account) => {
        const domainControl = getControl(this.domainFormGroup, 'domain');
        domainControl.patchValue(account.website);
      })
    );

    this.setCyberUpsellListeners();
  }

  setAnnualRevenueDisplay(account: InsuredAccount, state: string) {
    const { naicsCode } = account;
    const annualRevenueControl = getControl(this.form, 'policyInfo.annualRevenue');

    if (naicsCode) {
      // Always display revenue in TX
      if (state && state === 'TX') {
        enableDisableControl(annualRevenueControl, true);
      } else {
        // Otherwise only display for the subset of BopBusinessCategory.OFFICE naics codes
        const popularOfficeBusinessCategory = POPULAR_CLASS_CODES.find((classCode) => {
          return classCode.businessCategory === 'Offices';
        });

        const officeBusinessCategoryClassCodes = popularOfficeBusinessCategory
          ? popularOfficeBusinessCategory.classCodes
          : [];

        const popularNaicsCodes = officeBusinessCategoryClassCodes
          .map((classCode) => {
            if (classCode.isEligible && classCode.naicsCode && classCode.naicsCode.code) {
              return classCode.naicsCode.code;
            }
          })
          .filter((popularNaicsCode) => !!popularNaicsCode);

        if (!popularNaicsCodes.includes(naicsCode.code)) {
          enableDisableControl(annualRevenueControl, true);
        } else {
          enableDisableControl(annualRevenueControl, false);
        }
      }
    } else {
      enableDisableControl(annualRevenueControl, false);
    }
  }

  hideEffectiveDateBlink() {
    if (this.hasSeenEffectiveDateInfo()) {
      return;
    }
    this.isHoveringOnDateTooltip = true;
    setTimeout(() => {
      if (!this.isHoveringOnDateTooltip) {
        return;
      }
      this.newInformationalService.incrementValue(
        INFORMATIONAL_NAMES.EFFECTIVE_DATE_RATING_WARNING
      );
    }, 1000);
  }

  cancelEffectiveDateBlinkHide() {
    this.isHoveringOnDateTooltip = false;
  }

  hasSeenEffectiveDateInfo() {
    return (
      this.newInformationalService.getValue(INFORMATIONAL_NAMES.EFFECTIVE_DATE_RATING_WARNING) > 0
    );
  }

  getClassificationCodeForFirstBuilding() {
    return getControl(this.formService.form, 'locations.0.buildings.0.exposure.classification');
  }

  public initializeForm() {
    this.addLocation();
    // show account create step immediately; guideline step will be added later, if applicable
    this.formService.showInitialSteps(this.fromAccountCreate(), false);

    const initializeFormLocations = (insuredAccount: InsuredAccount) => {
      if (this.isSampleQuote) {
        return;
      }

      if (insuredAccount.addressLine1) {
        const locationAddress = this.locationAddressesFormArray().at(0);
        locationAddress.patchValue({
          addressLine1: insuredAccount.addressLine1,
          addressLine2: insuredAccount.addressLine2,
          city: insuredAccount.city,
          state: insuredAccount.state,
          zip: insuredAccount.zip,
        });
      }
    };

    const initializeBaseState = (insuredAccount: InsuredAccount) => {
      if (this.isSampleQuote) {
        return;
      }

      const mailingAddressState = insuredAccount.state;
      if (mailingAddressState) {
        this.formService.form.patchValue({ policyInfo: { baseState: mailingAddressState } });
      }
    };

    const initializeFormOrgType = (insuredAccount: InsuredAccount) => {
      if (this.isSampleQuote) {
        return;
      }

      if (
        insuredAccount.organizationType &&
        insuredAccount.organizationType !== PLACEHOLDER_ORG_TYPE
      ) {
        this.formService.form.patchValue({
          policyInfo: { organizationType: insuredAccount.organizationType },
        });
      }
    };

    const setISOMappings = (insuredAccount: InsuredAccount) => {
      if (this.isEditing || this.isSampleQuote) {
        return;
      }

      this.bopQuoteService
        .getNaicsToIsoMappings(insuredAccount.naicsCode, insuredAccount.state)
        .subscribe(
          (data: AskKodiakISOSuggestions) => {
            this.naicsIsoSuggestions = data;
            const exposure = getControl(this.formService.form, 'locations.0.buildings.0.exposure');
            const businessTypeKey =
              data.categories[0] &&
              _.findKey(AVAILABLE_BUSINESS_TYPES, (v) => v === data.categories[0]);
            if (exposure) {
              if (businessTypeKey) {
                exposure.patchValue({ businessType: businessTypeKey });
              }
              const locationState = getControl(
                this.formService.form,
                'locations.0.locationDetails.state'
              );
              if (data.categories[0] === 'LRO' && locationState.value !== 'CA') {
                exposure.patchValue({ lessorsRisk: true });
              }
            }

            const classificationControl = this.getClassificationCodeForFirstBuilding();
            // AskKodiak returns a string version of the ISO building classification, formatted like "71332_BarberShops"
            const [code, descriptionCode] = data.classifications[0]
              ? data.classifications[0].split('_')
              : [null, null];

            // Ignore AskKodiak when overriding eligibility, and use a placeholder code
            if (this.isEligibilityClassOverride()) {
              classificationControl.patchValue({
                code: {
                  code: '50061',
                  descriptionCode: 'ApplianceDistbtrsHouseholdTypesRadioTelevisionComp',
                },
              });
              return;
            }

            if (businessTypeKey && code && descriptionCode) {
              const matchingCode = _.some(
                buildingClassifications,
                (c: BuildingClassificationInfo) =>
                  c.code === code &&
                  c.descriptionCode === descriptionCode &&
                  c.category === businessTypeKey
              );
              if (classificationControl && matchingCode) {
                classificationControl.patchValue({ code: { code, descriptionCode } });
              } else {
                if (!(data.classifications[0] === 'LRO')) {
                  this.sentryService.notify('Ask Kodiak supplied unusable ISO suggestion', {
                    severity: 'info',
                    metaData: {
                      isoSuggestion: data,
                      naicsCode: insuredAccount.naicsCode,
                    },
                  });
                }
              }
            }
          },
          (error) => {
            console.warn('*** error fetching NAICS to ISO mappings ***' + error);
          }
        );
    };

    [
      this.insAccount$.subscribe(initializeFormLocations),
      this.insAccount$.subscribe(initializeFormOrgType),
      this.insAccount$.subscribe(initializeBaseState),
      this.insAccount$.subscribe(this.setupGuidelines),
      this.insAccount$.subscribe(setISOMappings),
    ].forEach((s) => this.sub.add(s));
  }

  ngOnDestroy() {
    removeZendeskLeftSnap();
    this.amplitudeService.unsetQuoteTSIDs();
    this.sub.unsubscribe();
  }

  resetQuoteResults() {
    this.quoteError = false;
    this.quoteTimeout = false;
    this.quoteIssue = false;
    this.quoteIssueMessage = '';
    this.declinedQuote = undefined;
    this.isoLocationError = false;
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    if (eventName === 'Quote Started') {
      const insuredAccount = this.insuredAccountService.insuredSubject.getValue();
      // Note: class_code, which is vendor specific, is not available on "Quote Started"
      this.segmentService.track({
        event: eventName,
        properties: {
          product: 'bop',
          carrier: 'attune',
          class_code: '',
          naics_code: insuredAccount.naicsCode,
          industry: '',
          primary_state: insuredAccount.state,
          insured_address: {
            addressLine1: insuredAccount.addressLine1,
            addressLine2: insuredAccount.addressLine2,
            city: insuredAccount.city,
            state: insuredAccount.state,
            zip: insuredAccount.zip,
          },
          insured_email: insuredAccount.emailAddress,
          business_name: insuredAccount.companyName,
        },
      });
    } else if (eventName === 'Quote Attempted') {
      const insuredAccount = this.insuredAccountService.insuredSubject.getValue();
      this.formService.getBopExposure$().subscribe({
        next: (exposureInfo: BopExposureInfo) => {
          const locations = exposureInfo.locations;
          const classCode = {
            business_type: this.locationsFormArray()
              ?.at(0)
              ?.get('buildings.0.exposure.businessType')?.value,
            classification: this.locationsFormArray()
              ?.at(0)
              ?.get('buildings.0.exposure.classification.code')?.value,
          };
          const industry = {
            industry: this.locationsFormArray()
              ?.at(0)
              ?.get('buildings.0.exposure.classification.code')?.value,
            business_type: this.locationsFormArray()
              ?.at(0)
              ?.get('buildings.0.exposure.businessType')?.value,
            group: determineBopIndustryGroup(
              this.locationsFormArray()?.at(0)?.get('buildings.0.exposure.businessType')?.value
            ),
          };
          this.segmentService.track({
            event: eventName,
            properties: {
              product: 'bop',
              carrier: 'attune',
              class_code: classCode,
              naics_code: insuredAccount.naicsCode,
              industry,
              primary_state: locations[0]?.locationDetails?.state,
              insured_address: locations[0]?.locationDetails,
              insured_email: insuredAccount.emailAddress,
              business_name: insuredAccount.companyName,
            },
          });
        },
        error: (err) => {
          this.sentryService.notify('Unable to get Bop Exposure to send segment event', {
            severity: 'error',
            metaData: {
              eventName,
              insuredAccount,
              underlyingError: err,
              underlyingErrorMessage: err?.message,
            },
          });
        },
      });
    }
  }

  submitQuote() {
    this.resetQuoteResults();
    const effectiveDate = effectiveDateToPeriodDate(
      this.formService.form.value.policyInfo.effectiveDate
    );
    // Omit standalone Cyber fields from the payload used for the BOP quote
    const bopFormValue = this.formService.getValueWithoutStandaloneCyberFields();
    const policy = new BopPolicy(bopFormValue, effectiveDate, this.tsRequestId);

    // If there is an eligibility override, alter the submission to guarantee a rejection
    if (this.isEligibilityOverride()) {
      policy.liabilityCoverages.damageToPremises = ((policy.liabilityCoverages
        .damageToPremises as unknown as number) * 1000000) as unknown as string;
    }
    this.trackSubmitAttempt();
    this.sendSegmentEvent('Quote Attempted');
    const insuredAccount = this.insuredAccountService.insuredSubject.getValue();

    this.uwAlertService
      .runUWAlert(insuredAccount.id, {
        insuredAccount: insuredAccount,
        newBopQuote: policy,
      })
      .subscribe();
    const jobNumber = _.get(this, 'parentQuoteId', null); // set in subclass bop-policy-edit-quote-form
    this.sub.add(
      forkJoin(
        this.requestQuoteService.requestV3Quote({
          accountId: this.accountId,
          parentQuoteId: jobNumber,
          policy,
          excessUwQuestionsHaveValidAnswers: this.uwQuestionsHaveValidAnswers,
          quoteGameEnabled: true,
        }),
        this.submitCoalitionCyberQuote()
      ).subscribe(
        ([bopResponse, cyberResponse]: [
          BopPricedQuote | ErrorResponse,
          QuoteResponse | UpsellEarlyDeclineKey[] | null
        ]) => {
          // Quote is successfully sent to GW. Delete draft
          if (this.currentDraftId) {
            this.draftQuoteService.delete(this.accountId, this.currentDraftId).subscribe();
          }
          if (this.isEligibilityOverride()) {
            this.requestQuoteService.hideQuoteGame();
            this.quoteOverrideDeclineModalOpen = true;
          } else {
            const config: Record<string, any> = { isSampleQuote: this.isSampleQuote };
            if (cyberResponse) {
              if (Array.isArray(cyberResponse)) {
                config.secondaryQuoteEarlyDeclineReasons = cyberResponse;
              } else {
                config.secondaryQuoteResponse = cyberResponse;
              }
            }

            this.updateQuote(bopResponse, this.accountId, config);
          }
        }
      )
    );
  }

  trackSubmitAttempt() {
    this.amplitudeService.trackWithOverride({
      eventName: 'quote_attempt',
      detail: 'bop',
      useLegacyEventName: true,
      payloadOverride: this.submitQuoteAmplitudePayloadOverride(),
    });
  }

  savePrefillDeclineDraftQuote(declineReasons: string[]) {
    this.draftQuoteService
      .create({
        accountId: this.accountId,
        formValue: this.formService.getValue(),
        product: DraftInsuranceProduct.BOP,
        origin: DraftOrigin.PrefillDecline,
        declineReasons: declineReasons,
        portalViewable: false,
      })
      .subscribe();
  }

  savePrefillOutageDraftQuote() {
    this.draftQuoteService
      .create({
        accountId: this.accountId,
        formValue: this.formService.getValue(),
        product: DraftInsuranceProduct.BOP,
        origin: DraftOrigin.PrefillOutage,
      })
      .subscribe(() => {
        this.goBackToAccount();
      });
  }

  onIncrementedStep(_nextStep: RouteFormStep) {
    const lastStepBuilding = this.formService.getPreviousStep().slug.match(/building-(\d+)-(\d+)$/);

    if (lastStepBuilding) {
      const locationIndex = parseInt(lastStepBuilding[1], 10) - 1;
      const buildingIndex = parseInt(lastStepBuilding[2], 10) - 1;

      const building = this.buildingsFormArray(locationIndex).at(buildingIndex);
      const location = this.locationsFormArray().at(locationIndex);
      const exposure = building.get('exposure');
      const locationDetails = location.get('locationDetails');
      const buildingLimitField = building.get('exposure.buildingLimit');
      const buildingLimit = parseMaskedInt(buildingLimitField ? buildingLimitField.value : 0);
      const bppField = building.get('exposure.limitForBusinessPersonalProperty');
      const salesField = building.get('exposure.totalSales');
      const sales = parseMaskedInt(salesField ? salesField.value : 0);
      const bpp = parseMaskedInt(bppField ? bppField.value : 0);

      const { naicsCode } = this.insAccount$.getValue();

      if (exposure && locationDetails) {
        this.buildingVerificationLoading = true;
        this.sub.add(
          this.buildingVerificationService
            .getBuildingVerification({
              accountNumber: this.accountId,
              tsRequestId: this.tsRequestId,
              exposure: exposure.value,
              address: locationDetails.value,
              naicsCode,
            })
            .subscribe((response: BuildingVerificationResponse) => {
              this.buildingVerificationLoading = false;

              const interventionAlerts: UWInterventionAlert[] = [];

              if (response.replacementEstimate) {
                interventionAlerts.push({
                  templateMessage: UW_INTERVENTION_BUILDING_LIMIT_TEMPLATE,
                  eventName: 'building_replacement',
                  field: buildingLimitField as UntypedFormControl,
                  userEntered: buildingLimit,
                  estimate: Math.round(response.replacementEstimate),
                });
              }

              if (response.bppEstimate) {
                interventionAlerts.push({
                  templateMessage: UW_INTERVENTION_BPP_TEMPLATE,
                  eventName: 'bpp',
                  field: bppField as UntypedFormControl,
                  userEntered: bpp,
                  estimate: Math.round(response.bppEstimate),
                });
              }

              if (response.salesEstimate) {
                interventionAlerts.push({
                  templateMessage: UW_INTERVENTION_SALES_TEMPLATE,
                  eventName: 'sales',
                  field: salesField as UntypedFormControl,
                  userEntered: sales,
                  estimate: Math.round(response.salesEstimate),
                });
              }

              if (interventionAlerts.length > 0) {
                this.displayEstimatedReplacementCostModal = true;
                this.uwInterventionAlerts = interventionAlerts;
              } else {
                // Check eligibility after building limit/bpp page.
                this.sub.add(this.checkEligibility().subscribe());
                this.navigateToCurrentStep();
              }
            })
        );
      } else {
        // Check eligibility after building limit/bpp page.
        this.sub.add(this.checkEligibility().subscribe());
      }
    }

    if (this.formService.getPreviousStep().slug === 'policy-info') {
      this.uwDecisionLoading = true;

      const domain = getControl(this.domainFormGroup, 'domain').value || '';
      const sanitizedDomain = sanitizeDomain(domain);
      const payload = this.insAccount$.getValue();
      const shouldDomainBeUpdated = !!(
        CYBER_DOMAIN_REGEX.exec(sanitizedDomain) && payload.website !== sanitizedDomain
      );

      // decline if this quote has both accredited and blackboard states
      if (this.isMixedCarrierQuote()) {
        this.displayMixedCarrierDeclineModal = true;
        return;
      }

      const newBopVersion = this.getBopVersionBasedOnStates();

      // redisplay guidelines if the bop version changes
      if (newBopVersion !== this.bopVersion()) {
        this.form.patchValue({
          guidelines: false,
        });
        this.formService.setBOPVersion(newBopVersion);
        this.loading = true;
        this.displayGuidelinesRecheckModal = true;
        this.setupGuidelines(this.insAccount$.getValue());
      }

      // Update the organization type
      const orgType = <UntypedFormControl>this.formService.form.get('policyInfo.organizationType');
      this.sub.add(this.insuredAccountService.updateOrgTypeIfNecessary(orgType.value).subscribe());

      if (shouldDomainBeUpdated) {
        payload.website = sanitizedDomain;
        this.sub.add(
          forkJoin([
            this.insuredAccountService.edit(payload).pipe(
              catchError((error) => {
                this.sentryService.notify('Error updating account website.', {
                  severity: 'error',
                  metaData: {
                    payload,
                    underlyingErrorMessage: error && error.message,
                    underlyingError: error,
                  },
                });
                return of(null);
              })
            ),
            this.prefill$(),
          ]).subscribe(() => {
            this.uwDecisionLoading = false;
            this.navigateToCurrentStep();
          })
        );
      } else {
        this.sub.add(
          this.prefill$().subscribe(() => {
            this.uwDecisionLoading = false;
            this.navigateToCurrentStep();
          })
        );
      }
    }
    if (this.formService.getPreviousStep().slug === 'liability-coverages') {
      if (this.formService.showHnoaUwQuestions && !this.formService.yesToAllPreHnoaQuestions()) {
        getControl(
          this.formService.form,
          'liabilityCoverages.acceptHiredNonOwnedAutoCoverage'
        ).setValue(false);
        this.displayHnoaIneligibleModal = true;
      }
    }

    if (this.formService.isAfterStep('location-1') && !lastStepBuilding) {
      // We want to begin doing eligibility checkpoints after we get back prefill data
      // and the user is filling out location data.
      // We skip calling checkEligibility() on the building step because it's going to be called separately
      // after the user has had a chance to confirm the bpp/building limit.
      this.sub.add(this.checkEligibility().subscribe());
    }

    if (
      !this.uwDecisionLoading &&
      !this.buildingVerificationLoading &&
      !this.eligibilityCheckLoading
    ) {
      this.navigateToCurrentStep();
    }
  }

  onDecrementedStep(_prevStep: RouteFormStep) {
    if (_prevStep.slug === 'account') {
      this.returnToAccountCreate();
    }
  }

  goBackToAccount() {
    this.router.navigate(['accounts', this.accountId]);
  }

  handleQuoteResultModalClose(result: { close: boolean; retry: boolean }) {
    super.handleQuoteResultModalClose(result, this.accountId);
    if (result.retry) {
      this.formService.stepForward();
    }
  }

  shouldShowInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.formService.form, this.formService.submitted);
  }

  locationsFormArray(): UntypedFormArray {
    return this.formService.get<UntypedFormArray>('locations');
  }

  locationAddressesFormArray(): UntypedFormArray {
    return this.formService.get<UntypedFormArray>('policyInfo.locationAddresses');
  }

  removeLocation(numberToRemove: number) {
    this.formService.removeLocation(numberToRemove);
  }

  moreThanOneLocation(): boolean {
    return this.locationAddressesFormArray().length > 1;
  }

  addLocation() {
    this.formService.addLocation();
    if (this.isEligibilityClassOverride()) {
      this.overrideAllBuildingClassifications();
    }
  }

  getUniqueAddressError(locationIndex: number): boolean {
    const policyInfoErrors = this.formService.get<UntypedFormGroup>('policyInfo').errors;
    if (
      policyInfoErrors &&
      policyInfoErrors.repeatAddress &&
      policyInfoErrors.repeatAddress.index === locationIndex
    ) {
      return true;
    }
    return false;
  }

  removeBuilding(locationIndex: number, buildingIndex: number) {
    return this.buildingsFormArray(locationIndex).removeAt(buildingIndex);
  }

  showRemove() {
    return this.locationsFormArray().controls.length > 1;
  }

  moreThanOneBuilding(locationIndex: number) {
    return this.buildingsFormArray(locationIndex).controls.length > 1;
  }

  buildingsFormArray(locationIndex: number): UntypedFormArray {
    return <UntypedFormArray>this.locationsFormArray().get([locationIndex, 'buildings']);
  }

  firstBuilding(): UntypedFormGroup {
    const location1Buildings = this.buildingsFormArray(0);
    return <UntypedFormGroup>location1Buildings.at(0);
  }

  addBuilding(locationIndex: number): UntypedFormGroup {
    const result = this.formService.addBuilding(locationIndex);

    return result;
  }

  getExposureFieldValue(exposureInfo: BopExposureInfo, exposureField: string) {
    return _.get(exposureInfo, `locations.0.buildings.0.exposure.${exposureField}`);
  }

  determinePropertyDeductibleRange(exposure: BopExposureInfo) {
    for (const [i, location] of exposure.locations.entries()) {
      const propertyDeductible = location.locationDetails.propertyDeductible;
      const building = location.buildings[0];
      const classCode = building.exposure?.classification?.code?.code;
      const formLocation = this.locationsFormArray().at(i);
      const propertyDeductibleForm = formLocation.get('locationDetails.propertyDeductible');
      let deductibles: number[] = [];

      /* Electronics stores (57326) will require a minimum deductible of 2500 */
      const isElectronicsStoreNonLRO =
        classCode === ELECTRONICS_STORE_CLASS_CODE && !building.exposure.lessorsRisk;
      if (this.bopVersion() === 2) {
        if (location.locationDetails.state === 'CA') {
          deductibles = AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_CA;
        } else if (location.locationDetails.state === 'NY') {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH
            : AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY;
        } else {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_HIGH
            : AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES;
        }
      } else if (this.bopVersion() === 1) {
        if (location.locationDetails.state === 'NY') {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH
            : AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY;
        } else {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_PROPERTY_DEDUCTIBLES_HIGH
            : AVAILABLE_PROPERTY_DEDUCTIBLES;
        }
      } else {
        /* We don't want to set anything if this function is called before we set a BOP version or state */
        return;
      }

      this.availablePropertyDeductiblesForLocation[i] = deductibles;

      /* Allow keeping a deductible from a previous policy */
      if (
        location.locationDetails.propertyDeductible &&
        propertyDeductibleForm &&
        propertyDeductibleForm.pristine
      ) {
        propertyDeductibleForm.setValue(location.locationDetails.propertyDeductible);
        propertyDeductibleForm.markAsDirty();
      } else {
        if (propertyDeductibleForm) {
          if (!location.locationDetails.propertyDeductible) {
            propertyDeductibleForm.setValue(String(deductibles[0]));
          } else if (parseMaskedInt(location.locationDetails.propertyDeductible) < deductibles[0]) {
            propertyDeductibleForm.setValue(String(deductibles[0]));
            this.displayDeductibleChangedWarning = true;
          } else {
            this.displayDeductibleChangedWarning = false;
          }
        }
      }
    }
  }

  determineEmployeeDishonestyCoverageRange(exposureInfo: BopExposureInfo) {
    const businessType = this.getExposureFieldValue(exposureInfo, 'businessType');
    const lessorsRisk = this.getExposureFieldValue(exposureInfo, 'lessorsRisk');

    let limits = AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY;

    if (
      !lessorsRisk &&
      BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000.includes(businessType)
    ) {
      limits = AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_25000;
    } else if (
      !lessorsRisk &&
      BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000.includes(businessType)
    ) {
      limits = AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_10000;
    }

    if (
      !_.isEqual(
        Object.keys(this.availableLimitsForEmployeeDishonesty),
        limits.map((lim: number) => lim.toString(10))
      )
    ) {
      // Add text ("Provided in Enhancement") to the first
      this.availableLimitsForEmployeeDishonesty = _.reduce(
        limits,
        (acc: any, val: number, index: number) => {
          if (index === 0 && val > 0) {
            acc[val] = `$${val} (Provided in Enhancement)`;
            return acc;
          }
          acc[val] = val;
          return acc;
        },
        {}
      );

      // Patch to the first value if the limits changed (typically 0 removed from options)
      // this.form.get('additionalCoverages.limitForEmployeeDishonesty
      const employeeDishonestyControl = this.form.get(
        'additionalCoverages.limitForEmployeeDishonesty'
      );
      if (employeeDishonestyControl && limits[0] !== employeeDishonestyControl.value) {
        employeeDishonestyControl.patchValue(limits[0]);
      }
    }
  }

  protected updateLiquorAggregate() {
    if (this.baseState() === 'IL') {
      return;
    }

    const eachCommonCauseLimit: number = getControl(
      this.form,
      'liabilityCoverages.liquorLiability.eachCommonCauseLimit'
    ).value;

    let maxAggregate = MAX_LIQUOR_LIABILITY_AGGREGATE;

    if (this.baseState() === 'NY' && this.bopVersion() === 1) {
      maxAggregate = BOPV1_MAX_LIQUOR_LIABILITY_AGGREGATE_NY;
      this.availableLimitsForLiquorLiabilityAggregate =
        BOPV1_AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE_NY;
    }

    getControl(
      this.formService.form,
      'liabilityCoverages.liquorLiability.aggregateLimit'
    ).patchValue(Math.min(eachCommonCauseLimit * 2, maxAggregate));
  }

  countAllErrors(form: UntypedFormGroup | UntypedFormArray): number {
    return Object.keys(form.controls).reduce((acc, key) => {
      const control = <AbstractControl>form.get(key);
      return (
        acc +
        (control instanceof UntypedFormGroup || control instanceof UntypedFormArray
          ? this.countAllErrors(control)
          : control.errors
          ? 1
          : 0)
      );
    }, 0);
  }

  populateIneligibleLocation() {
    this.formService.removeAllLocations();
    this.addLocation();
    this.formService.form.patchValue({
      policyInfo: {
        locationAddresses: [
          {
            addressLine1: '65 Greene Street',
            addressLine2: '4th Floor',
            city: 'Crimeville',
            state: 'New York',
            zip: '10012',
          },
        ],
      },
      locations: [
        {
          locationDetails: {
            employeeCount: '32',
          },
        },
      ],
    });
  }

  populateKnownGoodHappyPathValues(state: string, excess = false) {
    const happyState: string = state || this.baseState() || 'KY';
    this.formService.fillInHappyPath(happyState, excess);
  }

  getBuildingClassificationsForAllLocations(): BuildingClassificationHolder[] {
    return getBuildingClassificationsForLocations(this.locationsFormArray().value);
  }

  labelTextForPrintersErrorsAndOmissionsSalesOrPayroll(): string {
    if (this.baseState() === 'NY') {
      return 'Printers Errors and Omissions Sales';
    } else {
      return 'Printers Errors and Omissions Payroll';
    }
  }

  showOperationsSpecificCoverageSection() {
    const enabledOrDisabledControls = [
      'acceptSnowPlowCompletedOpsCoverage',
      'eachCoveredJobSiteLimit',
      'propertyInTransitLimit',
      'installationLimit',
      'toolsBlanketLimit',
      'toolsPerItemLimit',
    ].concat(<string[]>ALL_PROF_LIABILITIES);

    return _.some(enabledOrDisabledControls, (controlName) => {
      const ctrl = this.formService.get(`liabilityCoverages.${controlName}`);
      return ctrl && ctrl.enabled;
    });
  }

  isTerrorismCoverageOptional(): boolean {
    return this.bopVersion() === 2 || this.baseState() === 'NY';
  }

  displayCyberRetroNote(): boolean {
    const baseState = this.baseState();
    const cyberOptedIn = getControl(
      this.formService.form,
      'additionalCoverages.cyberLiabilityCoverage.optedIn'
    ).value;
    return baseState !== 'NY' && cyberOptedIn;
  }

  private prefillForm(prefillData: PrefillData, locIndex: number) {
    const { buildingPrefill, locationPrefill } = this.prefillService.getPrefill(prefillData);

    const locationsArray = this.locationsFormArray();
    const locationForm = locationsArray.at(locIndex);
    const locDetails = locationForm.value.locationDetails;

    if (locationForm && locDetails) {
      if (environment.showCreditableWaterSupplyField) {
        const { ppc } = locationPrefill;
        const isWithinCreditableWaterSupply = getControl(
          locationForm as UntypedFormGroup,
          'locationDetails.isWithinCreditableWaterSupplyForPPC'
        );
        if (isWithinCreditableWaterSupply) {
          enableDisableControl(
            isWithinCreditableWaterSupply,
            ppc !== undefined && ppc !== null && ppc.includes('/')
          );
        }
      }

      locationForm.patchValue({ locationPrefill });
      const address = getAddress(locDetails);
      const cachedAddress: Address | undefined = this.prefillAddressMap.get(locIndex);
      const buildingForm = locationForm.get(['buildings', '0', 'exposure']);

      // Do not prefill building information on sample quotes or for producer codes where prefill is turned off
      const buildingPrefillEnabled = !this.isSampleQuote && this.buildingPrefillIsEnabled;

      if (buildingPrefillEnabled && !_.isEqual(cachedAddress, address) && buildingForm) {
        buildingForm.patchValue(buildingPrefill);
        this.prefillAddressMap.set(locIndex, address);
      }
    }
  }

  // TODO(olex): This can move to bop form service
  private setEmployeePracticesAggregateLimit(value: number) {
    getControl(
      this.formService.form,
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit'
    ).patchValue(value);
  }

  // TODO(olex): This can move to bop form service
  private setCyberRetroDateAsEffectiveDate(value: string) {
    getControl(this.form, 'additionalCoverages.cyberLiabilityCoverage.retroactiveDate').patchValue(
      value
    );
  }

  setUWQuestionsHaveValidAnswers(isValid: boolean) {
    this.uwQuestionsHaveValidAnswers = isValid;
    this.triggerExcessLiabiltyQuotable();
  }

  triggerExcessLiabiltyQuotable() {
    const excessLiabilityOptInControl = getControl(this.formService.form, 'excessLiabilityOptIn');
    this.excessLiabiltyQuotable$.next(
      this.uwQuestionsHaveValidAnswers &&
        excessLiabilityOptInControl.enabled &&
        excessLiabilityOptInControl.value
    );
  }

  isQuoteInProgress() {
    return this.requestQuoteService.isQuoteInProgress();
  }

  getQuoteSuccessSubject() {
    return this.requestQuoteService.getQuotedSuccess();
  }

  setUpSampleQuoteInformation(sampleQuotesEnabled: boolean | null) {
    if (!sampleQuotesEnabled) {
      this.isSampleQuote = false;
      return;
    }

    const [urlRoot] = this.route.snapshot.url;
    this.isSampleQuote = urlRoot.path === 'sample';
  }

  fillInSampleQuoteInformation(sampleQuotesEnabled: boolean | null) {
    if (!sampleQuotesEnabled) {
      return;
    }

    if (!this.isSampleQuote) {
      return;
    }

    const sampleQuoteSelection = this.route.snapshot.queryParams['class'] || DEFAULT_SAMPLE;
    this.formService.patchSampleQuoteInfo(sampleQuoteSelection);
  }

  closeWindDeclineModal() {
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  closeGuidelinesRecheckModalAndProceed() {
    if (this.formService.form.value.guidelines) {
      this.displayGuidelinesRecheckModal = false;
      this.guidelinesRecheckError = false;
    } else {
      this.guidelinesRecheckError = true;
    }
  }

  closeGuidelinesRecheckModalAndStay() {
    this.clickBackward();

    this.formService.setBOPVersion(this.bopVersion() === 1 ? 2 : 1);
    this.displayGuidelinesRecheckModal = false;
  }

  closeClassCodeIneligbleModal() {
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  closeMixedCarrierDeclineModal() {
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  closeBopCatDeclineModal() {
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  handleQuoteOverrideModalClose() {
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  openWindDeclineModal(curState: string) {
    if (this.isEligibilityOverride()) {
      return;
    }
    this.windDeclineModalState = curState;
    this.displayWindDeclineModal = true;
  }

  closeNewOrOverwriteQuoteModal() {
    this.displayNewOrOverwriteQuoteModal = false;
  }

  openCyberPriceComparisonModal = () => {
    this.displayCyberPriceComparisonModal = true;
  };

  closeCyberPriceComparisonModal() {
    this.displayCyberPriceComparisonModal = false;
  }

  closeHnoaIneligibleModal() {
    this.displayHnoaIneligibleModal = false;
  }

  createNewQuoteWhileEditing() {
    return;
  }

  overwriteQuoteWhileEditing() {
    return;
  }

  getFraudLanguage() {
    if (this.bopVersion() !== 2 && !this.meToo()) {
      return '';
    }

    const baseState = this.baseState();
    if (!baseState) {
      return '';
    }
    const fraudLanguage = FRAUD_LANGUAGE_BY_STATE[baseState];
    if (!fraudLanguage) {
      return DEFAULT_FRAUD_LAUNGUAGE;
    }
    return fraudLanguage;
  }

  setupGuidelines = (insuredAccount: InsuredAccount) => {
    let { state } = insuredAccount;
    const { naicsCode } = insuredAccount;
    const baseState = this.baseState();
    if (baseState) {
      state = baseState;
    }

    let guidelines;

    const effectiveDate: string = getControl(this.form, 'policyInfo.effectiveDate').value;
    const effectiveDateMomentObj = moment(effectiveDate, US_DATE_MASK).startOf('day');

    if (!state || !naicsCode) {
      guidelines = observableOf({ guidelines: [], highlights: [] });
    } else if (
      this.getBopVersionBasedOnStates() === 1 &&
      !shouldGetMeToo(state, effectiveDateMomentObj)
    ) {
      guidelines = this.naicsService.getGuidelines(naicsCode.hash, state);
    } else {
      guidelines = this.naicsService.getBOPv2Guidelines(naicsCode.hash, state);
    }

    if (state) {
      this.formService.setBOPVersion(this.getBopVersionBasedOnStates());
    }

    guidelines.subscribe(
      (data: AskKodiakProductData) => {
        this.guidelinesData = data.guidelines.filter((g) => !g.match(/^NEW/));
        this.additionalGuidelines = data.guidelines.filter((g) => g.match(/^NEW/));

        if (!this.isEditing && data.guidelines.length && !this.displayGuidelinesRecheckModal) {
          this.formService.showInitialSteps(this.fromAccountCreate(), true);
          this.navigateToCurrentStep();
        }

        // NOTE: If the BOP version changes, but the new class code isn't eligible, we want to kick the user out of the flow. We decide that the class code is
        // ineligible if there are no guidelines for that class code and version. Everything that's eligible should have guidelines. This assumption isn't perfect,
        // because the best solution is to check eligibility itself, but that would mean we'd need to make another call to AskKodiak to get eligibility.
        // Since it's already an edge case that the base state changes such that the bop version changes, we're assuming that in most cases it's not an issue that
        // we're going with this assumption. We'll revisit if an issue pops up
        if (!data.guidelines.length && this.displayGuidelinesRecheckModal) {
          this.displayGuidelinesRecheckModal = false;
          this.displayClassCodeIneligibleModal = true;
          this.classCode = naicsCode ? naicsCode.description : '';
          this.sentryService.notify(
            'User got kicked out of the quote flow because the base state changed such that the BOP version changed, and the class code was ineligible for the new BOP version',
            {
              severity: 'info',
              metaData: {
                classCode: this.classCode,
                newBaseState: this.baseState(),
                accountNumber: insuredAccount.id,
              },
            }
          );
        }
        this.loading = false;
      },
      (error) => {
        console.warn('*** error fetching NAICS guidelines ***' + error);
        this.loading = false;
      }
    );
  };

  checkEligibility(): Observable<void> {
    const locationsFormArray = this.locationsFormArray();
    const bopLocations: BopLocation[] = locationsFormArray.value
      .map((location: BopLocation, index: number): BopLocation => {
        return {
          ...location,
          locationNumber: index + 1,
        };
      })
      .filter((location: BopLocation) => {
        // We only want to run eligibility on locations that the broker has already passed in the form.
        return this.formService.isAfterStep(`building-${location.locationNumber}-1`);
      });

    const effectiveDate = getControl(this.form, 'policyInfo.effectiveDate').value;

    this.eligibilityCheckLoading = true;

    return this.eligibilityService
      .fetchEligibilityCheck({
        accountId: this.accountId,
        bopVersion: this.bopVersion(),
        effectiveDate: effectiveDate,
        isBopMeTooQuote: this.meToo(),
        tsRequestId: this.tsRequestId,
        bopLocations: bopLocations,
      })
      .pipe(
        map((eligibilityResponse) => {
          // In the event there is no response, we treat the quote as eligible.
          // Planck data will also be checked in a post-bind batch job.

          const eligibilityDeclineReasons: string[] = [];
          // Check eligibility for locations
          eligibilityResponse.locationEligibilities.forEach((result) => {
            if (result.acceptRisk === false && result.riskDeclineReason.length > 0) {
              result.riskDeclineReason = result.riskDeclineReason.map((reason) => {
                // For location-specific rules, prepend the location number to the decline reason.
                // Each reason should be listed separately so that it is clear they are different reasons.
                return `Location ${result.locationNumber}: ${reason}`;
              });
              eligibilityDeclineReasons.push(...result.riskDeclineReason);
            }
          });
          // Check policy level eligibility
          if (
            eligibilityResponse?.policyEligibility?.acceptRisk === false &&
            eligibilityResponse?.policyEligibility?.riskDeclineReason.length > 0
          ) {
            // For policy-specific rules, show the decline reason returned from SQ as-is.
            eligibilityDeclineReasons.push(
              ...eligibilityResponse.policyEligibility.riskDeclineReason
            );
          }

          if (eligibilityDeclineReasons.length > 0) {
            this.showUwDecisionErrorModal(eligibilityDeclineReasons);
          } else {
            // Ensure that prefill or building verification is finished running before navigating to the current step.
            if (!this.uwDecisionLoading && !this.buildingVerificationLoading) {
              this.navigateToCurrentStep();
            }
          }

          this.eligibilityCheckLoading = false;
        })
      );
  }

  waiverOfSubrogationReadOnly() {
    return this.formService.isV2ContractorEnhancement();
  }

  getPerOccurenceLimit() {
    /* When customer is adding liquor liability, we want to limit per occurence maximum to $1M */
    const optedIn = this.form.get('liabilityCoverages.liquorLiability.optedIn');
    if (optedIn && optedIn.value === true) {
      return AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES_UP_TO_1000000;
    }
    return AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES;
  }

  checkIfCanQuoteWC(): Observable<boolean> {
    if (!this.wcCrossSellPromptProducers.includes(this.userProducerCode)) {
      return observableOf(false);
    }
    const hideWcPrompt = localStorage.getItem('hideWcPrompt');
    if (hideWcPrompt && JSON.parse(hideWcPrompt).hidePrompt) {
      return observableOf(false);
    }
    if (this.locationsFormArray().length > 1) {
      return observableOf(false);
    }
    if (this.wcQuoteExists) {
      return observableOf(false);
    }
    const { naicsCode } = this.insAccount$.getValue();
    if (!naicsCode) {
      return observableOf(false);
    }

    if (!this.isProductAvailable({ pasSource: 'employers', product: 'wc' }, naicsCode)) {
      return observableOf(false);
    }

    return this.naicsService
      .getEmployersWcEligibility(naicsCode.hash, this.baseState() || undefined)
      .pipe(
        map((eligibility) => {
          return eligibility.isEligible;
        })
      );
  }

  closeWCQuotePrompt() {
    if (this.wcPromptForm.value.dontShowAgain === true) {
      this.amplitudeService.track({
        eventName: 'checked_box_to_hide_wc_prompt',
        detail: '',
        useLegacyEventName: true,
      });
      localStorage.setItem('hideWcPrompt', JSON.stringify({ hidePrompt: true }));
    }
    this.showWCQuotePrompt = false;
  }

  startWCQuote() {
    this.amplitudeService.track({
      eventName: 'clicked_yes_on_wc_cross_sell_prompt',
      detail: '',
      useLegacyEventName: true,
    });
    if (this.wcPromptForm.value.dontShowAgain === true) {
      localStorage.setItem('hideWcPrompt', JSON.stringify({ hidePrompt: true }));
    }
    localStorage.setItem(
      'bopDataForWCQuote',
      JSON.stringify({
        accountId: this.accountId,
        basicInfo: {
          organizationType: this.formService.form.value.policyInfo.organizationType,
          yearsInBusiness: this.formService.form.value.policyInfo.yearsInBusiness,
        },
        locations: [
          {
            addressLine1: this.formService.form.value.locations[0].locationDetails.addressLine1,
            addressLine2: this.formService.form.value.locations[0].locationDetails.addressLine2,
            city: this.formService.form.value.locations[0].locationDetails.city,
            state: this.formService.form.value.locations[0].locationDetails.state,
            zip: this.formService.form.value.locations[0].locationDetails.zip,
            employeeCount: this.formService.form.value.locations[0].locationDetails.employeeCount,
          },
        ],
        policyInfo: {
          effectiveDate: this.formService.form.value.policyInfo.effectiveDate,
        },
      })
    );
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/accounts/${this.accountId}/workers-comp/new`])
    );
    window.open(url, '_blank');
    this.closeWCQuotePrompt();
  }

  updateIsEffectiveDateWarned() {
    let effectiveDate: string = getControl(this.form, 'policyInfo.effectiveDate').value;
    // Date parse logic mirrors the date mask
    if (effectiveDate && (effectiveDate.length <= 6 || effectiveDate.length === 8)) {
      const parsedDate = moment(effectiveDate, 'MM/DD/YY').format(US_DATE_MASK);
      if (parsedDate !== 'Invalid date') {
        effectiveDate = parsedDate;
      } else {
        effectiveDate = '';
      }
    }

    if (effectiveDate) {
      const today = moment().startOf('day');
      const ninetyDaysAhead = moment().startOf('day').add(90, 'days');
      const oneYearAhead = moment().startOf('day').add(1, 'years');
      const effectiveDateMomentObj = moment(effectiveDate, US_DATE_MASK).startOf('day');

      if (effectiveDateMomentObj.isBefore(today)) {
        this.isEffectiveDateInPast = true;
      } else {
        this.isEffectiveDateInPast = false;
      }

      if (
        effectiveDateMomentObj.isAfter(ninetyDaysAhead) &&
        effectiveDateMomentObj.isSameOrBefore(oneYearAhead)
      ) {
        this.isEffectiveDateNinetyDaysInFuture = true;
      } else {
        this.isEffectiveDateNinetyDaysInFuture = false;
      }
    }
  }

  isProductAvailable({ pasSource, product }: ProductCombination, naicsCode: NaicsCode) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    return (
      productAvailability.classCodeSelection === 'PARTIAL' &&
      _.includes(productAvailability.classCodes.naics, naicsCode.code)
    );
  }

  // Methods for Cyber upsell
  setCyberUpsellListeners() {
    // Set listener for Cyber industry ID, so we can fetch the industry when it changes
    this.sub.add(
      this.formService.industryId$.subscribe((industryId: number | null) => {
        if (industryId) {
          this.handleCyberIndustryIdControlUpdates(industryId);
        }
      })
    );

    this.insAccount$.subscribe((insuredAccount) => {
      this.getCoalitionCyberNaicsMapping(insuredAccount);
      this.updateNaicsCode(insuredAccount);
    });

    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.DOMAIN_ON_BOP_PLUS_POLICY_INFO)
      .subscribe((domainEnabled: boolean) => (this.showDomain = domainEnabled));

    const annualRevenueControl = getControl(this.form, 'policyInfo.annualRevenue');
    const annualRevenue$ = observableMerge(
      annualRevenueControl.valueChanges,
      observableOf(annualRevenueControl.value)
    );

    const cyberCoverageControl = getControl(
      this.form,
      'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage'
    );
    const cyberCoverage$ = observableMerge(
      cyberCoverageControl.valueChanges,
      observableOf(cyberCoverageControl.value)
    );

    combineLatest([annualRevenue$, cyberCoverage$]).subscribe(
      ([annualRevenue, cyberCoverage]: [string, string]) => {
        if (cyberCoverage === 'coalition-cyber') {
          this.form.patchValue({
            additionalCoverages: {
              cyberLiabilityCoverage: {
                company_revenue: annualRevenue,
              },
            },
          });
        }
      }
    );

    const stateControl = getControl(this.form, 'policyInfo.baseState');
    const stateControl$ = observableMerge(
      stateControl.valueChanges,
      observableOf(stateControl.value)
    );
    this.sub.add(
      combineLatest([this.insAccount$, stateControl$]).subscribe(() => {
        // We need the account and state to be present in order to fetch the cyber premium
        // Don't fetch it again if we've already fetched it
        if (!this.activeCyberPrice) {
          this.fetchCyberPremium();
        }
      })
    );
  }

  setDefaultCyberCoverage(state: string) {
    let optedIn: boolean;
    let selectedCyberCoverage: string;

    if (state === 'NY') {
      // NY does not have access to the HSB endorsement, but can still
      // choose Coalition Cyber if they want
      optedIn = false;
      selectedCyberCoverage = 'none';
    } else {
      // Attaching Coalition Cyber led to a small dip in premium, so we
      // should default to the HSB endorsement for all other states
      optedIn = true;
      selectedCyberCoverage = 'endorsement';
    }

    this.formService.form.patchValue({
      additionalCoverages: {
        cyberLiabilityCoverage: {
          optedIn,
          selectedCyberCoverage,
        },
      },
    });
  }

  updateNaicsCode(insuredAccount: InsuredAccount) {
    const naicsCode = insuredAccount.naicsCode?.code;
    if (naicsCode && NAICS_CODES_TO_INDUSTRY_IDS[naicsCode]) {
      this.formService.naicsCodeSubject.next(naicsCode);
    }
  }

  updateQuestionEnablementFlags(industryData: CyberIndustryData) {
    const flagNames = _.values(CyberQuestionEnablementFlag);
    this.flagsFromIndustryData = _.pick(industryData, flagNames);
    this.formService.updateQuestionEnablementFlags(this.flagsFromIndustryData);
  }

  handleCyberIndustryIdControlUpdates(industryId: number) {
    this.sub.add(
      this.digitalCarrierService
        .getCoalitionCyberIndustry(industryId, 'cyber_admitted')
        .subscribe((industryData) => {
          if (!industryData) {
            this.sentryService.notify(
              'Coalition Cyber Upsell: Failed to retrieve industry data; cannot determine questions to enable',
              {
                severity: 'error',
                metaData: {
                  industryId,
                  product: 'cyber_admitted',
                  quoteFlowProduct: 'bop',
                },
              }
            );
            return;
          }
          this.updateQuestionEnablementFlags(industryData);
        })
    );
  }

  getCoalitionCyberNaicsMapping(insuredAccount: InsuredAccount) {
    if (!insuredAccount.naicsCode) {
      return;
    }
    this.sub.add(
      this.naicsService
        .getCyberLiabilityNaicsMapping(insuredAccount.naicsCode.hash)
        .subscribe((CyberMappings) => {
          this.cyberNaicsMappings = CyberMappings.map((industry) => industry.id.toString(10));
        })
    );
  }

  fetchCyberPremium() {
    const state = this.baseState();
    const { naicsCode } = this.insAccount$.getValue();

    if (state && naicsCode?.hash) {
      this.sub.add(
        this.naicsService.getPremiumIndicator(naicsCode?.hash, state).subscribe((response) => {
          if (response?.premiumIndicator) {
            this.activeCyberPrice = Math.round(response.premiumIndicator / 12);
            this.endorsementPrice = 20;
          }
        })
      );
    }
  }

  /**
   * Creates a new cyber quote to be bundled with resulting bop quote.
   * Quote can come from
   *  - cyberParentId in the bundle edit flow
   *  - form data in upsell flow
   */
  submitCoalitionCyberQuote() {
    if (this.cyberParentId) {
      const rawEffectiveDate = getControl(this.form, 'policyInfo.effectiveDate').value as string;
      const effectiveDate = moment.utc(rawEffectiveDate).format(ISO_DATE_MASK);
      return this.quoteService.cloneCoalitionCyber(
        this.cyberParentId,
        this.insAccount$.getValue(),
        effectiveDate
      );
    }
    return this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.COALITION_CYBER_STANDALONE_UPSELL)
      .pipe(
        take(1),
        map((upsellIsEnabled) => {
          if (
            !upsellIsEnabled ||
            !this.formService.isCoalitionCyberSelected() ||
            this.cyberQuoteWasAttempted
          ) {
            return { action: 'none' };
          }

          const earlyDeclineReasons = this.formService.getCyberAdmittedEarlyDeclineReasons();
          if (earlyDeclineReasons.length) {
            return { action: 'earlyDecline', earlyDeclineReasons };
          }
          this.amplitudeService.track({
            eventName: 'bop_flow_submit_standalone_cyber_quote',
            detail: this.cyberDefaultForAmplitudeEvent,
          });
          return { action: 'quote', formData: this.formService.getStandaloneCyberFields() };
        }),
        switchMap(
          (
            result:
              | { action: 'quote'; formData: CoalitionCyberFormDataFormatted }
              | { action: 'earlyDecline'; earlyDeclineReasons: UpsellEarlyDeclineKey[] }
              | { action: 'none' }
          ): Observable<
            | { action: 'none' }
            | { action: 'earlyDecline'; earlyDeclineReasons: UpsellEarlyDeclineKey[] }
            | {
                action: 'quote';
                quoteResponse: QuoteResponse;
                digitalCarrierProduct: CoalitionCyberAdmittedProduct;
                formData: CoalitionCyberFormDataFormatted;
                account: AttuneInsuredAccount;
                effectiveDate: string;
              }
          > => {
            if (result.action === 'none' || result.action === 'earlyDecline') {
              return observableOf(result);
            }
            const { formData } = result;

            // All upsell cyber quotes will be in the Admitted market.
            const digitalCarrierProduct: CoalitionCyberAdmittedProduct = {
              product: 'cyber_admitted',
              pasSource: 'coalition',
            };

            const rawEffectiveDate = formData[CoalitionCyberQuestion.EFFECTIVE_DATE] as string;
            const effectiveDate = moment.utc(rawEffectiveDate).format(ISO_DATE_MASK);
            const account = this.digitalCarrierService.parseAccountForQuoteSubmit(
              this.insAccount$.getValue()
            );
            const quoteResponse$ = this.digitalCarrierService.submitQuote(
              digitalCarrierProduct,
              formData,
              account,
              effectiveDate,
              false
            );

            // If a BOP quote fails, it may be retried. In this case, we submit the form again.
            // We set this flag so that we do not create a new Cyber quote when the BOP quote is
            // retried.
            this.cyberQuoteWasAttempted = true;

            return forkJoin(
              quoteResponse$,
              observableOf({ digitalCarrierProduct, formData, account, effectiveDate })
            ).pipe(
              map((submitQuoteResult) => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const [quoteResponse, { digitalCarrierProduct, formData, account, effectiveDate }] =
                  submitQuoteResult;
                return {
                  action: 'quote',
                  quoteResponse,
                  digitalCarrierProduct,
                  formData,
                  account,
                  effectiveDate,
                };
              })
            );
          }
        ),
        switchMap(
          (
            submitQuoteResult:
              | { action: 'none' }
              | { action: 'earlyDecline'; earlyDeclineReasons: UpsellEarlyDeclineKey[] }
              | {
                  action: 'quote';
                  quoteResponse: QuoteResponse;
                  digitalCarrierProduct: CoalitionCyberAdmittedProduct;
                  formData: CoalitionCyberFormDataFormatted;
                  account: AttuneInsuredAccount;
                  effectiveDate: string;
                }
          ) => {
            if (submitQuoteResult.action === 'none') {
              return observableOf(null);
            } else if (submitQuoteResult.action === 'earlyDecline') {
              return observableOf(submitQuoteResult.earlyDeclineReasons);
            } else {
              const { quoteResponse, digitalCarrierProduct, formData, account, effectiveDate } =
                submitQuoteResult;
              if (
                isDraftQuoteResponse(quoteResponse) &&
                quoteResponse.pasId &&
                formData.company_revenue < 1_000_000
              ) {
                const quoteUuid = quoteResponse.uuid;

                return this.digitalCarrierService
                  .issueQuote(digitalCarrierProduct, formData, account, effectiveDate, quoteUuid)
                  .pipe(map((response) => response?.body));
              } else {
                return observableOf(quoteResponse);
              }
            }
          }
        )
      );
  }

  submitQuoteAmplitudePayloadOverride(): AmplitudePayloadOverride {
    return {
      cyberUpsellIsEnabled: this.cyberUpsellIsEnabled ? 'true' : 'false',
      defaultCyberProductForUpsell: this.cyberUpsellIsEnabled
        ? this.cyberDefaultForAmplitudeEvent
        : 'N/A',
      upsellProductAdded: this.formService.isCoalitionCyberSelected() ? 'cyber_admitted' : 'N/A',
    };
  }

  getSelectedCyberOption() {
    const control = this.form.get(
      'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage'
    );
    if (control) {
      return control.value;
    }
    return 'none';
  }
  // END Methods for Cyber upsell

  toggleFirstTimeLROModal() {
    if (this.quoteResultModalOpen && !this.lroFirstTimeModalOpen) {
      return;
    }
    this.lroFirstTimeModalOpen = !this.lroFirstTimeModalOpen;
  }

  /**
   * Performs validation on the value that is patched from the policyInfo page
   * formControl to the Cyber upsell formControl. Since there is no validation
   * on the policyInfo page, we *must* be sure that the value is valid,
   * otherwise the form will be invalid preventing submission, and the invalid
   * formControl will be hidden.
   *
   * @param annualRevenueControl the formControl whose value was used to patch
   */
  shouldHideAnnualRevenue(annualRevenueControl: UntypedFormControl) {
    return (
      annualRevenueControl.enabled &&
      annualRevenueControl.valid &&
      !!annualRevenueControl.value &&
      parseMoney(annualRevenueControl.value) > 0
    );
  }
}
