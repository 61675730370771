import { Component, isDevMode, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, concat, forkJoin, of as observableOf, race, timer } from 'rxjs';
import { bufferCount, map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

// Services
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { EligibilityService } from 'app/shared/services/eligibility.service';
import { AttuneBopEndorseQuoteService } from 'app/features/attune-bop/services/attune-bop-endorse-quote.service';
import {
  EndorsementOptInTypes,
  AttuneBopEndorseQuoteFormService,
} from 'app/features/attune-bop/services/attune-bop-endorse-quote-form.service';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import { PrefillService } from 'app/shared/services/prefill.service';
import { ZendeskService } from 'app/shared/services/zendesk.service';

// Models
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

// Helpers
import {
  allErrorsRecursively,
  getControl,
  getFormArray,
  getFormGroup,
} from 'app/shared/helpers/form-helpers';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';

// Constants
import { UNKNOWN_ERROR_WITHOUT_RETRY } from 'app/shared/quote-error-modal/errors';
import {
  BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS,
  PREFILL_REQUEST_GROUP_SIZE,
  PREFILL_TIMEOUT_MS,
  NAMED_INSURED_CHANGE_TYPES,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_HIGH,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES,
  AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH,
  AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY,
  AVAILABLE_PROPERTY_DEDUCTIBLES_HIGH,
  AVAILABLE_PROPERTY_DEDUCTIBLES,
  ELECTRONICS_STORE_CLASS_CODE,
} from '../../models/constants';
import { BopEndorsementForm, CoveredBuilding } from '../../models/bop-endorsement';
import { HttpErrorResponse } from '@angular/common/http';
import { US_DATE_MASK } from 'app/constants';
import { GWService } from '../../../../bop/services/gw.service';
import { RetrieveQuoteResponse } from '../../../../bop/guidewire/typings';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { BROKER_ENDORSEMENTS_GUIDE_LINK } from 'app/features/support/models/support-constants';

@Component({
  selector: 'app-attune-bop-endorse-form-page.app-page.app-page__form',
  templateUrl: './attune-bop-endorse-form-page.component.html',
  providers: [AttuneBopEndorseQuoteFormService],
})
export class AttuneBopEndorseFormPageComponent implements OnInit, OnDestroy {
  accountId: string;
  endorsementJobNumber: string;
  termNumber: string;
  policyNumber: string;
  insAccount: InsuredAccount;
  tsRequestId: string;
  isDevMode = isDevMode();
  loading = true;
  form: UntypedFormGroup;
  currentStep: RouteFormStep;

  locationAddresses: Address[];
  locations: QSLocation[];
  organizationTypes: { [key: string]: string } = {};
  protected prefillAddressMap: Map<number, Address> = new Map<number, Address>();
  underWritingDeclineReasons: string[] = [];
  uwDecisionLoading = false;
  eligibilityCheckLoading = false;

  priceDifference: number;
  policyChangeDescription: string;
  totalCost: number;

  availablePropertyDeductiblesForLocation: number[][] = [];
  displayDeductibleChangedWarning = false;

  isOtherEndorsement = false;
  isFutureDatedQuote = false;

  businessIncomeAndExtraExpensesIndemnityInMonthsOptions =
    BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS;

  namedInsuredChangeTypes = NAMED_INSURED_CHANGE_TYPES;
  brokerEndorsementsGuide = BROKER_ENDORSEMENTS_GUIDE_LINK;

  private sub: Subscription = new Subscription();
  isCreatingZendeskEndorsement: boolean;
  private isTransactionWaived = false;

  constructor(
    private amplitudeService: AmplitudeService,
    private bindService: GWBindService,
    private gwService: GWService,
    private endorseQuoteService: AttuneBopEndorseQuoteService,
    public formService: AttuneBopEndorseQuoteFormService,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    private organizationTypeService: OrganizationTypeService,
    private prefillService: PrefillService,
    protected route: ActivatedRoute,
    protected router: Router,
    private zendeskService: ZendeskService,
    private eligibilityService: EligibilityService
  ) {
    this.form = this.formService.form;

    this.organizationTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  get effectiveDate() {
    return this.form.get('endorsementRequests.effectiveDate') as UntypedFormControl;
  }
  get endorsementRequests() {
    return this.form.get('endorsementRequests') as UntypedFormGroup;
  }
  get typeOfEndorsementOptIn() {
    return this.form.get('endorsementRequests.typeOfEndorsementOptIn') as UntypedFormGroup;
  }

  get quoteReturnedSuccessfully() {
    return getControl(this.form, 'review.quoteReturnedSuccessfully').value;
  }

  get policyChangeFailed() {
    return getControl(this.form, 'review.policyChangeFailed').value;
  }

  get policyChangeTimedOut() {
    return getControl(this.form, 'review.policyChangeTimedOut').value;
  }

  handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    // Don't advance if we are waiting for prefill
    if (this.uwDecisionLoading) {
      return;
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  ngOnInit() {
    this.generateRequestId();

    this.accountId = this.route.snapshot.params['accountId'];
    this.endorsementJobNumber = this.route.snapshot.params['jobNumber'];

    this.termNumber = this.route.snapshot.queryParams['term'];
    this.policyNumber = this.route.snapshot.queryParams['policyNumber'];

    this.sub.add(
      this.bindService
        .getQuoteDetails(this.endorsementJobNumber)
        .pipe(
          switchMap((bopPolicyPeriod) => {
            return forkJoin(
              this.insuredAccountService.get(this.accountId),
              this.endorseQuoteService.retrievePolicyChange(this.endorsementJobNumber)
            );
          })
        )
        .subscribe(([insuredAccount, retrievePolicyChangeResponse]) => {
          if (this.accountId === insuredAccount.id.toString()) {
            this.insAccount = insuredAccount;

            if (this.insAccount.inForceQuotes && this.insAccount.inForceQuotes.length) {
              const relatedPolicy = this.insAccount.inForceQuotes.find((quote) => {
                return quote.policyNumber === this.policyNumber;
              });
              const relatedPolicyIsBlackboard =
                !!relatedPolicy && relatedPolicy.uwCompanyBlackBoard;

              const hasBopPlusQuote =
                relatedPolicyIsBlackboard &&
                this.insAccount.bopQuotes.some((quote) => quote.uwCompanyAccredited);
              const hasBopPlusPolicy =
                relatedPolicyIsBlackboard && !!this.insAccount.bopPlusPolicies.length;

              const reviewSection = this.formService.get('review');
              if (reviewSection) {
                reviewSection.patchValue({
                  hasBopPlusQuote,
                  hasBopPlusPolicy,
                });
              }
            }
          }

          if (retrievePolicyChangeResponse) {
            const effectiveDate = moment.utc(
              _.get(retrievePolicyChangeResponse, 'policy.editEffectiveDate')
            );
            if (effectiveDate && effectiveDate > moment.utc()) {
              this.isFutureDatedQuote = true;
            }

            this.form.patchValue({
              endorsementRequests: { effectiveDate: effectiveDate.format(US_DATE_MASK) },
            });

            this.locationAddresses = retrievePolicyChangeResponse.locations.map(
              (location) => location.address
            );
            this.locations = retrievePolicyChangeResponse.locations;

            this.formService.retrievedBopQuote$.next(retrievePolicyChangeResponse);

            this.formService.patchFormControls(retrievePolicyChangeResponse);

            if (this.route.snapshot.queryParamMap) {
              const changes = this.route.snapshot.queryParamMap.get('changes');
              if (changes) {
                this.fillChangeRequests(changes);
              }
            }

            this.loading = false;
          }
        })
    );

    this.sub.add(
      this.formService.currentStep$.subscribe((step: RouteFormStep) => {
        if (!this.uwDecisionLoading && !this.eligibilityCheckLoading) {
          this.navigateToCurrentStep();
        }
      })
    );

    this.formService.getBopExposure$().subscribe((exposureInfo: BopExposureInfo) => {
      this.determinePropertyDeductibleRange(exposureInfo);
    });

    this.navigateToCurrentStep();

    // Policy change quote api call
    this.sub.add(
      this.formService.incrementedStep$.subscribe((nextStep: RouteFormStep) => {
        // Run a prefill check if any locations have been modified
        // or if new locations have been added.
        if (
          this.formService.getPreviousStep().slug === 'policy-info' &&
          (this.modifiedExistingLocations().length || this.locationsFormArray().length)
        ) {
          this.uwDecisionLoading = true;
          this.sub.add(this.subscribeToPrefill());
        }

        // Check partial eligibility for location/policy
        if (nextStep.slug !== 'review') {
          // Check partial eligibility on every page turn except for when the quote is submitted.
          this.sub.add(this.checkEligibility().subscribe());
        }

        if (nextStep.slug === 'review' && this.isEndorsementAutomated()) {
          this.amplitudeService.track({
            eventName: 'endorsement_quote_attempt',
            detail: this.getEndorsementTypesSelected().join(),
            useLegacyEventName: true,
          });

          // Submit policy change quote call
          this.endorseQuoteService
            .submitPolicyChange({
              accountNumber: this.accountId,
              jobNumber: this.endorsementJobNumber,
              policyNumber: this.policyNumber,
              tsRequestId: this.tsRequestId,
              quote: this.formService.form.value,
            })
            .subscribe(
              (response) => {
                if (response) {
                  // Treat an empty change description as a failure, since it indicates the endorsement wasn't processed correctly.
                  if (!response.description) {
                    this.handlePolicyChangeFailure();
                    this.amplitudeService.trackWithOverride({
                      eventName: 'endorsement_quote_error',
                      detail: this.getEndorsementTypesSelected().join(),
                      useLegacyEventName: true,
                      payloadOverride: {
                        ...response,
                        error: `Quote policy change error: empty description`,
                      },
                    });

                    return;
                  }
                  if (response.status === 'Declined') {
                    this.handlePolicyChangeDecline();
                    this.amplitudeService.trackWithOverride({
                      eventName: 'endorsement_quote_decline',
                      detail: this.getEndorsementTypesSelected().join(),
                      useLegacyEventName: true,
                      payloadOverride: {
                        ...response,
                        error: `Quote policy change error: quote declined`,
                      },
                    });

                    return;
                  }
                  if (response.status === 'Failed') {
                    this.handlePolicyChangeFailure();
                    this.amplitudeService.trackWithOverride({
                      eventName: 'endorsement_quote_error',
                      detail: this.getEndorsementTypesSelected().join(),
                      useLegacyEventName: true,
                      payloadOverride: {
                        ...response,
                        error: 'Quote policy change error: unknown error',
                      },
                    });

                    return;
                  }

                  // Replace any additional whitespace characters with one space.
                  this.policyChangeDescription = response.description.replace(/\s\s+/g, ' ');
                  this.displayPremiumChangeAndHandleQuote(
                    response.jobNumber,
                    Number(response.priceDifference.replace(/[$]/, '')),
                    Number(response.totalCost.replace(/[$]/, ''))
                  );
                }
              },
              (error: HttpErrorResponse) => {
                // NOTE: In AWS environments, load balancer 504 comes through w/ status => 0
                if (error.status === 504 || error.status === 0) {
                  this.handlePolicyChangeTimeout();
                  this.amplitudeService.trackWithOverride({
                    eventName: 'endorsement_quote_timeout',
                    detail: this.getEndorsementTypesSelected().join(),
                    useLegacyEventName: true,
                    payloadOverride: {
                      error: 'Quote policy change timeout',
                      status: `${error.status} - ${error.statusText}`,
                      url: error.url || 'N/A',
                      effectiveDate: _.get(error, 'error.effectiveDate', 'N/A'),
                      jobNumber: _.get(error, 'error.jobNumber', 'N/A'),
                      policyNumber: _.get(error, 'error.policyNumber', 'N/A'),
                      termNumber: _.get(error, 'error.termNumber', 'N/A'),
                    },
                  });
                } else {
                  this.handlePolicyChangeFailure();
                  this.amplitudeService.trackWithOverride({
                    eventName: 'endorsement_quote_error',
                    detail: this.getEndorsementTypesSelected().join(),
                    useLegacyEventName: true,
                    payloadOverride: {
                      error: `Quote policy change error: ${_.get(
                        error,
                        'error.message',
                        'Unknown error'
                      )}`,
                      status: `${error.status} - ${error.statusText}`,
                      url: error.url || 'N/A',
                      effectiveDate: _.get(error, 'error.effectiveDate', 'N/A'),
                      jobNumber: _.get(error, 'error.jobNumber', 'N/A'),
                      policyNumber: _.get(error, 'error.policyNumber', 'N/A'),
                      termNumber: _.get(error, 'error.termNumber', 'N/A'),
                    },
                  });
                }
              }
            );
        }
      })
    );

    // Bind/endorse api call
    this.sub.add(
      this.formService.submittedForm$.subscribe((submittedForm) => {
        if (!this.isEndorsementAutomated()) {
          const zendeskTags = this.formService.getZendeskTags();
          const endorsementFormDiff = this.getDifferenceInFormValue(
            this.formService.originalFormValue,
            this.formService.form.value
          );

          this.isCreatingZendeskEndorsement = true;

          // Zendesk endorsements cannot be bound through the portal so this is a quote attempt.
          this.amplitudeService.track({
            eventName: 'endorsement_quote_attempt',
            detail: this.getEndorsementTypesSelected().join(),
            useLegacyEventName: true,
          });

          this.endorseQuoteService
            .submitZendeskPolicyChange(
              this.formService.form.value,
              endorsementFormDiff,
              this.endorsementJobNumber,
              this.policyNumber,
              this.termNumber,
              zendeskTags
            )
            .subscribe(
              (resp: any) => {
                if (resp) {
                  this.isCreatingZendeskEndorsement = false;
                  this.router.navigate([
                    '/accounts',
                    this.accountId,
                    'terms',
                    this.policyNumber,
                    this.termNumber,
                  ]);
                }
              },
              (error: HttpErrorResponse) => {
                this.amplitudeService.trackWithOverride({
                  eventName: 'endorsement_zendesk_error',
                  detail: this.getEndorsementTypesSelected().join(),
                  useLegacyEventName: true,
                  payloadOverride: {
                    error: `Zendesk policy change error: ${_.get(
                      error,
                      'error.message',
                      'Unknown error'
                    )}`,
                    status: `${error.status} - ${error.statusText}`,
                    url: error.url || 'N/A',
                    effectiveDate: _.get(error, 'error.effectiveDate', 'N/A'),
                    jobNumber: _.get(error, 'error.jobNumber', 'N/A'),
                    policyNumber: _.get(error, 'error.policyNumber', 'N/A'),
                    termNumber: _.get(error, 'error.termNumber', 'N/A'),
                  },
                });
              }
            );
        } else {
          let accountOnBind = null;
          const account = this.form.value.account;
          if (account) {
            accountOnBind = _.pick(account, ['id', 'doingBusinessAs']) as Partial<QSAccount>;
          }
          this.amplitudeService.track({
            eventName: 'endorsement_bind_attempt',
            detail: this.getEndorsementTypesSelected().join(),
            useLegacyEventName: true,
          });
          // Bind policy change call
          const bindPayload = this.createBindPayload(accountOnBind);
          this.endorseQuoteService
            .bindPolicyChange(this.endorsementJobNumber, bindPayload)
            .subscribe(
              (response) => {
                if (response) {
                  this.insuredAccountService.cachebust();
                  const policyNumber = response.policyNumber;
                  const termNumber = response.termNumber;
                  this.router.navigate([
                    '/accounts',
                    this.accountId,
                    'terms',
                    policyNumber,
                    termNumber,
                  ]);
                }
              },
              (error: HttpErrorResponse) => {
                this.handlePolicyChangeFailure();
                this.amplitudeService.trackWithOverride({
                  eventName: 'endorsement_bind_error',
                  detail: this.getEndorsementTypesSelected().join(),
                  useLegacyEventName: true,
                  payloadOverride: {
                    error: `Bind policy change error: ${_.get(
                      error,
                      'error.message',
                      'Unknown error'
                    )}`,
                    status: `${error.status} - ${error.statusText}`,
                    url: error.url || 'N/A',
                    effectiveDate: _.get(error, 'error.effectiveDate', 'N/A'),
                    jobNumber: _.get(error, 'error.jobNumber', 'N/A'),
                    policyNumber: _.get(error, 'error.policyNumber', 'N/A'),
                    termNumber: _.get(error, 'error.termNumber', 'N/A'),
                    endorsementTransactionWaived: this.isTransactionWaived ? 'true' : 'false',
                  },
                });
              }
            );
        }
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submitted() {
    return this.formService.submitted;
  }

  handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    return this.formService.stepForward();
  }

  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    const result = currentSlug ? currentSlug.match(slugRegex) : false;
    return result;
  }

  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  displayFaqs() {
    if (!this.currentStep) {
      return false;
    }
    const pagesWithFaqs = ['endorsement-requests', 'additional-insureds', 'coverages'];

    return pagesWithFaqs.includes(this.currentStep.slug);
  }

  isQuoteInProgress() {
    return this.endorseQuoteService.isQuoteInProgress();
  }

  getQuoteSuccessSubject() {
    return this.endorseQuoteService.getQuotedSuccess();
  }

  isBindInProgress() {
    return this.endorseQuoteService.isBindInProgress();
  }

  getBindSuccess() {
    return this.endorseQuoteService.getBindSuccess();
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields.';
  }

  getOriginalPolicyPremium() {
    return this.totalCost - this.priceDifference;
  }

  getTotalInteger(num: number) {
    return Math.trunc(num);
  }

  getAbsoluteValue(num: number) {
    return Math.abs(num);
  }

  getEstimatedFraction(num: number) {
    return (num - this.getTotalInteger(num)) * 100;
  }

  getErrorType() {
    return UNKNOWN_ERROR_WITHOUT_RETRY;
  }

  private getEndorsementTypesSelected(): string[] {
    const optedInValue = this.formService.endorsementOptInValue();

    return Object.keys(optedInValue).filter((key: keyof EndorsementOptInTypes) => {
      return optedInValue[key];
    });
  }

  private allErrors(): any {
    return allErrorsRecursively(this.formService.form);
  }

  isEndorsementAutomated(): boolean {
    // Other/miscellaneous endorsements and coverage endorsements are not automated. They generate zendesk tickets in service-quote.
    // Backdated endorsements are not automated for AIs EXCEPT: MORTGAGEE, OWNERS_OF_LAND, LOSS_PAYABLE, BUILDING_OWNER, MORTGAGE_HOLDER
    return this.formService.isEndorsementAutomated();
  }

  makeFormattedAddress(loc: Address) {
    const address = _.compact([
      loc.addressLine1,
      loc.addressLine2,
      loc.city + ',',
      loc.state,
      loc.zip,
    ])
      .filter((x) => !!x)
      .map((str) => str.trim())
      .join(' ');

    return address;
  }

  determinePropertyDeductibleRange(exposure: BopExposureInfo) {
    for (const [i, location] of exposure.locations.entries()) {
      const propertyDeductible = location.locationDetails.propertyDeductible;
      const building = location.buildings[0];
      const classCode = building.exposure?.classification?.code?.code;
      const formLocation = this.locationsFormArray().at(i);
      const propertyDeductibleForm = formLocation.get('locationDetails.propertyDeductible');
      let deductibles: number[] = [];

      /* Electronics stores (57326) will require a minimum deductible of 2500 */
      const isElectronicsStoreNonLRO =
        classCode === ELECTRONICS_STORE_CLASS_CODE && !building.exposure.lessorsRisk;
      if (this.bopVersion() === 2) {
        if (location.locationDetails.state === 'NY') {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH
            : AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY;
        } else {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_HIGH
            : AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES;
        }
      } else if (this.bopVersion() === 1) {
        if (location.locationDetails.state === 'NY') {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH
            : AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY;
        } else {
          deductibles = isElectronicsStoreNonLRO
            ? AVAILABLE_PROPERTY_DEDUCTIBLES_HIGH
            : AVAILABLE_PROPERTY_DEDUCTIBLES;
        }
      } else {
        /* We don't want to set anything if this function is called before we set a BOP version or state */
        return;
      }

      this.availablePropertyDeductiblesForLocation[i] = deductibles;

      /* Allow keeping a deductible from a previous policy */
      if (
        location.locationDetails.propertyDeductible &&
        propertyDeductibleForm &&
        propertyDeductibleForm.pristine
      ) {
        propertyDeductibleForm.setValue(location.locationDetails.propertyDeductible);
        propertyDeductibleForm.markAsDirty();
      } else {
        if (propertyDeductibleForm) {
          if (!location.locationDetails.propertyDeductible) {
            propertyDeductibleForm.setValue(String(deductibles[0]));
          } else if (parseMaskedInt(location.locationDetails.propertyDeductible) < deductibles[0]) {
            propertyDeductibleForm.setValue(String(deductibles[0]));
            this.displayDeductibleChangedWarning = true;
          } else {
            this.displayDeductibleChangedWarning = false;
          }
        }
      }
    }
  }

  getDifferenceInFormValue = (originalForm: any, updatedForm: any) => {
    const formWithChangesMarked = _.cloneDeep(updatedForm);

    if (!originalForm && updatedForm) {
      return {
        newValue: updatedForm,
      };
    }

    for (const key in formWithChangesMarked) {
      if (Array.isArray(formWithChangesMarked[key])) {
        formWithChangesMarked[key].forEach((value: any, index: number) => {
          formWithChangesMarked[key][index] = this.getDifferenceInFormValue(
            originalForm[key] ? originalForm[key][index] : undefined,
            formWithChangesMarked[key][index]
          );
        });
      } else if (typeof formWithChangesMarked[key] === 'object') {
        formWithChangesMarked[key] = this.getDifferenceInFormValue(
          originalForm[key],
          formWithChangesMarked[key]
        );
      } else if (formWithChangesMarked[key] !== originalForm[key]) {
        // Display the original value if applicable
        if (originalForm[key]) {
          formWithChangesMarked[
            key
          ] = `${formWithChangesMarked[key]} (UPDATED from ${originalForm[key]})`;
        } else {
          formWithChangesMarked[key] = formWithChangesMarked[key] + ' (UPDATED)';
        }
      }
    }
    return formWithChangesMarked;
  };

  createBindPayload = (account: Partial<QSAccount> | null) => {
    const payload: PolicyChangeBindPayload = {};
    if (account) {
      payload.account = account;
    }

    const form: BopEndorsementForm = this.form.value;
    const coveredBuildings: CoveredBuilding[] = _.get(
      form,
      'coverages.exposures.coveredBuildings',
      []
    );
    if (coveredBuildings.length) {
      const formDiffForZendeskTicket = this.getDifferenceInFormValue(
        this.formService.originalFormValue,
        form
      );
      payload.zendeskData = {
        form,
        formDiffForZendeskTicket,
        policyNumber: this.policyNumber,
        termNumber: this.termNumber,
      };
    }

    return payload;
  };

  // Location endorsement helpers

  locationsFormArray(): UntypedFormArray {
    return this.formService.locationsFormArray();
  }

  locationAddressesFormArray(): UntypedFormArray {
    return this.formService.locationAddressesFormArray();
  }

  currentStepIsNewLocation(): boolean {
    const locationIndex = this.endorsementLocationIndex();
    const existingLocationCount = this.existingLocationAddresses().length;

    return locationIndex === null ? false : locationIndex > existingLocationCount;
  }

  newLocationAddresses(): Address[] {
    return this.formService.newLocationAddresses();
  }

  existingLocationAddresses() {
    return this.formService.existingLocationAddresses();
  }

  addLocation() {
    this.formService.addLocation();
  }

  removeLocation(numberToRemove: number) {
    this.formService.removeLocation(numberToRemove);
  }

  currentLocation(): UntypedFormGroup | null {
    const locationsArray = this.locationsFormArray();
    const loc = this.endorsementLocationIndex();

    if (loc && locationsArray) {
      const locationFormIndex = loc - this.existingLocationAddresses().length - 1;
      return locationsArray.at(locationFormIndex) as UntypedFormGroup;
    }
    return null;
  }

  currentLocationIndex(): number | null {
    const locationsArray = this.locationsFormArray();
    const loc = this.endorsementLocationIndex();

    if (loc && locationsArray) {
      return loc - this.existingLocationAddresses().length;
    }
    return null;
  }

  currentLocationDetails(): UntypedFormGroup | null {
    const locationFormGroup: UntypedFormGroup | null = this.currentLocation();
    return locationFormGroup ? getFormGroup(locationFormGroup, 'locationDetails') : null;
  }

  endorsementLocationIndex(): number | null {
    const currentStepSlug = this.currentStep.slug;
    const locationSlugMatch =
      currentStepSlug.match(/^location-(\d+)/) || currentStepSlug.match(/^building-(\d+)-\d+/);

    if (locationSlugMatch) {
      return Number(locationSlugMatch[1]);
    } else if (/^policy-info/.test(currentStepSlug)) {
      return 1;
    }

    return null;
  }

  moreThanOneLocation(): boolean {
    return this.locationAddressesFormArray().length > 1;
  }

  getUniqueAddressError(locationIndex: number): boolean {
    const policyInfoErrors = getFormGroup(this.form, 'policyInfo').errors;
    if (
      policyInfoErrors &&
      policyInfoErrors.repeatAddress &&
      policyInfoErrors.repeatAddress.index === locationIndex
    ) {
      return true;
    }
    return false;
  }

  getBppLimitError(buildingIndex: number) {
    const buildingFormGroup = getFormArray(this.form, 'coverages.exposures.coveredBuildings').at(
      buildingIndex
    ) as UntypedFormGroup;
    const bppLimitControl = getControl(buildingFormGroup, 'limitForBusinessPersonalProperty');

    return _.get(bppLimitControl, 'errors.bppValidator.message', '');
  }

  // Building methods

  // Buildings are 1-indexed.
  buildingIndex() {
    const currentStepSlug = this.currentStep.slug;
    const buildingSlugMatch = currentStepSlug.match(/^building-\d+-(\d+)/);
    if (buildingSlugMatch) {
      return Number(buildingSlugMatch[1]);
    }

    return null;
  }

  buildingsFormArray(locationIndex: number): UntypedFormArray {
    return <UntypedFormArray>this.locationsFormArray().get([locationIndex, 'buildings']);
  }

  currentBuilding(): UntypedFormGroup | null {
    const loc = this.endorsementLocationIndex();
    const building = this.buildingIndex();

    if (loc && building) {
      const locationFormIndex = loc - this.existingLocationAddresses().length - 1;
      return this.buildingsFormArray(locationFormIndex).at(building - 1) as UntypedFormGroup;
    }
    return null;
  }

  currentBuildingExposure() {
    return (<UntypedFormGroup>this.currentBuilding()).get('exposure');
  }

  currentBuildingLessorsRisk() {
    return (<UntypedFormGroup>this.currentBuilding()).get('lessorsRisk');
  }

  currentBuildingCoverage() {
    return (<UntypedFormGroup>this.currentBuilding()).get('coverage');
  }
  // End of Location and Building methods

  // Prefill
  private subscribeToPrefill() {
    this.amplitudeService.track({
      eventName: 'prefill_attempt',
      detail: '',
      useLegacyEventName: true,
    });
    const { companyName, doingBusinessAs, website, phoneNumber, naicsCode } = this.insAccount;
    const naicsHash = naicsCode ? naicsCode.hash : '';
    const modifiedLocations: any[] = this.modifiedExistingLocations();
    const newLocations: Address[] = this.newLocationAddresses();

    const locationsToCheck = modifiedLocations.concat(newLocations);
    const prefillRequestGroups = _.chunk(locationsToCheck, PREFILL_REQUEST_GROUP_SIZE);

    return concat(
      ...prefillRequestGroups.map((prefillRequestGroup) => {
        return forkJoin(
          ...prefillRequestGroup.map((address: Address) =>
            race(
              this.prefillService.fetch(
                address,
                companyName,
                this.tsRequestId,
                this.accountId,
                'AGENT_PORTAL',
                this.formService.bopVersion(),
                doingBusinessAs,
                website,
                phoneNumber,
                naicsHash
              ),
              timer(PREFILL_TIMEOUT_MS).pipe(map(() => null))
            )
          )
        );
      })
    )
      .pipe(bufferCount(prefillRequestGroups.length))
      .subscribe(
        (res: Array<Array<PrefillData | null>>) => {
          const consolidatedResponses = _.flatten(res) as (PrefillData | null)[];

          const existingLocationCount = this.existingLocationAddresses().length;
          const modifiedLocationResponses = consolidatedResponses.slice(
            0,
            modifiedLocations.length
          );
          const newLocationResponses = consolidatedResponses.slice(modifiedLocations.length);

          // For modified locations, we only use prefill to check for declines; existing locations
          // do not have a location form to patch.
          modifiedLocationResponses.forEach((prefillResponse: PrefillData | null, i: number) => {
            const declineReasons: string[] = _.get(
              prefillResponse,
              'response.uwDecisionData.riskDeclineReason',
              []
            );

            const originalLocationIndex = modifiedLocations[i].originalLocationNumber - 1;
            this.patchPrefillDecline(originalLocationIndex, declineReasons);
          });

          let newLocationWasDeclined = false;
          // For new locations, we use the prefill data to check for declines AND to patch
          // the corresponding Location form with returned values.
          newLocationResponses.forEach((prefillResponse: PrefillData | null, i: number) => {
            const declineReasons: string[] = _.get(
              prefillResponse,
              'response.uwDecisionData.riskDeclineReason',
              []
            );
            this.patchPrefillDecline(i + existingLocationCount, declineReasons);
            if (declineReasons.length) {
              newLocationWasDeclined = true;
              // If location is declined, return without patching the corresponding Location form.
              return;
            }

            if (prefillResponse) {
              this.prefillForm(prefillResponse, i);
            }
          });

          const allLocationsHaveResponse = consolidatedResponses.every(
            (prefillData) => prefillData !== null
          );

          if (allLocationsHaveResponse) {
            this.amplitudeService.track({
              eventName: 'prefill_success',
              detail: '',
              useLegacyEventName: true,
            });
          } else {
            const locationsFormArray = this.locationsFormArray();
            const locations: BopLocation[] = locationsFormArray.value;

            const hasPrefillData = (location: BopLocation) => {
              return !_.isEmpty(location.locationPrefill);
            };
            // If we are missing one or more prefill responses, we check to see whether the corresponding
            // form was already patched. (This could happen if the user navigates back to the Policy Info page
            // after prefill ran successfully a first time.)
            if (!locations.every(hasPrefillData)) {
              // TODO (TH) - Drafts are not available in endorsement flow. Should we submit for review or kick user out of endorsement flow?
              this.informService.warnToast(
                'Due to a system outage some fields might be unavailable. Please try again later.',
                null,
                null,
                'Got it',
                null,
                0
              );
              this.amplitudeService.track({
                eventName: 'prefill_failed',
                detail: '',
                useLegacyEventName: true,
              });
            }
          }
          this.uwDecisionLoading = false;

          // If any of the new locations are declined, return to Policy Info step.
          // The user will need to remove the declined location in order to proceed.
          if (newLocationWasDeclined) {
            this.formService.stepBackward();
          } else {
            this.navigateToCurrentStep();
          }
        },
        () => {
          this.informService.warnToast(
            'Due to a system outage some fields might be unavailable. Please try again later.',
            null,
            null,
            'Got it',
            null,
            0
          );
          this.amplitudeService.track({
            eventName: 'prefill_failed',
            detail: '',
            useLegacyEventName: true,
          });
          if (this.uwDecisionLoading) {
            this.uwDecisionLoading = false;
            this.navigateToCurrentStep();
          }
        }
      );
  }

  modifiedExistingLocations() {
    return this.formService.modifiedExistingLocations();
  }

  private prefillForm(prefillData: PrefillData, locIndex: number) {
    const { buildingPrefill, locationPrefill } = this.prefillService.getPrefill(prefillData);
    const locationsArray = this.locationsFormArray();
    const locationForm = locationsArray.at(locIndex);
    const locDetails = locationForm.value.locationDetails;

    if (locationForm && locDetails) {
      locationForm.patchValue({ locationPrefill });
      const address = this.getAddress(locDetails);
      const cachedAddress: Address | undefined = this.prefillAddressMap.get(locIndex);
      const buildingForm = locationForm.get(['buildings', '0', 'exposure']);
      if (!_.isEqual(cachedAddress, address) && buildingForm) {
        buildingForm.patchValue(buildingPrefill);
        this.prefillAddressMap.set(locIndex, address);
      }
    }
  }

  private patchPrefillDecline(locationIndex: number, declineReasonsArray: string[]) {
    const locationAddressControl = this.locationAddressesFormArray().at(locationIndex);
    locationAddressControl.patchValue({ prefillDeclineReasons: declineReasonsArray });
  }

  getPrefillDeclineList(locationIndex: number): string[] | null {
    const prefillDeclineReasonsControl = this.formService.get([
      'policyInfo',
      'locationAddresses',
      locationIndex,
      'prefillDeclineReasons',
    ]);

    return prefillDeclineReasonsControl?.value.length > 0
      ? prefillDeclineReasonsControl?.value
      : null;
  }

  generateRequestId() {
    this.tsRequestId = uuidv4();
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  protected getAddress(locationDetails: Address): Address {
    const { addressLine1, addressLine2, zip, state, city } = locationDetails;
    return {
      addressLine1: addressLine1 || '',
      addressLine2: addressLine2 || null,
      zip: zip || '',
      state: state || '',
      city: city || '',
    };
  }
  // End of prefill

  // Helpers for handling quote response
  handlePolicyChangeDecline() {
    this.form.patchValue({ review: { quoteWasDeclined: true } });
    this.form.patchValue({ review: { quoteReturnedSuccessfully: false } });

    this.formService.updateReviewStep();
    this.formService.refreshCurrentStep();
  }

  handlePolicyChangeFailure() {
    this.form.patchValue({ review: { policyChangeFailed: true } });
    this.form.patchValue({ review: { quoteReturnedSuccessfully: false } });

    this.formService.updateReviewStep();
    this.formService.refreshCurrentStep();
  }

  handlePolicyChangeTimeout() {
    this.form.patchValue({ review: { policyChangeFailed: false } });
    this.form.patchValue({ review: { policyChangeTimedOut: true } });
    this.form.patchValue({ review: { quoteReturnedSuccessfully: false } });

    this.formService.updateReviewStep();
    this.formService.refreshCurrentStep();
  }

  handleSmallPremiumIncrease() {
    this.form.patchValue({ review: { premiumIncreaseIsSmall: true } });
    this.form.patchValue({ review: { quoteReturnedSuccessfully: false } });

    this.formService.updateReviewStep();
    this.formService.refreshCurrentStep();
  }

  handleSuccessfulQuote() {
    this.form.patchValue({ review: { quoteReturnedSuccessfully: true } });

    this.formService.updateReviewStep();
    this.formService.refreshCurrentStep();
  }
  // End Helpers for handling quote response

  bopVersion(): BopVersion {
    return this.formService.bopVersion();
  }

  fillChangeRequests(changes: string) {
    if (this.form && changes) {
      const changesToCheck = changes.split(',');
      changesToCheck.forEach((change) => {
        const inputControl = this.form.get(`endorsementRequests.typeOfEndorsementOptIn.${change}`);
        if (inputControl) {
          inputControl.patchValue(true);
        }
      });
    }
  }

  displayPremiumChangeAndHandleQuote(
    jobNumber: string,
    priceDifference: number,
    totalCost: number
  ) {
    this.sub.add(
      this.gwService.retrieveQuote(jobNumber).subscribe(
        (response: RetrieveQuoteResponse) => {
          if (response.return.QuoteSubmissionRequest.Periods.Entry[0].WaiveTransaction_ATTN) {
            // If WaiveTransaction flag is true, set price diff to 0 and set the total cost to the original premium
            this.priceDifference = 0;
            this.totalCost = totalCost - priceDifference;
            this.isTransactionWaived = true;

            this.amplitudeService.track({
              eventName: 'endorsement_transaction_waived',
              detail: this.getEndorsementTypesSelected().join(),
            });

            this.handleSuccessfulQuote();
          } else {
            if (priceDifference > 0 && priceDifference <= 10) {
              // For all Accredited policies and BB policies in CA, we do not waive the transaction on <$10 endorsements and it requires review
              this.handleSmallPremiumIncrease();
              this.amplitudeService.track({
                eventName: 'endorsement_small_premium_increase',
                detail: this.getEndorsementTypesSelected().join(),
              });
            } else {
              this.priceDifference = priceDifference;
              this.totalCost = totalCost;
              this.handleSuccessfulQuote();
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.handlePolicyChangeFailure();
          this.amplitudeService.trackWithOverride({
            eventName: 'endorsement_retrieve_quote_error',
            detail: this.getEndorsementTypesSelected().join(),
            payloadOverride: {
              error: `Error retrieving quote to display premium change: ${_.get(
                error,
                'error.message',
                'Unknown error'
              )}`,
            },
          });
        }
      )
    );
  }

  checkEligibility(): Observable<void> {
    const existingLocationCount = this.existingLocationAddresses().length;
    const locationsFormArray = this.locationsFormArray();
    const bopLocations: BopLocation[] = locationsFormArray.value
      .map((location: BopLocation, index: number): BopLocation => {
        return {
          ...location,
          locationNumber: existingLocationCount + index + 1,
        };
      })
      .filter((location: BopLocation) => {
        // We only want to run eligibility on locations that the broker has already passed in the form.
        return this.formService.isAfterStep(`building-${location.locationNumber}-1`);
      });

    if (bopLocations.length === 0) {
      // We do not use the result of this function so we return void early when there
      // are no locations to check eligibility for.
      return observableOf(void 0);
    }

    this.eligibilityCheckLoading = true;
    return this.eligibilityService
      .fetchEligibilityCheck({
        accountId: this.accountId,
        bopVersion: this.bopVersion(),
        effectiveDate: this.effectiveDate.value,
        isBopMeTooQuote: false,
        tsRequestId: this.tsRequestId,
        bopLocations: bopLocations,
      })
      .pipe(
        map((eligibilityResponse) => {
          let ineligibleLocation = false;
          let ineligiblePolicy = false;
          // Check eligibility for locations
          eligibilityResponse.locationEligibilities.forEach((result) => {
            if (result.acceptRisk === false && result.riskDeclineReason.length > 0) {
              result.riskDeclineReason = result.riskDeclineReason.map((reason) => {
                // For location-specific rules, prepend the location number to the decline reason.
                // Each reason should be listed separately so that it is clear they are different reasons.
                return `Location ${result.locationNumber}: ${reason}`;
              });

              this.patchPrefillDecline(
                (result.locationNumber as number) - 1,
                result.riskDeclineReason
              );
              ineligibleLocation = true;
            }
          });

          // Check policy level eligility
          if (
            eligibilityResponse?.policyEligibility.acceptRisk === false &&
            eligibilityResponse?.policyEligibility.riskDeclineReason.length > 0
          ) {
            // We will attach a 'policy' level decline to the first ineligible new location.
            // If there are no ineligible locations, we will attach it to the first new location.
            // This is done because we do not surface a modal for ineligible declines given a broker can still proceed with the rest of the endorsement.
            if (ineligibleLocation) {
              const firstIneligibleLocation = eligibilityResponse.locationEligibilities.find(
                (result) => result.acceptRisk === false
              ) as EligibilityResult;

              this.patchPrefillDecline((firstIneligibleLocation.locationNumber as number) - 1, [
                ...eligibilityResponse.policyEligibility.riskDeclineReason,
                ...firstIneligibleLocation.riskDeclineReason,
              ]);
            } else {
              const firstNewLocation = bopLocations[0];
              this.patchPrefillDecline((firstNewLocation.locationNumber as number) - 1, [
                ...eligibilityResponse.policyEligibility.riskDeclineReason,
              ]);
            }
            ineligiblePolicy = true;
          }
          if (ineligibleLocation || ineligiblePolicy) {
            // If the policy has ineligible locations or policy criteria (e.g. above total insured value cap)
            // We return back to the policy info locations screen and display the reasons.
            this.handleNavigateToSlug('policy-info');
          } else {
            this.navigateToCurrentStep();
          }

          this.eligibilityCheckLoading = false;
        })
      );
  }
}
