// these are utility functions that make generating the agreeDisagree
// statements and conditionals much simpler.  generally I don't want
// us to write a bunch of complex helper functions (macros),
// preferring simple json.
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  PlConfigNode,
  PlDivNode,
  LinkModalNode,
  PlCheckBoxGroupNode,
} from 'app/features/hiscox/models/pl-types';
import {
  checkboxGroupNode,
  CheckboxNodeConfig,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { ValidatorName } from 'app/shared/form-dsl/constants/form-dsl-constants';
import { ValidatorFn, UntypedFormGroup } from '@angular/forms';
import {
  CobOverrideGroups,
  GlV4Overrides,
  HISCOX_API_VERSION,
  HISCOX_PRODUCTS,
} from './hiscox-types';
import { shouldApplyOverrideNode } from './cob-overrides';
import { HiscoxPricedQuote } from './hiscox-priced-quote';
import {
  EOSTATEMENT1,
  EOSTATEMENT2_V4,
  EOSTATEMENT3_V4_NY,
  HISCOX_STATEMENT,
} from './pl-constants';
import { HiscoxGlFormDataFieldV4 } from './gl-constants';
import { HiscoxAcknowledgementsQuestionsV4, HISCOX_QUESTIONS_V4 } from './hiscox-constants';

const glRiV4 = (key: string) => {
  if (key.indexOf('ProductAcknowledgements') > -1) {
    return key;
  }

  const requestPath = 'Acknowledgements_' + key;
  return requestPath;
};

/*
  This function is being used to prepend string with Acknowledgements_,
  this is true for inputId values that are shortened, but
  need to be pre-fixed by Acknowledgements_, but ProductAcknowledgements
  already have the expected path
*/
const plRiV4 = (key: string) => {
  if (key.indexOf('ProductAcknowledgements') > -1) {
    return key;
  }

  const requestPath = 'Acknowledgements_' + key;
  return requestPath;
};

type RiFunc = (key: string) => string;

const setCobOnly = (node: PlConfigNode, classOfBusiness: string) => {
  return { ...node, cobOnly: classOfBusiness };
};

const agdBaseVcV4 = (
  inputId: string,
  labelText: string,
  riFunc: RiFunc,
  requiredField: boolean = false
) => {
  const baseChildRadio = {
    primitive: 'RADIO',
    inputId: inputId,
    nameOfFormControl: inputId,
    labelText: labelText,
    cssClass: 'sub-question',
    required: requiredField,
    options: {
      Agree: 'Agree',
      Disagree: 'Disagree',
    },
    versions: [HISCOX_API_VERSION.v4],
  };

  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: riFunc('AgreeDisagreeStatements'),
    enableValue: 'Disagree',
    required: requiredField,
    conditionalChildren: [baseChildRadio],
    versions: [HISCOX_API_VERSION.v4],
  };
};

/**
 * This helper function is used to add a RADIO node question to the quote flow
 * when the user disagrees to the statements in the modal
 *
 * @param inputId data item path
 * @param labelText question text
 * @param riFunc function
 * @param requiredField boolean
 * @param listOptions string[] of options to be shown in the question
 */
const agreeDisagreeBaseRadioWithListV4 = (
  inputId: string,
  labelText: string,
  riFunc: RiFunc,
  requiredField: boolean = false,
  listOptions: string[]
) => {
  const baseChildRadio = {
    primitive: 'RADIO',
    inputId: inputId,
    nameOfFormControl: inputId,
    labelText: labelText,
    cssClass: 'sub-question',
    required: requiredField,
    options: {
      Agree: 'Agree',
      Disagree: 'Disagree',
    },
    versions: [HISCOX_API_VERSION.v4],
    ul: {
      li: listOptions,
    },
  };

  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: riFunc('AgreeDisagreeStatements'),
    enableValue: 'Disagree',
    required: requiredField,
    conditionalChildren: [baseChildRadio],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agdChildValueConditionalDateNode = (
  depends: string,
  childInputId: string,
  labelText: string,
  errorText: string,
  requiredField: boolean = false
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Disagree',
    required: requiredField,
    conditionalChildren: [
      {
        primitive: 'DATE',
        inputType: 'text',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        labelText: labelText,
        errorText: errorText,
        required: requiredField,
        versions: [HISCOX_API_VERSION.v4],
        cssClass: 'sub-question2',
        validators: [ValidatorName.VALID_DATE],
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agdChildValueConditionalRadioNode = (
  depends: string,
  childInputId: string,
  labelText: string,
  errorText: string,
  requiredProperty: boolean = false
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Disagree',
    required: requiredProperty,
    conditionalChildren: [
      {
        primitive: 'RADIO',
        inputType: 'text',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        labelText: labelText,
        errorText: errorText,
        required: requiredProperty,
        versions: [HISCOX_API_VERSION.v4],
        options: {
          Agree: 'Agree',
          Disagree: 'Disagree',
        },
        cssClass: 'sub-question2',
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agreeDisagreeChildValueConditionalYesNo = (
  depends: string,
  childInputId: string,
  labelText: string,
  requiredProperty: boolean = false,
  errorText?: string
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Disagree',
    required: requiredProperty,
    conditionalChildren: [
      {
        primitive: 'RADIO',
        inputType: 'text',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        labelText: labelText,
        errorText: errorText,
        required: requiredProperty,
        versions: [HISCOX_API_VERSION.v4],
        options: {
          Yes: 'Yes',
          No: 'No',
        },
        cssClass: 'sub-question2',
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agreeDisagreeChildValueConditionalTextNode = (
  depends: string,
  childInputId: string,
  labelText: string,
  requiredField: boolean = false
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Disagree',
    required: requiredField,
    conditionalChildren: [
      {
        primitive: 'TEXT',
        labelText: labelText,
        cssClass: 'sub-question2',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        required: requiredField,
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agreeDisagreeChildValueConditionalNumberNode = (
  depends: string,
  childInputId: string,
  labelText: string,
  requiredField: boolean = false
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Disagree',
    required: requiredField,
    conditionalChildren: [
      {
        primitive: 'NUMBER',
        labelText: labelText,
        cssClass: 'sub-question2',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        required: requiredField,
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const yesNoChildValueConditionalTextNode = (
  depends: string,
  childInputId: string,
  labelText: string,
  requiredField: boolean = false
) => {
  return {
    primitive: 'VALUE-CONDITIONAL',
    dependsOn: depends,
    enableValue: 'Yes',
    required: requiredField,
    conditionalChildren: [
      {
        primitive: 'TEXT',
        labelText: labelText,
        cssClass: 'sub-question2',
        inputId: childInputId,
        nameOfFormControl: childInputId,
        required: requiredField,
      },
    ],
    versions: [HISCOX_API_VERSION.v4],
  };
};

const agdModalBody = (
  aboutBusiness: string,
  businessActivities: string,
  claimsAndLosses: string,
  productStatements: string,
  additionalStatements: string = ''
) => {
  const modalBody = `
  ${aboutBusiness}

  ${businessActivities}

  ${claimsAndLosses}

  ${productStatements}

  ${additionalStatements}`;

  return modalBody;
};

/*
  When the broker goes to the second step of the form, at the end of the form
  they will have the option to click a link to read some statements and then
  check an agree or disagree option.

  This helper method loads th emodal when the broker clicks the:
  `Important Legal Information` link

  A v4 version is used to differentiate between the different inputIds
*/
const agreeDisagreeModalNodeV4 = (modalBody: string, riFunc: RiFunc): LinkModalNode => {
  const modal: LinkModalNode = {
    primitive: 'LINK_MODAL',
    modalTitle: 'Customer Acknowledgments',
    modalPreLinkText: 'Please read our ',
    modalLinkText: 'Important Legal Information',
    modalPostLinkText: ' here.',
    modalBody: modalBody,
    removeClasses: ['app-page-form-scrollable-wrapper', 'form-animation'],
    child: {
      primitive: 'RADIO',
      inputId: riFunc('AgreeDisagreeStatements'),
      nameOfFormControl: riFunc('AgreeDisagreeStatements'),
      options: {
        'I have read and agree with these statements': 'Agree',
        'I have read and disagree with one or more of these statements': 'Disagree',
      },
      versions: [HISCOX_API_VERSION.v4],
    },
    versions: [HISCOX_API_VERSION.v4],
  };
  return modal;
};

const agdChildrenPlV4 = (modalNode: any, riFunc: RiFunc) => {
  return [
    modalNode,

    // ProductProductAcknowledgements
    agdBaseVcV4(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      "The business' owner, current and past partners, officers, directors, board members, trustees, or employees, have never been subject to disciplinary action by authorities as a result of professional activities.",
      riFunc,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionProfDate'
      ),
      'What is the date of professional activity that prompted disciplinary action?',
      'Enter date'
    ),
    agdChildValueConditionalDateNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionDate'
      ),
      'What is the date of the disciplinary action?',
      'Enter date'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionExplain'
      ),
      'Please describe the incident that caused the disciplinary action to arise.'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionAvoid'
      ),
      'If applicable, please describe steps taken to avoid future actions?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionName'
      ),
      'What is the name of entity that took the disciplinary action?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryAction'
      ),
      riFunc(
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_DisciplinaryActionAcknowledgements_EODisciplinaryActionOutcome'
      ),
      'What was the outcome of disciplinary action? (include any fines)'
    ),

    // Acknowledgements_ will be added by plRiV4

    // MergerAcquisitions
    agdBaseVcV4(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS]
          .labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsAction'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION
        ].labelText
      }`,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionPlace'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE
        ].labelText
      }`,
      'Enter date',
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionWhy'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY
        ].labelText
      }`,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsEntity'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY
        ].labelText
      }`,
      true
    ),

    // ApplicantAuthorized
    // not updating to use `HISCOX_QUESTIONS_V4` to minimize confusion
    agdBaseVcV4(
      riFunc('ApplicantAuthorized'),
      'As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage. ',
      riFunc
    ),

    agdBaseVcV4(riFunc('HiscoxStatement'), HISCOX_STATEMENT, riFunc),

    // InsuranceDecline
    agdBaseVcV4(
      riFunc('InsuranceDecline_InsuranceDecline'),
      'The business has never had any commercial insurance cancelled or rescinded.',
      riFunc
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineAction'),
      'Describe the action that took place and the reason:',
      true
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancel'),
      'Was this cancellation for non-payment?',
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancelDate'),
      'What was the date of decline, cancellation or rescission?',
      'Enter date'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCarrier'),
      'What is the name of carrier that declined, cancelled or rescinded?',
      true
    ),

    agdBaseVcV4(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU
        ].labelText
      }`,
      riFunc,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouClaimDate'),
      'What was the date of the claim(s)?',
      'Enter date'
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouActDate'),
      'When did the act/incident occur that resulted in the claim?',
      'Enter date'
    ),
    agdChildValueConditionalRadioNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouLossRun'),
      'Please note that a copy of a loss run may be required',
      '',
      false
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouAvoid'),
      'If applicable, please describe steps taken to avoid a future incident? \n\nPlease note that a copy of a loss run may be required.'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouName'),
      'Please provide the name of the parties involved.'
    ),

    // BusinessOwnership
    agdBaseVcV4(
      riFunc('BusinessOwnership_BusinessOwnership'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingComp'),
      'What is the name of the controlling entity?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingRelBus'),
      'What is the relationship of the controlling entity to the business?'
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsCov'),
      'Is the controlling party providing the insurance coverage that’s being requested?',
      true
    ),
    yesNoChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusOwnInsCov'),
      riFunc('BusinessOwnership_BusOwnInsCovPurpose'),
      'What is the purpose for procuring this insurance?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsReq'),
      'Is additional insured status required  for the controlling party?'
    ),
    agreeDisagreeChildValueConditionalNumberNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnPercentControl'),
      'What percentage of the business does the entity control?'
    ),
  ];
};

const agdChildrenAllCobsGlV4 = (modalNode: any, riFunc: RiFunc) => {
  return [
    modalNode,

    // ProductProductAcknowledgements

    // Excluded Activities - added when user disagrees to modal statements
    agreeDisagreeBaseRadioWithListV4(
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES,
      HISCOX_QUESTIONS_V4[
        HiscoxAcknowledgementsQuestionsV4
          .PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES
      ].labelText,
      riFunc,
      true,
      [
        'Automotive repair or sales',
        'Food service/restaurant operations',
        'Medical services',
        'Retail operations',
      ]
    ),
    // Acknowledgements_ will be added by plRiV4

    // MergerAcquisitions
    agdBaseVcV4(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS]
          .labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsAction'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION
        ].labelText
      }`,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionPlace'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE
        ].labelText
      }`,
      'Enter date',
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionWhy'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY
        ].labelText
      }`,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsEntity'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY
        ].labelText
      }`,
      true
    ),

    // ApplicantAuthorized
    agdBaseVcV4(
      riFunc('ApplicantAuthorized'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED]
          .labelText
      }`,
      riFunc,
      true
    ),

    agdBaseVcV4(riFunc('HiscoxStatement'), HISCOX_STATEMENT, riFunc),

    // InsuranceDecline
    agdBaseVcV4(
      riFunc('InsuranceDecline_InsuranceDecline'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineAction'),
      'Describe the action that took place and the reason:',
      true
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancel'),
      'Was this cancellation for non-payment?',
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancelDate'),
      'What was the date of decline, cancellation or rescission?',
      'Enter date'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCarrier'),
      'What is the name of carrier that declined, cancelled or rescinded?',
      true
    ),

    agdBaseVcV4(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU
        ].labelText
      }`,
      riFunc,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouClaimDate'),
      'What was the date of the claim(s)?',
      'Enter date'
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouActDate'),
      'When did the act/incident occur that resulted in the claim?',
      'Enter date'
    ),
    agdChildValueConditionalRadioNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouLossRun'),
      'Please note that a copy of a loss run may be required',
      '',
      false
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouAvoid'),
      'If applicable, please describe steps taken to avoid a future incident? \n\nPlease note that a copy of a loss run may be required.'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouName'),
      'Please provide the name of the parties involved.'
    ),

    // BusinessOwnership
    agdBaseVcV4(
      riFunc('BusinessOwnership_BusinessOwnership'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingComp'),
      'What is the name of the controlling entity?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingRelBus'),
      'What is the relationship of the controlling entity to the business?'
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsCov'),
      'Is the controlling party providing the insurance coverage that’s being requested?',
      true
    ),
    yesNoChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusOwnInsCov'),
      riFunc('BusinessOwnership_BusOwnInsCovPurpose'),
      'What is the purpose for procuring this insurance?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsReq'),
      'Is additional insured status required  for the controlling party?'
    ),
    agreeDisagreeChildValueConditionalNumberNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnPercentControl'),
      'What percentage of the business does the entity control?'
    ),
  ];
};

/**
 * This helper function adds all the modal statements as question into the quote flow.
 * This version is for Mobile Food COBs only.
 * @param modalNode
 * @param riFunc
 */
const agdChildrenMobileFoodCobsGlV4 = (modalNode: any, riFunc: RiFunc) => {
  return [
    modalNode,

    // ProductProductAcknowledgements

    // Excluded Activities - added when user disagrees to modal statements
    agreeDisagreeBaseRadioWithListV4(
      'ProductQuoteRqs_GeneralLiabilityQuoteRq_ProductAcknowledgements_ExcludedActivities',
      'The business does not conduct any of the following activities:',
      riFunc,
      true,
      ['Automotive repair or sales', 'Medical services']
    ),
    // Acknowledgements_ will be added by plRiV4

    // MergerAcquisitions
    agdBaseVcV4(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS]
          .labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsAction'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION
        ].labelText
      }`,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionPlace'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE
        ].labelText
      }`,
      'Enter date',
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionWhy'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY
        ].labelText
      }`,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsEntity'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY
        ].labelText
      }`,
      true
    ),

    // ApplicantAuthorized
    agdBaseVcV4(
      riFunc('ApplicantAuthorized'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED]
          .labelText
      }`,
      riFunc,
      true
    ),

    agdBaseVcV4(riFunc('HiscoxStatement'), HISCOX_STATEMENT, riFunc),

    // InsuranceDecline
    agdBaseVcV4(
      riFunc('InsuranceDecline_InsuranceDecline'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineAction'),
      'Describe the action that took place and the reason:',
      true
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancel'),
      'Was this cancellation for non-payment?',
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancelDate'),
      'What was the date of decline, cancellation or rescission?',
      'Enter date'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCarrier'),
      'What is the name of carrier that declined, cancelled or rescinded?',
      true
    ),

    agdBaseVcV4(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU
        ].labelText
      }`,
      riFunc,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouClaimDate'),
      'What was the date of the claim(s)?',
      'Enter date'
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouActDate'),
      'When did the act/incident occur that resulted in the claim?',
      'Enter date'
    ),
    agdChildValueConditionalRadioNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouLossRun'),
      'Please note that a copy of a loss run may be required',
      '',
      false
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouAvoid'),
      'If applicable, please describe steps taken to avoid a future incident? \n\nPlease note that a copy of a loss run may be required.'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouName'),
      'Please provide the name of the parties involved.'
    ),

    // BusinessOwnership
    agdBaseVcV4(
      riFunc('BusinessOwnership_BusinessOwnership'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingComp'),
      'What is the name of the controlling entity?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingRelBus'),
      'What is the relationship of the controlling entity to the business?'
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsCov'),
      'Is the controlling party providing the insurance coverage that’s being requested?',
      true
    ),
    yesNoChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusOwnInsCov'),
      riFunc('BusinessOwnership_BusOwnInsCovPurpose'),
      'What is the purpose for procuring this insurance?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsReq'),
      'Is additional insured status required  for the controlling party?'
    ),
    agreeDisagreeChildValueConditionalNumberNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnPercentControl'),
      'What percentage of the business does the entity control?'
    ),
  ];
};

/**
 * This helper function adds all the modal statements as question into the quote flow.
 * This version is for Retail COBs only.
 * @param modalNode
 * @param riFunc
 */
const agdChildrenRetailCobsGlV4 = (modalNode: any, riFunc: RiFunc) => {
  return [
    modalNode,
    // ProductProductAcknowledgements

    // Excluded Activities - added when user disagrees to modal statements
    agreeDisagreeBaseRadioWithListV4(
      'ProductQuoteRqs_GeneralLiabilityQuoteRq_ProductAcknowledgements_ExcludedActivities',
      'The business does not conduct any of the following activities:',
      riFunc,
      true,
      ['Automotive repair or sales', 'Food service/restaurant operations', 'Medical services']
    ),
    // Acknowledgements_ will be added by plRiV4

    // MergerAcquisitions
    agdBaseVcV4(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS]
          .labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsAction'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION
        ].labelText
      }`,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionPlace'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE
        ].labelText
      }`,
      'Enter date',
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsActionWhy'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY
        ].labelText
      }`,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('MergerAcquisitions_MergerAcquisition'),
      riFunc('MergerAcquisitions_MergerAcquisitionsEntity'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4
            .ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY
        ].labelText
      }`,
      true
    ),

    // ApplicantAuthorized
    agdBaseVcV4(
      riFunc('ApplicantAuthorized'),
      `${
        HISCOX_QUESTIONS_V4[HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED]
          .labelText
      }`,
      riFunc,
      true
    ),

    agdBaseVcV4(riFunc('HiscoxStatement'), HISCOX_STATEMENT, riFunc),

    // InsuranceDecline
    agdBaseVcV4(
      riFunc('InsuranceDecline_InsuranceDecline'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE
        ].labelText
      }`,
      riFunc,
      true
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineAction'),
      'Describe the action that took place and the reason:',
      true
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancel'),
      'Was this cancellation for non-payment?',
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCancelDate'),
      'What was the date of decline, cancellation or rescission?',
      'Enter date'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('InsuranceDecline_InsuranceDecline'),
      riFunc('InsuranceDecline_InsuranceDeclineCarrier'),
      'What is the name of carrier that declined, cancelled or rescinded?',
      true
    ),

    agdBaseVcV4(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      `${
        HISCOX_QUESTIONS_V4[
          HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU
        ].labelText
      }`,
      riFunc,
      true
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouClaimDate'),
      'What was the date of the claim(s)?',
      'Enter date'
    ),
    agdChildValueConditionalDateNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouActDate'),
      'When did the act/incident occur that resulted in the claim?',
      'Enter date'
    ),
    agdChildValueConditionalRadioNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou_ClaimsAgainstYouLossRun'),
      'Please note that a copy of a loss run may be required',
      '',
      false
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouAvoid'),
      'If applicable, please describe steps taken to avoid a future incident? \n\nPlease note that a copy of a loss run may be required.'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('ClaimsAgainstYou_ClaimsAgainstYou'),
      riFunc('ClaimsAgainstYou_ClaimsAgainstYouName'),
      'Please provide the name of the parties involved.'
    ),

    // BusinessOwnership
    agdBaseVcV4(
      riFunc('BusinessOwnership_BusinessOwnership'),
      'The business is not controlled or owned by any other firm, corporation, or entity',
      riFunc
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingComp'),
      'What is the name of the controlling entity?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnControllingRelBus'),
      'What is the relationship of the controlling entity to the business?'
    ),
    agreeDisagreeChildValueConditionalYesNo(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsCov'),
      'Is the controlling party providing the insurance coverage that’s being requested?',
      true
    ),
    yesNoChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusOwnInsCov'),
      riFunc('BusinessOwnership_BusOwnInsCovPurpose'),
      'What is the purpose for procuring this insurance?'
    ),
    agreeDisagreeChildValueConditionalTextNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnInsReq'),
      'Is additional insured status required  for the controlling party?'
    ),
    agreeDisagreeChildValueConditionalNumberNode(
      riFunc('BusinessOwnership_BusinessOwnership'),
      riFunc('BusinessOwnership_BusOwnPercentControl'),
      'What percentage of the business does the entity control?'
    ),
  ];
};

/*
In GL V4, the following data items are:
- ApplicantAuthorized
- BusinessOwnership
- MergerAcquisitions
- InsuranceDecline
*/
const agdModalBodyAboutBizGl = `
<strong>Statements About Your Business</strong><br>
- As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage.<br>
- The business is not controlled or owned by any other firm, corporation, or entity.<br>
- For the entire period of time that the business owner has owned and controlled the business, they have not sold, purchased or acquired, discontinued, merged into or consolidated with another business.<br>
- The business has never had any commercial insurance cancelled or rescinded.<br><br>`;

/**
 * ExcludedActivities
 *
 * For All COBs, except mobile fodd services COBs and all retail COBs
 */
const agdModalBodyBizActsGeneralV4 = `
<strong>Business Activities</strong><br>
The business does not conduct any of the following activities*:<br>
- Automotive repair or sales<br>
- Food service/restaurant operations<br>
- Medical services<br>
- Retail operations<br>
*<strong>Note:</strong> This does not include the activities of the business\' clients in any of these industries<br><br>`;

/**
 * ExcludedActivities
 *
 * For all retail COBs
 */
const agdModalBodyBizActsRetailV4 = `
<strong>Business Activities</strong><br>
The business does not conduct any of the following activities*:<br>
- Automotive repair or sales<br>
- Food service/restaurant operations<br>
- Medical services<br>
*<strong>Note:</strong>This does not include the activities of the business\' clients in any of these industries<br><br>`;

/**
 * ExcludedActivities
 *
 * For all Mobile food services
 */
const agdModalBodyBizActsFoodV4 = `
<strong>Business Activities</strong><br>
The business does not conduct any of the following activities*:<br>
- Automotive repair or sales<br>
- Medical services<br>
*<strong>Note:</strong> This does not include the activities of the business\' clients in any of these industries<br><br>`;

const agdModalBodyClmLss = `
<strong>Claims and Loss History</strong><br>
Based upon the business owner's knowledge and the knowledge of the business' current and past partners, officers, directors and employees, during the last five years a third party has never made a claim against the business the business owner does not know of any reason why someone may make a claim.<br><br>`;

/**
 * CGLStatement1 for non NY states
 */
const agdModalBodyProdStmtsGlV4 = `
<strong>General Liability</strong><br>
- The limits of liability represent the total amount available to pay judgments and settlements for any claims. We are not liable for any amounts that exceed these limits.<br><br>
<strong>New York Only</strong><br>
- Policies are underwritten by Hiscox Insurance Company Inc., which provides payment to Hiscox Inc. for its role in the sale and administration of each issued policy. You may obtain information about compensation for Hiscox Inc. based on the sale of a policy or any quotes presented by requesting such information from Hiscox Inc.<br><br>`;

const agdModalBodyAddlStmtsGlV4 = `
<strong>Additional Statements and Disclosures</strong><br>
- If coverage is provided, it shall apply only to occurences that take place during the policy period.<br><br>
`;

/**
 * For all GL COBs - if not Mobile Food or Retail COBs
 */
const agdChildrenGeneralGlV4 = agdChildrenAllCobsGlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizGl,
      agdModalBodyBizActsGeneralV4,
      agdModalBodyClmLss,
      agdModalBodyProdStmtsGlV4,
      agdModalBodyAddlStmtsGlV4
    ),
    glRiV4
  ),
  glRiV4
);

/**
 * For all GL Retail COBs
 */
const agdChildrenRetailGlV4 = agdChildrenRetailCobsGlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizGl,
      agdModalBodyBizActsRetailV4,
      agdModalBodyClmLss,
      agdModalBodyProdStmtsGlV4,
      agdModalBodyAddlStmtsGlV4
    ),
    glRiV4
  ),
  glRiV4
);

/**
 * For only Mobile Food COBs
 */
const agdChildrenFoodGlV4 = agdChildrenMobileFoodCobsGlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizGl,
      agdModalBodyBizActsFoodV4,
      agdModalBodyClmLss,
      agdModalBodyProdStmtsGlV4,
      agdModalBodyAddlStmtsGlV4
    ),
    glRiV4
  ),
  glRiV4
);

export const glAgreeDisagreeV4: { [characterName: string]: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_AGREEDISAGREESTATEMENTS]: {
    primitive: 'DIV',
    cobOverrideSets: [
      {
        cobSet: 'RETAIL',
        overrides: {
          children: agdChildrenRetailGlV4,
        },
      },
      {
        cobSet: 'MOBILE_FOOD',
        overrides: {
          children: agdChildrenFoodGlV4,
        },
      },
    ],
    children: agdChildrenGeneralGlV4,
  },
};

const agdModalBodyAboutBizGeneralPl = `
<strong>Statements About Your Business</strong><br>
- As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage.<br>
- The business is not controlled or owned by any other firm, corporation, or entity.<br>
- For the entire period of time that the business owner has owned and controlled the business, they have not sold, purchased or acquired, discontinued, merged into or consolidated with another business.<br>
- The business' current and past partners, officers, directors, board members, trustees, or employees, have never been subject to disciplinary action by authorities as a result of professional activities.<br>
- The business has never had any commercial insurance cancelled or rescinded.<br><br>`;

const agdModalBodyAboutBizMoPl = `
<strong>Statements About Your Business</strong><br>
- As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage.<br>
- The business is not controlled or owned by any other firm, corporation, or entity.<br>
- For the entire period of time that the business owner has owned and controlled the business, they have not sold, purchased or acquired, discontinued, merged into or consolidated with another business.<br>
- The business' current and past partners, officers, directors, board members, trustees, or employees, have never been subject to disciplinary action by authorities as a result of professional activities.<br><br>`;

// EOSTATEMENT1
// ALL COBs, ALL States
// Hiscox V4
const agdModalBodyProdEoStatement1Pl = `
<strong>Professional Liability</strong><br>
${EOSTATEMENT1}<br><br>`;

// HISCOX_STATEMENT to be shown to all COBs in all states
const agdModalBodyStatementsGeneralPlV4 = `
  <strong>Additional Statements and Disclosures</strong><br>
  - ${EOSTATEMENT2_V4}<br><br>
  - ${HISCOX_STATEMENT}<br><br>`;

const agdModalBodyAddlStmtsNyPlV4 = `
  <strong>Additional Statements and Disclosures</strong><br>
  Important New York disclosure for claims-made policies.<br>
  - We are required by the State of New York to bring your attention to some important information about <a href="https://www.hiscox.com/small-business-insurance/quote-and-buy/read-more-important-new-york-disclosure-for-claims-made-policies" target="_blank">claims-made insurance policies</a><br>
  - ${EOSTATEMENT3_V4_NY}<br>`;

const agdChildrenGeneralPlV4 = agdChildrenPlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizGeneralPl,
      '',
      agdModalBodyClmLss,
      agdModalBodyProdEoStatement1Pl,
      agdModalBodyStatementsGeneralPlV4
    ),
    plRiV4
  ),
  plRiV4
);

const agdChildrenMoPlV4 = agdChildrenPlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizMoPl,
      '',
      agdModalBodyClmLss,
      agdModalBodyProdEoStatement1Pl,
      agdModalBodyStatementsGeneralPlV4
    ),
    plRiV4
  ),
  plRiV4
);

const agdChildrenNyPlV4 = agdChildrenPlV4(
  agreeDisagreeModalNodeV4(
    agdModalBody(
      agdModalBodyAboutBizGeneralPl,
      '',
      agdModalBodyClmLss,
      agdModalBodyProdEoStatement1Pl,
      agdModalBodyAddlStmtsNyPlV4
    ),
    plRiV4
  ),
  plRiV4
);

export const plAgreeDisagreeGeneralModal = (): PlDivNode => {
  return {
    primitive: 'DIV',
    children: agdChildrenGeneralPlV4.map((c: PlConfigNode) => {
      return setCobOnly(c, 'ALL');
    }),
    cobOnly: 'ALL',
    statesExclude: ['MO', 'NY'], // Another function is being used for MO and NY separately
    versions: [HISCOX_API_VERSION.v4],
  };
};

export const plAgreeDisagreeMoV4: PlDivNode = {
  primitive: 'DIV',
  children: agdChildrenMoPlV4.map((c: PlConfigNode) => {
    return setCobOnly(c, 'ALL');
  }),
  cobOnly: 'ALL',
  stateOnly: 'MO',
  versions: [HISCOX_API_VERSION.v4],
};

export const plAgreeDisagreeNyV4: PlDivNode = {
  primitive: 'DIV',
  children: agdChildrenNyPlV4.map((c: PlConfigNode) => {
    return setCobOnly(c, 'ALL');
  }),
  cobOnly: 'ALL',
  stateOnly: 'NY',
  versions: [HISCOX_API_VERSION.v4],
};

/*
  Uses CheckboxNodeConfig to generate a base CHECKBOX-GROUP primitive,
  then appends versions and cobOnly attributes that are needed in
  PlConfigNode primitives, so plConfigRaw can be filtered by version
  and COB
*/
export const generatePlV4CheckboxGroupNode = (
  config: {
    nameOfFormControl: string;
    labelText: string;
    checkboxConfigs: CheckboxNodeConfig[];
    cssClass?: string;
    required?: boolean;
    validators?: ValidatorName[];
  },
  versions: HISCOX_API_VERSION[],
  cobOnly: string
): PlCheckBoxGroupNode => {
  const baseCheckboxGroupNode = checkboxGroupNode(config);
  return {
    ...baseCheckboxGroupNode,
    versions,
    cobOnly,
  };
};

/*
  This function validates checkbox and will not allow the form to be
  submitted until a checkbox is selected.
  TODO: checkbox component needs to be updated to decorate the checkboxes
  in the case of a missing required question
*/
export const validateCheckboxGroupNode: ValidatorFn = (group: UntypedFormGroup) => {
  const hasAnswers = Object.values(group.controls).some((answer) => {
    return !!answer.value;
  });

  if (!hasAnswers) {
    return {
      invalidSelection: {
        value: 0,
        message: 'Please select at least one option.',
      },
    };
  }

  return null;
};

// Create override node for a given class of business as necessary
export const makeOverrideNodeByCob = (
  businessClasses: string[],
  classOfBusinessOverrideGroups: CobOverrideGroups
) => {
  // override automated form-dsl generation for a field
  // based on class of business and additional factors
  const overrideNodeByCob = (node: PlConfigNode, cob: string) => {
    const baseOverride = { ...node };
    // factor in class of business overrides
    let cobOverrides = [];
    if (_.hasIn(baseOverride, 'cobOverrideSets')) {
      const cobOverrideSets = baseOverride.cobOverrideSets;
      cobOverrides = cobOverrideSets.filter((cos: any[]) => {
        const cobGroupName = cos[0];
        // if cobGroupName is an array, check if the logical group includes cob
        // either in the array or in the associated BUSINESS_CLASS_OVERRIDES member
        if (Array.isArray(cobGroupName)) {
          return cobGroupName.some((cgn) => {
            return shouldApplyOverrideNode(
              businessClasses,
              classOfBusinessOverrideGroups,
              cgn,
              cob,
              HISCOX_PRODUCTS.pl
            );
          });
        }
        // check if there is a BUSINESS_CLASS_OVERRIDES member that includes cob
        return shouldApplyOverrideNode(
          businessClasses,
          classOfBusinessOverrideGroups,
          cobGroupName,
          cob,
          HISCOX_PRODUCTS.pl
        );
      });
    }
    if (!_.isEmpty(cobOverrides)) {
      const cobOverrideEntry = cobOverrides[0];
      const cobOverride = cobOverrideEntry[1];
      return { ...baseOverride, ...cobOverride };
    }
    return baseOverride;
  };
  return overrideNodeByCob;
};

export const hasQuoteExpired = (quote: HiscoxPricedQuote) => {
  // EOD needed, otherwise formatting this in current timezone puts date on previous day
  const effectiveDateUTC = quote.effectiveDate.endOf('day').format();
  const tz = moment.tz.guess();
  // Quote effective date is valid through end of day
  const effectiveDateLocal = moment.tz(effectiveDateUTC, tz).endOf('day');
  const currentDateTime = moment.tz(tz);

  return effectiveDateLocal.isBefore(currentDateTime);
};
