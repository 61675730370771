interface EligibleClassCode {
  label: string;
  isEligible: true;
  favorite?: boolean;
  naicsCode: NaicsCode;
}

interface IneligibleClassCode {
  label: string;
  isEligible: false;
}

export type PopularClassCode = EligibleClassCode | IneligibleClassCode;

enum BopBusinessCategory {
  CONTRACTORS = 'Contractors',
  LESSORS_RISK = "Lessor's Risk",
  OFFICES = 'Offices',
  PROFESSIONAL_SERVICES = 'Professional Services',
  RESTAURANTS = 'Restaurants',
  RETAIL = 'Retail',
}

export interface ClassCodeList {
  businessCategory: BopBusinessCategory;
  classCodes: PopularClassCode[];
}

export const POPULAR_CLASS_CODES: ClassCodeList[] = [
  {
    businessCategory: BopBusinessCategory.RETAIL,
    classCodes: [
      {
        label: 'Auto body shop supplies merchant wholesaler',
        isEligible: true,
        naicsCode: {
          code: '423120',
          description: 'Auto body shop supplies merchant wholesalers',
          hash: '5cdb57b5c0eba5e13c9c2ea3d2e7c6f5',
        },
      },
      {
        label: 'Flower shops',
        isEligible: true,
        naicsCode: {
          code: '453110',
          description: 'Flower shops, fresh',
          hash: 'dc48a4db1b9cae93124d82158e0f863b',
        },
      },
      {
        label: 'Liquor stores, package',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '445310',
          description: 'Liquor stores, package',
          hash: '75a46b8fc5eee7a59f6db555ea566f92',
        },
      },
      {
        label: 'Art supply stores',
        isEligible: true,
        naicsCode: {
          code: '453998',
          description: 'Art supply stores',
          hash: 'e3f01db4c3ca586f8f11449a76d66bd9',
        },
      },
      {
        label: 'Book stores',
        isEligible: true,
        naicsCode: {
          code: '451211',
          description: 'Book stores',
          hash: '5f34f77d36c1c9fb201969dfecc84117',
        },
      },
      {
        label: 'Linen stores',
        isEligible: true,
        naicsCode: {
          code: '442299',
          description: 'Linen stores',
          hash: '90824d9e88c01ccacf5282763801b7ef',
        },
      },
      {
        label: 'Window stores',
        isEligible: true,
        naicsCode: {
          code: '444190',
          description: 'Window stores',
          hash: 'af415ec442043cfb14575ae399fd18b6',
        },
      },
      {
        label: 'Dollar stores',
        isEligible: true,
        naicsCode: {
          code: '452319',
          description: 'Dollar stores',
          hash: 'fe816873ee21cc110efa345c4e66222f',
        },
      },
      {
        label: 'Bridal gown shops (except custom)',
        isEligible: true,
        naicsCode: {
          code: '448190',
          description: 'Bridal gown shops (except custom)',
          hash: 'd728a01077151968df9a48871d96c5ed',
        },
      },
      {
        label: 'Pet supply stores',
        isEligible: true,
        naicsCode: {
          code: '453910',
          description: 'Pet supply stores',
          hash: 'afdf4642b3f5bfca97c276d3288aff7c',
        },
      },
      {
        label: 'Gift shops',
        isEligible: true,
        naicsCode: {
          code: '453220',
          description: 'Gift shops',
          hash: '92696326c87e79c449d84bb11d68ee7d',
        },
      },
      {
        label: 'Wine shops, packaged',
        isEligible: true,
        naicsCode: {
          code: '445310',
          description: 'Wine shops, packaged',
          hash: '7c1d5c7a7bbcfc33acc534fa50649a4e',
        },
      },
      {
        label: 'Thrift shops, used merchandise',
        isEligible: true,
        naicsCode: {
          code: '453310',
          description: 'Thrift shops, used merchandise',
          hash: '8edeb5cf82fb1407437c3e9c69c37e8c',
        },
      },
      {
        label: 'Department stores',
        isEligible: true,
        naicsCode: {
          code: '452210',
          description: 'Department stores',
          hash: 'c3fb1221af20f63fa368ee60e5d72568',
        },
      },
      {
        label: 'Beer stores, packaged',
        isEligible: true,
        naicsCode: {
          code: '445310',
          description: 'Beer stores, packaged',
          hash: 'b840906da4a2337fbd6d8c7170a5b890',
        },
      },
      {
        label: 'Furniture stores',
        isEligible: false,
      },
      {
        label: 'Drug stores',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '446110',
          description: 'Drug stores',
          hash: '9cc18286e91dce8985f284bb9df52285',
        },
      },
      {
        label: 'Pharmacies',
        isEligible: true,
        naicsCode: {
          code: '446110',
          description: 'Pharmacies',
          hash: '1f7d60802ae9af4ac362f6c1338fcfd6',
        },
      },
      {
        label: 'Comic book stores',
        isEligible: true,
        naicsCode: {
          code: '451212',
          description: 'Comic book stores',
          hash: '1f80c87e7b9e879cd43dd4a535e6d324',
        },
      },
      {
        label: 'Consumer electronics rental',
        isEligible: false,
      },
      {
        label: 'Used car dealers',
        isEligible: false,
      },
      {
        label: 'Vitamin stores',
        isEligible: false,
      },
      {
        label: 'E-cigarette stores',
        isEligible: false,
      },
      {
        label: 'Electrical supply stores',
        isEligible: false,
      },
      {
        label: 'Antique shops',
        isEligible: false,
      },
      {
        label: 'Building materials supply dealers',
        isEligible: false,
      },
      {
        label: 'Internet retail sales sites',
        isEligible: false,
      },
      {
        label: 'Gasoline stations with convenience stores',
        isEligible: false,
      },
      {
        label: 'Tobacco stores',
        isEligible: false,
      },
      {
        label: 'Medical equipment and supplies stores',
        isEligible: false,
      },
    ],
  },
  {
    businessCategory: BopBusinessCategory.LESSORS_RISK,
    classCodes: [
      {
        label: "Lessor's of nonresidential buildings (except miniwarehouses)",
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '531120',
          description: 'Lessors of nonresidential buildings (except miniwarehouses)',
          hash: '487de5d0db526b5d95d58506adfb2f80',
        },
      },
    ],
  },
  {
    businessCategory: BopBusinessCategory.CONTRACTORS,
    classCodes: [
      {
        label: 'Granite, interior, installation',
        isEligible: true,
        naicsCode: {
          code: '238340',
          description: 'Granite, interior, installation',
          hash: '3b2ce20f5a900b3579feaa338464aa94',
        },
      },
      {
        label: 'Painting (except roof) contractors',
        isEligible: true,
        naicsCode: {
          code: '238320',
          description: 'Painting (except roof) contractors',
          hash: 'b8a05e6eaa436028e5348ad732317156',
        },
      },
      {
        label: 'Electrical contractors',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '238210',
          description: 'Electrical contractors',
          hash: '698a6976a5776dceffb0138afda7a583',
        },
      },
      {
        label: 'Stonework (i.e., masonry) contractors',
        isEligible: true,
        naicsCode: {
          code: '238140',
          description: 'Stonework (i.e., masonry) contractors',
          hash: '2d00b079fbbeb45da545c3775b4c742d',
        },
      },
      {
        label: 'Bricklaying contractors',
        isEligible: true,
        naicsCode: {
          code: '238140',
          description: 'Bricklaying contractors',
          hash: '416f7ece9bf5503e7178d8303a88a614',
        },
      },
      {
        label: 'Plumbers',
        isEligible: false,
      },
      {
        label: 'House painting',
        isEligible: true,
        naicsCode: {
          code: '238320',
          description: 'House painting',
          hash: '5cfa1bfa977b2c605a84031d9fe9670e',
        },
      },
      {
        label: 'Septic tank cleaning services',
        isEligible: true,
        naicsCode: {
          code: '562991',
          description: 'Septic tank cleaning services',
          hash: 'fc53594491da83c45164d6660ea53f1e',
        },
      },
      {
        label: 'Lawn care services',
        isEligible: true,
        naicsCode: {
          code: '561730',
          description: 'Lawn care services (e.g., fertilizing, mowing, seeding, spraying)',
          hash: 'a6247a2276867683229b7278f18fbb6c',
        },
      },
      {
        label: 'Drywall contractors',
        isEligible: true,
        naicsCode: {
          code: '238310',
          description: 'Drywall contractors',
          hash: '950eb18a1b00ed337b45a97746473166',
        },
      },
      {
        label: 'Ceramic tile installation',
        isEligible: true,
        naicsCode: {
          code: '238340',
          description: 'Ceramic tile installation',
          hash: 'b3a3d91480fd29af74f6a11856866ef8',
        },
      },
      {
        label: 'Tree trimming services',
        isEligible: true,
        naicsCode: {
          code: '561730',
          description: 'Tree trimming services',
          hash: '45b90a21bb9116e5bb9993640950c020',
        },
      },
      {
        label: 'Water heater installation',
        isEligible: false,
      },
      {
        label: 'Painting traffic lanes or parking lots',
        isEligible: false,
      },
      {
        label: 'Office building construction',
        isEligible: false,
      },
      {
        label: 'Roofing contractors',
        isEligible: false,
      },
      {
        label: 'Carpentry work (except framing)',
        isEligible: false,
      },
      {
        label: 'Carpentry, (framing)',
        isEligible: false,
      },
      {
        label: 'Asphalt paving (i.e. highway, road, sidewalk)',
        isEligible: false,
      },
      {
        label: 'Handyman construction service',
        isEligible: false,
      },
      {
        label: 'Metal siding installation',
        isEligible: false,
      },
      {
        label: 'Concrete form contractors',
        isEligible: false,
      },
    ],
  },
  {
    businessCategory: BopBusinessCategory.OFFICES,
    classCodes: [
      {
        label: 'Insurance agencies',
        isEligible: true,
        naicsCode: {
          code: '524210',
          description: 'Insurance agencies',
          hash: 'ffaf621170e5688d4ba7279edb5f4150',
        },
      },
      {
        label: "Dermatologists' offices",
        isEligible: true,
        naicsCode: {
          code: '621111',
          description: "Dermatologists' offices (e.g., centers, clinics)",
          hash: '7d57e06415e671f7f0f25c1ae27b643e',
        },
      },
      {
        label: "Real estate agents' offices",
        isEligible: true,
        naicsCode: {
          code: '531210',
          description: "Real estate agents' offices",
          hash: 'ca8ec1843dd6d9f7fbee54abd8efb700',
        },
      },
      {
        label: 'Dental offices',
        isEligible: true,
        naicsCode: {
          code: '621210',
          description: 'Doctors of dental medicine (DMDs) offices (e.g., centers, clinics)',
          hash: 'cc35d27c243dfbdd5b426a865c741075',
        },
      },
      {
        label: 'Accountants',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '541211',
          description: "Accountants' (i.e., CPAs) offices, certified public",
          hash: '4b852ac4e94fadc894b23cbb02b01cdc',
        },
      },
      {
        label: "Radiologists' offices",
        isEligible: true,
        naicsCode: {
          code: '621111',
          description: "Radiologists' offices (e.g., centers, clinics)",
          hash: '1132712ba1fa14a7c4334da7140f2036',
        },
      },
      {
        label: "Attorney's offices",
        isEligible: true,
        naicsCode: {
          code: '541110',
          description: "Attorneys' offices",
          hash: 'b19e5ef27be50fec55688a2e919a31fc',
        },
      },
      {
        label: "Sports physical therapists' offices",
        isEligible: true,
        naicsCode: {
          code: '621340',
          description: "Sports physical therapists' offices (e.g., centers, clinics)",
          hash: 'ef237ad316e60569f63c9eaf49aa1cfd',
        },
      },
      {
        label: 'Funeral homes',
        isEligible: true,
        naicsCode: {
          code: '812210',
          description: 'Funeral homes',
          hash: 'b49ea019e0eea59b7dfc9c0bcf14818c',
        },
      },
      {
        label: "Chiropractors' offices",
        isEligible: true,
        naicsCode: {
          code: '621310',
          description: "Chiropractors' offices (e.g., centers, clinics)",
          hash: 'dfacd2808130cce169e1dc3fea3b4f58',
        },
      },
      {
        label: 'Apartment building rental or leasing',
        isEligible: false,
      },
      {
        label: 'Building, apartment, rental or leasing',
        isEligible: false,
      },
      {
        label: 'Mortgage brokerages',
        isEligible: false,
      },
      {
        label: 'Recording studios',
        isEligible: false,
      },
      {
        label: 'Contract staffing services',
        isEligible: false,
      },
      {
        label: 'Investment management',
        isEligible: false,
      },
      {
        label: 'Graphic design services',
        isEligible: false,
      },
      {
        label: 'Computer program or software development',
        isEligible: false,
      },
      {
        label: 'Financial planning services',
        isEligible: false,
      },
      {
        label: 'School bus services',
        isEligible: false,
      },
      {
        label: 'Taxicab services',
        isEligible: false,
      },
      {
        label: 'Banks, commercial',
        isEligible: false,
      },
    ],
  },
  {
    businessCategory: BopBusinessCategory.RESTAURANTS,
    classCodes: [
      {
        label: 'Bakeries',
        isEligible: true,
        naicsCode: {
          code: '311811',
          description:
            'Bakeries with baking from flour on the premises, retailing not for immediate consumption',
          hash: 'cd3de2c97620c79439480d4c80f15ff9',
        },
      },
      {
        label: 'Restaurants, carryout',
        isEligible: true,
        naicsCode: {
          code: '722513',
          description: 'Restaurants, carryout',
          hash: 'c50c4544e1bdb00f7dc7db331b9bd9cb',
        },
      },
      {
        label: 'Delicatessens',
        isEligible: true,
        naicsCode: {
          code: '445110',
          description: 'Delicatessens primarily retailing a range of grocery items and meats',
          hash: 'd28b40d04e6797bd031ba6299775506a',
        },
      },
      {
        label: 'Diners',
        isEligible: true,
        naicsCode: {
          code: '722511',
          description: 'Diners, full service',
          hash: 'a65b22c2cc2a5c130ee7db8a8bc579db',
        },
      },
      {
        label: 'Restaurants, full-service',
        isEligible: true,
        naicsCode: {
          code: '722511',
          description: 'Restaurants, full service',
          hash: 'babb175c507e13296c1435dd4d77599b',
        },
      },
      {
        label: 'Fast-food restaurants',
        isEligible: true,
        naicsCode: {
          code: '722513',
          description: 'Fast-food restaurants',
          hash: '3118ee273a5637127db76fdc8ffe4b16',
        },
      },
      {
        label: 'Pizzerias',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '722511',
          description: 'Pizzerias, full service',
          hash: '4b1421e0d304a3567ae8fc813da8148c',
        },
      },
      {
        label: 'Sandwich shops',
        isEligible: true,
        naicsCode: {
          code: '722513',
          description: 'Sandwich shops, limited-service',
          hash: '37138ed19094da53b7220822c445a420',
        },
      },
      {
        label: 'Coffee shops',
        isEligible: true,
        naicsCode: {
          code: '722515',
          description: 'Coffee shops, on premise brewing',
          hash: 'ee84420f2eb1003cc5dfe7e23cc8b524',
        },
      },
      {
        label: 'Ice cream parlors',
        isEligible: true,
        naicsCode: {
          code: '722515',
          description: 'Ice cream parlors',
          hash: '4a0a037c1b06bb42aa4467df6c3064d2',
        },
      },
      {
        label: 'Bars',
        isEligible: false,
      },
      {
        label: 'Taverns (i.e. drinking places)',
        isEligible: false,
      },
      {
        label: 'Caterers',
        isEligible: false,
      },
    ],
  },
  {
    businessCategory: BopBusinessCategory.PROFESSIONAL_SERVICES,
    classCodes: [
      {
        label: 'Credit agencies',
        isEligible: true,
        naicsCode: {
          code: '561450',
          description: 'Credit agencies',
          hash: '34626dd09ab55a6056df108c4ee52365',
        },
      },
      {
        label: 'Nail salons',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '812113',
          description: 'Nail salons',
          hash: '2e98be8563bdf7ec2dcfff196d8eb8d9',
        },
      },
      {
        label: 'Residential cleaning services',
        isEligible: true,
        naicsCode: {
          code: '561720',
          description: 'Residential cleaning services',
          hash: '39e18001feec22ac7a8d4123f56e5140',
        },
      },
      {
        label: 'Bookkeeping services',
        isEligible: true,
        naicsCode: {
          code: '541219',
          description: 'Bookkeeping services',
          hash: 'e7a1b1c4100791db00e4c3bc8dd4f652',
        },
      },
      {
        label: 'Barber shops',
        isEligible: true,
        favorite: true,
        naicsCode: {
          code: '812111',
          description: 'Barber shops',
          hash: '94d267f1d06212835761d6fbf6c67619',
        },
      },
      {
        label: 'Locksmith services',
        isEligible: true,
        naicsCode: {
          code: '561622',
          description: 'Locksmith services',
          hash: '5d7c2fed7ed51c2e600f7344555758c6',
        },
      },
      {
        label: 'Animal grooming services',
        isEligible: true,
        naicsCode: {
          code: '812910',
          description: 'Animal grooming services',
          hash: 'f739727af1ef8f46954c5cc995a0ec30',
        },
      },
      {
        label: 'Billing services',
        isEligible: true,
        naicsCode: {
          code: '541219',
          description: 'Billing services',
          hash: 'b800de74cc22dc7c6b1ad0edfc3128b2',
        },
      },
      {
        label: 'Butcher shops',
        isEligible: true,
        naicsCode: {
          code: '445210',
          description: 'Butcher shops',
          hash: 'c5ff7725bef398228b327fc27375ebeb',
        },
      },
      {
        label: 'Animal hospitals',
        isEligible: true,
        naicsCode: {
          code: '541940',
          description: 'Animal hospitals',
          hash: 'c868eb54b93e32ccd4596f3d701cd5f9',
        },
      },
      {
        label: 'Marketing consulting services',
        isEligible: true,
        naicsCode: {
          code: '541613',
          description: 'Marketing consulting services',
          hash: '63a147f793a36c9601bfd350f7229444',
        },
      },
      {
        label: 'Interior decorators',
        isEligible: true,
        naicsCode: {
          code: '541410',
          description: 'Interior design services',
          hash: '2ff77d3a4a58907d97ea3d45d63c720b',
        },
      },
      {
        label: 'Car repair shops, general',
        isEligible: false,
      },
      {
        label: 'Music instruction (e.g., guitar, piano)',
        isEligible: false,
      },
      {
        label: 'Day care centers, child or infant',
        isEligible: false,
      },
      {
        label: 'Building cleaning services, janitorial',
        isEligible: false,
      },
      {
        label: 'Property managing, residential real estate',
        isEligible: false,
      },
      {
        label: 'Massage parlors',
        isEligible: false,
      },
      {
        label: 'Window cleaning services',
        isEligible: false,
      },
      {
        label: 'Truck repair shops, general',
        isEligible: false,
      },
      {
        label: 'Drycleaners (except coin-operated)',
        isEligible: false,
      },
      {
        label: 'Drycleaners drop-off and pick-up sites',
        isEligible: false,
      },
      {
        label: 'Janitorial services',
        isEligible: false,
      },
      {
        label: 'Office administration services',
        isEligible: false,
      },
      {
        label: 'Electrical meter reading sercices, contract',
        isEligible: false,
      },
      {
        label: 'Laundry services',
        isEligible: false,
      },
      {
        label: 'Security guard services',
        isEligible: false,
      },
      {
        label: 'Home health care agencies',
        isEligible: false,
      },
      {
        label: 'Tattoo parlors',
        isEligible: false,
      },
    ],
  },
];
