<h1 class="form-header-below-link">Location {{ locationIndex }}</h1>
<div class="location-form-address">
  <span _ngcontent-c2="" aria-label="Address" class="icon-location contact-bullet-icon"></span>
  <span>{{ address.addressLine1 }},</span>
  <span *ngIf="address.addressLine2"> {{ address.addressLine2 }},</span>
  <span class="js-address-city-zip">
    {{ address.city }}, {{ address.state }} {{ address.zip }}</span
  >
</div>
<div *ngIf="addressLoaded" class="form-field">
  <a href="https://www.google.com/maps/place/{{ this.googleMapsAddress() }}" target="_blank">
    <div class="account-main-map" [class.account-main-map__hide]="loading">
      <img id="googleMapImg" class="map-image" src="{{ this.generateMapUrl() }}" alt="" />
    </div>
  </a>
</div>
<div *ngIf="partTimeEmployeeCountEnabled()" class="form-split">
  <app-form-field-text
    inputId="location-{{ locationIndex }}-employeeCount"
    [form]="locationFormGroup"
    [submitted]="submitted"
    labelText="Full time employees"
    nameOfFormControl="employeeCount"
    appDelimitedNumberMask
  ></app-form-field-text>
  <app-form-field-text
    inputId="location-{{ locationIndex }}-partTimeEmployeeCount"
    [form]="locationFormGroup"
    [submitted]="submitted"
    labelText="Part time employees"
    nameOfFormControl="partTimeEmployeeCount"
    appDelimitedNumberMask
  ></app-form-field-text>
</div>
<app-form-field-text
  *ngIf="!partTimeEmployeeCountEnabled()"
  inputId="location-{{ locationIndex }}-employeeCount"
  [form]="locationFormGroup"
  [submitted]="submitted"
  labelText="Employees at this location"
  specifierText="(full time and part time)"
  nameOfFormControl="employeeCount"
  appDelimitedNumberMask
></app-form-field-text>

<app-form-field-select
  inputId="location-{{ locationIndex }}-propertyDeductible"
  [form]="locationFormGroup"
  [submitted]="submitted"
  labelText="All Other Perils (AOP) deductible for this location"
  nameOfFormControl="propertyDeductible"
  [availableOptions]="availablePropertyDeductibles"
  specifierText="(for this location)"
  tooltipText="All Other Perils (AOP) deductible for this location applies to property claims caused by
  a peril unless a different deductible is specified for that peril
  (e.g., wind/hail deductible)"
></app-form-field-select>

<app-form-field-radio
  inputId="location-{{ locationIndex }}-isWithinCreditableWaterSupplyForPPC"
  [form]="locationFormGroup"
  [submitted]="submitted"
  questionText="Is this building within 1,000 feet of a creditable water supply, such as a fire hydrant, suction point, or dry hydrant?"
  nameOfFormControl="isWithinCreditableWaterSupplyForPPC"
>
</app-form-field-radio>
