import { of as observableOf, Observable } from 'rxjs';

import { map, tap, catchError } from 'rxjs/operators';
import {
  CONSTRUCTION_TYPE_CODES,
  DEFAULT_DISTANCE_TO_OCEAN_CODE,
} from 'app/features/attune-bop/models/constants';
import { PREFILL_WITH_UW_DECISION_API_URI } from 'app/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AmplitudeService } from 'app/core/services/amplitude.service';

import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class PrefillService {
  locationPrefillStore: Map<string, PrefillData> = new Map<string, PrefillData>();

  constructor(
    protected http: HttpClient,
    protected amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  protected prefillWithDecisionUri() {
    return PREFILL_WITH_UW_DECISION_API_URI;
  }

  getPrefill(prefillData: PrefillData): PrefillServiceResponse {
    const buildingPrefill: BopBuildingPrefill = {};

    this.amplitudeService.track({ eventName: 'prefill', detail: '', useLegacyEventName: true });
    const {
      bcegsCommercialCode,
      bopTerritoryCode,
      buildingReplacementCost,
      caDOIZone,
      capRisk,
      commEarthquakeTerritoryCode,
      earthquakeMMI100Year,
      earthquakeSoilType,
      enhancedCrimeFlag,
      floodZone,
      groupIICode,
      groupIICountyName,
      hail_risk_3,
      hail_srisk_3,
      hailDamageScore,
      hailRiskScore,
      hasSprinkler,
      isoCountyName,
      isoConstructionCode,
      isoDistToCoastRangeCode,
      latitude,
      longitude,
      numberOfStories,
      ppc,
      roofConditionRating,
      totalBuildingSquareFootage,
      wildfireRiskScore,
      yearBuilt,
    } = prefillData.response.data;

    const { planckCaseId } = prefillData.response;

    const distanceToOceanCode = isoDistToCoastRangeCode || DEFAULT_DISTANCE_TO_OCEAN_CODE;

    const locationPrefill: BopLocationPrefill = {
      bcegsCommercialCode,
      bopTerritoryCode,
      caDOIZone,
      capRisk,
      commEarthquakeTerritoryCode,
      earthquakeMMI100Year,
      earthquakeSoilType,
      enhancedCrimeFlag,
      floodZone,
      groupIICode,
      groupIICountyName,
      hail_risk_3,
      hail_srisk_3,
      hailDamageScore: hailDamageScore !== null ? parseInt(hailDamageScore, 10) : null,
      hailRiskScore,
      isoCountyName,
      isoDistToCoastRangeCode: distanceToOceanCode,
      latitude,
      longitude,
      planckCaseId,
      ppc,
      roofConditionRating,
      wildfireRiskScore,
    };

    buildingPrefill.squareFootage = totalBuildingSquareFootage
      ? String(totalBuildingSquareFootage)
      : null;

    buildingPrefill.buildingLimit = buildingReplacementCost || '$0';

    if (numberOfStories) {
      buildingPrefill.storyCount =
        numberOfStories > 20 ? 'Over20' : <StoryCountOptions>numberOfStories.toString();
    } else {
      buildingPrefill.storyCount = null;
    }

    if (isoConstructionCode) {
      buildingPrefill.constructionType = CONSTRUCTION_TYPE_CODES[isoConstructionCode];
    } else {
      buildingPrefill.constructionType = null;
    }

    buildingPrefill.hasAutomaticSprinklerSystem = hasSprinkler || null;

    buildingPrefill.yearBuilt = Number(yearBuilt) || null;

    return {
      buildingPrefill,
      locationPrefill,
    };
  }

  cacheKey(address: Address): string {
    return JSON.stringify(address, null, 4);
  }

  fetchCached(address: Address): PrefillData | null {
    return this.locationPrefillStore.get(this.cacheKey(address)) || null;
  }

  fetch(
    address: Address,
    companyName: string,
    tsRequestId: string,
    accountId: string,
    quoteSource: QuoteSource,
    bopVersion: BopVersion,
    dba: string,
    website: string,
    phoneNumber: string,
    naicsHash: string
  ): Observable<PrefillData> {
    const cachedPrefill = this.fetchCached(address);
    if (cachedPrefill) {
      return observableOf(cachedPrefill);
    }

    const prefillPayload = this.getRequestPayload(
      address,
      companyName,
      tsRequestId,
      accountId,
      quoteSource,
      bopVersion,
      dba,
      website,
      phoneNumber,
      naicsHash
    );

    return this.prefillHttpRequest(prefillPayload).pipe(
      tap((result: PrefillData) => {
        this.locationPrefillStore.set(this.cacheKey(address), result);
      })
    );
  }

  private prefillHttpRequest(prefillPayload: PrefillRequestPayload): Observable<PrefillData> {
    const uri = this.prefillWithDecisionUri();
    const params = new HttpParams().set('checkActivePolicies', 'true');
    return this.http.post<PrefillResponsePayload>(uri, prefillPayload, { params }).pipe(
      map((response: PrefillResponsePayload) => {
        return {
          request: prefillPayload,
          response: response,
        };
      }),
      catchError((error) => {
        this.sentryService.notify('Error getting prefill.', {
          severity: 'error',
          metaData: {
            prefillPayload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  private getRequestPayload(
    location: Address,
    companyName: string,
    requestId: string,
    accountId: string,
    quoteSource: QuoteSource,
    bopVersion: BopVersion,
    dba: string,
    website: string,
    phoneNumber: string,
    naicsHash: string
  ): PrefillRequestPayload {
    return {
      TSRequestID_HUSA: requestId,
      GWAccountId: accountId,
      quoteSource: quoteSource,
      bopVersion: bopVersion,
      effectiveDate: moment.utc().format('YYYY-MM-DD'),
      entity: {
        address: {
          city: location.city,
          state: location.state,
          street1: location.addressLine1,
          street2: location.addressLine2 || '',
          zipcode: location.zip,
        },
        business: {
          isoCSPCode: '',
          name: companyName,
          dba: dba,
          website: website,
          phoneNumber: phoneNumber,
          naicsHash: naicsHash,
        },
      },
      requestId: '',
      version: '1.0',
    };
  }
}
