import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { catchError, Observable, of } from 'rxjs';
import {
  PartialQuoteFormValue,
  PartialLocationFormValue,
  QuoteFormGroup,
  QuoteFormValue,
} from '../models/quote-form.model';
import {
  AttuneWcEligibilityCheckRequest,
  AttuneWcEligibilityCheckResponse,
  Jurisdiction,
} from '../models/eligibility.model';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { isNil } from 'lodash';
import { ATTUNE_WC_ELIGIBILITY_CHECK_API } from '../constants';
import { AttuneWcClassCodesService } from './attune-wc-class-code.service';

@Injectable()
export class AttuneWcEligibilityService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private attuneWcClassCodesService: AttuneWcClassCodesService
  ) {}

  fetchEligibilityCheck(
    payload: QuoteFormGroup,
    accountNumber: string,
    tsRequestId: string,
    isSubmit: boolean
  ): Observable<AttuneWcEligibilityCheckResponse> {
    const defaultAcceptRisk: AttuneWcEligibilityCheckResponse = {
      acceptRisk: true,
      declineReasons: [],
    };

    const translatedPayload = this.translateForm(payload, accountNumber, tsRequestId, isSubmit);

    return this.http
      .post<AttuneWcEligibilityCheckResponse>(ATTUNE_WC_ELIGIBILITY_CHECK_API, translatedPayload)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to fetch Attune WC eligibility check data', {
            severity: 'error',
            metaData: {
              payload,
              accountNumber,
              underlyingErrorMessage: error && error.message,
            },
          });

          return of(defaultAcceptRisk);
        })
      );
  }

  private translateForm(
    payload: QuoteFormGroup,
    accountNumber: string,
    tsRequestId: string,
    isSubmit: boolean
  ): AttuneWcEligibilityCheckRequest {
    const payloadValues = payload.value as QuoteFormValue;

    const jurisdictions = this.translateJurisdictions(payloadValues);
    const jurisdictionsArray = Object.values(jurisdictions);

    return {
      tsRequestId: tsRequestId,
      accountNumber: accountNumber,
      policy: {
        bankruptcyNotDischarged: payloadValues.basicInfo?.bankruptcyNotDischarged,
        lessThan100PercentOwnership: payloadValues.basicInfo?.lessThan100PercentOwnership,
      },
      jurisdictions: jurisdictionsArray as Jurisdiction[],
      isPartialPayload: !isSubmit,
    } as AttuneWcEligibilityCheckRequest;
  }

  private translateJurisdictions(
    payloadValues: PartialQuoteFormValue
  ): Record<string, Jurisdiction> {
    // Splits up locations by jurisdictions
    const jurisdictions = {} as any;
    if (Array.isArray(payloadValues.locations)) {
      for (const location of payloadValues.locations) {
        if (location.address?.state) {
          if (jurisdictions[location.address?.state as string]) {
            jurisdictions[location.address?.state as string].locations.push(
              this.translateLocation(location)
            );
          } else {
            jurisdictions[location.address?.state as string] = {
              state: location.address?.state,
              locations: [this.translateLocation(location)],
            };
          }
        }
      }
    }

    return jurisdictions;
  }

  private translateLocation(location: PartialLocationFormValue) {
    return {
      address: location.address,
      classCodes: location.classCodes?.map((classification) => {
        const classCode = classification?.classCode;
        const description = classification?.description;
        const state = location.address?.state;
        let hazardGroup = null;
        if (classCode && description && state) {
          hazardGroup = this.attuneWcClassCodesService.getHazardGroup(
            classCode,
            description,
            state
          );
        }

        return {
          classCode: classification?.classCode,
          hazardGroup: hazardGroup,
          payroll: !isNil(classification.payroll) ? parseMaskedInt(classification.payroll) : null,
          numberOfEmployees: !isNil(classification.numberOfEmployees)
            ? parseMaskedInt(classification.numberOfEmployees)
            : null,
        };
      }),
    };
  }
}
