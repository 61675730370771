import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { NavigationEnd, ActivatedRoute, Router, NavigationExtras, Params } from '@angular/router';
import {
  of as observableOf,
  merge as observableMerge,
  Subscription,
  Observable,
  zip,
  ReplaySubject,
} from 'rxjs';
import {
  mergeMap,
  switchMap,
  filter,
  map,
  distinctUntilChanged,
  tap,
  first,
  shareReplay,
  publishReplay,
  refCount,
  catchError,
} from 'rxjs/operators';
import * as _ from 'lodash';

import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { InvoicesListService } from 'app/features/invoices/services/invoices-list.service';
import { phoneValidator, getControl } from 'app/shared/helpers/form-helpers';
import {
  checkGWTempEmailAddress,
  validateEmailAddress,
} from '../../../attune-bop/models/form-validators';
import { GOOGLE_MAPS_API_KEY } from 'app/constants';
import { InformService } from '../../../../core/services/inform.service';
import {
  AccountPolicy,
  AccountPolicyTerm,
  ENDORSABLE_STATUSES,
  InsuredAccount,
  WITHDRAWN_RESCINDED_STATUSES,
} from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { User } from 'app/shared/models/user';
import { UserService } from 'app/core/services/user.service';
import { VersionCheckService } from 'app/shared/services/version-check.service';
import { disallowAccountEdits } from 'app/shared/helpers/account-helpers';
import { WcQuoteService } from 'app/workers-comp/employers/services/workers-comp-quote.service';
import {
  insertNewBrokerContact,
  transformContacts,
  patchBrokerContactForm,
  getBrokerContactChanges,
  TransformedContacts,
  getAllPossibleContacts,
  getNewBrokerContactFormSection,
} from '../../../../shared/helpers/broker-contact-helpers';
import { NaicsService, ProductEligibility } from '../../../../shared/services/naics.service';
import { SAMPLE_NOT_AVAILABLE_MESSAGE } from '../../../../shared/services/onboarding.service';
import { sanitizePhoneNumber } from '../../../../shared/directives/phone-mask.directive';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';

import { AccountPolicyPeriod, PolicyPeriod } from 'app/bop/guidewire/typings';
import { AttuneBopEndorseQuoteService } from 'app/features/attune-bop/services/attune-bop-endorse-quote.service';
import {
  INFORMATIONAL_NAMES,
  NewInformationalService,
} from '../../../../shared/services/new-informational.service';
import * as moment from 'moment';
import { HURRICANE_IAN_MORATORIUM_STATES } from 'app/features/attune-bop/models/constants';
import {
  WcPricedQuote,
  WcQuoteWithDocuments,
} from '../../../../workers-comp/employers/models/wc-priced-quote';
import { HISCOX_PRODUCTS } from 'app/features/hiscox/models/hiscox-types';
import {
  HiscoxBackendPricedQuote,
  HiscoxPricedQuote,
} from 'app/features/hiscox/models/hiscox-priced-quote';

@Component({
  selector: 'app-insured-account.app-page.app-page__account-show',
  styleUrls: ['./insured-account.component.scss'],
  templateUrl: './insured-account.component.html',
})
export class InsuredAccountComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  submitted = false;
  submitting = false;
  editContactsModalIsDisplayed = false;
  model: InsuredAccount = new InsuredAccount();
  accountList: Array<InsuredAccount>;
  accountId: string;
  latestAccountId: string;
  user: User;
  loading: Boolean = true;
  loadingAccountList: Boolean = true;
  googleMapSrc: String = '';
  naicsDescription: String = '';
  private sub = new Subscription();
  isEditEnabled: Boolean = true;
  hasBoundBop = false;
  onlyOneAccountQuoted = false;
  private accountId$: Observable<string>;
  private accountList$: Observable<InsuredAccount[]>;
  private endorsablePolicy$ = new ReplaySubject<AccountPolicy>(1);
  currentContact: any;
  availableContacts: TransformedContacts = {};
  subscribedContacts: TransformedContacts = {};
  public isHabEligible = false;
  showEditAccountModal = false;
  showHelpCenterMarketingModal = false;
  accountListReloadTimeout = 5000;
  editContactInfoError: String = '';
  private hasBoundPl: Boolean = false;
  private hasBoundGl: Boolean = false;
  private hasBoundWc: Boolean = false;
  private hasEndorsableBop: Boolean = false;
  private boundPolicyNumbers: Record<string, string>[] = [];
  private isBopPolicyPendingCancellation: Boolean = false;
  private hasZendeskEndorsementResponse: Boolean = false;
  private loadingEndorsableStatus: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private insuredAccountService: InsuredAccountService,
    private requestQuoteService: AttuneBopQuoteRequestService,
    private userService: UserService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private wcQuoteService: WcQuoteService,
    private formBuilder: UntypedFormBuilder,
    private informService: InformService,
    private amplitudeService: AmplitudeService,
    private versionCheckService: VersionCheckService,
    private billingListService: InvoicesListService,
    private naicsService: NaicsService,
    private endorseQuoteService: AttuneBopEndorseQuoteService,
    private newInformationalService: NewInformationalService
  ) {
    this.model.state = '';
    this.accountListReloadTimeout = 5000;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email, validateEmailAddress]],
      phoneNumber: [''],
      additionalEmailAddress: ['', [validateEmailAddress]],
      brokerContacts: getNewBrokerContactFormSection(),
    });

    getControl(this.form, 'phoneNumber').setValidators([phoneValidator, Validators.required]);

    this.sub.add(
      this.insuredAccountService.insuredError
        .pipe(mergeMap((error) => this.accountList$.pipe(map((_accountList) => error))))
        .subscribe((error) => {
          if (error) {
            this.informService.errorToast(
              `We encountered an error finding your account no. ${this.accountId}.`,
              null,
              'Account not found.',
              'Okay',
              null,
              0
            );

            // Redirect user to first account if accounts are present
            if (
              this.router.url !== '/accounts' &&
              this.accountId !== this.accountList[0].id &&
              this.accountList.length > 0
            ) {
              this.router.navigate(['/accounts', this.accountList[0].id]);
            }
          }
        })
    );

    this.sub.add(
      this.insuredAccountService
        .getCurrentContact()
        .subscribe((currentContact) => (this.currentContact = currentContact))
    );

    this.setupAccountListObservable();
    this.accountId$ = observableMerge(
      this.router.events.pipe(
        filter((evt) => {
          return evt instanceof NavigationEnd;
        })
      ),
      this.route.params
    ).pipe(
      map(() => {
        // Needs to happen twice on /accounts -> /accounts/1 redirect
        if (!this.route.firstChild || !this.route.firstChild.snapshot.params) {
          return this.route.snapshot.params;
        }

        return this.route.firstChild.snapshot.params;
      }),
      filter(
        (params) => Object.prototype.hasOwnProperty.call(params, 'accountId') && params.accountId
      ),
      map((params) => params.accountId),
      distinctUntilChanged(),
      tap((accountId: string) => {
        this.loading = true;
        this.loadingEndorsableStatus = true;
        this.accountId = String(accountId);
        const crossSellExperimentStringDone = localStorage.getItem('bopDataForWCQuote');
        if (crossSellExperimentStringDone) {
          const crossSellExperimentDone = JSON.parse(crossSellExperimentStringDone);
          if (crossSellExperimentDone && crossSellExperimentDone.accountId === this.accountId) {
            // If the user just completed both a BOP and WC quote as part of the cross sell prompt before checking accounts page,
            // we want to make sure both show up, rather than just the one that was completed in the current tab
            this.insuredAccountService.cachebust();
          }
        }
      }),
      publishReplay(1),
      refCount()
    );

    this.subscribeToQuoteData();

    this.sub.add(this.accountId$.subscribe((accountId) => this.loadSubscribedContacts()));

    this.sub.add(
      this.accountId$
        .pipe(
          switchMap((accountId: string) => this.getAccountById(accountId)),
          filter(
            (insuredAccount: InsuredAccount) => this.accountId === insuredAccount.id.toString()
          )
        )
        .subscribe(
          (insuredAccount: InsuredAccount) => {
            this.updateInsuredAccount(insuredAccount);
          },
          (error) => {
            console.error(
              '*** error response from insuredAccountService.get ***' + error.toString()
            );
            this.loading = false;
            // TODO: Show an error to the user?
          }
        )
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        this.fetchAvailableContacts();
      })
    );
  }

  updateInsuredAccount(insuredAccount: InsuredAccount) {
    this.model = insuredAccount;
    this.naicsDescription = insuredAccount.naicsCode ? insuredAccount.naicsCode.description : '';

    if (insuredAccount.naicsCode !== null) {
      this.naicsService
        .getProductEligibility(insuredAccount.naicsCode.hash, insuredAccount.state)
        .subscribe((eligibilities: ProductEligibility) => {
          this.isHabEligible = eligibilities.isHabEligible;
        });
    }

    if (GOOGLE_MAPS_API_KEY) {
      this.googleMapSrc = this.generateMapUrl(insuredAccount);
    }
    this.loading = false;
  }

  loadSubscribedContacts() {
    this.sub.add(
      this.insuredAccountService
        .getAccountContacts(this.accountId)
        .subscribe((accountContacts) => this.setSubscribedContacts(accountContacts))
    );
  }

  fetchAvailableContacts() {
    this.sub.add(
      this.insuredAccountService
        .getProducerCodeDetails(this.user.producer)
        .subscribe((producerInfo) => {
          this.availableContacts = transformContacts(
            _.get(producerInfo, 'ProdCodeCommContacts_ATTN.Entry', []),
            'Contact',
            'ProdCodComContactRol_ATTN.Entry',
            'CommunicationType_ATTN'
          );
        })
    );
  }

  getAllPossibleContacts(): TransformedContacts {
    return getAllPossibleContacts(
      this.currentContact,
      this.subscribedContacts,
      this.availableContacts
    );
  }

  getAccountById(accountId: string) {
    return this.insuredAccountService.get(accountId).pipe(
      filter((insuredAccount: InsuredAccount) => String(insuredAccount.id) === accountId),
      first(),
      shareReplay()
    );
  }

  setSubscribedContacts(contacts: any) {
    this.subscribedContacts = transformContacts(
      contacts.contacts || [],
      'CommunicationContact_ATTN.Contact',
      'CommunicationContact_ATTN.CommunicationContactRoles.Entry',
      'CommunicationType'
    );
  }

  removeContact(index: number) {
    const brokerContacts = <UntypedFormArray>this.form.get('brokerContacts');
    if (brokerContacts.length > 1) {
      brokerContacts.removeAt(index);
    }
  }

  addBrokerContact() {
    const defaultContactId = Object.keys(this.getAllPossibleContacts())[0];
    insertNewBrokerContact(<UntypedFormArray>this.form.get('brokerContacts'), defaultContactId);
  }

  getInvoicesLink() {
    return ['accounts/invoice-list', this.model.id];
  }

  setupAccountListObservable() {
    this.accountList$ = this.insuredAccountService.listCached();

    // When an account list comes in
    // ... subroute with account id? -> no-op
    // ... else, on /accounts
    //     -> redirect to first account if exists
    this.sub.add(
      this.accountList$.subscribe(
        (response) => {
          this.loadingAccountList = false;

          const userVisibleAccounts = this.hideMultipleSampleAccounts(response);
          this.accountList = userVisibleAccounts;

          if (userVisibleAccounts[0]) {
            this.latestAccountId = userVisibleAccounts[0].id;
          }

          // Check if JS code running in production/staging is different than code running in the browser.
          this.versionCheckService.hasCodeBeenUpdated.subscribe((isCodeUpdated: boolean) => {
            // If code/build has changed, reload the page on the account list page (this should be a safe place to do a refresh).
            if (isCodeUpdated) {
              window.location.reload();
            }
          });

          const noAccountIdInRoute =
            !this.route.firstChild ||
            !Object.prototype.hasOwnProperty.call(
              this.route.firstChild.snapshot.params,
              'accountId'
            );

          if (noAccountIdInRoute && userVisibleAccounts.length > 0) {
            // Forward any existing query params along with the "silent" redirect
            const queryParams = this.route.snapshot.queryParams || {};

            // The editContactInfo query param is removed if there is no account ID param in the route
            const { ['editContactInfo']: editContactInfo, ...params }: Params & NavigationExtras =
              queryParams;

            this.router.navigate(['/accounts', userVisibleAccounts[0].id], {
              skipLocationChange: true,
              queryParams: params,
            });
          }
        },
        (_error) => {
          this.informService.errorToast(
            'We encountered an error while loading your accounts. Please try refreshing the page.',
            null,
            'Failed to load accounts.',
            'Retry',
            () => this.setupAccountListObservable(),
            this.accountListReloadTimeout
          );
          // Note: Exponential backoff with a max wait time of 5 minutes, plus ~1 second jitter
          this.accountListReloadTimeout =
            Math.min(this.accountListReloadTimeout * 1.5, 5 * 60 * 1000) + Math.random() * 1000;
        }
      )
    );

    // Display a call-to-action flag to users with
    // only one account that has a BOP or WC  quote
    this.sub.add(
      this.accountList$
        .pipe(
          switchMap((accountList) => {
            // If account list has only one account,
            // fetch the BOP and WC quotes
            if (accountList.length === 1) {
              const accountId = accountList[0].id;
              return zip(
                this.getAccountById(accountId),
                this.wcQuoteService.getQuote(accountId).pipe(catchError((e) => observableOf(null)))
              );
            }
            // Otherwise, return empty account
            // with 0 BOP quotes and null WC quote
            return observableOf([new InsuredAccount(), null]);
          })
        )
        .subscribe(([insuredAccount, wcQuote]) => {
          if (
            (insuredAccount &&
              (insuredAccount.bopQuotes.length > 0 || insuredAccount.habQuotes.length > 0)) ||
            wcQuote !== null
          ) {
            this.onlyOneAccountQuoted = true;
          } else {
            this.onlyOneAccountQuoted = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showEditAccount() {
    // Do not allow editing for sample accounts
    if (this.model.sampleAccount) {
      this.informService.infoToast(SAMPLE_NOT_AVAILABLE_MESSAGE);
      return;
    }

    // Reset form submision state when the modal is reopened
    this.submitted = false;
    this.showEditAccountModal = true;
  }

  hideEditAccount(submitted: boolean) {
    this.showEditAccountModal = false;
    if (submitted) {
      // Force the account service to reload both account list and details
      this.insuredAccountService.list().subscribe();
      this.insuredAccountService.cachebust();
      this.sub.add(
        this.insuredAccountService
          .get(this.accountId)
          .subscribe((insuredAccount) => this.updateInsuredAccount(insuredAccount))
      );
    }
  }

  hideMultipleSampleAccounts(accounts: InsuredAccount[]) {
    const currentUser = _.lowerCase(this.user && this.user.husaUserName);
    if (!currentUser) {
      return accounts;
    }

    let firstCreatedSampleAccountId: string;

    for (let i = accounts.length - 1; i >= 0; i--) {
      const account = accounts[i];
      if (account.sampleAccount && currentUser === _.lowerCase(account.createdByUser || '')) {
        firstCreatedSampleAccountId = account.id;
        break;
      }
    }

    // Only include non-sample accounts and the first sample account created by the current user
    return accounts.filter(
      (account) => !account.sampleAccount || firstCreatedSampleAccountId === account.id
    );
  }

  displayHelpCenterMarketingModal(_event: Event | null, display: boolean) {
    this.showHelpCenterMarketingModal = display;
  }

  routeIsAccountShow() {
    return (
      this.route.firstChild &&
      Object.prototype.hasOwnProperty.call(this.route.firstChild.snapshot.params, 'accountId') &&
      Object.keys(this.route.firstChild.snapshot.params).length === 1
    );
  }

  generateMapUrl(insuredAccount: InsuredAccount): string {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${insuredAccount.gMapsAddress()}&zoom=14&size=600x180&maptype=roadmap&markers=color:red%7C${insuredAccount.gMapsAddress()}&key=${GOOGLE_MAPS_API_KEY}`;
  }

  displayEditContactsModal() {
    if (this.editContactsModalIsDisplayed) {
      this.editContactsModalIsDisplayed = false;
      return;
    }

    this.form.patchValue({
      emailAddress: checkGWTempEmailAddress(this.model),
      additionalEmailAddress: this.model.additionalEmailAddress,
      phoneNumber: sanitizePhoneNumber(this.model.phoneNumber),
    });
    patchBrokerContactForm(
      <UntypedFormArray>this.form.get('brokerContacts'),
      this.subscribedContacts
    );
    this.editContactsModalIsDisplayed = true;
    this.editContactInfoError = '';
  }

  editAccount() {
    this.submitted = true;
    if (this.form.valid) {
      this.submitting = true;
      this.model.emailAddress = this.form.value.emailAddress;
      this.model.additionalEmailAddress = this.form.value.additionalEmailAddress;
      const simplePhoneNumber = this.form.value.phoneNumber.replace(/\D/g, '');
      this.model.phoneNumber = simplePhoneNumber;

      const contactChanges = getBrokerContactChanges(
        <UntypedFormArray>this.form.get('brokerContacts'),
        this.subscribedContacts
      );
      const submissionObservables: Observable<any>[] = contactChanges.map((contactChange) => {
        if (contactChange.action === 'follow') {
          return this.insuredAccountService.followAccount(
            this.accountId,
            contactChange.contactId as string,
            contactChange.roles
          );
        } else if (contactChange.action === 'unfollow') {
          return this.insuredAccountService.unfollowAccount(
            this.accountId,
            contactChange.contactId as string,
            contactChange.roles
          );
        } else if (contactChange.action === 'create') {
          return this.insuredAccountService.followNewAccount(
            this.accountId,
            _.get(contactChange, 'contactInfo.email', ''),
            _.get(contactChange, 'contactInfo.firstName', ''),
            _.get(contactChange, 'contactInfo.lastName', ''),
            contactChange.roles
          );
        }
        return observableOf(null);
      });
      submissionObservables.push(this.insuredAccountService.edit(this.model));

      this.sub.add(
        zip.apply(this, submissionObservables).subscribe(
          () => {
            this.editContactsModalIsDisplayed = false;
            this.submitting = false;
            this.insuredAccountService.cachebust();
            this.sub.add(
              this.insuredAccountService
                .get(this.accountId)
                .subscribe((insuredAccount) => this.updateInsuredAccount(insuredAccount))
            );
            this.amplitudeService.track({
              eventName: 'account_edit_email',
              detail: this.accountId,
              useLegacyEventName: true,
            });

            // force a refresh of the contact list, if there were any changes
            if (contactChanges.length) {
              this.fetchAvailableContacts();
              this.loadSubscribedContacts();
            }
          },
          (error: any) => {
            this.editContactInfoError = 'There was a problem editing contact information.';
            this.submitting = false;
          }
        )
      );
    }
  }

  public displayLossRunsUpload(model: InsuredAccount): boolean {
    return !this.isHabEligible;
  }

  isQuoteInProgress() {
    return this.requestQuoteService.isQuoteInProgress();
  }

  getQuoteSuccessSubject() {
    return this.requestQuoteService.getQuotedSuccess();
  }

  showViewInvoicesButton() {
    // If there is no bound BOP policy, do not fetch invoices
    if (!this.hasBoundBop) {
      return observableOf(false);
    }

    return this.route.queryParams.pipe(
      switchMap((params) => {
        if (!params.waitForInvoice) {
          // Only show this link if invoices have been fetched, and at least one exists
          return this.billingListService.invoicesSubject.pipe(
            map((invoiceResponse) => {
              return (
                invoiceResponse &&
                invoiceResponse.length &&
                invoiceResponse[0].accountNumber === this.accountId
              );
            })
          );
        }
        // Note: Emits false if we timed out, or true if we found an invoice
        return this.billingListService.waitUntilInvoiceIsAvailable(
          this.accountId,
          this.user.producer
        );
      })
    );
  }

  subscribeToQuoteData() {
    this.sub.add(
      this.accountId$
        .pipe(
          switchMap((accountId: string) =>
            zip(
              this.getAccountById(accountId),
              this.wcQuoteService
                .getQuote(this.accountId)
                .pipe(catchError((e) => observableOf(null))),
              this.hiscoxQuoteService
                .getQuote(HISCOX_PRODUCTS.pl, accountId)
                .pipe(catchError((e) => observableOf(null))),
              this.hiscoxQuoteService
                .getQuote(HISCOX_PRODUCTS.gl, accountId)
                .pipe(catchError((e) => observableOf(null)))
            )
          ),
          filter(
            ([insuredAccount]: [
              InsuredAccount,
              WcQuoteWithDocuments | null,
              HiscoxBackendPricedQuote | null,
              HiscoxBackendPricedQuote | null
            ]) => {
              return this.accountId === insuredAccount.id.toString();
            }
          )
        )
        .subscribe(
          ([insuredAccount, wcQuoteResponse, plV4QuoteResponse, glV4QuoteResponse]: [
            InsuredAccount,
            WcQuoteWithDocuments | null,
            HiscoxBackendPricedQuote | null,
            HiscoxBackendPricedQuote | null
          ]) => {
            this.model = insuredAccount;
            this.boundPolicyNumbers = [];

            const wcQuote = wcQuoteResponse ? wcQuoteResponse.quote : null;
            if (wcQuote) {
              const wcPricedQuote = new WcPricedQuote(wcQuote);
              if (wcPricedQuote.isBound()) {
                this.hasBoundWc = true;
                this.boundPolicyNumbers.push({
                  lineOfBusiness: 'wc',
                  policyNumber: wcPricedQuote.policyNumber,
                });
              } else {
                this.hasBoundWc = false;
              }
            } else {
              this.hasBoundWc = false;
            }

            if (plV4QuoteResponse) {
              const plPricedQuote = new HiscoxPricedQuote(plV4QuoteResponse);
              if (plPricedQuote.isBound()) {
                this.hasBoundPl = true;
                this.boundPolicyNumbers.push({
                  lineOfBusiness: 'professional_liability',
                  policyNumber: plPricedQuote.policyNumber,
                });
              } else {
                this.hasBoundPl = false;
              }
            }

            if (glV4QuoteResponse) {
              const glPricedQuote = new HiscoxPricedQuote(glV4QuoteResponse);
              this.hasBoundGl = glPricedQuote.isBound();
              if (glPricedQuote.isBound()) {
                this.hasBoundGl = true;
                this.boundPolicyNumbers.push({
                  lineOfBusiness: 'general_liability',
                  policyNumber: glPricedQuote.policyNumber,
                });
              } else {
                this.hasBoundGl = false;
              }
            }

            const boundBopPolicies = insuredAccount.policiesWithTerms.filter(
              (policy) => policy.productName === 'Businessowners Line (v7)'
            );
            this.hasBoundBop = boundBopPolicies.length > 0;

            const endorsablePolicies = boundBopPolicies.filter((policy) => {
              return _.some(policy.terms, (term: AccountPolicyTerm) => {
                return ENDORSABLE_STATUSES.includes(term.status);
              });
            });

            if (disallowAccountEdits(insuredAccount, wcQuoteResponse)) {
              const editContactInfo = this.route.snapshot.queryParams['editContactInfo'];
              const hasAccountId = !!(
                this.route.snapshot.params['accountId'] ||
                this.route.firstChild?.snapshot.params['accountId']
              );
              if (editContactInfo && hasAccountId) {
                this.displayEditContactsModal();
              }

              this.isEditEnabled = false;
              let policyNumber = '';
              let boundPolicy = null;

              if (endorsablePolicies.length > 0) {
                this.hasEndorsableBop = true;

                for (const policy of endorsablePolicies) {
                  const scheduledTerm = _.find(policy.terms, (term) => term.status === 'Scheduled');

                  // If there's a bound bop policy with a scheduled term, use that policy for autofill (indicates renewal/rollover policy)
                  if (scheduledTerm) {
                    policyNumber = scheduledTerm.policyNumber;
                    boundPolicy = policy;
                    break;
                  }

                  policyNumber = policy.policyNumber;
                  boundPolicy = policy;
                }

                if (boundPolicy) {
                  this.endorsablePolicy$.next(boundPolicy);
                  this.checkIfPolicyEndorsable();
                }

                if (policyNumber) {
                  this.boundPolicyNumbers.push({
                    lineOfBusiness: 'bop',
                    policyNumber,
                  });
                }
              }
            } else {
              this.isEditEnabled = true;
            }

            this.updateShowHelpCenterModal();
          }
        )
    );
  }

  getSupportPrefillParams() {
    const queryParams: Record<string, string> = { accountNumber: this.model.id };
    if (this.boundPolicyNumbers.length === 1) {
      queryParams.lineOfBusiness = this.boundPolicyNumbers[0].lineOfBusiness;
      queryParams.policyNumber = this.boundPolicyNumbers[0].policyNumber;
    }
    return queryParams;
  }

  displayMakeRequest() {
    return this.hasBoundGl || this.hasBoundPl || this.hasBoundWc || this.hasBoundBop;
  }

  displaySelfServiceEndorsementLink() {
    return (
      // TODO(NY) - Remove after Hurricane Ian moratorium is lifted.
      !HURRICANE_IAN_MORATORIUM_STATES.includes(this.model.state) &&
      this.hasEndorsableBop &&
      !this.loadingEndorsableStatus &&
      !this.isBopPolicyPendingCancellation &&
      !this.hasZendeskEndorsementResponse &&
      !this.hasBoundGl &&
      !this.hasBoundPl &&
      !this.hasBoundWc
    );
  }

  isPendingCancellation(policy: AccountPolicy) {
    const policyInForce = policy.terms.find((term) => term.status === 'In Force');
    if (policyInForce) {
      return this.insuredAccountService
        .retrievePolicyTransactionTerms(
          policyInForce.policyNumber,
          String(policyInForce.termNumber)
        )
        .pipe(
          map((response) => {
            const portalTermTransactions: AccountPolicyPeriod[] = _.get(
              response,
              'PolicyTerm.PortalViewableTermPeriods.Entry',
              []
            );

            const latestPortalTermTransaction =
              portalTermTransactions[portalTermTransactions.length - 1];

            if (latestPortalTermTransaction) {
              return (
                latestPortalTermTransaction.Job.Subtype === 'Cancellation' &&
                !WITHDRAWN_RESCINDED_STATUSES.includes(latestPortalTermTransaction.Status || '')
              );
            }
          })
        );
    }
    return observableOf(false);
  }

  checkIfPolicyEndorsable() {
    this.sub.add(
      this.endorsablePolicy$
        .pipe(
          switchMap((policy: AccountPolicy) =>
            zip(
              this.isPendingCancellation(policy),
              this.endorseQuoteService.getZendeskEndorsement(policy.policyNumber)
            )
          )
        )
        .subscribe(([isPendingCancellationResponse, hasZendeskResponse]) => {
          const zendeskEndorsementPresent =
            hasZendeskResponse && hasZendeskResponse.openZendeskTickets.length > 0;
          this.isBopPolicyPendingCancellation = !!isPendingCancellationResponse;
          this.hasZendeskEndorsementResponse = zendeskEndorsementPresent;
          this.loadingEndorsableStatus = false;
        })
    );
  }

  createPolicyChange(policyChanges?: string) {
    const queryParams = {
      changes: policyChanges ? policyChanges : '',
      policyNumber: '',
      term: '',
    };

    this.sub.add(
      this.endorsablePolicy$
        .pipe(
          mergeMap((policy: AccountPolicy) => {
            // Prioritize a scheduled policy term (indicates rollover/renewal)
            const scheduledPolicyTerm = policy.terms.find(
              (term: AccountPolicyTerm) => term.status === 'Scheduled'
            ) as AccountPolicyTerm;

            if (scheduledPolicyTerm) {
              queryParams['policyNumber'] = scheduledPolicyTerm.policyNumber;
              queryParams['term'] = scheduledPolicyTerm.termNumber.toString();
            } else {
              const accountPolicyTerm = policy.terms.find(
                (term: AccountPolicyTerm) => term.policyNumber === policy.policyNumber
              ) as AccountPolicyTerm;

              if (accountPolicyTerm) {
                queryParams['policyNumber'] = accountPolicyTerm.policyNumber;
                queryParams['term'] = accountPolicyTerm.termNumber.toString();
              } else {
                queryParams['policyNumber'] = policy.policyNumber;
                queryParams['term'] = '1';
              }
            }

            return this.insuredAccountService.retrievePolicyTransactionTerms(
              queryParams['policyNumber'],
              queryParams['term']
            );
          }),
          mergeMap((period: PolicyPeriod) => {
            const binds = period.PolicyTerm.PortalViewableTermPeriods.Entry;
            let latestMoment = moment();
            for (const termPeriod of binds) {
              if (moment(termPeriod.EditEffectiveDate).isAfter(latestMoment)) {
                latestMoment = moment(termPeriod.EditEffectiveDate);
              }
            }
            return this.endorseQuoteService.createPolicyChange(
              queryParams['policyNumber'],
              queryParams['term'],
              latestMoment
            );
          })
        )
        .subscribe((response) => {
          if (response && response.jobNumber) {
            this.router.navigate(
              ['/accounts', this.accountId, 'bop', 'endorsements', response.jobNumber],
              {
                queryParams,
              }
            );
          }
        })
    );
  }

  updateShowHelpCenterModal() {
    const accountHasBoundPolicy =
      this.hasBoundBop || this.hasBoundGl || this.hasBoundPl || this.hasBoundWc;
    const userHasSeenHelpCenterModal = this.newInformationalService.getValue(
      INFORMATIONAL_NAMES.NEW_HELP_CENTER
    );

    if (!userHasSeenHelpCenterModal && accountHasBoundPolicy) {
      this.showHelpCenterMarketingModal = true;
      this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.NEW_HELP_CENTER);
      this.amplitudeService.track({
        eventName: 'account_help_center_modal_open',
        detail: this.accountId,
      });
    }
  }
}
