import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'app/core/services/user.service';
import { User } from 'app/shared/models/user';
import { BookInfo, BookInfoResponse, BookMixService } from 'app/shared/services/book-mix.service';
import { some } from 'lodash';
import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-activity-book-mix-insights',
  templateUrl: './activity-book-mix-insights.component.html',
})
export class BookMixInsightsComponent implements OnInit {
  constructor(private bookMixService: BookMixService, private userService: UserService) {}

  @Input() attuneWcIsEnabled: Boolean;
  @Input() producerCode: string;

  private sub: Subscription = new Subscription();

  loadingBookMixInformation = true;
  hasBopPolicies = false;
  hasWcPolicies = false;
  bookMixInformation: BookInfo;

  ngOnInit() {
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user: User) => {
            return this.bookMixService.getBookMixInformation(user.producer);
          })
        )
        .subscribe((bookMixInformation: BookInfoResponse) => {
          if (bookMixInformation) {
            this.bookMixInformation = bookMixInformation.producerCodeBookInfo;
            this.hasBopPolicies = some(this.bookMixInformation.bop, (tier) => {
              return tier.numPolicies > 0;
            });
            this.hasWcPolicies = some(this.bookMixInformation.wc, (tier) => {
              return tier.numPolicies > 0;
            });
          }
          this.loadingBookMixInformation = false;
        })
    );
  }
}
