<div class="activity-book-mix-progress-container">
  <div class="top-row">
    <div class="product-details">
      <div class="product-badge-group">
        <app-product-badge product="{{ productType }}" pasSource="attune"></app-product-badge>
        <div>
          <h3 class="account-policies-header-h3">{{ getSectionHeading() }}</h3>
          <p class="p p__no-margin">
            Attune <span class="gray-text">({{ getAttunePaper() }})</span>&nbsp;&nbsp;<a
              href="{{ getGuidelinesLink() }}"
              target="_blank"
              >Learn more</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="book-mix-status js-book-mix-status-message_{{ productType }}">
      <div class="preference-icons-text" *ngIf="preferredPercentage < targetPercentage">
        <span class="quote_preference__non_preferred"></span>
        <span class="quote_preference__non_preferred"></span>
        <span class="quote_preference__preferred"></span>
        <span>{{ getBookMixStatusMessage() }}</span>
      </div>
      <div class="preference-icons-text" *ngIf="preferredPercentage === targetPercentage">
        <span class="quote_preference__non_preferred"></span>
        <span class="quote_preference__preferred"></span>
        <span>{{ getBookMixStatusMessage() }}</span>
      </div>
      <div class="preference-icons-text" *ngIf="preferredPercentage > targetPercentage">
        <span class="quote_preference__non_preferred"></span>
        <span class="quote_preference__preferred"></span>
        <span class="quote_preference__preferred"></span>
        <span>{{ getBookMixStatusMessage() }}</span>
      </div>
    </div>
  </div>
  <div class="bottom-full-width-row">
    <app-activity-book-mix-progress-bar
      class="full-width-row"
      [targetPercentage]="targetPercentage"
      [preferredCount]="preferredCount"
      [nonPreferredCount]="nonPreferredCount"
      [targetCount]="totalTargetPreferredPoliciesCount"
      [productType]="productType"
    >
    </app-activity-book-mix-progress-bar>
  </div>
  <div class="bottom-full-width-row" *ngIf="preferredCount > 0 || nonPreferredCount > 0">
    <app-activity-policies-drop-drown-list class="full-width-row" [productType]="productTypeName">
    </app-activity-policies-drop-drown-list>
  </div>
</div>
