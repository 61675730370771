<div class="policy-pane-content">
  <div class="policy-pane-nav">
    <a class="button button__nav button__no-margin" [routerLink]="['../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>

    <div class="policy-pane-nav-buttons">
      <a
        (click)="unbundle()"
        (keyup.enter)="unbundle()"
        class="button button__small button__secondary button__no-margin js-quote-unbundle-button"
      >
        Unbundle
      </a>
      <div class="dropdown" appClickOutside (clickOutside)="showEditDropdown = false">
        <button
          (click)="showEditDropdown = !showEditDropdown"
          (keyup.enter)="showEditDropdown = !showEditDropdown"
          class="button button__small button__secondary button__no-margin js-go-to-edit"
        >
          Edit
        </button>
        <div class="dropdown-child" *ngIf="showEditDropdown" role="menu">
          <a
            (click)="editBop()"
            (keyup.enter)="editBop()"
            data-amplitude-input-name="bundle-edit-bop"
            class="dropdown-child-link"
            role="menu-item"
            >Businessowners' Policy</a
          >
          <a
            (click)="editCyber()"
            (keyup.enter)="editCyber()"
            data-amplitude-input-name="bundle-edit-cyber"
            class="dropdown-child-link"
            role="menu-item"
            >Active Cyber</a
          >
        </div>
      </div>
      <button
        *ngIf="nonBindingRole"
        class="
          button button__small button__primary button__no-margin button__discouraged
          js-go-to-bind
        "
        (click)="handleErrorToast()"
        (keyup.enter)="handleErrorToast()"
      >
        Continue to bind
      </button>
      <button
        *ngIf="!nonBindingRole && cyberQuote && bopQuote"
        class="button button__small button__primary button__no-margin js-go-to-bind"
        [routerLink]="['/accounts', accountId, 'bundle-bind']"
        [queryParams]="{ bopPolicyId: bopQuote.pasId, cyberPolicyId: cyberQuote.uuid }"
      >
        Continue to bind
      </button>
    </div>
  </div>

  <div class="policy-pane-header policy-pane-header-h2">
    <div class="policy-pane-header-left">
      <div class="product-badge-group">
        <div class="product-badge product-badge__sidebar product-badge__bop-plus">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__attune_gw"></div>
        </div>
        <div class="product-badge product-badge__sidebar product-badge__cyber_admitted">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__coalition"></div>
        </div>
      </div>
      <div class="policy-pane-quote-info">
        <h2 class="policy-pane-header-h2">Quote bundle details</h2>
        <small class="gray-text"
          >No. {{ bopQuote?.pasId }}, {{ cyberQuote?.displayId || cyberQuote?.pasId }}</small
        >
      </div>
    </div>
    <div class="policy-pane-header-right">
      <h2
        *ngIf="bopQuote?.premium && cyberQuote?.premium"
        class="policy-pane-header-h2 js-premium-number"
      >
        {{ bopQuote.premium + cyberQuote.premium | currency: 'USD':'symbol':'1.2-2' }}
      </h2>
      <h2
        *ngIf="!(bopQuote?.premium && cyberQuote?.premium)"
        class="policy-pane-header-h2 js-premium-number"
      >
        N/A
      </h2>
      <small class="gray-text">Premium</small>
    </div>
  </div>
  <hr class="hr__small" />
  <div class="policy-detail-subsection">
    <dl class="dl__no-space-between dl__no-dotted-line">
      <dt><strong>Premium Breakdown</strong></dt>
    </dl>
    <dl class="gray-text dl__no-space-between">
      <dt>Businessowners' Policy</dt>
      <dd class="js-bop-premium-number">
        {{ bopQuote?.premium | currency: 'USD':'symbol':'1.2-2' }}
      </dd>
    </dl>
    <dl class="gray-text dl__no-space-between">
      <dt>Active Cyber</dt>
      <dd class="js-cyber-premium-number">
        {{ cyberQuote?.premium | currency: 'USD':'symbol':'1.2-2' }}
      </dd>
    </dl>
  </div>

  <div class="document-download-container">
    <app-document-download
      *ngIf="bopQuote?.status === 'quoted'"
      displayName="Bop Quote Letter"
      [documentDownload$]="bopQuoteLetterDownload$"
      [documentReady$]="bopQuoteLetterDownloadReady$"
    >
    </app-document-download>
    <app-document-download
      *ngIf="cyberQuote?.status === 'quoted'"
      displayName="Cyber Quote Letter"
      [documentDownload$]="cyberQuoteLetterDownload$"
      [documentReady$]="cyberQuoteLetterDownloadReady$"
    >
    </app-document-download>

    <app-document-download
      *ngIf="showSubmissionDownload"
      displayName="BOP Application Input"
      [documentDownload$]="bopSubmissionDownload$"
    >
    </app-document-download>

    <app-document-download
      *ngIf="hasDomains"
      displayName="Cyber Risk Profile"
      [documentDownload$]="coalitionRiskAssessmentDownload$"
      [documentReady$]="carrierDocumentsReady$"
    >
    </app-document-download>
    <app-document-download
      *ngIf="isLoadingCarrierDocuments"
      displayName="Carrier Documents"
      [documentReady$]="carrierDocumentsReady$"
    >
    </app-document-download>
    <ng-container *ngIf="!isLoadingCarrierDocuments">
      <app-document-download
        *ngFor="let doc of carrierDocuments"
        [displayName]="doc.label"
        [documentDownload$]="coalitionDocumentDownload$(doc.documentType, doc.name)"
      >
      </app-document-download>
    </ng-container>
  </div>

  <div class="policy-detail-subsection">
    <dl>
      <dt>Effective date</dt>
      <dd class="js-effective-date">{{ bopQuote?.effectiveDate | date: 'mediumDate':'UTC' }}</dd>
    </dl>
  </div>
  <app-dialog-box
    *ngIf="!isUnBundleTooltipDismissed"
    dismissable="true"
    type="primary"
    (dismissDialog)="this.handleUnBundleTooltipClose()"
  >
    To turn bundle coverage into two separate quotes, click ‘Unbundle’ above. Note that unbundling
    will not impact the terms or pricing of either quote.
  </app-dialog-box>
  <app-dropdown-subsection header="Businessowners' Policy">
    <app-attune-bop-quote-details-page
      *ngIf="bopQuote"
      isBundle="true"
      [policyId]="bopQuote.pasId"
      [cyberId]="cyberQuote.uuid"
    ></app-attune-bop-quote-details-page>
  </app-dropdown-subsection>
  <app-dropdown-subsection header="Cyber Coverage">
    <app-carrier-quote-details *ngIf="cyberQuote" [isBundle]="true" [quoteId]="cyberQuote.uuid">
    </app-carrier-quote-details>
  </app-dropdown-subsection>
</div>
