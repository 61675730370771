<h1 class="form-header-below-link">Policy info</h1>
<app-form-field-text
  inputId="policyInfo-employerIdentificationNumberCrossSellExperiment"
  labelText="FEIN"
  [form]="policyFormGroup"
  [submitted]="submitted"
  nameOfFormControl="employerIdentificationNumberCrossSellExperiment"
  placeholder="00-0000000"
  appFeinMask
  (focusOut)="checkFEINValue()"
>
</app-form-field-text>
<div *ngIf="isFEINPlaceholderValue" class="dialog-text dialog-text__error">
  <strong>Is this a real FEIN number?</strong> FEINs are used to calculate premium, so please, no
  placeholder values. If you proceed with this FEIN, your account will be flagged for internal
  review.
</div>
<app-form-field-text
  inputId="policyInfo-effectiveDate"
  labelText="Policy effective date"
  [form]="policyFormGroup"
  [submitted]="submitted"
  nameOfFormControl="effectiveDate"
  appDateMask
>
</app-form-field-text>
<div class="validation" role="alert" *ngIf="fieldInvalid('effectiveDate')">
  <p class="validation-message">
    Please enter a date that is today or up to three months from today.
  </p>
</div>

<app-form-field-select
  inputId="empLiabilityLimits"
  [form]="policyFormGroup"
  [submitted]="submitted"
  labelText="Employer's Liability Limits (Each Claim, Each Employee, Policy Limit)"
  nameOfFormControl="employersLiabilityLimits"
  [availableOptions]="empLiabilityOptions()"
>
</app-form-field-select>

<div *ngFor="let stateBureauInfo of getStateBureauInfoFormArray().controls; let i = index">
  <div *ngIf="stateBureauInfo.get('taxOrUnemploymentLabel')">
    <app-form-field-text
      inputId="state-bureau-info-{{ i }}-taxOrUnemploymentId"
      [form]="stateBureauInfo"
      [submitted]="submitted"
      [labelText]="stateBureauInfo.get('taxOrUnemploymentLabel').value"
      nameOfFormControl="taxOrUnemploymentId"
      [maxLength]="this.taxOrUnemploymentIdLength[stateBureauInfo.value.state].max"
      appNumberMask
    >
    </app-form-field-text>
  </div>
</div>
