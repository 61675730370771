<a (click)="isExpanded = !isExpanded" (keyup.enter)="isExpanded = !isExpanded" class="">
  <span
    class="icon-carat-right button-icon"
    [ngClass]="{
      'icon-carat-right': !isExpanded,
      'icon-carat-down': isExpanded
    }"
  ></span>
</a>
<strong class="drop-down-list-title">{{ title }}</strong>
<div class="drop-down-list-table" *ngIf="isExpanded">
  <table>
    <thead>
      <th>Account</th>
      <th>Description</th>
      <th>Created</th>
      <th>Preference</th>
      <th></th>
    </thead>

    <tbody *ngFor="let item of listItems">
      <td data-column-name="Account" class="account-text">
        {{ item.accountName }}
      </td>
      <td data-column-name="Description" class="small-text">
        {{ getDescription(item) }}
      </td>
      <td data-column-name="Created" class="">
        {{ item.boundDate | date: 'MM/dd/yy' }}
      </td>
      <td data-column-name="Preference" class="policy-type-preference">
        <div class="preference-icons-text list_preference">
          <span class="quote_preference__{{ getPolicyPreference(item) }}"></span>
          <span>{{ getDisplayPreference(item) }}</span>
        </div>
      </td>
      <td class="quote-table-view-details-td td__right">
        <a class="js-view-details-link" [routerLink]="getRouterLink(item)">Details</a>
      </td>
    </tbody>
  </table>
</div>
