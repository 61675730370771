<div class="app-address-field-group" *ngIf="form.enabled">
  <ng-container>
    <label for="{{ prefix }}-addressLine1" [attr.data-specifier]="specifierText">{{
      labelText
    }}</label>
    <app-form-field-address-autocomplete
      inputId="{{ prefix }}-addressLine1"
      [form]="form"
      [submitted]="submitted"
      labelText=""
      nameOfFormControl="addressLine1"
      placeholder="Address 1"
      cssClass="form-field__no-margin"
      (focusout)="handleFocusOut()"
      [readonly]="readonly"
      (newAutoCompleteAddress)="handleNewAutoCompleteAddress($event)"
    >
    </app-form-field-address-autocomplete>
  </ng-container>
  <div
    *ngIf="form.controls.addressLine1.errors && form.controls.addressLine1.errors.maxlength"
    class="validation"
  >
    <span class="validation-message">Address 1 must be fewer than 60 characters. </span>
  </div>
  <div
    *ngIf="
      form.controls.addressLine1.errors && form.controls.addressLine1.errors.specialCharacterError
    "
    class="validation"
  >
    <span class="validation-message"
      >Your address cannot include special characters: (+:;'"@&*()[]).
    </span>
  </div>
  <div
    *ngIf="form.controls.addressLine1.errors && form.controls.addressLine1.errors.POBoxError"
    class="validation"
  >
    <span class="validation-message">Your address cannot be a PO Box. </span>
  </div>
  <ng-container *ngIf="useAddressLine2">
    <app-form-field-text
      inputId="{{ prefix }}-addressLine2"
      [form]="form"
      [submitted]="submitted"
      [readonly]="readonly"
      labelText=""
      nameOfFormControl="addressLine2"
      placeholder="Address 2"
      cssClass="form-field__no-margin"
      [enableAutocomplete]="false"
    >
    </app-form-field-text>
    <div
      *ngIf="
        form.controls.addressLine2.errors && form.controls.addressLine2.errors.specialCharacterError
      "
      class="validation"
    >
      <span class="validation-message"
        >Your address cannot include special characters: (:;'"@&*()[]).
      </span>
    </div>
    <div
      *ngIf="form.controls.addressLine2.errors && form.controls.addressLine2.errors.maxlength"
      class="validation"
    >
      <span class="validation-message">Address 2 must be fewer than 60 characters. </span>
    </div>
    <div
      *ngIf="
        form.controls.addressLine2.errors && form.controls.addressLine2.errors.invalidAddressLength
      "
      class="validation"
    >
      <span class="validation-message">
        {{ form.controls.addressLine2.errors.invalidAddressLength }}
      </span>
    </div>
  </ng-container>
  <div class="address-col">
    <div class="address-col-city">
      <app-form-field-text
        inputId="{{ prefix }}-city"
        [form]="form"
        [submitted]="submitted"
        labelText=""
        nameOfFormControl="city"
        placeholder="City"
        cssClass="form-field__no-margin"
        [readonly]="readonly"
        [enableAutocomplete]="false"
      >
      </app-form-field-text>
    </div>
    <div class="address-col-state">
      <app-form-field-select
        inputId="{{ prefix }}-state"
        [form]="form"
        [submitted]="submitted"
        [readonly]="disableStateChange || readonly"
        labelText=""
        nameOfFormControl="state"
        placeholder="State"
        [availableOptions]="usStateService.getUsStateAbbreviations()"
        cssClass="form-field__no-margin"
      >
      </app-form-field-select>
    </div>
    <div class="address-col-zip">
      <app-form-field-text
        inputId="{{ prefix }}-zip"
        [form]="form"
        [submitted]="submitted"
        labelText=""
        nameOfFormControl="zip"
        placeholder="Zip"
        appNumberMask
        maxLength="5"
        cssClass="form-field__no-margin"
        [readonly]="readonly"
        [enableAutocomplete]="false"
      >
      </app-form-field-text>
    </div>
  </div>
  <div *ngIf="form.errors && form.errors.locationStateAndRiskStateMismatch" class="validation">
    <span class="validation-message">
      {{ form.errors.locationStateAndRiskStateMismatch.validationMessage }}
    </span>
  </div>
  <div *ngIf="isStreetNumberMissing" class="validation">
    <span class="validation-message validation-message__warning">
      No street number was found for the selected address. Please confirm the above address appears
      correct before proceeding.
    </span>
  </div>
  <p class="form-field-note" *ngIf="questionNote" [innerHTML]="questionNote"></p>
</div>
<ng-container *ngIf="displayMap">
  <figure class="address-field-map-container">
    <div class="address-field-map address-field-map__placeholder" *ngIf="isMapLoading"></div>
    <img
      alt="Map of the address {{ formatAddress(form.value) }}"
      class="address-field-map js-address-field-map"
      [src]="addressMapUrl"
      *ngIf="addressMapUrl && !isMapLoading"
    />
  </figure>
</ng-container>
