import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { combineLatest, forkJoin, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import {
  CoalitionCyberProduct,
  DraftQuoteResponse,
  EditRequestParams,
  FormDSLQuoteRequest,
  isDraftQuoteResponse,
  isQuoteErrorResponse,
  isUnavailableResponse,
  ProductAvailability,
  QuotedQuoteResponse,
  QuoteResponse,
  FrontendQuote,
  isQuotedQuoteResponse,
  DigitalCarrierPolicyDetails,
} from 'app/features/digital-carrier/models/types';
import { SentryService } from 'app/core/services/sentry.service';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { NaicsService } from 'app/shared/services/naics.service';
import {
  formatFormFieldValues,
  formatFormFieldValuesForDisplay,
  sanitizeDomain,
} from '../../models/cyber-form-helpers.model';
import {
  BundleOption,
  CoalitionCyberFormStepPath,
  CoalitionCyberMarketDisplay,
  CoalitionCyberQuestion,
  CoalitionCyberSubmissionField,
  CoalitionCyberValidators,
  CyberDraftQuoteResponse,
  CyberIndustryData,
  CyberMarketingConfig,
  CyberProduct,
  CyberQuestionEnablementFlag,
  CyberQuestionEnablementFlags,
  EarlyDeclineReason,
  FirstPartyCoverageNestedQuestion,
  INELIGIBLE_FOR_ADMITTED,
  IneligibleForAdmittedReason,
  InsuranceMarketControl,
  PathCyberProduct,
} from '../../models/cyber-typings.model';
import { numberToMoneyString, parseMoney } from 'app/shared/helpers/number-format-helpers';
import { COALITION_CYBER_VALIDATORS } from '../../models/cyber-validators.model';
import { FormDslData } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { ISO_DATE_MASK, US_DATE_MASK } from 'app/constants';
import { getControl, getFormGroup } from '../../../../shared/helpers/form-helpers';
import {
  ADMITTED_EXCEEDS_MAXIMUM_REVENUE,
  ADMITTED_KNOCK_OUT_STATES,
  ADMITTED_MAX_REVENUE,
  COALITION_BLOCKLISTED_DOMAIN_RESPONSE_ERROR,
  COALITION_BROKER_OF_RECORD_REFERRAL_REASON,
  CYBER_CALL_TO_ACTION_MESSAGES,
  CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M,
  CYBER_DOMAIN_REGEX,
  DUPLICATE_BROKER_ERROR_MESSAGE,
  ESSENTIAL_COVERAGES,
  GOVT_RISK_IDS,
  INITIAL_RENTENTION_RANGE_MIN_INDEX,
  INITIAL_RETENTION_RANGE__MAX_INDEX,
  LIMIT_OPTIONS,
  MIMIMUM_SIR_LIMITS,
  MIMIMUM_SIR_LIMITS_GOVT,
  MIMIMUM_SIR_REVENUES,
  MIMIMUM_SIR_REVENUES_GOVT,
  MINIMUM_SIR_GRAPH,
  MINIMUM_SIR_GRAPH_GOVT,
  pathToProduct,
  productToPath,
  RETENTION_LIMIT_DIVISOR,
  RETENTION_OPTIONS,
  SURPLUS_NOT_AVAILABLE_IN_STATE,
  SURPLUS_NOT_YET_AVAILABLE,
  BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL,
  CYBER_MARKETING_GROUP_TO_CONFIG,
  INVALID_AGENCY_DATA_ERROR_MESSAGE,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  ESSENTIAL_DEFAULT_RETENTION_INDEX,
} from '../../models/cyber-constants.model';
import {
  AppError,
  COALITION_BLOCKLISTED_DOMAIN_ERROR,
  COALITION_DEFAULT_RETENTION_ERROR,
  COALITION_DUPLICATE_BROKER_ERROR,
  COALITION_EARLY_DECLINE_ERROR,
  COALITION_INVALID_DOMAIN_ERROR,
  COALITION_MISSING_DOMAIN_ERROR,
  COALITION_SURPLUS_MARKET_UNAVAILABLE_ERROR,
  UNKNOWN_COALITION_ERROR,
  COALITION_BROKER_OF_RECORD_REFERRAL_ERROR,
  COALITION_INVALID_AGENCY_DATA_ERROR,
  TIMEOUT_ERROR,
} from '../../../../shared/quote-error-modal/errors';
import { DigitalCarrierDuplicateRequestCheckService } from 'app/features/digital-carrier/services/digital-carrier-duplicate-request-check.service';
import { environment } from 'environments/environment';
import { removeFormDataSteps } from '../../../../shared/form-dsl/utils/form-dsl-helpers';
import { CoalitionCyberDuplicateBrokerZendeskTicketService } from '../../services/coalition-cyber-duplicate-broker-zendesk-ticket.service';
import { AttuneBopQuoteService } from '../../../attune-bop/services/attune-bop-quote.service';
import { BopPolicy, BopQuotePayload } from '../../../attune-bop/models/bop-policy';
import {
  ADMITTED_DEPENDENCIES,
  CYBER_DEPENDENCIES,
  SURPLUS_DEPENDENCIES,
} from '../../models/cyber-dependencies.model';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { AttuneBopQuoteRequestService } from '../../../attune-bop/services/attune-bop-quote-request.service';
import { BopPricedQuote } from '../../../attune-bop/models/bop-priced-quote';
import { HttpResponse } from '@angular/common/http';
import { DigitalCarrierPolicyService } from '../../../digital-carrier/services/digital-carrier-policy.service';
import { CoalitionCyberQuoteFormService } from '../../services/coalition-cyber-quote-form.service';
import { BundleService } from '../../../bundle/services/bundle.service';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';

@Component({
  selector: 'app-coalition-cyber-quote-form',
  templateUrl: './coalition-cyber-quote-form.component.html',
  providers: [CoalitionCyberQuoteFormService],
})
export class CoalitionCyberQuoteFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit, OnDestroy
{
  model: InsuredAccount = new InsuredAccount();
  coverageFormGroup: UntypedFormGroup;
  limitPath = CoalitionCyberQuestion.AGGREGATE_LIMIT;
  retentionPath = CoalitionCyberQuestion.DEFAULT_RETENTION;

  accountId: string;
  quoteId: string;
  renewalOfQuoteId: string | null = null;
  premium: number | null = null;
  insuredAccount: InsuredAccount;

  isEditing = false;
  quoteIsReadyForIssue = false;
  isLoadingNextStep = false;
  isLoadingPremium = false;
  isSubmittingForm = false;
  isMakingEditRequest = false;
  quoteFormUpdatedSinceLastEditRequest = false;

  selectedProduct: CyberProduct;

  LIMIT_OPTIONS = LIMIT_OPTIONS;

  // Set the initial range for the Default Retention slider.
  // The allowed range changes as the user updates Aggregate Limit.
  retentionMin = INITIAL_RENTENTION_RANGE_MIN_INDEX;
  retentionMax = INITIAL_RETENTION_RANGE__MAX_INDEX;

  latestQuoteResponse: CyberDraftQuoteResponse | QuotedQuoteResponse | null = null;
  // Product combination for Cyber Admitted or Surplus
  digitalCarrierProduct: CoalitionCyberProduct;

  // Validator dictionary
  formValidators: CoalitionCyberValidators;

  quoteErrorModalOpen = false;
  hasDomainErrorModalBeenOpen = false;
  quoteError: AppError;
  earlyDeclineErrors: EarlyDeclineReason[] = [];

  hasBlockListedDomainError = false;
  hasDuplicateBrokerError = false;
  hasInvalidAgencyDataError = false;
  hasBrokerOfRecordError = false;

  productAvailabilities: ProductAvailability[];
  productAvailabilitiesLoading = true;

  netNewQuote = false;
  isRenewalQuote = false;
  showDefaultedUnderwritingQuestionsTooltip = false;

  statesEnabledForCyberSurplus = environment.statesEnabledForCyberSurplus;
  cyberCallToActionMessages = CYBER_CALL_TO_ACTION_MESSAGES;

  market: CoalitionCyberMarketDisplay;

  flags: CyberQuestionEnablementFlags | null = null;
  employeeCountValue = '';
  isQuickQuote = true;

  bopQuoteUuid: string;
  bopParentId: string;

  isQuoteHelperVideoEnabled = false;
  showQuoteHelperVideo = false;

  videoWidth: string;
  videoHeight: string;

  cyberMarketingConfig: CyberMarketingConfig | null = null;

  constructor(
    private sentryService: SentryService,
    public formService: CoalitionCyberQuoteFormService,
    private bundleService: BundleService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private digitalCarrierPolicyService: DigitalCarrierPolicyService,
    private duplicateRequestCheckService: DigitalCarrierDuplicateRequestCheckService,
    private cyberDuplicateBrokerZendeskTicketService: CoalitionCyberDuplicateBrokerZendeskTicketService,
    private featureFlagService: FeatureFlagService,
    private insuredAccountService: InsuredAccountService,
    private location: Location,
    private naicsService: NaicsService,
    private route: ActivatedRoute,
    private router: Router,
    private bopQuoteService: AttuneBopQuoteService,
    private amplitudeService: AmplitudeService,
    private requestQuoteService: AttuneBopQuoteRequestService,
    private segmentService: SegmentService
  ) {
    super(formService);
  }

  ngOnInit() {
    this.onResize();
    // URL is one of:
    //  - quickQuote: `accounts/:accountId/(cyber-admitted || cyber-surplus)/quick
    //  - new:  `/accounts/:accountId/(cyber-admitted || cyber-surplus)/new
    //  - new based on existing:
    //          `/accounts/:accountId/(cyber-admitted || cyber-surplus)/new?fromQuote=[uuid]
    //  - renewal based on existing:
    //          `/accounts/:accountId/(cyber-admitted || cyber-surplus)/new?renewalOf=[uuid]&previousPolicy=[uuid]`
    //  - edit: `/accounts/:accountId/(cyber-admitted || cyber-surplus)/:quoteId/edit
    const routeSnapshot = this.route.snapshot;

    // Parse URL:
    this.accountId = routeSnapshot.params['accountId'];
    const editingQuoteId = _.get(routeSnapshot.params, 'quoteId', null);
    const product = routeSnapshot.url[2].path as PathCyberProduct;
    const basedOnQuoteId: string | null = _.get(routeSnapshot.queryParams, 'fromQuote', null);
    this.renewalOfQuoteId = _.get(routeSnapshot.queryParams, 'renewalOf', null);
    const previousPolicyId = _.get(routeSnapshot.queryParams, 'previousPolicy');
    const bopId: string | null = _.get(routeSnapshot.queryParams, 'bopId', null);
    this.bopParentId = _.get(routeSnapshot.queryParams, 'bopParentId', null);
    if (bopId) {
      this.digitalCarrierService
        .getQuoteDetails(bopId, 'pasId')
        .pipe(filter((bopQuote) => bopQuote !== null))
        .subscribe((bopQuote: FrontendQuote) => {
          this.bopQuoteUuid = bopQuote.uuid;
        });
    }
    this.isQuickQuote = routeSnapshot.url[routeSnapshot.url.length - 1].path === 'quick';

    if (this.isQuickQuote) {
      this.amplitudeService.track({
        eventName: 'quick_quote_start',
        detail: `coalition-${product}`,
      });
    }

    const formConfiguration = this.formService.generateFormSteps(this.isQuickQuote);

    this.isRenewalQuote = !!this.renewalOfQuoteId;
    // Note: Casting is necessary here so compiler does not think some values are 'undefined'
    this.formValidators = COALITION_CYBER_VALIDATORS(
      this.isRenewalQuote
    ) as CoalitionCyberValidators;

    this.updateProduct({
      pasSource: 'coalition',
      product: pathToProduct[product],
    });

    this.hasDomainErrorModalBeenOpen = false;

    const quoteIdToFetch = editingQuoteId || basedOnQuoteId || this.renewalOfQuoteId;
    this.netNewQuote = !quoteIdToFetch;

    // If the editingQuoteId is set, then it's possible that the quote has already been issued
    // (e.g., the broker navigates directly to the /edit page, or clicks back in their browser).
    // Therefore, we need to get the quote to check its status to prevent clicking the
    // "Continue as ..." button, which would put the issued quote into the unavailable state
    if (editingQuoteId) {
      this.digitalCarrierService
        .getQuoteDetails(editingQuoteId)
        .subscribe((quote: FrontendQuote) => {
          if (quote.status !== 'draft') {
            this.quoteError = UNKNOWN_COALITION_ERROR;
            this.quoteErrorModalOpen = true;
          }
        });
    }
    super.ngOnInit();
    this.setFormConfig(formConfiguration);
    if (quoteIdToFetch) {
      const quoteSubmission$ = this.digitalCarrierService.getQuoteSubmission(
        this.digitalCarrierProduct,
        quoteIdToFetch
      );
      const previousPolicy$ = previousPolicyId
        ? this.digitalCarrierPolicyService.getPolicyDetails(
            previousPolicyId,
            this.digitalCarrierProduct
          )
        : observableOf(null);
      this.sub.add(
        forkJoin([quoteSubmission$, previousPolicy$]).subscribe(
          ([submission, previousPolicy]: [
            FormDSLQuoteRequest,
            DigitalCarrierPolicyDetails | null
          ]) => {
            this.coverageFormGroup = getFormGroup(
              this.formService.form,
              CoalitionCyberFormStepPath.COVERAGE
            );
            if (!submission) {
              // If the submission could not be fetched, then treat this as a new quote.
              return;
            }

            // isEditing must be `true` in order for the form to be patched
            this.isEditing = true;
            const oldFormData = submission.formData;
            const oldFormDataDisplay = formatFormFieldValuesForDisplay(oldFormData);
            this.setFormData(oldFormDataDisplay as FormDslData);

            // If we are editing an existing quote, save the quote ID to use for edit requests
            // and prevent the user from switching markets.
            if (editingQuoteId) {
              this.quoteId = editingQuoteId;
            }

            // For renewal quotes, prefill the effective date, and prevent the user
            // from editing the date or switching markets.
            if (this.isRenewalQuote) {
              this.selectedProduct = pathToProduct[product];
              if (previousPolicy) {
                this.setEffectiveDateFromExpiringPolicy(previousPolicy.expirationDate);
              }
            }
          }
        )
      );
    } else {
      this.sub.add(
        this.insuredAccountService.get(this.accountId).subscribe((model) => {
          this.model = model;
          this.sendSegmentEvent('Quote Started');
          let latestTermEffectiveDate = moment(0);
          const latestPolicy = _.maxBy(
            [...model.bopPlusPolicies, ...model.bopV1Policies],
            (policy) =>
              _.maxBy(policy.terms, (term) => {
                latestTermEffectiveDate = moment.max(latestTermEffectiveDate, term.updatedAt);
                return term.updatedAt;
              })
          );
          const filteredQuotes = _.filter(model.bopQuotes, (quote) => quote.status === 'Quoted');
          const latestQuote = _.maxBy(filteredQuotes, (quote) => quote.timeLastUpdated);
          const latestBop = latestPolicy || latestQuote;
          if (latestBop) {
            this.bopQuoteService
              .getTranslatedQuoteV2(latestBop.id)
              .pipe(
                catchError((error) => {
                  this.sentryService.notify(
                    'Coalition Cyber: Error when retrieving BOP quote for prefill',
                    {
                      severity: 'error',
                      metaData: {
                        product: this.digitalCarrierProduct.product,
                        underlyingErrorMessage: error && error.message,
                        underlyingError: error,
                      },
                    }
                  );

                  return observableOf(null);
                })
              )
              .subscribe((payload: null | DeepPartial<BopQuotePayload>) => {
                this.prefillEffectiveDate(
                  latestPolicy ? latestTermEffectiveDate.format(US_DATE_MASK) : undefined
                );
                this.coverageFormGroup = getFormGroup(
                  this.formService.form,
                  CoalitionCyberFormStepPath.COVERAGE
                );
                if (payload) {
                  this.prefillFromBop(payload);
                }
              });
          } else {
            this.prefillEffectiveDate();
            this.coverageFormGroup = getFormGroup(
              this.formService.form,
              CoalitionCyberFormStepPath.COVERAGE
            );
          }
        })
      );
    }

    // Set insured account information
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => this.accountId === insuredAccount.id),
          tap((insuredAccount) => {
            this.insuredAccount = insuredAccount;
            // make sure the formService.form exists to avoid runtime errors
            if (!this.isQuickQuote && !this.isEditing && this.formService.form) {
              const domain = getFormGroup(
                this.formService.form,
                CoalitionCyberFormStepPath.POLICY_INFO
              ).get(CoalitionCyberQuestion.DOMAIN_NAMES);
              if (domain && insuredAccount.website) {
                domain.patchValue(insuredAccount.website);
              }
              const address = getFormGroup(
                this.formService.form,
                CoalitionCyberFormStepPath.POLICY_INFO
              ).get(CoalitionCyberQuestion.ADDRESS);
              if (address) {
                address.patchValue({
                  addressLine1: this.insuredAccount.addressLine1,
                  addressLine2: this.insuredAccount.addressLine2,
                  city: this.insuredAccount.city,
                  state: this.insuredAccount.state,
                  zip: this.insuredAccount.zip,
                });
              }
            }

            if (this.insuredAccount.naicsCode) {
              this.naicsService
                .getCyberMarketingGroup(this.insuredAccount.naicsCode.code)
                .subscribe((response) => {
                  if (response) {
                    this.cyberMarketingConfig =
                      CYBER_MARKETING_GROUP_TO_CONFIG[response.marketingGroup];
                  }
                });
              this.sub.add(
                this.naicsService
                  .getCyberLiabilityNaicsMapping(this.insuredAccount.naicsCode.hash)
                  .subscribe((cyberMappings) => {
                    this.formService.updateIndustryOptions(cyberMappings);
                  })
              );
            }
          })
        )
        .subscribe()
    );

    // Set listeners for coverage step, so we can make edit calls as controls change
    this.sub.add(
      this.formService.userIsEditingForm$.subscribe((isEditing) => {
        this.isLoadingPremium = isEditing;
        if (this.isFinalStep()) {
          this.calculateRetentionMinMax();
        }
      })
    );

    // Set up distinct and debounced updates to the form to make edit request
    this.sub.add(
      this.formService.coverageStepWasUpdated$.subscribe(() => {
        this.handleCoverageControlUpdates();
      })
    );

    // Set listener for industry ID, so we can make a fetch when it changes
    this.sub.add(
      this.formService.industryId$.subscribe((industryId: number | null) => {
        if (industryId) {
          this.handleIndustryIdControlUpdates(industryId);
        }
      })
    );

    // Get the product availabilities to see if this broker has access to both admitted and surplus, or only admitted
    this.naicsService.getProductAvailability().subscribe((resp) => {
      this.productAvailabilities = resp;
      this.productAvailabilitiesLoading = false;
    });

    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.CYBER_QUOTE_HELP_VIDEO)
      .subscribe((value) => {
        this.isQuoteHelperVideoEnabled = value || false;
      });
  }

  @HostListener('window: resize')
  onResize() {
    if (window.innerWidth < 450) {
      this.videoWidth = '100vw';
      this.videoHeight = '100vh';
      return;
    }
    this.videoHeight = '450px';
    this.videoWidth = '948px';
  }

  prefillFromBop(bopQuotePayload: DeepPartial<BopQuotePayload>) {
    if (bopQuotePayload.locations) {
      const employees = _.sumBy(bopQuotePayload.locations, (location) =>
        location.locationDetails
          ? parseInt(location.locationDetails.employeeCount || '0', 10) +
            parseInt(location.locationDetails.partTimeEmployeeCount || '0', 10)
          : 0
      );

      let employeeCountValue;
      if (employees <= 25) {
        employeeCountValue = '1-25';
      } else if (employees <= 50) {
        employeeCountValue = '26-50';
      } else if (employees <= 250) {
        employeeCountValue = '51-250';
      } else if (employees <= 1000) {
        employeeCountValue = '251-1000';
      } else {
        employeeCountValue = '1001+';
      }

      if (this.isQuickQuote) {
        this.employeeCountValue = employeeCountValue;
      } else {
        getControl(
          getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO),
          CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT
        ).patchValue(employeeCountValue);
      }
    }
  }

  selectProductAndContinue(event: Event, selectedProduct: CyberProduct) {
    this.updateProduct({
      pasSource: 'coalition',
      product: selectedProduct,
    });

    this.market = selectedProduct === 'cyber_admitted' ? 'Admitted' : 'Surplus';

    this.loadNextStep(event);
  }

  setShowQuoteHelperVideo(value: boolean) {
    this.showQuoteHelperVideo = value;
  }

  loadNextStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    // Prevent advancing if form validation fails
    if (!this.isCurrentStepValid()) {
      this.clickForward();
      return;
    }

    // Prevent advancing if an error/warning modal should be opened after the first step
    if (!this.isQuickQuote && this.isFirstStep() && this.openPolicyInfoStepModal()) {
      return;
    }

    this.isLoadingNextStep = true;

    if (this.isQuickQuote) {
      this.submitForm();
    } else if (this.isFirstStep()) {
      // Case #1: advancing from 1st step to 2nd step
      // Submit request before advancing so we can check for early decline.
      if (this.shouldCreateDraftQuote()) {
        this.sub.add(this.createDraftQuote$().subscribe());
      } else {
        super.loadNextStep();
      }
    } else {
      // Case #2: Advancing from 2nd step to final step
      // Submit after advancing so we can generate premium on the last page.
      super.loadNextStep();
      const bundleFormControl = this.coverageFormGroup.get(CoalitionCyberQuestion.BUNDLE);
      if (bundleFormControl && !bundleFormControl.value) {
        bundleFormControl.patchValue(BundleOption.ESSENTIAL);
      }
      this.editDraftQuote();
    }
  }

  openPolicyInfoStepModal() {
    const policyInfo = getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    const state = getControl(
      policyInfo,
      `${CoalitionCyberQuestion.ADDRESS}.${CoalitionCyberSubmissionField.STATE}`
    );
    const techEO = getControl(policyInfo, CoalitionCyberQuestion.HAS_TECH_EO);
    const revenue = getControl(policyInfo, CoalitionCyberQuestion.COMPANY_REVENUE);
    const mgmtEO = getControl(
      policyInfo,
      CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY
    );

    const product = _.get(this.digitalCarrierProduct, 'product') as CyberProduct;

    const showSurplusUnavailableModal =
      !this.isQuoteEligibleForAdmitted() && !this.isSurplusEnabled();
    const showStateIneligibleForSurplusModal =
      !this.isQuoteEligibleForAdmitted() && !this.stateIsEligibleForSurplus();

    const isAdmitted = product === 'cyber_admitted';
    const userPreviouslySelectedAdmitted = this.selectedProduct === 'cyber_admitted';

    // Check for early decline
    if (showSurplusUnavailableModal || showStateIneligibleForSurplusModal || isAdmitted) {
      if (state && ADMITTED_KNOCK_OUT_STATES.includes(state.value)) {
        this.earlyDeclineErrors.push(IneligibleForAdmittedReason.INVALID_STATE);
      }
      if (techEO && techEO.enabled && techEO.value === 'No') {
        this.earlyDeclineErrors.push(IneligibleForAdmittedReason.TECH_EO_REQUIRED);
      }
      if (revenue && revenue.enabled && parseMoney(revenue.value) > ADMITTED_MAX_REVENUE) {
        this.earlyDeclineErrors.push(IneligibleForAdmittedReason.EXCEEDS_MAXIMUM_REVENUE);
      }
      if (isAdmitted && mgmtEO && mgmtEO.enabled && mgmtEO.value === 'No') {
        this.earlyDeclineErrors.push(
          IneligibleForAdmittedReason.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY_REQUIRED
        );
      }
    }

    if (this.earlyDeclineErrors.length > 0) {
      if (userPreviouslySelectedAdmitted) {
        this.earlyDeclineErrors.push(INELIGIBLE_FOR_ADMITTED);
      }
      this.quoteErrorModalOpen = true;
      if (showSurplusUnavailableModal) {
        this.quoteError = COALITION_SURPLUS_MARKET_UNAVAILABLE_ERROR(SURPLUS_NOT_YET_AVAILABLE);
      } else if (showStateIneligibleForSurplusModal) {
        this.quoteError = COALITION_SURPLUS_MARKET_UNAVAILABLE_ERROR(
          SURPLUS_NOT_AVAILABLE_IN_STATE
        );
      } else {
        this.quoteError = COALITION_EARLY_DECLINE_ERROR;
      }
      return true;
    }

    // Check for missing domain
    const domain = getControl(policyInfo, CoalitionCyberQuestion.DOMAIN_NAMES);

    const sanitizedDomain = sanitizeDomain(domain.value);
    if (sanitizedDomain && !CYBER_DOMAIN_REGEX.exec(sanitizedDomain)) {
      domain.setValue('', { emitEvent: false });
      this.quoteErrorModalOpen = true;

      this.quoteError = COALITION_INVALID_DOMAIN_ERROR;
      this.hasDomainErrorModalBeenOpen = true;
      return true;
    }

    // Only show the missing domain error modal once per submission and only when the blocklisted domain error doesn't exist in response
    if (
      !this.hasDomainErrorModalBeenOpen &&
      (!domain || !domain.value) &&
      !this.hasBlockListedDomainError
    ) {
      this.quoteErrorModalOpen = true;

      this.quoteError = COALITION_MISSING_DOMAIN_ERROR;
      this.hasDomainErrorModalBeenOpen = true;
      return true;
    }

    return false;
  }

  getQuickQuoteFormData(
    formDataWithoutSteps: Partial<Record<CoalitionCyberQuestion | InsuranceMarketControl, any>>
  ) {
    // default data for quick quote
    if (this.isQuickQuote) {
      const coverages = ESSENTIAL_COVERAGES;
      const omitted = [];
      const omittedByProduct =
        this.digitalCarrierProduct.product === 'cyber_surplus'
          ? ADMITTED_DEPENDENCIES
          : SURPLUS_DEPENDENCIES;
      if (this.flags) {
        for (const [key, value] of Object.entries(CYBER_DEPENDENCIES)) {
          if (
            value &&
            value.flagDependency &&
            this.flags[value.flagDependency.flagName] !== value.flagDependency.shouldEnableForValue
          ) {
            omitted.push(key);
          }
        }
      }

      coverages[CoalitionCyberQuestion.FIRST_PARTY_COVERAGES] = _.omit(
        coverages[CoalitionCyberQuestion.FIRST_PARTY_COVERAGES],
        omittedByProduct
      ) as Record<FirstPartyCoverageNestedQuestion, boolean>;

      formDataWithoutSteps = {
        ...formDataWithoutSteps,
        ...coverages,
        ...CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M,
        [CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT]: this.employeeCountValue || '1-25',
        [CoalitionCyberQuestion.AGGREGATE_LIMIT]: ESSENTIAL_AGGREGATE_LIMIT_INDEX,
        [CoalitionCyberQuestion.DEFAULT_RETENTION]: ESSENTIAL_DEFAULT_RETENTION_INDEX,
        address: {
          addressLine1: this.insuredAccount.addressLine1,
          addressLine2: this.insuredAccount.addressLine2,
          city: this.insuredAccount.city,
          state: this.insuredAccount.state,
          zip: this.insuredAccount.zip,
        },
      };
      formDataWithoutSteps = _.omit(formDataWithoutSteps, omitted);
    }
    return formDataWithoutSteps;
  }

  getDcpRequestParams() {
    const digitalCarrierProduct = this.digitalCarrierProduct;
    const rawFormData = this.formService.getValue();
    const formDataWithoutSteps = this.isQuickQuote
      ? this.getQuickQuoteFormData(
          removeFormDataSteps<CoalitionCyberQuestion | InsuranceMarketControl>(rawFormData)
        )
      : removeFormDataSteps<CoalitionCyberQuestion | InsuranceMarketControl>(rawFormData);

    const formData = formatFormFieldValues(formDataWithoutSteps);
    const isoStringEffectiveDate = formData[CoalitionCyberQuestion.EFFECTIVE_DATE] as string;
    const effectiveDate = moment.utc(isoStringEffectiveDate).format(ISO_DATE_MASK);
    const account = this.digitalCarrierService.parseAccountForQuoteSubmit(this.insuredAccount);
    const quoteId = this.quoteId;
    const renewalOfQuoteId = this.renewalOfQuoteId || undefined;

    return {
      digitalCarrierProduct,
      formData,
      account,
      effectiveDate,
      quoteId,
      renewalOfQuoteId,
    };
  }

  createDraftQuote$() {
    const { digitalCarrierProduct, formData, account, effectiveDate, renewalOfQuoteId } =
      this.getDcpRequestParams();

    return this.digitalCarrierService
      .submitQuote(
        digitalCarrierProduct,
        formData,
        account,
        effectiveDate,
        false,
        true,
        undefined,
        undefined,
        renewalOfQuoteId
      )
      .pipe(
        tap((response) => {
          if (isDraftQuoteResponse(response)) {
            this.handleCreateDraftResponse(response);
            // Since we've fetched a draft, we are now in edit flow.
            this.isEditing = true;

            // Construct edit URL: `/accounts/:accountId/(cyber-admitted || cyber-surplus)/:quoteId/edit
            const product = productToPath[this.digitalCarrierProduct.product];
            const editUrl = `accounts/${this.accountId}/${product}/${this.quoteId}/edit`;

            // Record that we have selected a product
            this.selectedProduct = this.digitalCarrierProduct.product;

            if (!this.isQuickQuote) {
              // We replace the URL (vs. navigating) to avoid reloading the component
              // which would navigate to the first step of the quote flow.
              this.location.replaceState(editUrl);

              this.checkShowDefaultedUnderwritingQuestionsTooltip();
              super.loadNextStep();
            }
          } else if (
            isUnavailableResponse(response) &&
            response.unavailableReasons.includes(COALITION_BROKER_OF_RECORD_REFERRAL_REASON)
          ) {
            this.sentryService.notify(
              'Coalition Cyber: Submit quote response contains broker of record error, redirecting user to Help Center form',
              {
                severity: 'info',
                metaData: { response, product: this.digitalCarrierProduct.product },
              }
            );

            this.hasBrokerOfRecordError = true;
            this.quoteError = COALITION_BROKER_OF_RECORD_REFERRAL_ERROR;
            this.quoteErrorModalOpen = true;
          } else if (
            isUnavailableResponse(response) &&
            response.unavailableReasons.includes(COALITION_BLOCKLISTED_DOMAIN_RESPONSE_ERROR)
          ) {
            this.hasBlockListedDomainError = true;
            this.quoteError = COALITION_BLOCKLISTED_DOMAIN_ERROR;
            this.quoteErrorModalOpen = true;
            this.isLoadingNextStep = false;
          } else if (
            isQuoteErrorResponse(response) &&
            response.errors.includes(INVALID_AGENCY_DATA_ERROR_MESSAGE)
          ) {
            this.sentryService.notify(
              'Coalition Cyber: Submit quote response did not have draft status due to Invalid agency data',
              {
                severity: 'info',
                metaData: { response, product: this.digitalCarrierProduct.product },
              }
            );
            this.hasInvalidAgencyDataError = true;
            this.quoteError = COALITION_INVALID_AGENCY_DATA_ERROR;
            this.quoteErrorModalOpen = true;
            this.isLoadingNextStep = false;
          } else if (
            isQuoteErrorResponse(response) &&
            response.errors.includes(DUPLICATE_BROKER_ERROR_MESSAGE)
          ) {
            this.sentryService.notify(
              'Coalition Cyber: Submit quote response did not have draft status due to Duplicate broker error',
              {
                severity: 'info',
                metaData: { response, product: this.digitalCarrierProduct.product },
              }
            );
            this.hasDuplicateBrokerError = true;
            this.quoteError = COALITION_DUPLICATE_BROKER_ERROR;
            this.quoteErrorModalOpen = true;
            this.isLoadingNextStep = false;
          } else {
            this.sentryService.notify(
              'Coalition Cyber: Submit quote response did not have draft status',
              {
                severity: 'error',
                metaData: { response, product: this.digitalCarrierProduct.product },
              }
            );
            this.quoteError = UNKNOWN_COALITION_ERROR;
            this.quoteErrorModalOpen = true;
          }
        })
      );
  }

  admittedWasSelected() {
    return this.selectedProduct === 'cyber_admitted';
  }

  surplusWasSelected() {
    return this.selectedProduct === 'cyber_surplus';
  }

  surplusButtonIsDisabled() {
    const surplusIsNotAvailable = !this.isSurplusEnabled() || !this.stateIsEligibleForSurplus();
    // Only disable the surplus button if the risk is ineligible for Admitted
    return this.isQuoteEligibleForAdmitted() && surplusIsNotAvailable;
  }

  getNextButtonToolTipText(product: CyberProduct) {
    if (product === 'cyber_surplus' && this.isQuoteEligibleForAdmitted()) {
      if (!this.isSurplusEnabled()) {
        return SURPLUS_NOT_YET_AVAILABLE;
      } else if (!this.stateIsEligibleForSurplus()) {
        return SURPLUS_NOT_AVAILABLE_IN_STATE;
      }
    }

    const formStep = this.isQuickQuote
      ? getFormGroup(this.formService.form, CoalitionCyberFormStepPath.QUICK_QUOTE)
      : getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    const techEO = getControl(formStep, CoalitionCyberQuestion.HAS_TECH_EO);
    const address = getControl(formStep, CoalitionCyberQuestion.ADDRESS);
    const mgmtEO = getControl(
      formStep,
      CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY
    );

    let state;
    if (this.isQuickQuote) {
      state = this.insuredAccount.state;
    } else if (address) {
      state = address.get(CoalitionCyberSubmissionField.STATE)?.value;
    }
    if (state && ADMITTED_KNOCK_OUT_STATES.includes(state)) {
      return IneligibleForAdmittedReason.INVALID_STATE;
    }

    if (techEO && techEO.enabled && techEO.value === 'No') {
      return IneligibleForAdmittedReason.TECH_EO_REQUIRED;
    }

    if (mgmtEO && mgmtEO.enabled && mgmtEO.value === 'No') {
      return IneligibleForAdmittedReason.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY_REQUIRED;
    }

    const revenuePath = this.formService.controlNameToFormPath[
      CoalitionCyberQuestion.COMPANY_REVENUE
    ] as string;
    const revenue = getControl(this.formService.form, revenuePath);
    if (
      product === 'cyber_admitted' &&
      revenue &&
      parseMoney(revenue.value) >= ADMITTED_MAX_REVENUE
    ) {
      return ADMITTED_EXCEEDS_MAXIMUM_REVENUE;
    }

    const productIsDisabled =
      (product === 'cyber_admitted' && this.surplusWasSelected()) ||
      (product === 'cyber_surplus' && this.admittedWasSelected());

    return productIsDisabled
      ? 'The insurance market cannot be changed once the quote is started. To complete a quote in a different insurance market, please start a new quote from the accounts page.'
      : null;
  }

  calculateRetentionMinMax() {
    if (this.isQuickQuote) {
      // These fields are prefilled on quick quote submission
      return;
    }
    const rawFormData = this.formService.getValue();

    const limit =
      LIMIT_OPTIONS[
        rawFormData[CoalitionCyberFormStepPath.COVERAGE][
          CoalitionCyberQuestion.AGGREGATE_LIMIT
        ] as number
      ];
    const revenuePath = this.formService.controlNameToFormPath[
      CoalitionCyberQuestion.COMPANY_REVENUE
    ] as string;
    const idPath = this.formService.controlNameToFormPath[
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    ] as string;

    const revenue = getControl(this.formService.form, revenuePath).value;
    const { id } = getControl(this.formService.form, idPath).value;

    const oldRetentionMin = this.retentionMin;
    const oldRetentionMax = this.retentionMax;
    this.retentionMin = this.getRetentionMin(limit || 1000000, parseMoney(revenue), id);
    this.retentionMax = limit
      ? this.getRetentionMax(limit as number)
      : RETENTION_OPTIONS.length - 1;

    const retention = this.coverageFormGroup.get(this.retentionPath);
    if (retention) {
      const minMaxUpdated =
        oldRetentionMin !== this.retentionMin || oldRetentionMax !== this.retentionMax;
      // This is the relative value in between the min and max so the retention stays in relatively the same position
      // even when the min and max change.
      const distanceFromMin = Math.max(retention.value - oldRetentionMin, 0);
      const oldRange = oldRetentionMax - oldRetentionMin;
      const newRange = this.retentionMax - this.retentionMin;
      const relativeValue = minMaxUpdated
        ? Math.floor((distanceFromMin / oldRange) * newRange + this.retentionMin + 0.5)
        : retention.value;
      retention.patchValue(relativeValue, {
        emitEvent: false,
      });
    }
  }

  editDraftQuote() {
    if (this.currentStep.formPath === CoalitionCyberFormStepPath.COVERAGE) {
      this.isLoadingPremium = true;
      this.calculateRetentionMinMax();
    }

    // Do not submit edit request if the form is invalid
    if (!this.isSubmittable()) {
      return;
    }

    const { digitalCarrierProduct, formData, account, effectiveDate } = this.getDcpRequestParams();

    const editRequestParams: EditRequestParams = {
      dcp: digitalCarrierProduct,
      formData: formData,
      account: account,
      effectiveDate: effectiveDate,
      isEditing: true,
      quoteId: this.quoteId,
    };

    if (CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION in formData) {
      formData[CoalitionCyberQuestion.CC_CUSTOMER_COUNT] =
        formData[CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION].toString();
    }

    if (CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION in formData) {
      formData[CoalitionCyberQuestion.PII_PHI_COUNT] =
        formData[CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION].toString();
    }

    if (!this.duplicateRequestCheckService.shouldTriggerRequest(editRequestParams)) {
      this.sentryService.notify('Coalition Cyber: Edit quote tried to make duplicate request', {
        severity: 'warning',
        metaData: {
          requestParameters: editRequestParams,
          product: this.digitalCarrierProduct.product,
        },
      });
      this.isLoadingPremium = false;
      this.quoteIsReadyForIssue = true;
      return;
    }

    // We don't want to make an edit request while one is already being made
    // Flag `quoteFormUpdatedSinceLastEditRequest` to make an updated request when edit returns
    if (this.isMakingEditRequest) {
      this.quoteFormUpdatedSinceLastEditRequest = true;
      return;
    } else {
      this.isMakingEditRequest = true;
    }

    this.duplicateRequestCheckService.newRequestMade(editRequestParams);

    const editQuoteResponse$ = this.digitalCarrierService.submitQuote(
      digitalCarrierProduct,
      formData,
      account,
      effectiveDate,
      true,
      true,
      this.quoteId
    );

    this.sub.add(
      editQuoteResponse$.subscribe((response) => {
        this.isMakingEditRequest = false;
        if (isDraftQuoteResponse(response)) {
          this.duplicateRequestCheckService.setRequestSuccess(true);
          this.handleEditDraftResponse(response);
          this.checkShowDefaultedUnderwritingQuestionsTooltip();
          // if `quoteFormUpdatedSinceLastEditRequest` then the response from this request is out of date with the form and
          // should be sent again with the current state of the form
          if (this.quoteFormUpdatedSinceLastEditRequest) {
            this.quoteFormUpdatedSinceLastEditRequest = false;
            this.editDraftQuote();
            return;
          }
          if (response.details && response.details.limitRetentionCorrections) {
            const limitRetentionCorrections = response.details.limitRetentionCorrections;
            const retention = this.coverageFormGroup.get(this.retentionPath);
            const limit = this.coverageFormGroup.get(this.limitPath);
            if (retention && limitRetentionCorrections.default_retention) {
              retention.patchValue(
                RETENTION_OPTIONS.indexOf(limitRetentionCorrections.default_retention)
              );
            }
            if (limit && limitRetentionCorrections.aggregate_limit) {
              limit.patchValue(LIMIT_OPTIONS.indexOf(limitRetentionCorrections.aggregate_limit));
            }
            this.quoteErrorModalOpen = true;
            this.quoteError = COALITION_DEFAULT_RETENTION_ERROR;
            return;
          }
          if (this.isFinalStep()) {
            this.isLoadingPremium = false;
            this.quoteIsReadyForIssue = true;
            return;
          }
        } else {
          this.duplicateRequestCheckService.setRequestSuccess(false);
          this.sentryService.notify(
            'Coalition Cyber: Edit quote response did not have draft status',
            {
              severity: 'error',
              metaData: { response, product: this.digitalCarrierProduct.product },
            }
          );
          this.quoteIsReadyForIssue = false;
          this.quoteError = UNKNOWN_COALITION_ERROR;
          this.quoteErrorModalOpen = true;
          this.isLoadingPremium = false;
        }
      })
    );
  }

  handleCreateDraftResponse(response: DraftQuoteResponse) {
    this.latestQuoteResponse = response as CyberDraftQuoteResponse;
    this.quoteId = this.latestQuoteResponse.uuid;

    this.isLoadingNextStep = false;
  }

  handleEditDraftResponse(response: DraftQuoteResponse) {
    this.latestQuoteResponse = response as CyberDraftQuoteResponse;
    this.premium = response.premium || null;

    this.isLoadingNextStep = false;
    this.formService.userIsEditingFormSubject.next(false);
  }

  handleCoverageControlUpdates() {
    if (this.currentStep.formPath !== CoalitionCyberFormStepPath.COVERAGE) {
      return;
    }
    if (!this.isSubmittable()) {
      this.isLoadingPremium = false;
      // Trigger error validation
      this.clickForward();
      return;
    }
    this.editDraftQuote();
  }

  updateProduct(productCombination: CoalitionCyberProduct) {
    this.digitalCarrierProduct = productCombination;
    this.formService.updateProduct(productCombination.product);
  }

  updateQuestionEnablementFlags(industryData: CyberIndustryData) {
    const flagNames = _.values(CyberQuestionEnablementFlag);
    this.flags = _.pick(industryData, flagNames);
    this.formService.updateQuestionEnablementFlags(this.flags as CyberQuestionEnablementFlags);
  }

  handleIndustryIdControlUpdates(industryId: number) {
    this.isLoadingNextStep = true;

    this.sub.add(
      this.digitalCarrierService
        .getCoalitionCyberIndustry(industryId, this.digitalCarrierProduct.product)
        .subscribe((industryData) => {
          this.isLoadingNextStep = false;
          if (!industryData) {
            // TODO: We should surface this error to the user, since it will break flow functionality.
            this.sentryService.notify(
              'Coalition Cyber: Failed to retrieve industry data; cannot determine questions to enable',
              {
                severity: 'error',
                metaData: {
                  industryId,
                  product: this.digitalCarrierProduct.product,
                },
              }
            );
            return;
          }
          this.updateQuestionEnablementFlags(industryData);
        })
    );
  }

  getNextButtonText(productType: CyberProduct) {
    if (this.isLoadingNextStep || this.isSubmittingForm) {
      return this.isQuickQuote ? 'Submitting...' : 'Loading';
    }

    if (this.isQuickQuote) {
      return 'Submit';
    }

    if (productType === 'cyber_surplus') {
      return 'Continue as surplus';
    }

    if (this.isQuoteEligibleForAdmitted()) {
      return 'Continue as admitted';
    } else {
      return 'Ineligible for admitted';
    }
  }

  isQuoteEligibleForAdmitted() {
    // If the user is past the first step or has previously selected Admitted,
    // assume quote should be eligible for admitted.
    if (!this.isQuickQuote && (!this.isFirstStep() || this.selectedProduct === 'cyber_admitted')) {
      return true;
    }

    const formStep = this.isQuickQuote
      ? getFormGroup(this.formService.form, CoalitionCyberFormStepPath.QUICK_QUOTE)
      : getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    const revenue = formStep.get(CoalitionCyberQuestion.COMPANY_REVENUE);
    const techEO = formStep.get(CoalitionCyberQuestion.HAS_TECH_EO);
    const mgmtEO = getControl(
      formStep,
      CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY
    );

    let state;
    if (this.isQuickQuote) {
      state = this.insuredAccount.state;
    } else {
      const address = formStep.get(CoalitionCyberQuestion.ADDRESS);
      if (address) {
        state = address.get(CoalitionCyberSubmissionField.STATE)?.value;
      }
    }

    if (state && ADMITTED_KNOCK_OUT_STATES.includes(state)) {
      return false;
    }

    if (revenue && parseMoney(revenue.value) >= ADMITTED_MAX_REVENUE) {
      return false;
    }

    if (techEO && techEO.enabled && techEO.value === 'No') {
      return false;
    }

    if (mgmtEO && mgmtEO.enabled && mgmtEO.value === 'No') {
      return false;
    }

    return true;
  }

  stateIsEligibleForSurplus() {
    const formStep = this.isQuickQuote
      ? getFormGroup(this.formService.form, CoalitionCyberFormStepPath.QUICK_QUOTE)
      : getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    let state;
    if (this.isQuickQuote) {
      state = this.insuredAccount.state;
    } else {
      const address = formStep.get(CoalitionCyberQuestion.ADDRESS);
      if (address) {
        state = address.get(CoalitionCyberSubmissionField.STATE)?.value;
      }
    }

    // If a state has not been selected, assume it is eligible
    if (!state) {
      return true;
    }

    return this.statesEnabledForCyberSurplus.includes(state);
  }

  shouldCreateDraftQuote() {
    return this.currentStep.formPath === CoalitionCyberFormStepPath.POLICY_INFO && !this.quoteId;
  }

  getPremium() {
    if (this.premium === null || this.isLoadingPremium) {
      return '--';
    }
    return numberToMoneyString(this.premium);
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    const address = {
      addressLine1: this.model.addressLine1,
      addressLine2: this.model.addressLine2,
      city: this.model.city,
      state: this.model.state,
      zip: this.model.zip,
    };

    // Note: class_code, which is vendor specific, is not available on "Quote Started" event
    let classCode;
    if (eventName === 'Quote Attempted') {
      const companyIndustryIdPath = this.formService.controlNameToFormPath[
        CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
      ] as string;
      classCode = getControl(this.formService.form, companyIndustryIdPath).value;
    }

    this.segmentService.track({
      event: eventName,
      properties: {
        product: this.selectedProduct,
        carrier: 'coalition',
        naics_code: this.model.naicsCode,
        class_code: classCode,
        primary_state: this.model.state,
        insured_address: address,
        insured_email: this.model.emailAddress,
        business_name: this.model.companyName.toString(),
      },
    });
  }

  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.isSubmittable()) {
      // Trigger error validation
      this.clickForward();
      return;
    }

    this.isSubmittingForm = true;
    this.sendSegmentEvent('Quote Attempted');

    const { digitalCarrierProduct, formData, account, effectiveDate, quoteId } =
      this.getDcpRequestParams();

    let editQuoteResponse$: Observable<QuoteResponse>;

    if (this.quoteIsReadyForIssue && this.latestQuoteResponse) {
      editQuoteResponse$ = observableOf(this.latestQuoteResponse);
    } else if (this.isQuickQuote) {
      editQuoteResponse$ = this.createDraftQuote$();
    } else {
      /**
       * If we are in the regular quote flow and the most recent edit has failed
       * we should attempt it one more time before attempting to issue.
       * Additionally, when we are in quick quote mode, then we must create a
       * quote before we attempt to submit it.
       */

      // Note/TODO: With this implementation, we are issuing the quote without the
      // user seeing the resulting premium of their latest changes. We may
      // need more sophisticated handling here that ensures the edit is successful
      // before the user can submit the form and issue the quote.
      editQuoteResponse$ = this.digitalCarrierService.submitQuote(
        digitalCarrierProduct,
        formData,
        account,
        effectiveDate,
        true,
        true,
        quoteId
      );
    }

    this.sub.add(
      editQuoteResponse$
        .pipe(
          switchMap((editQuoteResponse: QuoteResponse) => {
            if (isDraftQuoteResponse(editQuoteResponse)) {
              this.quoteIsReadyForIssue = true;
              this.handleEditDraftResponse(editQuoteResponse);
              if (editQuoteResponse?.details?.limitRetentionCorrections?.default_retention) {
                formData[CoalitionCyberQuestion.DEFAULT_RETENTION] =
                  editQuoteResponse.details.limitRetentionCorrections.default_retention;
              }
              if (editQuoteResponse?.details?.limitRetentionCorrections?.aggregate_limit) {
                formData[CoalitionCyberQuestion.AGGREGATE_LIMIT] =
                  editQuoteResponse.details.limitRetentionCorrections.aggregate_limit;
              }
              return combineLatest(
                this.digitalCarrierService.issueQuote(
                  digitalCarrierProduct,
                  formData,
                  account,
                  effectiveDate,
                  editQuoteResponse.uuid
                ),
                this.bopParentId ? this.cloneBop() : observableOf(null)
              );
            } else {
              return observableOf([null, null]);
            }
          }),
          catchError((errorResponse) => {
            this.sentryService.notify(
              'Coalition Cyber: Error attempting edit attempt on form submission',
              {
                severity: 'error',
                metaData: {
                  response: errorResponse,
                  product: this.digitalCarrierProduct.product,
                },
              }
            );

            return observableOf([null, null]);
          })
        )
        .subscribe(
          ([issueQuoteResponse, bopQuote]: [
            HttpResponse<QuoteResponse> | null,
            FrontendQuote | null
          ]) => {
            if (!this.bopQuoteUuid && bopQuote) {
              this.bopQuoteUuid = bopQuote.uuid;
            }

            if (!issueQuoteResponse?.body || isQuoteErrorResponse(issueQuoteResponse.body)) {
              this.isSubmittingForm = false;
              this.quoteError = UNKNOWN_COALITION_ERROR;

              this.quoteErrorModalOpen = true;
              this.sentryService.notify('Coalition Cyber: Error response from issue request', {
                severity: 'error',
                metaData: {
                  response: issueQuoteResponse,
                  product: this.digitalCarrierProduct.product,
                },
              });
            } else if (this.bopQuoteUuid && isQuotedQuoteResponse(issueQuoteResponse.body)) {
              this.bundleService
                .createBundle(this.accountId, this.bopQuoteUuid, issueQuoteResponse.body.uuid)
                .subscribe((bundleResponse) => {
                  if (bundleResponse) {
                    this.router.navigate([
                      'accounts',
                      this.accountId,
                      'bundle',
                      bundleResponse.bundleUuid,
                    ]);
                  }
                });
            } else if (issueQuoteResponse?.status === 202) {
              this.isSubmittingForm = false;
              this.quoteError = TIMEOUT_ERROR;
              this.quoteErrorModalOpen = true;
            } else {
              this.isSubmittingForm = false;
              this.router.navigate([
                '/accounts',
                this.accountId,
                'quotes',
                issueQuoteResponse.body.uuid,
              ]);
            }
          }
        )
    );
  }

  cloneBop(): Observable<FrontendQuote | null> {
    return this.bopQuoteService.getTranslatedQuoteV2(this.bopParentId).pipe(
      switchMap((bopQuotePayload: BopQuotePayload) => {
        const isoStringEffectiveDate = this.formService.get('policyInfo.effective_date')
          ?.value as string;
        const effectiveDate = moment.utc(isoStringEffectiveDate);
        const policy = new BopPolicy(
          bopQuotePayload,
          effectiveDate.toDate() || moment().toDate()
        ) as BopPolicy;

        this.amplitudeService.track({
          eventName: 'cyber_flow_clone_bop_for_bundle_edit',
          detail: this.accountId,
        });
        return this.requestQuoteService.requestV3Quote({
          accountId: this.accountId,
          excessUwQuestionsHaveValidAnswers: true,
          parentQuoteId: this.bopParentId,
          policy,
          quoteGameEnabled: false,
        });
      }),
      switchMap((bopResponse: BopPricedQuote | ErrorResponse) => {
        if (
          !this.bopQuoteUuid &&
          bopResponse &&
          !Object.prototype.hasOwnProperty.call(bopResponse, 'error')
        ) {
          const pricedQuote = bopResponse as BopPricedQuote;
          return this.digitalCarrierService.getQuoteDetails(pricedQuote.id, 'pasId');
        }
        return observableOf(null);
      }),
      catchError((error) => {
        this.sentryService.notify('Error cloning bop quote for bundle edit flow', {
          severity: 'warning',
          metaData: {
            accountId: this.accountId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  clearDomain() {
    const policyInfo = getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    const domains = getControl(policyInfo, CoalitionCyberQuestion.DOMAIN_NAMES);
    domains.patchValue('');
  }

  onCloseErrorModal($event: { close: boolean; retry: boolean }) {
    if (!$event.close && !$event.retry) {
      this.router.navigate(['/accounts', this.accountId]);
      return;
    }

    if ($event.close) {
      if (this.hasBrokerOfRecordError) {
        this.router.navigate([`${BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL}`]);
      }

      if (this.hasBlockListedDomainError) {
        this.quoteErrorModalOpen = false;
        this.clearDomain();
      }

      if (this.hasDuplicateBrokerError) {
        this.cyberDuplicateBrokerZendeskTicketService
          .createTicket(this.digitalCarrierProduct.product, this.accountId)
          .subscribe((_createTicketResponse) => {
            this.router.navigate(['/accounts', this.accountId]);
            return;
          });
      }

      if (this.hasInvalidAgencyDataError) {
        this.router.navigate(['/accounts', this.accountId]);
        return;
      }

      this.quoteErrorModalOpen = false;
    }

    if ($event.retry) {
      this.loadNextStep();
    }
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields.';
  }

  fillInHappyPath() {
    this.formService.fillInHappyPath();
  }

  getRetentionMin(limit: number, revenue: number, id: number) {
    let limits, revenues, graph;
    if (GOVT_RISK_IDS.includes(id)) {
      limits = MIMIMUM_SIR_LIMITS_GOVT;
      revenues = MIMIMUM_SIR_REVENUES_GOVT;
      graph = MINIMUM_SIR_GRAPH_GOVT;
    } else {
      limits = MIMIMUM_SIR_LIMITS;
      revenues = MIMIMUM_SIR_REVENUES;
      graph = MINIMUM_SIR_GRAPH;
    }

    // The highest limit option that is ≤ aggregate limit input
    const limitIndex = _.findLastIndex(limits, (lowerBound) => {
      return lowerBound <= limit;
    });

    // The highest revenue option that is ≤ revenue input
    const revenueIndex = _.findLastIndex(revenues, (lowerBound) => {
      return lowerBound <= revenue;
    });
    return RETENTION_OPTIONS.indexOf(graph[revenueIndex][limitIndex]);
  }

  getRetentionMax(limit: number) {
    const index = _.findLastIndex(
      RETENTION_OPTIONS,
      (lowerBound) => lowerBound <= limit / RETENTION_LIMIT_DIVISOR
    );
    return index !== -1 ? index : RETENTION_OPTIONS.length - 1;
  }

  limitDisplayValueCalculator(index: number) {
    return LIMIT_OPTIONS[index];
  }

  retentionDisplayValueCalculator(index: number) {
    return RETENTION_OPTIONS[index];
  }

  isSurplusEnabled() {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource: 'coalition',
      product: 'cyber_surplus',
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    // Cyber will never only allow PARTIAL class code selection
    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    return false;
  }

  checkShowDefaultedUnderwritingQuestionsTooltip() {
    const policyInfo = getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO);
    const revenue = getControl(policyInfo, CoalitionCyberQuestion.COMPANY_REVENUE);

    // Admitted quotes with less than 1m revenue use common defaulted underwriting answers
    // to help users not be overwhelmed with questions they might not be familiar with.
    if (parseMoney(revenue.value) < 1000000 && this.selectedProduct === 'cyber_admitted') {
      this.showDefaultedUnderwritingQuestionsTooltip = true;
      if (this.netNewQuote) {
        this.setFormData(CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M as FormDslData);
      }
    }
  }

  prefillEffectiveDate(effectiveDate = moment().format(US_DATE_MASK)) {
    const dateControl = (
      this.isQuickQuote
        ? getFormGroup(this.formService.form, CoalitionCyberFormStepPath.QUICK_QUOTE)
        : getFormGroup(this.formService.form, CoalitionCyberFormStepPath.POLICY_INFO)
    ).get(CoalitionCyberQuestion.EFFECTIVE_DATE);
    if (dateControl) {
      dateControl.patchValue(effectiveDate);
    }
  }

  setEffectiveDateFromExpiringPolicy(expirationDate: string) {
    const formattedExpirationDate = moment.utc(expirationDate).format(US_DATE_MASK);
    this.formService.setEffectiveDateAsReadonly();
    this.prefillEffectiveDate(formattedExpirationDate);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
