import { catchError } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { V3_ELIGIBILITY_CHECK_API, V3_PREFILL_DECLINE_REASONS } from 'app/constants';
import { HttpClient } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class EligibilityService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getPrefillDeclineReasons(accountId: string) {
    return this.http
      .get<{ declineReasons: string[] }>(`${V3_PREFILL_DECLINE_REASONS}/${accountId}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to fetch prefill decline reasons', {
            severity: 'error',
            metaData: {
              accountId,
              underlyingErrorMessage: error && error.message,
            },
          });
          // We log error above and return an empty list so that BOP can still be quoted.
          return observableOf({
            declineReasons: [],
          });
        })
      );
  }

  fetchEligibilityCheck(
    payload: BopEligibilityCheckRequest
  ): Observable<BopEligibilityCheckResponse> {
    const defaultAcceptRisk = {
      locationEligibilities: [],
      policyEligibility: {
        acceptRisk: true,
        riskDeclineReason: [],
      },
    };
    // If there are no locations to check, we do not have to request eligibility.
    if (payload.bopLocations.length === 0) {
      return observableOf(defaultAcceptRisk);
    }

    return this.http.post<BopEligibilityCheckResponse>(V3_ELIGIBILITY_CHECK_API, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to fetch BOP eligibility check data', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
          },
        });
        // We log error above and allow the quote to proceed.
        return observableOf(defaultAcceptRisk);
      })
    );
  }
}
