import * as moment from 'moment';
import { BopAdditionalInsuredBusiness } from './bop-additional-insured-business';
import { UW_QUESTIONS, UWQuestion } from 'app/features/attune-bop/models/constants';
import { US_DATE_MASK } from 'app/constants';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { effectiveDateToPeriodDate } from 'app/features/attune-bop/models/date-helpers';

interface ExcessCommercialAutoCoverageVehicleCounts {
  BusesOver20PassengersCount: number;
  CementMixersCount: number;
  ExHvyTruckAndTractorLHCount: number;
  ExHvyTruckAndTractorSHCount: number;
  HeavyTrucksCount: number;
  LightTrucksCount: number;
  MediumTrucksCount: number;
  PrivatePassengerVehicleCount: number;
}

interface ExcessCommercialAutoCoverageVehicleExposure {
  CommodityIIIORIVHauling: boolean;
  GasHaulHzdWasteRedLblMat: boolean;
  MoreThan10HtlMtlParkVan: boolean;
  MoreThan15PassengerCourtseyVan: boolean;
  PoliceVehFireTrkAmbulance: boolean;
  RapidDevliveryOperations: boolean;
  SchoolBusOrVan: boolean;
}

interface ExcessCommercialAutoCoverageUninsuredStates {
  hasCommercialAutoInFL: boolean;
  hasCommercialAutoInLA: boolean;
  hasCommercialAutoInNH: boolean;
  hasCommercialAutoInVT: boolean;
  hasCommercialAutoInWV: boolean;
}

export interface SubgrogationWaiverName {
  orgOrPersonName: string;
}

class BopQuoteBase<T extends string | number> {
  additionalCoverages: {
    acceptCertifiedActsOfTerrorismCoverage: boolean;
    additionalInsuredBusinesses: Array<BopAdditionalInsuredBusiness>;
    cyberLiabilityCoverage: {
      aggregateLimit: number | null;
      firstPartyLimit: number | null;
      deductible: number | null; // Calculated for BOPv2 & only relevant in BOPv1
      optedIn: boolean;
      selectedCyberCoverage: string;
      retroactiveDate: string; // Only for BOPv1
    };
    employmentRelatedPracticesLiabilityCoverage: {
      optedIn: boolean;
      // BOPv1 Controls
      aggregateLimit: T; // Used for BOPv1 only
      deductible: T; // Used for BOPv1 only
      eachEmploymentWrongfulActLimit: T; // Used for BOPv1 only
      retroactiveDate: string; // Used for BOPv1 only

      // BOPv2 Controls
      aggregateLimitV2: T; // Aggregate Limit, all states EXCEPT AR, LA, MT, NM, VT
      defenseLimitV2: T; // Split limit, used in AR, LA, MT, NM, VT
      deductibleV2: T;
      perLocationPartTimeEmployees: number[];
    };
    hasAdditionalInsuredBusinesses: boolean;
    hasWaiversOfSubrogation: boolean;
    limitForEmployeeDishonesty: T;
    waiversOfSubrogation: Array<SubgrogationWaiverName>;
  };
  quoteName?: string;
  excessLiability: {
    excessCommercialAutoCombinedLimit?: number;
    excessCommercialAutoCoverageIsScheduled: boolean;
    excessCommercialAutoCoverageStates?: ExcessCommercialAutoCoverageUninsuredStates;
    excessCommercialAutoCoverageVehicleCounts?: ExcessCommercialAutoCoverageVehicleCounts;
    excessCommercialAutoCoverageVehicleExposure?: ExcessCommercialAutoCoverageVehicleExposure;
    excessLiabilityCoverageIsScheduled: boolean;
    excessLiabilityLimit: number | null;
    excessLiabilityPerAccidentCoverage?: string;
    excessLiabilityPerDiseaseCoverage?: string;
    excessLiabilityPerPolicyCoverage?: string;
    excessAnnualRevenue: string | null;
    excessCommercialAutoUnderlyingPremium: string | null;
    excessEmployersUnderlyingPremium: string | null;
    uwQuestions?: { [k: string]: boolean };
  };
  excessLiabilityOptIn?: boolean;
  guidelines: boolean;
  guidewireId?: string;
  liabilityCoverages: {
    acceptHiredNonOwnedAutoCoverage: boolean;
    acceptSnowPlowCompletedOpsCoverage: boolean;
    installationLimit: T;
    barberShopsAndHairSalonsProfessionalLiability: boolean;
    barberShopsNumberOfOperators: T;
    beautySalonsDescriptionOfAdditionalServices: string;
    beautySalonsNumberOfOperators: T;
    beautySalonsProfessionalLiability: boolean;
    damageToPremises: T;
    eachCoveredJobSiteLimit: T;
    eligibilityUWQuestions?: QSEligibilityUWQuestions;
    employeeBenefitsLiabilityCoverage: {
      aggregateLimit: number | null;
      deductible: number | null;
      eachEmployeeLimit: number | null;
      optedIn: boolean;
      retroactiveDate: string;
    };
    employeeHandbook: boolean | null;
    equipmentLeftInVehicle: boolean | null;
    funeralDirectorsProfessionalLiability: boolean;
    hnoaNumberOfDrivers: number;
    limitPerOccurrenceOfLiabilityAndMedicalExpenses: T;
    limitPerPersonOfMedicalExpenses: T;
    liquorLiability: {
      aggregateLimit: number;
      eachCommonCauseLimit: number;
      liquorLicenseNumber: string;
      optedIn: boolean;
    };
    numberOfVeterinarians: T;
    opticalAndHearingAidEstablishmentsProfessionalLiability: boolean;
    opticalAndHearingAidSales: T;
    printersErrorsAndOmissionsProfessionalLiability: boolean;
    printersErrorsAndOmissionsSalesOrPayroll: T;
    propertyInTransitLimit: string;
    stopGap: boolean | null;
    stressTestsWaterLines: boolean | null;
    janitorialServices: boolean | null;
    toolsBlanketLimit: T;
    toolsPerItemLimit: T;
    veterinariansOnlyTreatHousePets: boolean | null;
    veterinariansProfessionalLiability: boolean;
  };
  locations: Array<BopLocation>;
  policyInfo: {
    baseState: string;
    bopVersion: BopVersion;
    effectiveDate?: string;
    yearsInBusiness?: T;
    numberOfLocations?: number;
    organizationType?: string;
    irpmVersion?: string;
    ratingModelVersion?: string;
    agentName?: string;
    agentLicenseNumber?: string;
    annualRevenue?: string;
    meToo?: boolean;
  };
  uwQuestions?: { [k: string]: boolean };
  uuid?: string;
}

export type BopQuoteFormValue = BopQuoteBase<string>;
export type BopQuotePayload = BopQuoteBase<number>;

export class BopPolicy extends BopQuoteBase<string> {
  startDate: Date;
  tsRequestId: string;
  exclusionsToAdd: string[];
  uwQuestions: { [k: string]: boolean } = {};

  constructor(fields?: BopQuoteFormValue | {}, startDate?: Date, tsRequestId?: string) {
    super();
    if (fields) {
      Object.assign(this, fields);
      this.exclusionsToAdd = this.buildExclusions(this.uwQuestions);
    }

    let now;
    if (startDate) {
      this.startDate = startDate;
    } else {
      now = moment().startOf('day').add('12', 'hours');
      this.startDate = effectiveDateToPeriodDate(now.format(US_DATE_MASK));
    }

    this.tsRequestId = tsRequestId || uuidv4();
  }

  periodStart() {
    return this.startDate;
  }

  periodEnd() {
    return moment(this.periodStart()).add(1, 'year').toDate();
  }

  cyberLiabilityRetroactiveMoment(): moment.Moment {
    return this.parsedMomentOrNow(this.additionalCoverages.cyberLiabilityCoverage.retroactiveDate);
  }

  employeeBenefitsLiabilityRetroactiveMoment(): moment.Moment {
    return this.parsedMomentOrNow(
      this.liabilityCoverages.employeeBenefitsLiabilityCoverage.retroactiveDate
    );
  }

  // Parse a given string into a moment using the standard BOPv2 form date
  // format.  If the parsing fails because the string is invalid, return
  // moment() i.e. now.  IMPORTANT: The default of now makes sense for the
  // above use cases, but it is not necessarily always the correct default.
  // Check the business logic for your situation before using this default.
  parsedMomentOrNow(dateString: string): moment.Moment {
    const parsedMoment = moment(dateString, US_DATE_MASK);
    if (parsedMoment.isValid()) {
      return parsedMoment;
    } else {
      console.warn(`Invalid date: ${dateString}`);
      return moment();
    }
  }

  parseInt(maskedInt: string): number {
    return parseInt(maskedInt.replace(/\D+/g, ''), 10);
  }

  buildExclusions(uwQuestions: { [k: string]: boolean }): string[] {
    return _.flatten(
      Object.keys(uwQuestions).map((questionID) => {
        const question: UWQuestion = UW_QUESTIONS[questionID];
        if (!_.isEmpty(question.exclusionsToAddIfYes) && uwQuestions[questionID] === true) {
          return <string[]>question.exclusionsToAddIfYes;
        }
        return [];
      }) || []
    );
  }
}
