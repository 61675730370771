import { Directive, Input } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';
@Directive({
  selector: '[appMoneyMask]',
})
export class MoneyMaskDirective extends BaseMaskDirective {
  @Input() appMoneyMask: 'WITHOUT_DECIMAL' | 'WITH_DECIMAL';

  getMask(value: string | null) {
    if (value === '$') {
      return value;
    }

    let numberVal = null;
    if (this.appMoneyMask === 'WITHOUT_DECIMAL') {
      numberVal = this.getNumberValueFormattedWithoutDecimals(value);
    } else if (this.appMoneyMask === 'WITH_DECIMAL') {
      numberVal = this.getNumberValueFormattedWithDecimals(value);
    }

    if (numberVal !== null) {
      return '$' + numberVal;
    }

    return '';
  }
}
