import { merge, pick } from 'lodash';

import { HISCOX_PRODUCTS } from './hiscox-types';
import {
  GL_BUSINESS_CLASSES_V4,
  HISCOX_BUSINESS_CLASS_CODES_V4,
  HiscoxGlFormDataFieldV4,
} from './gl-constants';
import { PL_BUSINESS_CLASS_CODES_V4, PL_BUSINESS_CLASSES_V4 } from './pl-constants';

const allStatesExceptionsGl = () =>
  pick(GL_BUSINESS_CLASSES_V4, [
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE,
    HISCOX_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    HISCOX_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_ANALYSIS_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    HISCOX_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING,
    HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_SANDBLASTING_BUILDING_EXTERIOR,
    HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_GENERAL_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_CONVENIENCE_FOOD_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_LIQUOR_STORES_PACKAGE,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_SUPERSTORES_IE_FOOD_AND_GENERAL_MERCHANDISE,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CONVENIENCE_FOOD_WITH_GASOLINE_STATIONS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOTIVE_TIRE_DEALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AIRCRAFT_DEALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CAMPER_DEALERS_RECREATIONAL,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_USED_ONLY,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_NEW_ONLY_OR_NEW_AND_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_GASOLINE_STATIONS_WITHOUT_CONVENIENCE_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_MARKETING_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INVESTMENT_ADVICE_CONSULTING_SERVICES_CUSTOMIZED_FEES_PAID_BY_CLIENT,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_JURY_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_LANDSCAPE_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_DECORATING_CONSULTING_SERVICES_INTERIOR,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_POLITICAL_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EDUCATIONAL_CONSULTANTS,
    HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_NANOBIOTECHNOLOGIES_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_LABORATORIES,
    HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_BOTANY_COMPUTER_ENGINEERING_FOOD_LIFE_INDUSTRIAL_LABORATORIES_OR_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_REMOVAL_HIGHWAY,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_BUSINESS_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_PUBLIC_RELATIONS_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS,
    HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_EQUIPMENT_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_PERIPHERAL_EQUIPMENT_RENTAL_OR_LEASING,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_AUTOMOTIVE_AUDIO_EQUIPMENT_STORES,
  ]);

const allStatesExceptionsPl = () =>
  pick(PL_BUSINESS_CLASSES_V4, [
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INVESTMENT_ADVICE_CONSULTING_SERVICES_CUSTOMIZED_FEES_PAID_BY_CLIENT,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_JURY_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_LANDSCAPE_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_POLITICAL_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EDUCATIONAL_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_NANOBIOTECHNOLOGIES_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_LABORATORIES,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_ANALYSIS_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_MARKETING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_BUSINESS_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_PUBLIC_RELATIONS_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_EQUIPMENT_STORES,
    PL_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_PERIPHERAL_EQUIPMENT_RENTAL_OR_LEASING,
  ]);

interface CobException {
  [product: string]: { [cob: string]: string };
}

export const COB_EXCEPTIONS_BY_STATE_V4: {
  [state: string]: CobException;
} = {
  AZ: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  AL: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  AK: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  AR: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  CA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES,
      ]),
      allStatesExceptionsPl()
    ),
  },
  CO: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  CT: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  DC: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  DE: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  FL: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  GA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  HI: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  ID: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  IL: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  IN: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
        PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
        PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.PUBLIC_RELATIONS_PUBLIC_RELATIONS_AGENCIES,
      ]),
      allStatesExceptionsPl()
    ),
  },
  IA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  KS: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  KY: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  LA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  ME: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MD: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MI: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MN: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MS: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  MO: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  MT: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  NE: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  NV: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
      ]),
      allStatesExceptionsPl()
    ),
  },
  NH: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  NJ: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  NM: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  NY: {
    [HISCOX_PRODUCTS.gl]: merge(
      {},
      pick(HISCOX_BUSINESS_CLASS_CODES_V4, [
        HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_AUTOMOTIVE_AUDIO_EQUIPMENT_STORES,
        HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_RADIO_EXCEPT_TWOWAY_RADIO_REPAIR_AND_MAINTENANCE_SERVICES_WITHOUT_RETAILING_NEW_RADIOS,
      ]),
      {
        // small contractors - from Mustang UI Development Guidelines Quote v4 - v1.5.xlsx
        [HISCOX_BUSINESS_CLASS_CODES_V4.AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.CABLING_INSTALLATION_COMPUTER_AND_NETWORK_CABLE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .CABLING_INSTALLATION_COMPUTER_AND_NETWORK_CABLE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_CABLE_TELEVISION_HOOKUP_CONTRACTORS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_CABLE_TELEVISION_HOOKUP_CONTRACTORS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_SATELLITE_DISH_HOUSEHOLDTYPE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_SATELLITE_DISH_HOUSEHOLDTYPE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_THEATER_INSTALLATION_HOME_THEATER_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.HOME_THEATER_INSTALLATION_HOME_THEATER_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CONCRETE_FINISHING]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CONCRETE_FINISHING
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.LOCKSMITHS_LOCK_REKEYING_SERVICES]:
          GL_BUSINESS_CLASSES_V4[HISCOX_BUSINESS_CLASS_CODES_V4.LOCKSMITHS_LOCK_REKEYING_SERVICES],
        [HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_SECURITY_AND_FIRE_SYSTEM_INSTALLATION_ONLY]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .SECURITY_SYSTEM_INSTALLATION_SECURITY_AND_FIRE_SYSTEM_INSTALLATION_ONLY
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_BURGLAR_ALARM_SALES_COMBINED_WITH_INSTALLATION_REPAIR_OR_MONITORING_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .SECURITY_SYSTEM_INSTALLATION_BURGLAR_ALARM_SALES_COMBINED_WITH_INSTALLATION_REPAIR_OR_MONITORING_SERVICES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_SOUND_EQUIPMENT_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_SOUND_EQUIPMENT_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS
          ],
        [HISCOX_BUSINESS_CLASS_CODES_V4.WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES]:
          GL_BUSINESS_CLASSES_V4[
            HISCOX_BUSINESS_CLASS_CODES_V4
              .WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES
          ],
      }
    ),
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL,
        PL_BUSINESS_CLASS_CODES_V4.MEDICAL_BILLING_MEDICAL_CODING_SERVICES_COMBINED_WITH_ACCOUNTING_SERVICES_EXCEPT_CPA_SERVICES,
      ]),
      allStatesExceptionsPl()
    ),
  },
  NC: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  ND: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  OH: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  OK: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  OR: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  PA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  RI: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  SC: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  SD: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  TN: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
  TX: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
        PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
      ]),
      allStatesExceptionsPl()
    ),
  },
  UT: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  VT: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
        PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
        PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
        PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
        PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
        PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
      ]),
      allStatesExceptionsPl()
    ),
  },
  VA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  WA: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  WV: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  WI: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: {
      ...allStatesExceptionsPl(),
    },
  },
  WY: {
    [HISCOX_PRODUCTS.gl]: {
      ...allStatesExceptionsGl(),
    },
    [HISCOX_PRODUCTS.pl]: merge(
      {},
      pick(PL_BUSINESS_CLASSES_V4, [
        // DOC lists `Property management`
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
        PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
        PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
      ]),
      allStatesExceptionsPl()
    ),
  },
};

export enum HiscoxSharedDataField {
  AGENTINFO_AGENTID = 'AgentInfo_AgentID',
  AGENTINFO_AGENCYNAME = 'AgentInfo_AgencyName',
  AGENTINFO_PERSON_NAME_FIRSTNAME = 'AgentInfo_Person_Name_FirstName',
  AGENTINFO_PERSON_NAME_LASTNAME = 'AgentInfo_Person_Name_LastName',
  AGENTINFO_AGENTREPRESENTATIVE = 'AgentInfo_AgentRepresentative',
  AGENTINFO_AGENTREGIONALOFFICE = 'AgentInfo_AgentRegionalOffice',
  AGENTINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER = 'AgentInfo_Person_CommunicationsInfo_PhoneInfo_PhoneNumber',
  AGENTINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONEEXTENSION = 'AgentInfo_Person_CommunicationsInfo_PhoneInfo_PhoneExtension',
  AGENTINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR = 'AgentInfo_Person_CommunicationsInfo_EmailInfo_EmailAddr',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_1 = 'AgentInfo_RetailAgentCustom_AgentCustom_1',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_2 = 'AgentInfo_RetailAgentCustom_AgentCustom_2',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_3 = 'AgentInfo_RetailAgentCustom_AgentCustom_3',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_4 = 'AgentInfo_RetailAgentCustom_AgentCustom_4',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_5 = 'AgentInfo_RetailAgentCustom_AgentCustom_5',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_6 = 'AgentInfo_RetailAgentCustom_AgentCustom_6',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_7 = 'AgentInfo_RetailAgentCustom_AgentCustom_7',
  AGENTINFO_RETAILAGENTCUSTOM_AGENTCUSTOM_8 = 'AgentInfo_RetailAgentCustom_AgentCustom_8',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1 = 'BusinessInfo_MailingAddress_AddrInfo_Addr1',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2 = 'BusinessInfo_MailingAddress_AddrInfo_Addr2',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY = 'BusinessInfo_MailingAddress_AddrInfo_City',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD = 'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE = 'BusinessInfo_MailingAddress_AddrInfo_PostalCode',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY = 'BusinessInfo_MailingAddress_AddrInfo_County',
  PRODUCERINFO_EMAILINFO_EMAILADDR = 'ProducerInfo_EmailInfo_EmailAddr',
  PRODUCERINFO_PRODUCERCLIENT = 'ProducerInfo_ProducerClient',
  PRODUCERINFO_PARTNERUNIQUEID = 'ProducerInfo_PartnerUniqueID',
}

export enum HiscoxAcknowledgementsQuestionsV4 {
  ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED,
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP,
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS,
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION,
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE,
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY,
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY,
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE,
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU = HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU,
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES = HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES,
}

export const HISCOX_QUESTIONS_V4: Record<HiscoxAcknowledgementsQuestionsV4, { labelText: string }> =
  {
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED]: {
      labelText:
        'As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage.',
    },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP]: {
      labelText:
        'The business is not controlled or owned by any other firm, corporation, or entity.',
    },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS]: {
      labelText:
        'For the entire period of time that the business owner has owned and controlled the business, they have not sold, purchased or acquired, discontinued, merged into or consolidated with another business.',
    },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION]:
      {
        labelText: 'What action occurred?',
      },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE]:
      {
        labelText: 'When did the action take place?',
      },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY]:
      {
        labelText: 'Why did this action take place?',
      },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY]:
      {
        labelText: 'If applicable, what is the name of other entity?',
      },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE]: {
      labelText: 'The business has never had any commercial insurance cancelled or rescinded.',
    },
    [HiscoxAcknowledgementsQuestionsV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU]: {
      labelText:
        "Based upon the business owner's knowledge and the knowledge of the business' current and past partners, officers, directors and employees, during the last five years a third party has never made a claim against the business and the business owner does not know of any reason why someone may make a claim.",
    },
    [HiscoxAcknowledgementsQuestionsV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES]:
      {
        labelText: 'The business does not conduct any of the following activities:',
      },
  };

// Value used for option in questions where answering 'no' will mean we can remove the answer from the payload sent to SQ
export const REMOVE_FROM_PAYLOAD = 'no';

export const SMALL_CONTRACTOR_WITH_1M_LOI_IN_ALL_STATES_NAMES: string[] = [
  'AirConditioningSystemsInstallationAndRepair',
  'ApplianceAndAccessoriesInstallationAndRepair',
  'CarpentryInteriorOnly',
  'DrivewayOrSidewalkPavingRepaving',
  'DrywallOrWallboardInstallationAndRepair',
  'HandypersonNoRoofWork',
  'HeatingAndAirConditioningInstallationAndRepairNoLiquefiedPetroleumGasLpg',
  'MasonryWork',
  'PlasteringOrStuccoWork',
  'PlumbingResidentialOrDomestic',
  'PlumbingCommercialOrIndustrial',
  'FenceInstallationAndRepair',
];
