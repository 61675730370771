<div class="policy-pane-content">
  <div class="policy-pane-nav">
    <a class="button button__nav button__no-margin" [routerLink]="['../../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>

    <ng-container *ngIf="quoteDetails">
      <div class="policy-pane-nav-buttons" *ngIf="!shouldDisplayRequoteButton && !bindBlockStatus">
        <!-- Archive button -->
        <button
          class="button button__small button__secondary button__no-margin js-quote-archive-button"
          (click)="showArchivingConfirmationPanel()"
          (keyup.enter)="showArchivingConfirmationPanel()"
        >
          Archive
        </button>

        <!-- Edit quote button -->
        <button
          class="button button__small button__secondary button__no-margin js-go-to-edit"
          routerLink="edit"
        >
          Edit
        </button>

        <!-- Bind button -->
        <button
          *ngIf="quoteDetails.bindable"
          class="button button__small button__primary button__no-margin js-go-to-bind"
          (click)="continueToBind()"
          [ngClass]="{ button__discouraged: nonBindingRole }"
        >
          Continue to bind
        </button>
      </div>

      <app-dialog-box
        *ngIf="archivingConfirmationPanelShowing"
        type="primary"
        header="Are you sure?"
        class="
          js-dialog-text-with-button
          dialog-text__margin-top dialog-text__full-width
          policy-pane-dialog
        "
      >
        This action cannot be undone.
        <div class="dialog-text-links">
          <a
            (click)="archiveQuote()"
            (keyup.enter)="archiveQuote()"
            [ngClass]="{ 'link-disabled': archiving }"
            class="js-quote-archive-confirm-button dialog-text-link"
          >
            {{ archiving ? 'Archiving' : 'Yes, archive' }}
          </a>
        </div>
      </app-dialog-box>
    </ng-container>
  </div>

  <!-- Requote when account details change -->
  <ng-container *ngIf="shouldDisplayRequoteButton">
    <app-dialog-box
      *ngIf="!requoteError"
      type="primary"
      header="Requote required"
      class="dialog-text__full-width"
    >
      The account details have been changed. Click "Requote" to get a bindable quote.
      <div class="dialog-text-links">
        <a
          (click)="requote()"
          (keyup.enter)="requote()"
          [ngClass]="{ 'link-disabled': requoting }"
          class="js-go-to-requote dialog-text-link"
          >{{ requoting ? 'Requoting...' : 'Requote' }}</a
        >
      </div>
    </app-dialog-box>
    <app-dialog-box
      *ngIf="requoteError"
      type="danger"
      header="Failed to requote"
      class="dialog-text__full-width"
    >
      An error occurred when attempting to requote. Please contact our Customer Care Team for
      further assistance.
      <div class="dialog-text-links">
        <a class="dialog-text-link" href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
          >Get in touch</a
        >
      </div>
    </app-dialog-box>
  </ng-container>

  <ng-container *ngIf="!quoteDetails">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
  </ng-container>

  <ng-container *ngIf="quoteDetails">
    <!-- View for non-declined quote -->
    <ng-container *ngIf="quoteDetails.status !== 'Draft'">
      <app-dialog-box
        *ngIf="bindBlockStatus === 'non-preferred-bind-block'"
        id="not-bindable-dialog"
        fullWidth="true"
        type="warning"
      >
        <strong>Non-Preferred quote not bindable at this time</strong><br />
        Your agency is no longer able to bind non-preferred quotes in our portal.
        <br />
        <br />
        To discuss reinstating non-preferred access, please contact your account manager.
        <br />
        <br />
        <a
          *ngIf="accountManager"
          href="{{ accountManager.bookBalanceLink }}"
          target="_blank"
          data-amplitude-input-name="quote-not-bindable-book-balance"
        >
          Schedule time with {{ accountManager.name }} by clicking here.
        </a>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="bindBlockStatus === 'non-preferred-no-access'"
        id="not-bindable-dialog"
        fullWidth="true"
        type="warning"
      >
        <strong>Non-Preferred quote not bindable at this time</strong><br />
        Your agency is not able to bind non-preferred quotes at this time.
        <br />
        <br />
        Please contact your account manager to discuss gaining access to bind authority for
        non-preferred quotes.
        <br />
        <br />
        <a
          *ngIf="accountManager"
          href="{{ accountManager.bookBalanceLink }}"
          target="_blank"
          data-amplitude-input-name="quote-not-bindable-book-balance"
        >
          Schedule time with {{ accountManager.name }} by clicking here.
        </a>
      </app-dialog-box>

      <div class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div class="product-badge product-badge__wc">
            <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
          </div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2">Quote details</h2>
            <small class="gray-text">No. {{ quoteDetails.id }}</small>
            <div class="quote-type-info">
              <div *ngIf="isPreferredQuote" class="quote-type quote-type__preferred"></div>
              <div *ngIf="isNonPreferredQuote" class="quote-type quote-type__non-preferred"></div>
              {{ quoteType }}
              <span
                *ngIf="quoteTypeTip"
                class="more-info-tip tool-tip tool-tip__large"
                attr.data-tooltip="{{ quoteTypeTip }}"
              >
                <span class="icon icon-question"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="policy-pane-header-right">
          <h2 class="policy-pane-header-h2 js-premium-number">{{ formatCost(quoteDetails) }}</h2>
          <div class="premium-explainer">
            <small>Policy amount</small>
          </div>
        </div>
      </div>
      <div class="document-download-container">
        <app-document-download
          *ngIf="quoteDetails.status !== 'Expired' && !bindBlockStatus"
          displayName="Quote Letter"
          [documentDownload$]="quoteLetterDownload$"
          [documentReady$]="quoteLetterDownloadReady$"
        ></app-document-download>
        <app-document-download
          displayName="Application Input"
          [documentDownload$]="quoteSubmissionDownload$"
        ></app-document-download>
      </div>

      <div class="policy-pane-details" *ngIf="!(quoteDetails.status === 'Expired')">
        <dl>
          <dt>Governing Class</dt>
          <dd class="js-governing-class {{ governingClassCssClass }}">
            {{ quoteDetails.governingClassDescription }} ({{ quoteDetails.governingClassCode }})
          </dd>
        </dl>
        <dl>
          <dt>Hazard Group</dt>
          <dd class="js-hazard-group">
            {{ quoteDetails.hazardGroup }}
          </dd>
        </dl>
        <dl>
          <dt>Effective date</dt>
          <dd>{{ quoteDetails.policyStart | date: 'mediumDate':'UTC' }}</dd>
        </dl>

        <div class="policy-detail-subsection">
          <dl *ngIf="quoteDetails.patternCodes.wcDeductible">
            <dt>Deductible</dt>
            <dd>
              {{ formatDeductible(quoteDetails.patternCodes.wcDeductible) }}
            </dd>
          </dl>
          <dl *ngIf="quoteDetails.patternCodes.wcEmpLiabilityLimits">
            <dt>Employer's Liability Limits (Each Claim, Each Employee, Policy Limit)</dt>
            <dd>
              {{ formatEmpLiabLimits(quoteDetails.patternCodes.wcEmpLiabilityLimits) }}
            </dd>
          </dl>

          <dl *ngIf="fein">
            <dt>FEIN</dt>
            <dd>
              {{ fein }}
            </dd>
          </dl>

          <dl *ngIf="riskId">
            <dt>Risk-ID</dt>
            <dd>
              {{ riskId }}
            </dd>
          </dl>

          <ng-container *ngIf="!isEmpty(experienceModifiersByState)">
            <h3>Experience Modifiers</h3>
            <div *ngFor="let item of experienceModifiersByState | keyvalue">
              <dl>
                <dt>Jurisdiction</dt>
                <dd>{{ item.key }}</dd>
              </dl>

              <dl *ngFor="let modifier of item.value">
                <dt>{{ modifier.startDate }} - {{ modifier.endDate }}</dt>
                <dd>
                  {{ modifier.modifierRate }}
                </dd>
              </dl>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="!bindBlockStatus">
          <div class="mt-4 pb-2">
            <app-quote-servicing-information
              carrier="attune-gw-wc"
            ></app-quote-servicing-information>
          </div>
        </ng-container>
      </div>

      <!-- dev debug -->
      <div *ngIf="isDevMode">
        <div class="adminz-only">
          <pre>{{ quoteDetails | json }}</pre>
        </div>
      </div>
    </ng-container>

    <!-- View for declined quote -->
    <ng-container *ngIf="quoteDetails.status === 'Draft'">
      <app-dialog-box
        *ngIf="uwIssues.length > 0"
        type="danger"
        header="Quote declined"
        class="dialog-text__full-width js-policy-issue"
      >
        This quote was declined for the following reasons:
        <ul class="bulleted-list">
          <li *ngFor="let issue of uwIssues">{{ issue }}</li>
        </ul>
        <div class="dialog-text-links">
          <a class="dialog-text-link" href="/support/form/request-review">Request a review</a>
        </div>
      </app-dialog-box>
      <div class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div class="product-badge product-badge__wc">
            <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
          </div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2 js-quote-declined-header">Quote details</h2>
            <small class="gray-text">No. {{ quoteDetails.id }}</small>
          </div>
        </div>
        <div class="policy-pane-header-right">
          <h2 class="policy-pane-header-h2 js-policy-premium">N/A</h2>
          <small class="gray-text">Premium</small>
        </div>
      </div>

      <app-document-download
        *ngIf="quoteDetails.uwIssues.length !== 0"
        displayName="Application Input"
        [documentDownload$]="quoteSubmissionDownload$"
      ></app-document-download>

      <div class="policy-pane-date">
        <div class="policy-pane-date__left">Effective Date</div>
        <div class="policy-pane-date__right">
          {{ quoteDetails.policyStart | date: 'mediumDate':'UTC' }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <app-additional-documents [isAttuneWc]="true" paneType="quote"></app-additional-documents>
</div>
