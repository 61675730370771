import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, startWith } from 'rxjs';

// Constants
import {
  ATTUNE_WC_PAYMENT_PLAN_MAPPING,
  ATTUNE_WC_PAYMENT_PLAN_OPTIONS,
} from 'app/workers-comp/attune/constants';

// Services
import { AttuneWcBindFormService } from 'app/workers-comp/attune/services/attune-wc-bind-form.service';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Models
import { QSAttuneWcPaymentPlan } from 'app/workers-comp/attune/models/payment-plans.model';
import { PaymentDetailsFormGroup } from 'app/workers-comp/attune/models/bind-form.model';

@Component({
  selector: 'app-wc-bind-payment-page',
  templateUrl: './attune-wc-bind-payment-details.component.html',
})
export class AttuneWcBindPaymentDetailsComponent
  extends SteppedFormPageComponent<AttuneWcBindFormService, PaymentDetailsFormGroup>
  implements OnInit, OnDestroy
{
  form: PaymentDetailsFormGroup;
  formPath = 'paymentDetails';

  sub: Subscription = new Subscription();
  paymentPlanOptions = ATTUNE_WC_PAYMENT_PLAN_OPTIONS;
  disabledPaymentPlanOptions: string[];

  constructor(protected formService: AttuneWcBindFormService) {
    super(formService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupPaymentPlanSubscription();
    this.disabledPaymentPlanOptions = this.getDisabledPaymentPlans();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getDisabledPaymentPlans() {
    // Some payment plans require the total premium to be above a certain threshold.
    const totalPremium = this.paymentDetails.totalPremium;
    return Object.entries(this.paymentPlanOptions)
      .filter(([_paymentPlanSelection, PaymentPlanId]) => {
        const paymentPlanDetails = ATTUNE_WC_PAYMENT_PLAN_MAPPING[PaymentPlanId];
        return totalPremium < paymentPlanDetails.premiumThreshold;
      })
      .map(([paymentPlanSelection, _paymentPlanId]) => {
        return paymentPlanSelection;
      });
  }

  setupPaymentPlanSubscription() {
    const paymentPlanControl = this.form.get('paymentPlan');
    if (!paymentPlanControl) {
      return;
    }

    this.sub.add(
      paymentPlanControl.valueChanges
        .pipe(startWith(paymentPlanControl.value))
        .subscribe((paymentPlanVal) => {
          this.setPaymentDetails(paymentPlanVal as QSAttuneWcPaymentPlan);
        })
    );
  }

  setPaymentDetails(paymentPlanSelected: QSAttuneWcPaymentPlan) {
    const paymentPlanDetails = ATTUNE_WC_PAYMENT_PLAN_MAPPING[paymentPlanSelected];
    this.paymentDetails.updatePaymentPlan(
      paymentPlanDetails.numberOfPayments,
      paymentPlanDetails.downPaymentPercentage
    );
  }

  get paymentDetails() {
    return this.formService.policyPaymentDetails;
  }

  get paymentPlanQuestionNote() {
    if (this.disabledPaymentPlanOptions.length > 0) {
      return 'Due to the small premium size, some payment options are disabled for this quote.';
    }
    return '';
  }
}
