import * as _ from 'lodash';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { User } from 'app/shared/models/user';
import {
  API_DATE_FORMAT,
  EMP_LIABILITY_MAP,
  EMP_TAX_ID_KEY_BY_STATE,
  EMPLOYERS_QUESTION_SLUG,
} from 'app/workers-comp/employers/constants';
import * as moment from 'moment';
import { US_DATE_MASK } from '../../../constants';

export interface WcFormValue {
  uuid: string;
  basicInfo: WcFormBasicInfo;
  locations: Array<WcFormLocation>;
  policyInfo: WcFormPolicyInfo;
  lossInfo: WcFormLossInfo;
  underwritingInfo: WcFormUwInfo;
}

export interface WcFormLossInfo {
  lossHistory: Array<WcFormLossPolicyPeriod>;
}

export interface WcFormPolicyInfo {
  effectiveDate: string;
  employersLiabilityLimits: '100k' | '500k' | '1m' | '2m';
  stateWorkersCompBureauInfo: Array<WcFormStateBureauInfo>;
  employerIdentificationNumberCrossSellExperiment?: string;
}

export interface WcFormBasicInfo {
  employerIdentificationNumber: string;
  yearsInBusiness: string;
  yearsOfIndustryExperience?: string;
  numberOfLocations: number;
  organizationType?: string;
}

export type WcFormUwInfo = {
  [key in EMPLOYERS_QUESTION_SLUG]?: boolean;
};

export interface WcFormLocation {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  employeeCount: string;
  employeeClassifications: Array<WcFormEmployeeClassification>;
  executives?: Array<WcFormExecutive>;
  manyEmployees?: WcFormLocationManyEmployees;
}

export interface WcFormLocationManyEmployees {
  employeesInShift1: string;
  employeesInShift2: string;
  employeesInShift3: string;
  constructionType: string;
  yearBuilt: string;
  storiesCount: string;
}

export interface WcFormEmployeeClassificationCode {
  classCode: string;
  classSeq: string;
  description: string;
  easyRate: boolean;
  quoteable: boolean;
  industryGroup: string;
}

export interface WcCompanionClass {
  codeHigh: string;
  codeLow: string;
  codeWithSeqHigh: string;
  codeWithSeqLow: string;
  highDesc: string;
  lowDesc: string;
  seqHigh: string;
  seqLow: string;
}

export interface WcFormEmployeeClassification {
  code: WcFormEmployeeClassificationCode;
  remuneration: string;
}

export interface WcFormExecutive {
  firstName: string;
  lastName: string;
  title: string;
  ownership: string;
  isIncluded: boolean;
  questions?: Array<any>;
}

export interface WcFormStateBureauInfo {
  state: string;
  taxOrUnemploymentId?: string;
}

export interface WcFormLossPolicyPeriod {
  hasClaims: boolean;
  policyEffectiveDate: string;
  policyExpirationDate: string;
  numClaims?: string;
  amountPaid?: string;
  amountReserved?: string;
}

// Payload Models below
export interface WcQuotePayload {
  producer: WcQuotePayloadProducer;
  uuid: string;
  policy: WcQuotePolicy;
  account: WcQuotePayloadAccount;
  uwAnswers: Array<WcUwAnswer>;
  wcLossHistory: Array<WcLossPolicyPeriod>;
}

interface WcQuotePolicy {
  effectiveDate: string;
  employersLiabilityLimits: {
    eachEmployee: number;
    eachClaim: number;
    policyLimit: number;
  };
}

interface WcQuotePayloadProducer {
  producerCode: string;
  guidewireUserName: string;
  ldapUsername: string;
}

interface WcUwAnswer {
  slug: string;
  answer: boolean;
  explanation?: string;
  occurrenceData?: { explanation: string }[];
}

interface WcExecutiveQuestionAnswer {
  questionCode: string;
  answer: 'Yes' | 'No';
}

interface WcQuotePayloadAccount {
  [key: string]: Object | undefined;
  guidewireAccountId: string;
  baseState: string;
  name: string;
  mailingAddress: Address;
  contactEmail: string;
  contactPhone: string;
  employerIdentificationNumber: string;
  startedOperatingAtYear: number;
  organizationType: string;
  yearsOfIndustryExperience: number;
  yearsInBusiness: number;
  stateWorkersCompBureauInfo: Array<WcStateBureauInfo>;
  locations: Array<WcLocation>;
  michiganUnemploymentNumber?: string;
  minnesotaUnemploymentNumber?: string;
  newJerseyTaxIdNumber?: string;
  utahUnemploymentNumber?: string;
  maineUnemploymentNumber?: string;
}

export interface WcStateBureauInfo {
  state: string;
}

export interface WcLocation {
  address: Address;
  employeeCount: number;
  executives?: Array<WcExecutive>;
  employeeClassifications: Array<WcEmployeeClassification>;
  employersLocationInfo?: {
    employeesInShift1: number;
    employeesInShift2: number;
    employeesInShift3: number;
    employersConstructionDate: string;
    employersConstructionCode: string;
    employersNumStories: number;
  };
}

export interface WcEmployeeClassificationCode {
  code: string;
  codeDescription: string;
}

export interface WcEmployeeClassification extends WcEmployeeClassificationCode {
  remuneration: number;
}
export interface WcExecutive {
  firstName: string;
  lastName: string;
  title: string;
  ownership: number;
  isIncluded: boolean;
  questions?: Array<WcExecutiveQuestionAnswer>;
}

export interface WcLossPolicyPeriod {
  policyEffectiveDate: string;
  policyExpirationDate: string;
  numClaims: number;
  amountPaid: number;
  amountReserved: number;
}

export class WcPolicy implements WcQuotePayload {
  producer: WcQuotePayloadProducer;
  account: WcQuotePayloadAccount;
  wcLossHistory: Array<WcLossPolicyPeriod>;
  uuid: string;
  policy: {
    effectiveDate: string;
    employersLiabilityLimits: {
      eachEmployee: number;
      eachClaim: number;
      policyLimit: number;
    };
  };
  uwAnswers: Array<WcUwAnswer>;

  constructor(wcForm: WcFormValue, insuredAccount: InsuredAccount, user: User) {
    this.producer = this.createProducerFromUser(user);
    this.account = this.createAccount(wcForm);
    Object.assign(this.account, this.accountDetailsFromInsuredAccount(insuredAccount));
    this.policy = this.createPolicy(wcForm);
    this.uuid = wcForm.uuid;
    this.uwAnswers = this.createWcUwAnswers(wcForm);

    const wcLossHistory = this.createWcLossHistory(wcForm);
    if (wcLossHistory) {
      this.wcLossHistory = wcLossHistory;
    }
  }

  private accountDetailsFromInsuredAccount(insuredAccount: InsuredAccount) {
    const mailingAddress = <Address>{};

    Object.assign(
      mailingAddress,
      _.pickBy(
        _.pick(insuredAccount, ['addressLine1', 'addressLine2', 'city', 'state', 'zip']),
        _.identity
      )
    );

    if (mailingAddress.addressLine2 && mailingAddress.addressLine2.length < 3) {
      mailingAddress.addressLine2 = 'Unit ' + mailingAddress.addressLine2;
    }

    return {
      contactEmail: insuredAccount.emailAddress,
      contactPhone: insuredAccount.phoneNumber,
      guidewireAccountId: insuredAccount.id.toString(),
      mailingAddress: mailingAddress,
      name: insuredAccount.companyName,
      organizationType: '',
    };
  }

  private createProducerFromUser(user: User): WcQuotePayloadProducer {
    return <WcQuotePayloadProducer>{
      guidewireUserName: user.husaUserName,
      ldapUsername: user.userName,
      producerCode: user.producer,
    };
  }

  private createAccount(wcForm: WcFormValue): WcQuotePayloadAccount {
    const account = <WcQuotePayloadAccount>{};

    Object.assign(account, _.pick(wcForm.basicInfo, ['guideWireInsuredAccountId']));

    let fein;
    if (wcForm.basicInfo.employerIdentificationNumber) {
      fein = String(wcForm.basicInfo.employerIdentificationNumber);
    } else {
      fein = String(wcForm.policyInfo.employerIdentificationNumberCrossSellExperiment);
    }

    account.employerIdentificationNumber = fein.replace(/\D+/g, '');

    account.yearsInBusiness = parseMaskedInt(wcForm.basicInfo.yearsInBusiness);

    account.yearsOfIndustryExperience = wcForm.basicInfo.yearsOfIndustryExperience
      ? parseInt(wcForm.basicInfo.yearsOfIndustryExperience, 10)
      : account.yearsInBusiness;

    const formStateBureauInfo = wcForm.policyInfo.stateWorkersCompBureauInfo;

    if (formStateBureauInfo) {
      account.stateWorkersCompBureauInfo = formStateBureauInfo.map((wcFormStateBureauInfo) => {
        const stateBureauInfo: WcStateBureauInfo = { state: wcFormStateBureauInfo.state };

        return stateBureauInfo;
      });

      // Collect NJ, UT, MI, MN or ME state unemployment codes
      formStateBureauInfo.forEach((wcFormStateBureauInfo: WcFormStateBureauInfo) => {
        if (
          Object.prototype.hasOwnProperty.call(EMP_TAX_ID_KEY_BY_STATE, wcFormStateBureauInfo.state)
        ) {
          const accountKey: string =
            EMP_TAX_ID_KEY_BY_STATE[wcFormStateBureauInfo.state].accountKey;

          account[accountKey] = wcFormStateBureauInfo.taxOrUnemploymentId;
        }
      });
    }
    if (wcForm.locations[0]) {
      account.baseState = wcForm.locations[0].state;
    }
    account.locations = this.createLocations(wcForm.locations);
    const currentYear = moment().year();
    account.startedOperatingAtYear = currentYear - account.yearsInBusiness;

    return account;
  }

  private createLocations(wcFormLocations: Array<WcFormLocation>) {
    return wcFormLocations.map((wcFormLoc) => {
      const employeeCount = parseMaskedInt(wcFormLoc.employeeCount);
      const wcLoc: WcLocation = {
        address: _.pickBy(
          _.pick(wcFormLoc, ['addressLine1', 'addressLine2', 'city', 'state', 'zip']) as Address,
          _.identity
        ) as Address,
        employeeCount,
        employeeClassifications: [],
      };
      if (!_.isEmpty(wcFormLoc.employeeClassifications)) {
        wcLoc.employeeClassifications = wcFormLoc.employeeClassifications.map(
          (wcFormClassification) => {
            return this.createWcClassification(wcFormClassification);
          }
        );
      }
      if (employeeCount > 100) {
        const manyEmp = wcFormLoc.manyEmployees as WcFormLocationManyEmployees;
        wcLoc.employersLocationInfo = {
          employeesInShift1: parseMaskedInt(manyEmp.employeesInShift1),
          employeesInShift2: parseMaskedInt(manyEmp.employeesInShift2),
          employeesInShift3: parseMaskedInt(manyEmp.employeesInShift3),
          employersConstructionCode: manyEmp.constructionType,
          employersConstructionDate: moment(manyEmp.yearBuilt, 'YYYY').format(API_DATE_FORMAT),
          employersNumStories: parseMaskedInt(manyEmp.storiesCount),
        };
      }
      if (wcFormLoc.executives) {
        wcLoc.executives = wcFormLoc.executives.map((wcFormExec) =>
          this.createWcExecutive(wcFormExec)
        );
      }
      return wcLoc;
    });
  }

  private createWcClassification(wcFormClassification: WcFormEmployeeClassification) {
    return {
      code: _.get(wcFormClassification, 'code.classCode', null),
      codeDescription: _.get(wcFormClassification, 'code.description', null),
      remuneration: parseMaskedInt(wcFormClassification.remuneration),
    };
  }

  private createWcExecutive(wcFormExec: WcFormExecutive) {
    return <WcExecutive>{
      firstName: wcFormExec.firstName,
      lastName: wcFormExec.lastName,
      ownership: parseFloat(wcFormExec.ownership),
      title: wcFormExec.title,
      isIncluded: wcFormExec.isIncluded,
      questions: wcFormExec.questions ? this.createWcExecutiveQuestionAnswer(wcFormExec) : [],
    };
  }

  private createWcLossHistory(wcForm: WcFormValue): Array<WcLossPolicyPeriod> | null {
    // Returns null if none of the loss policy periods have claims
    // Otherwise, converts all loss policy periods from the form (usually 4):
    //   - no claims ? only includes dates and numClaims = 0
    //   - has claims -> includes all other fields
    const someClaimsPresent = _.some(
      wcForm.lossInfo.lossHistory,
      (lossPeriod) => lossPeriod.hasClaims
    );

    if (someClaimsPresent) {
      return wcForm.lossInfo.lossHistory.map((lossPeriod) => {
        const wcLossPolicyPeriod = <WcLossPolicyPeriod>{};

        wcLossPolicyPeriod.policyEffectiveDate = moment(
          lossPeriod.policyEffectiveDate,
          US_DATE_MASK
        ).format(API_DATE_FORMAT);
        wcLossPolicyPeriod.policyExpirationDate = moment(
          lossPeriod.policyExpirationDate,
          US_DATE_MASK
        ).format(API_DATE_FORMAT);

        // NOTE: form can send hasClaims = false (loss period UI not showing)
        // but the form lossPeriod numClaims might be >= 1
        wcLossPolicyPeriod.numClaims = 0;

        if (lossPeriod.hasClaims) {
          wcLossPolicyPeriod.numClaims = parseInt(lossPeriod.numClaims as string, 10);
          wcLossPolicyPeriod.amountPaid = parseMaskedInt(lossPeriod.amountPaid as string);
          wcLossPolicyPeriod.amountReserved = parseMaskedInt(lossPeriod.amountReserved as string);
        }

        return wcLossPolicyPeriod;
      });
    }

    return null;
  }

  private createPolicy(wcForm: WcFormValue): WcQuotePolicy {
    return {
      effectiveDate: moment(wcForm.policyInfo.effectiveDate, US_DATE_MASK).format(API_DATE_FORMAT),
      employersLiabilityLimits: EMP_LIABILITY_MAP[wcForm.policyInfo.employersLiabilityLimits],
    };
  }

  private createWcUwAnswers(wcForm: WcFormValue): Array<WcUwAnswer> {
    return _.toPairs(wcForm.underwritingInfo).map(([key, value]) => {
      return <WcUwAnswer>{
        answer: value,
        slug: key,
      };
    });
  }

  private createWcExecutiveQuestionAnswer(wcForm: WcFormExecutive) {
    return _.toPairs(wcForm.questions).map(([key, value]) => {
      const answerString = value !== null ? (value ? 'Yes' : 'No') : null;
      return <WcExecutiveQuestionAnswer>{
        answer: answerString,
        questionCode: key,
      };
    });
  }
}
