<h3
  class="js-book-mix-header"
  *ngIf="!loadingBookMixInformation && (hasBopPolicies || (attuneWcIsEnabled && hasWcPolicies))"
>
  Agency book balance insights
</h3>
<ng-container *ngIf="!loadingBookMixInformation && hasBopPolicies">
  <div class="app-activity-book-mix-progress-container mb-2">
    <app-activity-book-mix-progress
      productType="bop"
      [productBookMixInfo]="bookMixInformation.bop"
    ></app-activity-book-mix-progress>
  </div>
</ng-container>
<ng-container *ngIf="attuneWcIsEnabled && !loadingBookMixInformation && hasWcPolicies">
  <div class="app-activity-book-mix-progress-container">
    <app-activity-book-mix-progress
      productType="wc"
      [productBookMixInfo]="bookMixInformation.wc"
    ></app-activity-book-mix-progress>
  </div>
</ng-container>
