import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { InvoicesService } from './invoices.service';
import { SentryService } from 'app/core/services/sentry.service';
import { map } from 'rxjs/operators';
import { prettyLineOfBusiness, prettyPaymentPlan } from '../models/invoices-constants';
import { MXS_PRODUCT_NAME } from 'app/features/insured-account/models/insured-account.model';

import * as _ from 'lodash';

type PolicyStatus = 'Canceled' | 'Active' | 'Pending Cancel';

interface PolicyTermDetails {
  lineOfBusiness: string;
  totalValue: number;
  paymentPlan: string;
}

@Injectable()
export class InvoicesBannerService {
  constructor(private invoiceService: InvoicesService, private sentryService: SentryService) {}

  getAssociatedInvoicesAndPolicies(
    id: string,
    token: string
  ): Observable<[BackendAssociatedInvoices?, BackendInvoiceAssociatedPolicyResponse?] | undefined> {
    return forkJoin(
      this.invoiceService.getAssociatedInvoices(id, token),
      this.invoiceService.getAssociatedPolicies(id, token)
    ).pipe(
      map(
        ([associatedInvoices, associatedPolicies]):
          | [BackendAssociatedInvoices, BackendInvoiceAssociatedPolicyResponse]
          | undefined => {
          if (
            !associatedPolicies ||
            !associatedPolicies.associatedPolicies ||
            !associatedPolicies.associatedPolicies.length
          ) {
            this.sentryService.notify(
              'Invoice Page: associatedPolicies undefined or zero length.',
              {
                severity: 'error',
                metaData: {
                  associatedPolicies,
                  invoiceId: id,
                },
              }
            );
            return;
          }
          if (
            !associatedInvoices ||
            !associatedInvoices.associatedInvoices ||
            !associatedInvoices.associatedInvoices.length
          ) {
            this.sentryService.notify(
              'Invoice Page: associatedInvoices undefined or zero length.',
              {
                severity: 'error',
                metaData: {
                  associatedInvoices,
                  invoiceId: id,
                },
              }
            );
            return;
          }
          return [associatedInvoices, associatedPolicies];
        },
        () => {
          // Note: Silently drop errors -- there is nothing to show to user here, and they are logged in Sentry from service already
        }
      )
    );
  }

  /*
   * The purpose of this function is to ensure that our logic for displaying the banners only operates on the correct data
   */
  filterInvoicesAndPoliciesForTopBanner(
    associatedInvoices: BackendListInvoice[],
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ): [BackendListInvoice[], BackendInvoiceAssociatedPolicy[]] {
    // Note: We are only interested in unpaid invoices
    const unpaidInvoices = associatedInvoices.filter((invoice) => {
      return invoice.amountDue !== 0;
    });

    // Note: Sort into chronological order, with earliest invoice at 0 and most recent invoice in last element
    unpaidInvoices.sort((invoice1, invoice2) => {
      return moment.utc(invoice1.dueDate).isBefore(moment.utc(invoice2.dueDate)) ? -1 : 1;
    });

    // Note: sort policies into chronological order, with earliest policy at 0 and most recent policy in last element
    let sortedPolicies = associatedPolicies.slice().sort((policy1, policy2) => {
      return moment.utc(policy1.PeriodStart).isBefore(moment.utc(policy2.PeriodStart)) ? -1 : 1;
    });

    let invoicedPolicyNumbers: string[] = [];
    if (sortedPolicies.length) {
      // Note: Default to last policy, in the case all invoices are paid.
      invoicedPolicyNumbers = [sortedPolicies[sortedPolicies.length - 1].PolicyNumber];
    }
    if (unpaidInvoices.length) {
      invoicedPolicyNumbers = unpaidInvoices[0].lineItems.map((lineItem) => lineItem.policyNumber);
    }
    invoicedPolicyNumbers = _.uniq(invoicedPolicyNumbers);

    // Note: Filter out policies that are canceled within a week of being generated.
    // These policies are typically errors that were canceled and rewritten.
    // If all policies associated with an account are of that type, though, we should show them.
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const policiesNotImmediatelyCanceled = sortedPolicies.filter((policy) => {
      return (
        policy.TermDisplayStatus_ATN !== 'Canceled' ||
        moment(policy.WrittenDate).diff(policy.UpdateTime) > oneWeekInMilliseconds
      );
    });
    if (policiesNotImmediatelyCanceled.length) {
      sortedPolicies = policiesNotImmediatelyCanceled;
    }

    // Note: Filter down to policies we are concered with, either:
    //         1/ The policies for which there are outstanding invoices
    //         2/ The latest policy in the case of no unpaid invoices
    sortedPolicies = sortedPolicies.filter((policy) => {
      return invoicedPolicyNumbers.includes(policy.PolicyNumber);
    });

    // Filter out policies with same policy numbers to avoid
    // policies with canceled and rewrite showing as canceled.
    const filteredAssociatedPolicies = [];
    const policyNumbersSeen: any = {};
    for (let i = sortedPolicies.length - 1; i >= 0; i--) {
      if (policyNumbersSeen[sortedPolicies[i].PolicyNumber]) {
        continue;
      }
      filteredAssociatedPolicies.unshift(sortedPolicies[i]);
      policyNumbersSeen[sortedPolicies[i].PolicyNumber] = true;
    }
    sortedPolicies = filteredAssociatedPolicies;

    // Note: Because we fetch all invoices for the account, we have to filter down to the invoices that concern the
    //       policies that are still active or have unpaid invoices.
    const unpaidInvoicesForPolicies = unpaidInvoices.filter((invoice) => {
      return invoice.lineItems.some((lineItem) =>
        invoicedPolicyNumbers.includes(lineItem.policyNumber)
      );
    });

    return [unpaidInvoicesForPolicies, sortedPolicies];
  }

  getPolicyStatus(invoicedPolicies: BackendInvoiceAssociatedPolicy[]): PolicyStatus {
    let latestPolicies = invoicedPolicies.reduce(
      (acc: BackendInvoiceAssociatedPolicy[], policy) => {
        if (!acc.length) {
          return [policy];
        }
        if (policy.PeriodStart === acc[0].PeriodStart) {
          return acc.concat([policy]);
        } else if (moment(policy.PeriodStart).isAfter(moment(acc[0].PeriodStart))) {
          return [policy];
        }
        return acc;
      },
      []
    );
    const latestPoliciesIncludeInForce = latestPolicies.some(
      (policy) => policy.TermDisplayStatus_ATN === 'In Force'
    );
    if (latestPoliciesIncludeInForce) {
      latestPolicies = latestPolicies.filter(
        (policy) => policy.TermDisplayStatus_ATN !== 'Canceled'
      );
    }
    const isCanceled = latestPolicies.some((policy) => {
      return policy.TermDisplayStatus_ATN === 'Canceled';
    });
    if (isCanceled) {
      return 'Canceled';
    }
    const isPendingCancel = latestPolicies.some((policy) => {
      const termPeriods = policy.PolicyTerm.PortalViewableTermPeriods;
      // Note: Find the latest cancellation term
      let cancellationTerm = null;
      for (let i = termPeriods.length - 1; i >= 0; i--) {
        if (
          termPeriods[i].Job.Subtype === 'Cancellation' &&
          termPeriods[i].Status !== 'Rescinded'
        ) {
          cancellationTerm = termPeriods[i];
          break;
        }
      }
      if (!cancellationTerm) {
        return false;
      }
      return cancellationTerm.Status === 'Canceling' || cancellationTerm.Status === 'Quoted';
    });
    if (isPendingCancel) {
      return 'Pending Cancel';
    }
    return 'Active';
  }

  getCancellationDate(associatedPolicies: BackendInvoiceAssociatedPolicy[]): Date {
    const cancellationTerms: InvoicePolicyTermPeriods[] = associatedPolicies.reduce(
      (cancellationTermsAccumulator, policy) => {
        const termPeriods = policy.PolicyTerm.PortalViewableTermPeriods;
        // Note: Find the latest cancellation term
        let cancellationTerm = null;
        for (let i = termPeriods.length - 1; i >= 0; i--) {
          if (
            termPeriods[i].Job.Subtype === 'Cancellation' &&
            termPeriods[i].Status !== 'Rescinded'
          ) {
            cancellationTerm = termPeriods[i];
            break;
          }
        }
        if (cancellationTerm) {
          cancellationTermsAccumulator.push(cancellationTerm);
        }
        return cancellationTermsAccumulator;
      },
      [] as InvoicePolicyTermPeriods[]
    );
    const cancellationMoments = cancellationTerms.map((cancellationTerm) => {
      return moment.utc(cancellationTerm.EditEffectiveDate);
    });
    return moment.min(cancellationMoments).toDate();
  }

  isNonPayCancellation(invoicedPolicies: BackendInvoiceAssociatedPolicy[]): boolean {
    return invoicedPolicies.some((policy) => {
      const termPeriods = policy.PolicyTerm.PortalViewableTermPeriods;
      // Note: Find the latest cancellation term
      let cancellationTerm = null;
      for (let i = termPeriods.length - 1; i >= 0; i--) {
        if (termPeriods[i].Job.Subtype === 'Cancellation') {
          cancellationTerm = termPeriods[i];
          break;
        }
      }
      if (!cancellationTerm) {
        return false;
      }
      return cancellationTerm.CancelReasonCode === 'nonpayment';
    });
  }

  isUWCancellation(invoicedPolicies: BackendInvoiceAssociatedPolicy[]): boolean {
    return invoicedPolicies.some((policy) => {
      const termPeriods = policy.PolicyTerm.PortalViewableTermPeriods;
      // Note: Find the latest cancellation term
      let cancellationTerm = null;
      for (let i = termPeriods.length - 1; i >= 0; i--) {
        if (termPeriods[i].Job.Subtype === 'Cancellation') {
          cancellationTerm = termPeriods[i];
          break;
        }
      }
      if (!cancellationTerm) {
        return false;
      }
      return cancellationTerm.CancelReasonCode === 'uwreasons';
    });
  }

  getNumOfOpenInvoices(unpaidInvoices: BackendListInvoice[]) {
    return unpaidInvoices.reduce((count, invoice) => {
      if (invoice.status === 'Billed') {
        return count + 1;
      }
      return count;
    }, 0);
  }

  getNumOfPastDueInvoices(unpaidInvoices: BackendListInvoice[]) {
    return unpaidInvoices.reduce((count, invoice) => {
      if (moment.utc().isAfter(moment.utc(invoice.dueDate))) {
        return count + 1;
      }
      return count;
    }, 0);
  }

  getRenewalPolicyNumber(associatedPolicies: BackendInvoiceAssociatedPolicy[]): string | null {
    const renewalPolicy = associatedPolicies.find((policy) => {
      const termPeriods = policy.PolicyTerm.PortalViewableTermPeriods;
      let renewals = null;
      for (let i = termPeriods.length - 1; i >= 0; i--) {
        if (termPeriods[i].Job.Subtype === 'Renewal') {
          renewals = termPeriods[i];
          break;
        }
      }
      if (!renewals) {
        return false;
      }
      return renewals.Status === 'Bound';
    });
    return renewalPolicy ? renewalPolicy.PolicyNumber : null;
  }

  getFirstInvoiceForPolicy(invoices: BackendListInvoice[], policyNumber: string) {
    const invoicesEarliestFirst = invoices.sort((a, b) => (a.dueDate > b.dueDate ? -1 : 1));
    return invoicesEarliestFirst.find((invoice) => {
      return invoice.lineItems.some((lineItem) => lineItem.policyNumber === policyNumber);
    });
  }

  getPolicyTermDetails(
    accountSummary: AccountSummary,
    invoice: Invoice | null
  ): PolicyTermDetails[] {
    if (!accountSummary || !invoice) {
      return [];
    }

    const annualPremiumDetails: PolicyTermDetails[] = [];
    const invoicePolicyPeriods = this.getPolicyPeriodsOnInvoice(invoice);

    Object.entries(invoicePolicyPeriods).forEach(([lob, invoicePolNumbers]) => {
      const accountPolicyPeriods = accountSummary.account.policyPeriods;
      // All the policy periods for a LOB that have been charged on the invoice.
      const relevantAccountPolicyPeriods = accountPolicyPeriods
        .filter((polPeriod: PolicyPeriods) => polPeriod.lineOfBusinessCode === lob)
        .filter((polPeriod: PolicyPeriods) => {
          return invoicePolNumbers.some((invoicePolNumber) => {
            // Account summary tacks on an extra "-1" for the term number so using === to compare won't work.
            return polPeriod.policyNumber.startsWith(invoicePolNumber);
          });
        });

      // There will only ever be one in force policy for each LOB.
      const inForcePolicyPeriod = relevantAccountPolicyPeriods.filter(
        (polPeriod: PolicyPeriods) => polPeriod.isInForce
      )[0];

      if (inForcePolicyPeriod) {
        annualPremiumDetails.push({
          lineOfBusiness: prettyLineOfBusiness(inForcePolicyPeriod.lineOfBusinessCode),
          totalValue: inForcePolicyPeriod.totalValue,
          paymentPlan: prettyPaymentPlan(inForcePolicyPeriod.paymentPlan as BackendPaymentPlan),
        });
      } else {
        // If there isn't a non-cancelled policy term, show the  latest cancelled term.
        const latestTerm = relevantAccountPolicyPeriods.sort((a, b) => {
          return a.effectiveDate < b.effectiveDate ? 1 : -1;
        })[0];
        if (latestTerm) {
          annualPremiumDetails.push({
            lineOfBusiness: prettyLineOfBusiness(latestTerm.lineOfBusinessCode),
            totalValue: latestTerm.totalValue,
            paymentPlan: prettyPaymentPlan(latestTerm.paymentPlan as BackendPaymentPlan),
          });
        }
      }
    });

    return annualPremiumDetails;
  }

  getPolicyPeriodsOnInvoice(invoice: Invoice): { [lineOfBusiness: string]: string[] } {
    const lobToPolicyNumbersMap: { [lineOfBusiness: string]: string[] } = {};

    for (const charge of invoice.charges) {
      const lob = charge.lineOfBusiness;
      if (!lobToPolicyNumbersMap[lob]) {
        lobToPolicyNumbersMap[lob] = [];
      }
      lobToPolicyNumbersMap[lob].push(charge.policyNumber);
    }

    return lobToPolicyNumbersMap;
  }

  calculatefilterPolicies(
    associatedPolicies: BackendInvoiceAssociatedPolicy[],
    accountSummary: AccountSummary
  ) {
    // If there are multiple policies, we should exclude XS policies from banner calculations,
    // because XS cancellations are relatively meaningless if the accompanying BOP is effective.
    let filteredPolicies = associatedPolicies.filter((policy) => {
      const relatedAccountPolicy =
        accountSummary &&
        accountSummary.account.policyPeriods.find(
          (period) => period.policyNumber && period.policyNumber.includes(policy.PolicyNumber)
        );
      return relatedAccountPolicy
        ? relatedAccountPolicy.lineOfBusinessCode !== MXS_PRODUCT_NAME
        : true;
    });
    // If somehow the XS policy is the only one (this should be impossible), undo the filtering
    if (!filteredPolicies.length) {
      filteredPolicies = associatedPolicies;
    }
    return filteredPolicies;
  }
}
