import { Component, OnInit, OnDestroy } from '@angular/core';
import { reduce, merge } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  verifyCpspQuestionsAreInFlow,
  checkCpspQuestionOrder,
  validateCpspHappyPathData,
} from 'app/features/liberty-mutual/models/debug-helpers';
import { LibertyMutualCpspQuoteFormService } from 'app/features/liberty-mutual/services/liberty-mutual-cpsp-quote-form.service';
import {
  LmButtonFunctionName,
  EARLY_DECLINE_REASONS,
} from 'app/features/liberty-mutual/models/common-typings';
import {
  ProductCombination,
  FormDSLFormData,
  isQuoteErrorResponse,
  QuoteErrorResponse,
  FormDSLQuoteRequest,
} from 'app/features/digital-carrier/models/types';
import {
  formatFormDataForDisplayCpsp,
  formatFormFieldValuesCpsp,
} from 'app/features/liberty-mutual/models/form-helpers';
import {
  LmCpspQuestion,
  LmCpspFormStepPath,
  LmCpspValidators,
  LmCpspComplexValidators,
  LmCpspArrayValidators,
} from 'app/features/liberty-mutual/models/lm-cpsp-typings';
import {
  LM_CPSP_COMPLEX_VALIDATORS,
  LM_CPSP_FORM_ARRAY_VALIDATORS,
  LM_CPSP_VALIDATORS,
} from 'app/features/liberty-mutual/models/lm-cpsp-validators';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import { SentryService } from 'app/core/services/sentry.service';
import {
  UNKNOWN_ERROR_WITHOUT_RETRY,
  LIBERTY_MUTUAL_DECLINED_ERROR_CPSP,
} from 'app/shared/quote-error-modal/errors';
import {
  cpspControlPath,
  CPSP_COB_CODE_EXCEPTIONS_BY_STATE,
  CPSP_STATE_EXCEPTIONS,
} from 'app/features/liberty-mutual/models/lm-cpsp-constants';
import { retryWithBackoff } from '../../../../shared/helpers/api-helpers';
import { getControl } from 'app/shared/helpers/form-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { FormDslData, TextAreaNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';

@Component({
  selector: 'app-liberty-mutual-cpsp-quote-form.component',
  templateUrl: './liberty-mutual-cpsp-quote-form.component.html',
  providers: [LibertyMutualCpspQuoteFormService],
})
export class LibertyMutualCpspQuoteFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit, OnDestroy
{
  accountId: string;
  quoteId: string;
  insuredAccount: InsuredAccount;
  insuredAccount$: Subject<InsuredAccount> = new Subject();

  isEditing = false;
  isSubmittingForm = false;
  quotedSuccess$ = new Subject<boolean>();

  // Validator dictionaries.
  // Note: Casting is necessary here so compiler does not think some values are 'undefined'
  formValidators = LM_CPSP_VALIDATORS as LmCpspValidators;
  complexFormValidators = LM_CPSP_COMPLEX_VALIDATORS as LmCpspComplexValidators;
  formArrayValidators = LM_CPSP_FORM_ARRAY_VALIDATORS as LmCpspArrayValidators;

  quoteError: QuoteErrorResponse | null = null;
  quoteErrorModalOpen = false;
  earlyDeclineErrors: EARLY_DECLINE_REASONS[] = [];
  quoteEarlyDeclinedModalOpen = false;

  libertyMutualOutages: Observable<boolean | null>;

  UNKNOWN_ERROR_WITHOUT_RETRY = UNKNOWN_ERROR_WITHOUT_RETRY;
  LIBERTY_MUTUAL_DECLINED_ERROR_CPSP = LIBERTY_MUTUAL_DECLINED_ERROR_CPSP;

  // Product combination for LM CPSP
  digitalCarrierProduct: ProductCombination = {
    pasSource: 'liberty_mutual',
    product: 'cpsp',
  };

  constructor(
    public formService: LibertyMutualCpspQuoteFormService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private featureFlagService: FeatureFlagService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(formService);
  }

  ngOnInit() {
    if (this.isDevMode) {
      // Log a warning if there are any questions that are not included in the quote flow.
      verifyCpspQuestionsAreInFlow();
      // Log a warning if questions are out of order per dependencies.
      checkCpspQuestionOrder();
      // Log a warning if the happy path is inconsistent with the form steps.
      validateCpspHappyPathData();
    }

    const formConfiguration = this.formService.generateFormSteps(true);
    this.accountId = this.route.snapshot.params['accountId'];
    this.quoteId = this.route.snapshot.params['quoteId'];
    const digitalCarrierProduct = this.digitalCarrierProduct;

    // Check Liberty Mutual outages feature flags
    this.libertyMutualOutages = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.LIBERTY_MUTUAL_OUTAGES
    );

    const formActionIndex = this.route.snapshot.url.length - 1;
    const formAction = this.route.snapshot.url[formActionIndex].path;
    this.isEditing = formAction === 'edit';

    if (this.isEditing) {
      this.digitalCarrierService
        .getQuoteSubmission(digitalCarrierProduct, this.quoteId)
        .subscribe((submission: FormDSLQuoteRequest) => {
          const oldFormData = submission.formData;
          const oldFormDataDisplay = formatFormDataForDisplayCpsp(oldFormData);
          super.ngOnInit();
          this.setFormConfig(formConfiguration);
          this.setFormData(oldFormDataDisplay as FormDslData);
          this.setAccountInfo();
        });
    } else {
      super.ngOnInit();
      this.setFormConfig(formConfiguration);
      this.setAccountInfo();
    }
  }

  // Retrieve relevant account and populate form with dependent data
  setAccountInfo() {
    const MAX_TRIES = 4;
    const RETRY_DELAY = 2000;
    // Fill in information within the quote flow based on account details
    this.sub.add(
      this.insuredAccount$
        .pipe(
          tap((account) => {
            this.insuredAccount = account;
            const { description, state } = account;
            const baseState = getControl(
              this.formService.form,
              cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE)
            );
            const descriptionControl = getControl(
              this.formService.form,
              cpspControlPath(LmCpspQuestion.DESCRIPTION_OF_OPERATIONS)
            );
            if (!descriptionControl.value) {
              const descriptionNode = this.formService.getNodeFunc(
                LmCpspQuestion.DESCRIPTION_OF_OPERATIONS
              ) as TextAreaNode;
              if (descriptionNode.maxLength) {
                descriptionControl.patchValue(
                  description.slice(0, parseInt(descriptionNode.maxLength, 10))
                );
              } else {
                descriptionControl.patchValue(description);
              }
            }
            if (!baseState.value) {
              baseState.patchValue(state);
            }
          })
        )
        .subscribe()
    );

    // Set insured account information
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          retryWithBackoff(MAX_TRIES, RETRY_DELAY),
          filter(
            (insuredAccount: InsuredAccount) => String(this.accountId) === String(insuredAccount.id)
          ),
          tap((insuredAccount) => this.insuredAccount$.next(insuredAccount))
        )
        .subscribe()
    );
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields';
  }

  handleInterpreterOutput({ methodName, args }: { methodName: LmButtonFunctionName; args: any[] }) {
    switch (methodName) {
      case LmButtonFunctionName.ADD_LOSS_GROUP:
        this.formService.addLossGroup();
        break;
      case LmButtonFunctionName.REMOVE_LOSS_GROUP:
        // REMOVE_LOSS_GROUP is the function on the loss array node's `childFooterButtons`. Any function bound to
        // these buttons receives a single argument corresponding to the child's index within the array.
        const idx = args[0];
        this.formService.removeLossGroup(idx);
        break;
    }
  }

  getFormData() {
    const rawFormData = this.formService.getValue();
    const flattened = this.flattenFormData(rawFormData);

    return formatFormFieldValuesCpsp(flattened) as FormDSLFormData;
  }

  // Returns a flattened list of form controls
  // NOTE: If form groups/arrays are added to LM, we may need to adapt this.
  flattenFormData(formValueWithSteps: any) {
    return reduce(
      formValueWithSteps,
      (fields, stepFields, _stepName) => {
        return merge(fields, stepFields);
      },
      {}
    );
  }

  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.isSubmittable()) {
      this.clickForward();
      return;
    }

    this.isSubmittingForm = true;

    const digitalCarrierProduct = this.digitalCarrierProduct;
    const formData = this.getFormData();
    const effectiveDate = formData[LmCpspQuestion.EFFECTIVE_DATE] as string;
    const account = this.digitalCarrierService.parseAccountForQuoteSubmit(this.insuredAccount);

    this.sub.add(
      this.digitalCarrierService
        .submitQuote(
          digitalCarrierProduct,
          formData,
          account,
          effectiveDate,
          this.isEditing,
          false,
          this.quoteId
        )
        .subscribe((response: any) => {
          this.isSubmittingForm = false;
          this.quotedSuccess$.next(true);

          if (isQuoteErrorResponse(response)) {
            this.sentryService.notify('Liberty Mutual CPSP: Error submitting quote', {
              severity: 'error',
              metaData: {
                backendErrorMessage: response.errorMessage,
                errors: response.errors,
              },
            });
            this.quoteError = response;
            this.quoteErrorModalOpen = true;
          } else {
            this.router.navigate(['/accounts', this.accountId, 'quotes', response.uuid]);
          }
        })
    );
  }

  onCloseErrorModal(event: Event) {
    this.quoteErrorModalOpen = false;
    this.router.navigate(['/accounts', this.accountId]);
  }

  loadNextStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this.currentStep.formPath === LmCpspFormStepPath.POLICY_INFO) {
      this.checkForEarlyDecline();
    }
    super.loadNextStep(event);
  }

  checkForEarlyDecline() {
    const baseStateStr = cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE);
    const baseState = getControl(this.formService.form, baseStateStr).value;

    const trackDetails: { [string: string]: string } = {
      baseState,
    };

    const cobExceptionsForSelectedStateArray = CPSP_COB_CODE_EXCEPTIONS_BY_STATE[baseState];
    const classCodeStr = cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE);
    const classOfBusinessValue = getControl(this.formService.form, classCodeStr).value;

    // Early decline if state is Florida, independent of COB selection
    if (CPSP_STATE_EXCEPTIONS.includes(baseState)) {
      trackDetails.declineReason = EARLY_DECLINE_REASONS.FLORIDA_STATE;
      trackDetails.classOfBusinessCode = classOfBusinessValue ? classOfBusinessValue.code : null;

      this.amplitudeService.trackWithOverride({
        eventName: 'early_quote_decline',
        detail: 'liberty_mutual-cpsp',
        payloadOverride: trackDetails,
      });
      this.earlyDeclineErrors.push(EARLY_DECLINE_REASONS.FLORIDA_STATE);
      this.quoteEarlyDeclinedModalOpen = true;
    } else if (cobExceptionsForSelectedStateArray && classOfBusinessValue) {
      const classOfBusinessCode = classOfBusinessValue.code;

      const cobExceptionFound = cobExceptionsForSelectedStateArray.some((exeption) => {
        return exeption === classOfBusinessCode;
      });

      if (cobExceptionFound) {
        trackDetails.declineReason = EARLY_DECLINE_REASONS.COB_STATE_COMBO;
        trackDetails.classOfBusinessCode = classOfBusinessValue.code || null;

        this.amplitudeService.trackWithOverride({
          eventName: 'early_quote_decline',
          detail: 'liberty_mutual-cpsp',
          payloadOverride: trackDetails,
        });

        this.earlyDeclineErrors.push(EARLY_DECLINE_REASONS.COB_STATE_COMBO);
        this.quoteEarlyDeclinedModalOpen = true;
      }
    }
  }

  fillInHappyPath() {
    this.formService.fillInHappyPath();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
