<div class="progress-container">
  <div class="progress-label left-label js-progress-bar-left-label-{{ productType }}">
    <span class="progress-label-percent"> {{ getLeftLabel() }}% </span>
    <span class="progress-label-preference-left">preferred</span>
  </div>
  <div class="book-mix-progress-bar" [ngStyle]="{ '--left-position': midLabelMarkerLeftPosition }">
    <div class="progress-bar-label__left">{{ preferredCount }} policies</div>
    <div class="progress" [style.width]="progressWidth"></div>
    <div class="progress-bar-label__right">{{ nonPreferredCount }} policies</div>
    <div
      class="progress-label mid-label js-progress-bar-middle-label-{{ productType }}"
      [style.left]="midLabelTextPosition"
    >
      <strong>Target:</strong> {{ targetPercentage }}% Preferred
    </div>
  </div>
  <div class="progress-label right-label js-progress-bar-right-label-{{ productType }}">
    <span class="progress-label-preference-right">non-preferred</span>
    <span class="progress-label-percent"> {{ getRightLabel() }}% </span>
  </div>
  <p class="progress-message">
    <strong>{{ getProgressMessage() }}</strong>
    Contact your account manager for more information.
  </p>
</div>
