import { API_V3_BASE } from 'app/constants';
import { ContactPurposeConstants } from 'app/shared/helpers/insured-contact-helpers';
import { AppError } from 'app/shared/quote-error-modal/errors';
import { US_STATES_DICT } from 'app/shared/services/us-state.service';

import {
  QSAttuneWcPaymentPlan,
  PaymentOptionsSelectionName,
  AttuneWcPaymentPlanDetails,
} from 'app/workers-comp/attune/models/payment-plans.model';

const ATTUNE_WC_BASE_API = `${API_V3_BASE}/quotes/attune/workers-comp`;
export const ATTUNE_WC_ELIGIBILITY_CHECK_API = `${ATTUNE_WC_BASE_API}/eligibility-checkpoint`;
export const ATTUNE_WC_QUOTE_API = `${ATTUNE_WC_BASE_API}`;
export const ATTUNE_WC_CLASSCODES_API = `${ATTUNE_WC_BASE_API}/class-codes`;

export const DEFAULT_EMP_LIABILITY_LIMIT = '100_500_100';

export const EMP_LIABILITY_LIMITS_OPTIONS: { [key: string]: string } = {
  [DEFAULT_EMP_LIABILITY_LIMIT]: '$100k / $500k / $100k',
  '500_500_500': '$500k / $500k / $500k',
  '1000_1000_1000': '$1M / $1M / $1M',
};

export const DEFAULT_DEDUCTIBLE_OPTION = '0';
export const DEDUCTIBLE_OPTIONS: { [key: string]: string } = {
  [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
  '500': '$500',
  '1000': '$1,000',
  '1500': '$1,500',
  '2000': '$2,000',
  '2500': '$2,500',
};

export const DEDUCTIBLE_OPTIONS_OK: { [key: string]: string } = {
  [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
  '1000': '$1,000',
  '2000': '$2,000',
  '3000': '$3,000',
  '4000': '$4,000',
  '5000': '$5,000',
};

export const NO_DEDUCTIBLE_STATES = ['MS'];
export const NO_DEDUCTIBLE_STATES_FULL_NAMES = NO_DEDUCTIBLE_STATES.map(
  (state) => US_STATES_DICT[state]
);

export const WAIVER_OF_SUB_OPTIONS: { [key: string]: string } = {
  blanket: 'blanket',
  specific: 'specific',
};

export const ATTUNE_WC_ELIGIBILITY_ERROR: AppError = {
  body: 'This quote is not eligible for this program.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Quote',
  image: 'assets/img/ineligible.png',
};

export const ATTUNE_WC_INVALID_FEIN_ERROR: AppError = {
  body: 'Please correct the FEIN, or contact the Customer Care Team if you believe this is an error.',
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Update FEIN',
  hideDialogBox: true,
  header: 'Incorrect FEIN',
  image: 'assets/img/issue.png',
};

export const ATTUNE_WC_BIND_FEIN_BLOCK: AppError = {
  body: 'We are unable to bind this quote. There is already an in-force Workers Compensation policy for this insured.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Quote',
  image: 'assets/img/ineligible.png',
};

export const ATTUNE_WC_BIND_ERROR: AppError = {
  body: "We're having trouble issuing this policy. Please try again or contact the Customer Care Team if you continue to have this issue.",
  displayBack: true,
  displayRetry: false,
  displayClose: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const DEFAULT_INSTALLMENT_FEE_AFTER_DOWN_PAYMENT = 5;

export const DEFAULT_PAYMENT_PLAN: QSAttuneWcPaymentPlan = 'ONE_PAY';

export const ATTUNE_WC_PAYMENT_PLAN_OPTIONS: Record<
  PaymentOptionsSelectionName,
  QSAttuneWcPaymentPlan
> = {
  'One-time payment': DEFAULT_PAYMENT_PLAN,
  'Ten Payments, 10% Down': 'TEN_PAY_TEN_DOWN',
  'Ten Payments, 25% Down': 'TEN_PAY_25_DOWN',
};

export const ATTUNE_WC_PAYMENT_PLAN_MAPPING: Record<
  QSAttuneWcPaymentPlan,
  AttuneWcPaymentPlanDetails
> = {
  ONE_PAY: {
    downPaymentPercentage: 1,
    premiumThreshold: 0,
    numberOfPayments: 1,
  },
  TEN_PAY_TEN_DOWN: {
    downPaymentPercentage: 0.1,
    premiumThreshold: 2500,
    numberOfPayments: 10,
  },
  TEN_PAY_25_DOWN: {
    downPaymentPercentage: 0.25,
    premiumThreshold: 1000,
    numberOfPayments: 10,
  },
};

/**
 * Keys represent the GW name for the insured contact role. Keys are the UI-friendly label.
 */
export const ATTUNE_WC_INSURED_CONTACT_OPTIONS: ContactPurposeConstants = {
  BillingContact: 'Billing',
  AuditContact:
    'Audit <a class="insured-contact-label-link" href="https://cdn.brandfolder.io/8ATZIINR/at/tv3h74rqf3gqkzgtf2v7hrq/WC_Audit_One_Pager.pdf" target="_blank">Learn more</a>',
  LossControlContact:
    'Loss Control <a class="insured-contact-label-link" href="https://cdn.brandfolder.io/8ATZIINR/at/pzg469fvq5cqxcc9c8qzrj2c/Attune_WC_-_Loss_Control_Procedures.pdf" target="_blank">Learn more</a>',
} as const;

export const EXECUTIVE_ELECTION_ATTESTATION = `I attest that the insured (my client) has complied with the applicable workers' compensation laws of the states shown in item 3.A. of the policy information page, and I will maintain and make available upon request all required documentation in the Agency file.`;

export const HOT_PREFERRED_HAZARD_GROUPS = ['A', 'B'];

export const PREFERRED_HAZARD_GROUPS = ['C', 'D', 'E'];

export const HIGH_HAZARD_GROUPS = ['F', 'G'];

export const ACCOUNT_MANAGERS = [
  {
    name: 'Brittani Garcia',
    bookBalanceLink: 'https://calendly.com/brittanigarcia/attune-work-comp-book-balance',
    states: ['TN', 'AZ', 'CO', 'HI', 'ID', 'MT', 'NM', 'NV', 'OK', 'OR', 'UT', 'WA'],
  },
  {
    name: 'Jannen Hermann',
    bookBalanceLink: 'https://calendly.com/jhermann-_9pu/attune-work-comp-production-review',
    states: ['FL'],
  },
  {
    name: 'Daniel Hoopes',
    bookBalanceLink: 'https://calendly.com/dhoopes/attune-work-comp-book-balance',
    states: ['CT', 'DC', 'DE', 'MA', 'MD', 'ME', 'NH', 'NJ', 'OH', 'PA', 'RI', 'VA', 'VT'],
  },
  {
    name: 'Josh Woolard',
    bookBalanceLink: 'https://calendly.com/jwoolard/attune-work-comp-book-balance',
    states: ['GA', 'SC', 'WV'],
  },
  {
    name: 'Zorie Arilotta',
    bookBalanceLink: 'https://calendly.com/zarilotta/attune-work-comp-book-balance',
    states: ['AL', 'MS', 'NC'],
  },
];
