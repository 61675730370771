import {
  merge as observableMerge,
  combineLatest as observableCombineLatest,
  of as observableOf,
  Observable,
  Subscription,
  BehaviorSubject,
  Subject,
  timer,
  ReplaySubject,
  zip,
} from 'rxjs';
import {
  filter,
  map,
  take,
  distinctUntilChanged,
  debounceTime,
  share,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SentryService } from 'app/core/services/sentry.service';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MOBILE_WIDTH_THRESHOLD, US_DATE_MASK, ISO_DATE_MASK } from 'app/constants';
import {
  BOP_POLICY_PAYMENT_PLAN_OPTIONS,
  BindFormSteps,
  INSTALLMENT_FEE_10PAY,
  INSTALLMENT_FEE_4PAY,
  INSTALLMENT_FEE_FL,
  INSTALLMENT_FEE_BOPV2,
  PREMIUM_CUTOFF_1PAY_ONLY,
  POLICY_PAYMENT_PLAN_IDS,
  BOP_V2_UW_COMPANY,
  BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES,
  BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES_IN_FIRST_PAYMENT,
  TECHNOLOGY_FEE_TEXT_PER_STATE,
} from 'app/features/attune-bop/models/constants';
import { Carrier } from 'app/features/attune-bop/models/constants-carriers';
import { BopGWPolicyPayment } from 'app/features/attune-bop/models/bop-gw-policy-payment';
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import { AttuneBopExcessQuoteService } from 'app/features/attune-bop/services/attune-bop-excess-quote.service';
import { BopGWPolicyPaymentPresenter } from 'app/features/attune-bop/models/bop-gw-policy-payment-presenter';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import {
  minDateExceededValidator,
  maxDateExceededValidator,
  getControl,
  phoneValidator,
  enableDisableControl,
} from 'app/shared/helpers/form-helpers';
import {
  insertNewBrokerContact,
  transformContacts,
  getBrokerContactChanges,
  TransformedContacts,
  getAllPossibleContacts,
  getNewBrokerContactFormSection,
  selectivelyPatchBrokerContactForm,
  ContactListGroup,
} from 'app/shared/helpers/broker-contact-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { CarrierService } from 'app/bop/services/carrier.service';
import { CovTerm, GwAccountContacts } from 'app/bop/guidewire/typings';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import * as _ from 'lodash';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { UserService } from 'app/core/services/user.service';
import { User } from 'app/shared/models/user';
import { InformService } from 'app/core/services/inform.service';
import { BIND_GUIDE_FAQS } from 'app/features/invoices/models/invoices-constants';
import {
  NewInformationalService,
  BOP_BIND_INFORMATIONAL,
  INFORMATIONAL_NAMES,
} from 'app/shared/services/new-informational.service';
import { validateEmailAddress } from '../../models/form-validators';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import {
  ProductCombination,
  AttuneBOPProduct,
  BundleBindAdditionalLineItem,
} from '../../../digital-carrier/models/types';
import {
  AppError,
  BOP_BIND_ERROR,
  BOP_BUNDLE_BIND_ERROR,
  BOP_MORATORIUM_BIND_ERROR,
} from '../../../../shared/quote-error-modal/errors';
import { ErrorModalEmittedEvent } from '../../../../shared/quote-error-modal/quote-error-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';
import { BopQuotePayload } from '../../models/bop-policy';
import { determineBopIndustryGroup } from '../../models/quoting-helpers';

@Component({
  selector: 'app-page.app-page.app-page__form',
  templateUrl: './attune-bop-bind-form-page.component.html',
})
export class AttuneBopBindFormPageComponent implements OnInit, OnDestroy {
  quoteDetailsLoading = true;
  bopQuoteDetails: Subject<(QuoteDetails | null)[]>;
  bopPolicyId: string;
  boundBopPolicyId: string;
  boundBopPolicyTermNumber: string;
  excessPolicyId: string | null;
  contact: BackendContact;
  form: UntypedFormGroup;
  submitted = false;
  showProgressBar = false;
  updatingExcess = false;
  errorModalOpen = false;
  bindError: AppError = BOP_BIND_ERROR;
  isMoratorium: boolean = false;
  displayPriceDiffModal = false;
  paymentPlanOptions = BOP_POLICY_PAYMENT_PLAN_OPTIONS;
  DEFAULT_NUMBER_OF_PAYMENTS = 1;
  bopPolicyPayment$ = new BehaviorSubject<BopGWPolicyPayment | null>(null);
  numberOfPayments$ = new BehaviorSubject<number>(this.DEFAULT_NUMBER_OF_PAYMENTS);
  excessPolicyPayment$ = new BehaviorSubject<BopGWPolicyPayment | null>(null);
  policyPaymentPresenter: BopGWPolicyPaymentPresenter;
  formSteps: BindFormSteps;
  hasExcess = false;
  hasExcessEmployersLiability = false;
  hasExcessCommercialAuto = false;
  excessCLLimit: number | false;
  umuimStates: string[];
  hasUMUIM: boolean;
  umuimCache: CovTerm[];
  umuimPassThrough: CovTerm[] = [];
  focusEL$ = new Subject<string>();
  clickEL$ = new Subject<string>();
  focusCA$ = new Subject<string>();
  clickCA$ = new Subject<string>();
  bindSuccess$ = new Subject();
  carrierService = new CarrierService();
  validationMessageDateAfter: string;
  protected sub: Subscription = new Subscription();
  newTotalCost: number;
  originalTotalCost: number;
  currentEffectiveDate$ = new ReplaySubject<string>(1);
  installmentFeeDownPayment: number;
  installmentFeeAfterDownPayment: number;
  availableContacts: TransformedContacts;
  subscribedContacts: TransformedContacts;
  currentContact: any;
  disabledPaymentPlans: string[];
  isBopPaymentRadioDisabled: boolean;
  bindFirstTimeModalOpen = false;
  bindGuideModalOpen = false;
  bindGuideCompletedEmailTip = false;
  techFeeText: string;
  // Pre-loads video on first open if false
  bindGuideModalFirstTimeClose = false;
  videoWidth: string;
  videoHeight: string;
  bindGuideFAQs: (Faq & { isToggled?: boolean })[] = BIND_GUIDE_FAQS;
  loggedBrokerContactTip = false;
  loggedEmailTip = false;
  isHoveringOnDateTooltip = false;
  isEffectiveDateInPast = false;

  insuredAccount: InsuredAccount;

  protected isBundleBindFlow = false;
  public productsToBind: (ProductCombination | AttuneBOPProduct)[] = [
    { pasSource: 'attune_gw', product: 'bop' },
  ];
  protected additionalLineItems: BundleBindAdditionalLineItem[] = [];

  private static getInstallmentFee(bopPolicyPayment: BopGWPolicyPayment, numInstallments: number) {
    if (bopPolicyPayment.uwCompanyCode === BOP_V2_UW_COMPANY) {
      if (BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES.includes(bopPolicyPayment.policyBaseState)) {
        return 0;
      }
      return INSTALLMENT_FEE_BOPV2;
    }
    if (numInstallments === 3) {
      return INSTALLMENT_FEE_4PAY;
    }
    return INSTALLMENT_FEE_10PAY;
  }

  isNotNull = (x: any): boolean => x !== null;

  constructor(
    public bindService: GWBindService,
    public quoteService: AttuneBopQuoteService,
    protected excessQuoteService: AttuneBopExcessQuoteService,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected formBuilder: UntypedFormBuilder,
    protected amplitudeService: AmplitudeService,
    protected sentryService: SentryService,
    protected newInformationalService: NewInformationalService,
    protected userService: UserService,
    protected onboardingService: OnboardingService,
    protected segmentService: SegmentService
  ) {}

  @HostListener('window: resize')
  onResize() {
    if (window.innerWidth < 450) {
      this.videoWidth = '100vw';
      this.videoHeight = '100vh';
      return;
    }
    this.videoHeight = '450px';
    this.videoWidth = '948px';
  }

  get effectiveDate() {
    return getControl(this.form, 'policyDetails.effectiveDate');
  }

  get excessEffectiveDateEL() {
    return getControl(this.form, 'excessDetails.employersLiability.effectiveDate');
  }

  get excessExpirationDateEL() {
    return getControl(this.form, 'excessDetails.employersLiability.expirationDate');
  }

  get excessEffectiveDateCA() {
    return getControl(this.form, 'excessDetails.commercialAuto.effectiveDate');
  }

  get excessExpirationDateCA() {
    return getControl(this.form, 'excessDetails.commercialAuto.expirationDate');
  }

  get excessULCarrierEL() {
    return getControl(this.form, 'excessDetails.employersLiability.carrier');
  }

  get excessULCarrierCA() {
    return getControl(this.form, 'excessDetails.commercialAuto.carrier');
  }

  showEmailTip() {
    const visible =
      !this.bindGuideCompletedEmailTip &&
      this.newInformationalService.isUserAtBopBindState(
        BOP_BIND_INFORMATIONAL.BIND_EMAIL_INPUT_TIP
      );
    if (visible && !this.loggedEmailTip) {
      this.amplitudeService.track({
        eventName: 'bop_bind_education_email_tip',
        detail: 'bop',
        useLegacyEventName: true,
      });
      this.loggedEmailTip = true;
    }
    return visible;
  }

  completeEmailTip() {
    const emailInput = this.form.get('policyDetails.emailAddress');
    if (emailInput && emailInput.valid) {
      this.bindGuideCompletedEmailTip = true;
    }
  }

  showBrokerContactTip() {
    const visible =
      this.bindGuideCompletedEmailTip &&
      this.newInformationalService.isUserAtBopBindState(
        BOP_BIND_INFORMATIONAL.BIND_EMAIL_INPUT_TIP
      );
    if (visible && !this.loggedBrokerContactTip) {
      this.amplitudeService.track({
        eventName: 'bop_bind_education_broker_contact_tip',
        detail: 'bop',
        useLegacyEventName: true,
      });
      this.loggedBrokerContactTip = true;
    }
    return visible;
  }

  setTechFeeTooltip(baseState: string, effectiveDateStr: string) {
    const effectiveDate = moment(effectiveDateStr, US_DATE_MASK);
    if (!effectiveDate.isValid()) {
      return;
    }

    let techFeesByDate;
    if (baseState in TECHNOLOGY_FEE_TEXT_PER_STATE) {
      techFeesByDate = TECHNOLOGY_FEE_TEXT_PER_STATE[baseState];
    } else {
      techFeesByDate = TECHNOLOGY_FEE_TEXT_PER_STATE['default'];
    }
    techFeesByDate.forEach(([feeEffectiveDate, techFeePercentage, techFeeMax]) => {
      if (moment(feeEffectiveDate, ISO_DATE_MASK).isSameOrBefore(effectiveDate)) {
        this.techFeeText = `This is a fee of ${techFeePercentage}% of the premium, no more than $${techFeeMax}, that offsets the costs related to technology resources used to distribute and service the policy.`;
      }
    });
  }

  completeBrokerContactTip() {
    this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.BIND_EMAIL_INPUT_TIP);
  }

  validateEffectiveDate = (formControl: UntypedFormControl) => {
    const today: moment.Moment = moment().startOf('day');

    const threeMonthsFromToday: moment.Moment = moment().add(3, 'months');

    const minDateValidation = minDateExceededValidator(today)(formControl);
    const maxDateValidation = maxDateExceededValidator(threeMonthsFromToday)(formControl);
    // Validate to ensure that effective date is between today and 3 months in the future.
    if (minDateValidation) {
      return minDateValidation;
    } else if (maxDateValidation) {
      return maxDateValidation;
    } else {
      return null;
    }
  };

  hideEffectiveDateBlink() {
    if (this.hasSeenEffectiveDateInfo()) {
      return;
    }
    this.isHoveringOnDateTooltip = true;
    setTimeout(() => {
      if (!this.isHoveringOnDateTooltip) {
        return;
      }
      this.newInformationalService.incrementValue(
        INFORMATIONAL_NAMES.EFFECTIVE_DATE_RATING_WARNING
      );
    }, 1000);
  }

  cancelEffectiveDateBlinkHide() {
    this.isHoveringOnDateTooltip = false;
  }

  hasSeenEffectiveDateInfo() {
    return (
      this.newInformationalService.getValue(INFORMATIONAL_NAMES.EFFECTIVE_DATE_RATING_WARNING) > 0
    );
  }

  ngOnInit() {
    this.onResize();

    // Do not show the modal (or the rest of the bind education flow) on mobile
    if (
      this.newInformationalService.isUserAtBopBindState(BOP_BIND_INFORMATIONAL.BIND_VIDEO_MODAL) &&
      window.innerWidth > MOBILE_WIDTH_THRESHOLD
    ) {
      this.bindFirstTimeModalOpen = true;
      this.amplitudeService.track({
        eventName: 'bop_bind_education_video_modal',
        detail: 'bop',
        useLegacyEventName: true,
      });
    }

    // Because of the way `moment` interprets dates, a month, or a month and day are technically valid, so the `minLength` validation is a quick fix to ensure an explicit year is included.

    // The default value is today's date and is replaced when policy is fetched
    this.form = this.formBuilder.group({
      policyDetails: this.formBuilder.group({
        effectiveDate: [
          moment().format(US_DATE_MASK),
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            this.validateEffectiveDate,
          ]),
        ],
        confirmEffectiveDate: [false, [Validators.requiredTrue]],
        emailAddress: ['', [Validators.required, validateEmailAddress]],
        additionalEmailAddress: ['', [validateEmailAddress]],
        phoneNumber: ['', [Validators.required, phoneValidator]],
        bindExcess: [{ value: false, disabled: true }, Validators.required],
        brokerContacts: getNewBrokerContactFormSection(),
      }),
      excessDetails: this.formBuilder.group({
        employersLiability: this.formBuilder.group({
          carrier: [null, Validators.required, this.validateCarrier],
          policyNumber: [null],
          effectiveDate: [
            moment().format(US_DATE_MASK),
            Validators.compose([Validators.required, Validators.minLength(6)]),
          ],
          expirationDate: [
            null,
            Validators.compose([Validators.required, Validators.minLength(6)]),
          ],
        }),
        commercialAuto: this.formBuilder.group({
          carrier: [null, Validators.required, this.validateCarrier],
          policyNumber: [null],
          effectiveDate: [
            moment().format(US_DATE_MASK),
            Validators.compose([Validators.required, Validators.minLength(6)]),
          ],
          expirationDate: [
            null,
            Validators.compose([Validators.required, Validators.minLength(6)]),
          ],
        }),
        umuim: this.formBuilder.group({}),
      }),
      numberOfPayments: [this.DEFAULT_NUMBER_OF_PAYMENTS, Validators.required],
    });
    // This field is disabled by default. It may be enabled in the Bundle Bind component
    this.toggleConfirmEffectiveDateField(false);

    this.formSteps = {
      allSteps: ['Policy Details', 'Excess Liability', 'Payment Details'],
      paths: {
        'Policy Details': 'policyDetails',
        'Excess Liability': 'excessDetails',
        'Payment Details': 'numberOfPayments',
      },
      activeSteps: [],
      currentStepIndex: 0,
    };

    this.fetchContacts();

    // Start with Excess disabled.
    this.enableExcessDetailsForm(false, false, false);

    this.setPolicyIds();

    this.bopQuoteDetails = new Subject();

    this.requestQuoteInformation();

    this.bopQuoteDetails.subscribe(([quoteDetails, excessDetails]) => {
      this.setDetailsFromPolicy(quoteDetails as QuoteDetails, excessDetails);
    });

    const bindExcessChange$ = getControl(this.form, 'policyDetails.bindExcess').valueChanges.pipe(
      share()
    );

    bindExcessChange$.subscribe((val) => {
      if (val) {
        this.enableExcessDetailsForm(
          this.hasExcessEmployersLiability,
          this.hasExcessCommercialAuto,
          this.hasUMUIM
        );
      } else {
        this.enableExcessDetailsForm(false, false, false);
      }
      this.syncAllSteps();
    });

    // This allows the payment figures to be initialized and updated based on presence of
    // bopPolicyPayment and selected numberOfPayments.
    observableCombineLatest([
      this.bopPolicyPayment$.pipe(filter(this.isNotNull)),
      this.excessPolicyPayment$,
      bindExcessChange$,
      this.numberOfPayments$,
    ]).subscribe(([bopPolicyPayment, excessPolicyPayment, bindExcess, numberOfPayments]) => {
      this.policyPaymentPresenter = new BopGWPolicyPaymentPresenter(
        bopPolicyPayment,
        bindExcess ? excessPolicyPayment : null,
        numberOfPayments
      );

      let combinedTotalPremium = bopPolicyPayment.totalPremium;
      if (excessPolicyPayment && excessPolicyPayment.totalPremium) {
        combinedTotalPremium = excessPolicyPayment.totalPremium + bopPolicyPayment.totalPremium;
      }

      if (combinedTotalPremium <= PREMIUM_CUTOFF_1PAY_ONLY) {
        this.disabledPaymentPlans = ['Four payments', 'Ten payments'];
        this.isBopPaymentRadioDisabled = true;
      } else {
        this.isBopPaymentRadioDisabled = false;
      }

      const currentState = this.contact.PrimaryAddress.State;
      if (currentState === 'FL') {
        this.installmentFeeDownPayment = INSTALLMENT_FEE_FL;
        this.installmentFeeAfterDownPayment = INSTALLMENT_FEE_FL;
        return;
      }
      this.installmentFeeAfterDownPayment = AttuneBopBindFormPageComponent.getInstallmentFee(
        bopPolicyPayment,
        this.policyPaymentPresenter.installments
      );
      if (
        bopPolicyPayment.uwCompanyCode === BOP_V2_UW_COMPANY &&
        BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES_IN_FIRST_PAYMENT.includes(
          bopPolicyPayment.policyBaseState
        )
      ) {
        this.installmentFeeDownPayment = 0;
      } else {
        this.installmentFeeDownPayment = this.installmentFeeAfterDownPayment;
      }
    });
    this.sub.add(
      observableCombineLatest([
        this.bopPolicyPayment$.pipe(filter(this.isNotNull)),
        this.effectiveDate.valueChanges,
      ]).subscribe(([bopPolicyPayment, effectiveDateStr]) => {
        this.setTechFeeTooltip(bopPolicyPayment.policyBaseState, effectiveDateStr);
      })
    );

    zendeskLeftSnap();
    (<UntypedFormControl>this.form.get('numberOfPayments')).valueChanges.subscribe(
      this.numberOfPayments$
    );

    this.validationMessageDateAfter =
      'Please enter a date between today and three months from today.';
  }

  requestQuoteInformation() {
    this.sub.add(
      // Make quote details requests in serial, and return null (triggering error handling)
      // if either is blank where it was expected to have a value.
      this.bindService
        .getQuoteDetails(this.bopPolicyId)
        .pipe(
          switchMap((detailsResponse: QuoteDetails | null) => {
            if (detailsResponse && detailsResponse.hasExcessPolicy && detailsResponse.linkedJobId) {
              return this.bindService.getQuoteDetails(detailsResponse.linkedJobId).pipe(
                map((excessDetailsResponse: QuoteDetails) => {
                  return [detailsResponse, excessDetailsResponse];
                })
              );
            } else {
              return observableOf([detailsResponse, null]);
            }
          })
        )
        .subscribe(
          (detailsResponses: QuoteDetails[]) => {
            this.bopQuoteDetails.next(detailsResponses);
          },
          (err: Error) => {
            this.showPageRetryGrowl('BOP');
          }
        )
    );
  }

  showPageRetryGrowl(product: 'BOP' | 'Cyber') {
    this.informService.minorErrorToast(
      `We encountered an error while loading information about this ${product} policy. Please try refreshing the page.`,
      null,
      'Failed to retrieve policy.',
      'Retry',
      () => this.requestQuoteInformation(),
      0
    );
  }

  fetchContacts() {
    this.sub.add(
      this.insuredAccountService
        .getAccountContacts(this.route.snapshot.params.accountId)
        .subscribe((accountContacts) => this.setSubscribedContacts(accountContacts))
    );

    this.sub.add(
      this.insuredAccountService
        .getCurrentContact()
        .subscribe((currentContact) => (this.currentContact = currentContact))
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.fetchAvailableContacts(user);
      })
    );
  }

  fetchAvailableContacts(user: User) {
    this.sub.add(
      this.insuredAccountService.getProducerCodeDetails(user.producer).subscribe((producerInfo) => {
        this.availableContacts = transformContacts(
          _.get(producerInfo, 'ProdCodeCommContacts_ATTN.Entry', []),
          'Contact',
          'ProdCodComContactRol_ATTN.Entry',
          'CommunicationType_ATTN'
        );
      })
    );
  }

  private shouldBindExcess() {
    if (!this.hasExcess) {
      return false;
    }
    const excessControl = getControl(this.form, 'policyDetails.bindExcess');
    return excessControl && excessControl.value;
  }

  private enableExcessDetailsForm(
    enableEL: boolean = true,
    enableCA: boolean = true,
    enableUMUIM: boolean = true
  ) {
    const formGroupEL = getControl(this.form, 'excessDetails.employersLiability');
    const formGroupCA = getControl(this.form, 'excessDetails.commercialAuto');
    const formGroupUMUIM = getControl(this.form, 'excessDetails.umuim');
    if (enableEL) {
      formGroupEL.enable();
    } else {
      formGroupEL.disable();
    }

    if (enableCA) {
      formGroupCA.enable();
    } else {
      formGroupCA.disable();
    }

    if (enableUMUIM) {
      formGroupUMUIM.enable();
    } else {
      formGroupUMUIM.disable();
    }
  }

  setSubscribedContacts(contacts: GwAccountContacts) {
    this.subscribedContacts = transformContacts(
      contacts.contacts || [],
      'CommunicationContact_ATTN.Contact',
      'CommunicationContact_ATTN.CommunicationContactRoles.Entry',
      'CommunicationType'
    );
    this.patchContactsValue();
  }

  patchContactsValue() {
    selectivelyPatchBrokerContactForm(
      this.subscribedContacts,
      this.currentContact,
      this.form.get('policyDetails')?.get('brokerContacts') as ContactListGroup
    );
  }

  getAllPossibleContacts(): TransformedContacts {
    return getAllPossibleContacts(
      this.currentContact,
      this.subscribedContacts,
      this.availableContacts
    );
  }

  addBrokerContact() {
    const defaultContactId = Object.keys(this.getAllPossibleContacts())[0];
    insertNewBrokerContact(
      <UntypedFormArray>this.form.get('policyDetails.brokerContacts'),
      defaultContactId
    );
  }

  removeContact(index: number) {
    const brokerContacts = <UntypedFormArray>this.form.get('policyDetails.brokerContacts');
    if (brokerContacts.length > 1) {
      brokerContacts.removeAt(index);
    }
  }

  shouldShowExcessStep(): boolean {
    return (
      this.shouldBindExcess() && (this.hasExcessEmployersLiability || this.hasExcessCommercialAuto)
    );
  }

  private syncAllSteps() {
    this.formSteps.activeSteps = this.shouldShowExcessStep()
      ? this.formSteps.allSteps
      : this.formSteps.allSteps.filter((step) => step !== 'Excess Liability');
  }

  setPolicyIds() {
    this.bopPolicyId = this.route.snapshot.params.policyId;
  }

  setDetailsFromPolicy(bopDetails: QuoteDetails, excessDetails: QuoteDetails | null = null) {
    this.addEffectiveDateToForm(bopDetails.policyStart);
    this.addExpirationDateToForm(bopDetails.policyEnd);
    this.contact = bopDetails.contact;
    this.originalTotalCost = bopDetails.totalCost;
    this.currentEffectiveDate$.next(moment.utc(bopDetails.policyStart).format(US_DATE_MASK));

    if (excessDetails) {
      this._setExcessDetails(excessDetails);
    }
    const excessControl = getControl(this.form, 'policyDetails.bindExcess');
    excessControl.setValue(this.hasExcess);

    this.enableExcessDetailsForm(
      this.hasExcessEmployersLiability,
      this.hasExcessCommercialAuto,
      this.hasUMUIM
    );

    this.bopPolicyPayment$.next(new BopGWPolicyPayment(bopDetails));

    this.toggleOffLoadingState();
    this.syncAllSteps();
  }

  toggleOffLoadingState() {
    this.quoteDetailsLoading = false;
  }

  private _setExcessDetails(excessDetails: QuoteDetails) {
    this.excessPolicyId = excessDetails.id;

    if (excessDetails.hasExcessPolicyDeclined) {
      return;
    }

    this.hasExcess = true;
    const excessControl = getControl(this.form, 'policyDetails.bindExcess');
    excessControl.enable();

    if (excessDetails.excessELClause) {
      this.hasExcessEmployersLiability = true;
    }
    if (excessDetails.excessCAClause) {
      this.hasExcessCommercialAuto = true;
    }

    this.excessCLLimit = excessDetails.excessCLLimit;

    if (excessDetails.excessUMUIMClause) {
      const statesWeCareAboutForBind = ['FL', 'LA', 'NH', 'VT'];
      const patternCodesWeCareAbout = _.flatMap(statesWeCareAboutForBind, (s) => [
        `CUP_UMUIM${s}_SELECTED`,
        `CUP_UMUIM${s}_CUE`,
      ]);
      // Other states we don't need to ask about in the bind we want to keep
      this.umuimPassThrough = excessDetails.excessUMUIMClause.CovTerms.Entry.filter(
        (c: CovTerm) => !patternCodesWeCareAbout.includes(c.PatternCode)
      );
      // Local Cache
      this.umuimCache = excessDetails.excessUMUIMClause.CovTerms.Entry.filter((c: CovTerm) =>
        patternCodesWeCareAbout.includes(c.PatternCode)
      );
      const selectedCodes = this.umuimCache
        .filter((c: CovTerm) => c.CovTermValueForRating_HUSA === 'true')
        .map((c: CovTerm) => c.PatternCode);

      // Only set once, so we don't lose options on back.
      this.umuimStates =
        this.umuimStates ||
        statesWeCareAboutForBind.filter((state: string) =>
          selectedCodes.includes(`CUP_UMUIM${state}_SELECTED`)
        );
    }

    this.hasUMUIM = (this.umuimStates || []).length ? true : false;
    this.excessPolicyPayment$.next(new BopGWPolicyPayment(excessDetails));
  }

  umuimLimit(state: string): number | null {
    const entry = this.umuimCache.find((c) => c.PatternCode === `CUP_UMUIM${state}_CUE`);
    return entry && entry.CovTermValueForRating_HUSA
      ? Number(entry.CovTermValueForRating_HUSA)
      : null;
  }

  // If a policy's effective date is before today , automatically update it to today's date
  addEffectiveDateToForm(policyStart: moment.Moment) {
    const today: moment.Moment = moment.utc().startOf('day');
    const isBeforeToday: boolean = moment.utc(policyStart).isBefore(today);

    if (!isBeforeToday) {
      const effectiveDate = moment.utc(policyStart).format(US_DATE_MASK);
      getControl(this.form, 'policyDetails').patchValue({ effectiveDate });
      getControl(this.form, 'excessDetails.commercialAuto').patchValue({ effectiveDate });
      getControl(this.form, 'excessDetails.employersLiability').patchValue({ effectiveDate });
    }
  }

  addExpirationDateToForm(policyEnd: number) {
    const expirationDate: string = moment(policyEnd).format(US_DATE_MASK);
    getControl(this.form, 'excessDetails.commercialAuto').patchValue({ expirationDate });
    getControl(this.form, 'excessDetails.employersLiability').patchValue({ expirationDate });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    removeZendeskLeftSnap();
  }

  getUIUIMTerms(): CovTerm[] | null {
    const formGroup = this.form.get('excessDetails.umuim');
    if (!formGroup) {
      return null;
    }

    return Object.entries(formGroup.value).reduce(
      (arr: CovTerm[], [state, obj]: [string, { accepted: string; limit: number }]) => {
        return arr.concat([
          {
            CovTermValueForRating_HUSA: String(obj.accepted),
            PatternCode: `CUP_UMUIM${state}_SELECTED`,
          },
          {
            CovTermValueForRating_HUSA: obj.limit ? obj.limit.toString() : null,
            PatternCode: `CUP_UMUIM${state}_CUE`,
          },
        ]);
      },
      []
    );
  }

  addToUMUIMFormGroup(stateGroup: UntypedFormGroup, state: string) {
    const formGroup: UntypedFormGroup | null = <UntypedFormGroup>(
      this.form.get('excessDetails.umuim')
    );
    if (!formGroup) {
      return;
    }
    formGroup.addControl(state, stateGroup);
  }

  handleSubmit() {
    if (this.isCurrentStepValid()) {
      // Hide the broker contact tip, if it was visible on the page
      // This method is a no-op if it was not visible
      this.completeBrokerContactTip();
      this.stepForward();
    } else {
      this.submitted = true;
    }
  }

  private hasUMUIMChanged(umuim: CovTerm[]): boolean {
    return !_.isEqual(_.sortBy(this.umuimCache, 'PatternCode'), _.sortBy(umuim, 'PatternCode'));
  }

  handleUMUIMUpdate(): Observable<boolean> {
    const umuim = this.getUIUIMTerms();
    if (!this.excessPolicyId || umuim === null || !this.hasUMUIMChanged(umuim)) {
      return observableOf(false);
    }
    // Cache the last value sent, so we don't have to update again if nothing changed.
    this.umuimCache = umuim;
    this.updatingExcess = true;
    return this.excessQuoteService
      .patchUMUIM(this.excessPolicyId, [...umuim, ...this.umuimPassThrough])
      .pipe(
        switchMap((newExcessResp) => {
          const policy = newExcessResp.return.SubmissionResponse.SubmissionResults.Entry[0];
          this.excessPolicyId = policy.Period.Job.JobNumber;
          return this.bindService.getQuoteDetails(this.excessPolicyId);
        }),
        map((excessDetails) => {
          this._setExcessDetails(excessDetails);
          this.updatingExcess = false;
          return true;
        })
      );
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    this.quoteService.getTranslatedQuoteV2(this.bopPolicyId).subscribe({
      next: (res: BopQuotePayload) => {
        if (res && res.locations) {
          const bopQuote = res;
          const address = {
            addressLine1: this.contact.PrimaryAddress.AddressLine1,
            addressLine2: this.contact.PrimaryAddress.AddressLine2,
            city: this.contact.PrimaryAddress.City,
            state: this.contact.PrimaryAddress.State,
            zip: this.contact.PrimaryAddress.PostalCode,
          };

          const classCode = {
            business_type: bopQuote?.locations[0]?.buildings[0]?.exposure.businessType,
            classification: bopQuote?.locations[0]?.buildings[0]?.exposure?.classification?.code,
          };
          const industry = {
            industry: bopQuote?.locations[0]?.buildings[0]?.exposure?.classification?.code,
            business_type: bopQuote?.locations[0]?.buildings[0]?.exposure.businessType,
            group: determineBopIndustryGroup(
              bopQuote?.locations[0]?.buildings[0]?.exposure.businessType
            ),
          };

          this.segmentService.track({
            event: eventName,
            properties: {
              product: 'bop',
              carrier: 'attune',
              class_code: classCode,
              naics_code: this.insuredAccount?.naicsCode,
              industry,
              primary_state: bopQuote?.locations[0]?.locationDetails?.state,
              insured_address: address,
              insured_email: this.contact.EmailAddress1,
              business_name: this.insuredAccount.companyName,
            },
          });
        }
      },
      error: (err) => {
        this.sentryService.notify('Unable to get Translated Quote V2 to send segment event', {
          severity: 'error',
          metaData: {
            eventName,
            underlyingError: err,
            underlyingErrorMessage: err?.message,
          },
        });
      },
    });
  }

  submitBindForm() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    this.amplitudeService.track({
      eventName: 'bind_attempt',
      detail: 'bop',
      useLegacyEventName: true,
    });
    if (this.shouldBindExcess()) {
      this.amplitudeService.track({
        eventName: 'bind_attempt',
        detail: 'excess',
        useLegacyEventName: true,
      });
    }

    this.showProgressBar = true;
    const emailAddress = getControl(this.form, 'policyDetails.emailAddress').value;
    const additionalEmailAddress = getControl(
      this.form,
      'policyDetails.additionalEmailAddress'
    ).value;

    const phoneNumber = getControl(this.form, 'policyDetails.phoneNumber').value;
    const { EmailAddress1, EmailAddress2, WorkPhone } = this.contact;

    // If contact details have changed, fetch new contact details
    if (
      emailAddress.trim() !== EmailAddress1 ||
      (additionalEmailAddress && additionalEmailAddress.trim() !== EmailAddress2) ||
      WorkPhone !== phoneNumber.trim().replace(/[^0-9+]/g, '')
    ) {
      this.sub.add(
        this.insuredAccountService
          .get(this.route.snapshot.params.accountId)
          .pipe(take(1))
          .subscribe((account: InsuredAccount) => {
            this.insuredAccount = account;
            this.sendSegmentEvent('Bind Attempted');
            account.phoneNumber = phoneNumber;
            account.emailAddress = emailAddress;
            this.contact.EmailAddress1 = emailAddress;
            this.contact.WorkPhone = phoneNumber;
            if (additionalEmailAddress) {
              account.additionalEmailAddress = additionalEmailAddress;
              this.contact.EmailAddress2 = additionalEmailAddress;
            }

            const accountId = this.route.snapshot.params.accountId;
            const contactChanges = getBrokerContactChanges(
              <UntypedFormArray>this.form.get('policyDetails.brokerContacts'),
              this.subscribedContacts
            );
            const submissionObservables = contactChanges.map((contactChange) => {
              if (contactChange.action === 'follow') {
                return this.insuredAccountService.followAccount(
                  accountId,
                  contactChange.contactId as string,
                  contactChange.roles
                );
              } else if (contactChange.action === 'unfollow') {
                return this.insuredAccountService.unfollowAccount(
                  accountId,
                  contactChange.contactId as string,
                  contactChange.roles
                );
              } else if (contactChange.action === 'create') {
                return this.insuredAccountService.followNewAccount(
                  accountId,
                  _.get(contactChange, 'contactInfo.email', ''),
                  _.get(contactChange, 'contactInfo.firstName', ''),
                  _.get(contactChange, 'contactInfo.lastName', ''),
                  contactChange.roles
                );
              }
              return observableOf(null);
            });
            submissionObservables.push(observableOf(null));
            this.sub.add(
              zip
                .apply(this, submissionObservables)
                .pipe(switchMap(() => this.insuredAccountService.edit(account)))
                .subscribe(
                  () => {
                    this.updateAndPriceCheck(this.contact);
                  },
                  (err: any) => {
                    this.openBopBindErrorModal();
                    return;
                  }
                )
            );
          })
      );
    } else {
      this.updateAndPriceCheck(this.contact);
    }
  }

  updateAndPriceCheck(contact: BackendContact) {
    this.showProgressBar = true;
    const effectiveDate: string = this.effectiveDate.value;

    // If dates are same, continue binding.
    return this.currentEffectiveDate$.subscribe((currentEffectiveDate) => {
      if (currentEffectiveDate === effectiveDate) {
        this.callBindService();
      } else {
        return this.quoteService
          .updateEffectiveDateByIdV3(
            this.bopPolicyId,
            effectiveDate,
            this.route.snapshot.params.accountId
          )
          .subscribe(
            (resp) => {
              if (!resp || 'error' in resp) {
                this.openBopBindErrorModal();
                return;
              }

              const newTotalCost: string | null = resp.totalCost;

              // Total cost might be null at some point.  One example is if a quote is declined
              // while the broker was binding
              if (!newTotalCost) {
                this.openBopBindErrorModal();
                this.sentryService.notify(
                  'Trying to bind a policy with a null totalCost field.  Perhaps the quote was declined while the user was binding',
                  {
                    severity: 'error',
                    metaData: {
                      updateQuoteResponse: resp,
                    },
                  }
                );
                return;
              }
              if (newTotalCost) {
                // Update policy ID to ensure we bind the newly created quote and not the original.
                this.bopPolicyId = resp.id;
                const formattedTotalCost = parseMoney(newTotalCost);
                // If dates are different and prices are diff, display modal

                if (Math.abs(formattedTotalCost - this.originalTotalCost) >= 1) {
                  this.newTotalCost = formattedTotalCost;
                  this.showProgressBar = false;
                  this.displayPriceDiffModal = true;
                  return;
                }
                // If dates are diff, but prices are same, continue to bind.
                return this.callBindService();
              }
            },
            (err) => {
              this.openBopBindErrorModal();
            }
          );
      }
    });
  }

  callBindService(bundleId?: string) {
    this.displayPriceDiffModal = false;
    this.showProgressBar = true;
    const numberOfPayments = (<UntypedFormControl>this.form.get('numberOfPayments')).value;

    const paymentPlansForBindPayload = POLICY_PAYMENT_PLAN_IDS;

    this.amplitudeService.track({
      eventName: 'bind_api_call',
      detail: 'bop',
      useLegacyEventName: true,
    });

    let carrierConfig: QSCarrierConfig | undefined;
    if (this.shouldBindExcess()) {
      const caPublicID = this.excessULCarrierCA.value && this.excessULCarrierCA.value.publicId;
      const elPublicID = this.excessULCarrierEL.value && this.excessULCarrierEL.value.publicId;
      carrierConfig = {
        commercialAutoCarrierId: caPublicID,
        employersLiabilityCarrierId: elPublicID,
      };
      this.amplitudeService.track({
        eventName: 'bind_api_call',
        detail: 'excess',
        useLegacyEventName: true,
      });
    }

    this.insuredAccountService
      .get(this.route.snapshot.params.accountId)
      .pipe(
        take(1),
        switchMap((insuredAccount: InsuredAccount) => {
          return this.bindService.bind(
            paymentPlansForBindPayload[numberOfPayments],
            this.bopPolicyId,
            'BOP',
            carrierConfig,
            undefined,
            bundleId,
            insuredAccount
          );
        }),
        catchError((error: HttpErrorResponse) => {
          return observableOf(null);
        })
      )
      .subscribe((resp) => {
        const bindResponse = resp && resp.success && resp.bindResponse;
        if (resp && resp.success) {
          this.boundBopPolicyId = _.get(bindResponse, '[0].data.policyId');
          this.boundBopPolicyTermNumber = _.get(bindResponse, '[0].data.policyTermNumber');

          this.amplitudeService.track({
            eventName: 'bind',
            detail: 'bop',
            useLegacyEventName: true,
          });
          if (this.shouldBindExcess()) {
            this.amplitudeService.track({
              eventName: 'bind',
              detail: 'excess',
              useLegacyEventName: true,
            });
          }

          this.handleBindSuccess();
        } else {
          if (resp && 'isMoratoriumError' in resp && resp.isMoratoriumError) {
            this.bindError = BOP_MORATORIUM_BIND_ERROR;
            this.isMoratorium = true;
          }
          // If we don't have a resp here (from bop or xs), we had an error
          this.openBopBindErrorModal();
        }
      });
  }

  openBopBindErrorModal() {
    if (this.isBundleBindFlow) {
      this.bindError = BOP_BUNDLE_BIND_ERROR;
    }
    this.showProgressBar = false;
    this.errorModalOpen = true;
  }

  handleBindSuccess() {
    this.bindSuccess$.next(true);

    timer(3000).subscribe(() => {
      this.navigateToPolicyPage(this.boundBopPolicyId, this.boundBopPolicyTermNumber);
    });
  }

  getCurrentFormStep(): string {
    return this.formSteps.activeSteps[this.formSteps.currentStepIndex];
  }

  isCurrentStep(step: string) {
    return this.getCurrentFormStep() === step;
  }

  isNavigable(step: string | undefined, index: number) {
    if (step) {
      index = this.formSteps.activeSteps.indexOf(step);
    }
    return index < this.formSteps.currentStepIndex;
  }

  handleStepClicked(step: string) {
    const goToIndex: number = this.formSteps.activeSteps.indexOf(step);
    if (this.isNavigable(undefined, goToIndex)) {
      this.goTo(goToIndex);
    }
  }

  navigateToPolicyPage(policyId: string, policyTermNumber: string) {
    this.insuredAccountService.cachebust();
    // Note: If policy Id is not passed in, then just navigate to the account page.
    if (!policyId || !policyTermNumber) {
      this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
      return;
    }
    // Note: Default to first term (1)
    this.router.navigate(
      ['/accounts', this.route.snapshot.params.accountId, 'terms', policyId, policyTermNumber],
      {
        queryParams: {
          waitForInvoice: 'true',
        },
      }
    );
  }

  onCloseErrorModal(event: ErrorModalEmittedEvent) {
    if (event.retry && this.isMoratorium) {
      this.errorModalOpen = false;
      this.showProgressBar = true;

      const numberOfPayments = (<UntypedFormControl>this.form.get('numberOfPayments')).value;

      const paymentPlansForBindPayload = POLICY_PAYMENT_PLAN_IDS;
      this.bindService
        .createMoratoriumZendeskTicket(
          paymentPlansForBindPayload[numberOfPayments],
          this.bopPolicyId
        )
        .pipe(
          catchError(() => observableOf(null)),
          switchMap(() => {
            this.bindSuccess$.next(true);
            return timer(3000);
          })
        )
        .subscribe(() => {
          this.goBackToAccount();
        });
    } else {
      this.goBackToAccount();
    }
  }

  goBackToAccount() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  isFinalStep() {
    return this.formSteps.currentStepIndex === this.formSteps.activeSteps.length - 1;
  }

  goTo(index: number) {
    scrollToTop();
    this.submitted = false;
    this.formSteps.currentStepIndex = index;
  }

  stepForward() {
    if (this.isFinalStep()) {
      return;
    }

    if (this.isCurrentStep('Excess Liability')) {
      this.handleUMUIMUpdate().subscribe(() => {
        this.goTo(this.formSteps.currentStepIndex + 1);
      });
    } else {
      this.goTo(this.formSteps.currentStepIndex + 1);
    }
  }

  stepBackward() {
    if (this.formSteps.currentStepIndex === 0) {
      return;
    }

    this.goTo(this.formSteps.currentStepIndex - 1);
  }

  isCurrentStepValid() {
    const currentStep = this.getCurrentFormStep();

    if (!currentStep) {
      return false;
    }
    if (!getControl(this.form, this.formSteps.paths[currentStep]).valid) {
      return false;
    }

    return true;
  }

  formatSearchCarrierResults = (c: Carrier) => {
    return c.name;
  };

  hideFirstTimeModal() {
    this.bindFirstTimeModalOpen = false;
    this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.BIND_VIDEO_MODAL);
  }

  searchCarrier =
    (focus$: Subject<string>, click$: Subject<string>) => (query$: Observable<string>) => {
      const debouncedText$ = query$.pipe(debounceTime(200), distinctUntilChanged());

      return observableMerge(debouncedText$, focus$, click$).pipe(
        map((query) => {
          return this.carrierService.lookup(query);
        })
      );
    };

  validateCarrier = (control: AbstractControl) => {
    const val = control.value;
    const result = this.carrierService.isValidCarrier(val.publicId)
      ? null
      : { 'invalid carrier': true };
    return observableOf(result);
  };

  toggleModal() {
    this.displayPriceDiffModal = !this.displayPriceDiffModal;
  }

  playBindVideo() {
    this.bindGuideModalOpen = true;
  }

  toggleBindVideo() {
    if (this.bindGuideModalOpen) {
      this.bindGuideModalFirstTimeClose = true;
    }
    this.bindGuideModalOpen = !this.bindGuideModalOpen;
  }

  getBindQuoteFormParams() {
    return {
      effectiveDate: this.effectiveDate.value,
      accountNumber: this.route.snapshot.params.accountId,
      quoteNumber: this.route.snapshot.params.policyId,
    };
  }

  updateIsEffectiveDateInPast() {
    let effectiveDate: string = getControl(this.form, 'policyDetails.effectiveDate').value;
    // Date parse logic mirrors the date mask
    if (effectiveDate && (effectiveDate.length <= 6 || effectiveDate.length === 8)) {
      const parsedDate = moment(effectiveDate, 'MM/DD/YY').format(US_DATE_MASK);
      if (parsedDate !== 'Invalid date') {
        effectiveDate = parsedDate;
      } else {
        effectiveDate = '';
      }
    }

    if (effectiveDate) {
      const today = moment().startOf('day');
      const effectiveDateMomentObj = moment(effectiveDate, US_DATE_MASK).startOf('day');

      if (effectiveDateMomentObj.isBefore(today)) {
        this.isEffectiveDateInPast = true;
      } else {
        this.isEffectiveDateInPast = false;
      }
    }
  }

  totalDollars(): number {
    return this.policyPaymentPresenter.estimatedTotalIntegral;
  }

  totalCents(): number {
    return this.policyPaymentPresenter.estimatedTotalFractional;
  }

  totalTax() {
    return this.policyPaymentPresenter.downPaymentTax;
  }

  downPaymentSubtotal() {
    return this.policyPaymentPresenter.downPaymentSubtotal + this.installmentFeeDownPayment;
  }

  getAdditionalLineItems(): BundleBindAdditionalLineItem[] {
    return [];
  }

  toggleConfirmEffectiveDateField(enable: boolean) {
    const confirmEffectiveDateControl = getControl(this.form, 'policyDetails.confirmEffectiveDate');
    enableDisableControl(confirmEffectiveDateControl, enable);
  }
}
